import { Pie, Bar } from 'react-chartjs-2';
import {Chart} from 'chart.js';

import 
{
    Select,
    MenuItem,
    Divider,
    Button
} from '@mui/material';
import Skeleton from '@material-ui/lab/Skeleton';

import React,
{ 
    useState,
} from 'react';
import { History, LocationState } from "history";

import 
{ 
    makeStyles 
} from '@material-ui/core/styles';

import 
{ 
    DateRange, 
} from '@mui/lab/DateRangePicker';

import
{
    DesktopDateRangePicker,
    DateRangePickerDayProps, 
    DateRangePickerDay
} from '@mui/lab';
import UsersAnalyticsEnhancedTable, { UserPerCompanyAnalytic } from '../../UsersAnalyticsEnhancedTable';

interface IAppUsersAnalyticsProps
{
    history?: History<LocationState>,
    classes?: any,
}

// interface IAppUsersAnalyticsState 
// {
    
// }

const useStyles = makeStyles
(
    {
        root: 
        {
            padding: '10px',
            height: '100%',
            // width: '100%'
        },
        visuallyHidden: 
        {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        selectRoot:
        {
            color: '#FFFFFF !important',
            '& fieldset':
            {
                borderColor: 'rgba(255, 255, 255, 0.23) !important'
            },
            '& svg':
            {
                color: 'white',
            }
        },
        filtersArea:
        {
            marginBottom: '5px',
            display: 'flex',
            flexDirection: 'row-reverse',
            '& *:not(:first-child)':
            {
                marginLeft: '5px'
            }
        },
        menu:
        {
            backgroundColor: '#232427 !important',
            color: '#FFF !important'
        },
        analyticChartSkeletonFitContent:
        {
            maxWidth: '100%',
            width: '100%'
        },
        analyticChartSkeletonText:
        {
            height: 'auto',
            transform: 'scale(1, 1)',
            marginTop: 0,
            borderRadius: '4px',
            marginBottom: 0,
            transformOrigin: '0 60%'
        },
        analytics:
        {
            backgroundColor: '#202225',
            border: '1px #1B1B1B solid',
            borderRadius: '5px',
            padding: '5px',
            // '& * div:not(:first-child)':
            // {
            //     marginTop: '10px'
            // }
        },
        chartRoot:
        {
            width: '100%',
            display: 'flex',
            flexDirection: 'column' as const
        },
        innerRoot:
        {
            display: 'flex',
            flexDirection: 'row' as const,
            padding: '15px',
            '& > *':
            {
                width: '50%'
            },
        },
        chartHeader:
        {
            display: 'flex',
            flexDirection: 'row' as const,
            justifyContent: 'space-between',
            '& h3':
            {
                margin: '0'
            }
        },
        titleStyle:
        {
            color: 'white',
            fontSize: '1em',
            paddingTop: '10px',            
        },
        dividerRoot:
        {
            backgroundColor: 'rgba(255, 255, 255, 0.12)'
        },
        dateRangePicker:
        {
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center'
        },
        dateRangePickerRoot:
        {
            '& .MuiPaper-root':
            {
                color: '#FFFFFF',
                backgroundColor: 'rgb(14, 15, 15)',
            },
            '& .MuiPickersCalendarHeader-dayLabel':
            {
                color: '#FFFFFF80'
            },
            '& .MuiPickersDay-root':
            {
                backgroundColor: 'transparent'
            },
            '& .MuiTypography-root':
            {
                color: 'rgb(232, 230, 227)'
            },
            '& .PrivatePickersYear-yearButton:disabled':
            {
                color: 'rgba(255, 255, 255, 0.54) !important',
            },
            '& .MuiPaper-root > div > div > div:not(:last-of-type)':
            {
                borderRight: '2px solid rgba(48, 52, 54, 0.12) !important'
            }
        },
        datePickerIconButton:
        {
            color: '#FFFFFF !important',
            // backgroundColor: '#424242 !important',
            '&:disabled':
            {
                color: 'rgba(255, 255, 255, 0.54) !important',
            }
        },
        datePickerDay:
        {
            color: '#FFFFFF'
        },
        userChartLabelRoot:
        {
            display: 'flex',
            flexDirection: 'row',
            paddingRight: '15px'
        },
        platformButtonRoot:
        {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start !important',
            cursor: 'auto !important'
        },
        platformButtonTitle:
        {
            color: '#bdbdbd',
            fontSize: '11px',
            lineHeight: '16px',
            letterSpacing: '.8px',
            fontWeight: 500,
            fontFamily: 'Roboto, Arial, Helvetica, sans-serif'
        },
        platformButtonPercentage:
        {
            color: 'white',
            fontSize: '22px',
            lineHeight: '28px',
            fontWeight: 400,
            fontFamily: '"Google Sans", Arial, Helvetica, sans-serif'
        },
        userLast30MinHeader:
        {
            display: 'flex',
            flexDirection: 'column' as const,
            color: 'white',
            // justifyContent: 'space-between',
            '& h5':
            {
                color: '#bdbdbd',
                fontSize: '0.83em',
                margin: '0'
            }
        },
        userLast30MinText:
        {
            fontSize: '32px'
        }
        // chartsRoot:
        // {
        //     display: 'flex',
        //     flexDirection: 'row',
        //     // '& *':
        //     // {
        //     //     width: '50%'
        //     // },
        //     // '&:not(:first-child)':
        //     // {
        //     //     marginLeft: '50px'
        //     // }
        // }
    }
);

interface IAnalyticsFilter
{
    id: string,
    app: string
}

interface IUsersAnalytics
{
    platform: string,
    data:
    {
        userCount: number | string
    }
}

interface IUsersRealTimeAnalytics
{
    platform: string,
    data:
    {
        minutesAgo: number | string,
        userCount: number | string
    }
}

export default function AppUsersAnalytics(props: IAppUsersAnalyticsProps)
{
    const classes = useStyles();
    // const [isLogsLoading, setIsAnalyticsLoading] = useState<boolean>(false);
    const [isAnalyticsLoading, setIsAnalyticsLoading] = useState<boolean>(false);
    const [isTableAnalyticsLoading, setIsTableAnalyticsLoading] = useState<boolean>(false);
    // const [_usersAnalytics, setUsersAnalytics] = useState<Array<IUsersAnalytics>>([]);
    const [analyticsFilters, setAnalyticsFilters] = useState<Array<IAnalyticsFilter>>([{app: 'Todos', id: 'todos'}]);
    const [analyticsFilter, setAnalyticsFilter] = useState<string>('todos');
    const [chartsAnalyticsLabel, setChartsAnalyticsLabel] = useState<Array<string>>([]);
    const [chartsAnalyticsData, setChartsAnalyticsData] = useState<Array<number>>([]);
    // const [chartsAnalyticsScatterData, setChartsAnalyticsScatterData] = useState<Array<{x:number, y: number}>>([]);
    const [dateRangeFilterValue, setDateRangeFilterValue] = React.useState<DateRange<Date>>([new Date(Date.now()-(1000*60*60*24*28)), new Date()]);
    // const [dateRangeFilterHasChanged, setDateRangeFilterHasChanged] = React.useState<boolean>(false);
    const [sessionsPerCompanyAnalytics, setSessionsPerCompanyAnalytics] = useState<{[key in string]:Array<UserPerCompanyAnalytic>}>({});
    const [sessionsPerCompanyType, setSessionsPerCompanyType] = useState<keyof typeof sessionsPerCompanyAnalytics>('Dev');
    
    const [openDateRange, setOpenDateRange] = useState<boolean>(false);

    const maxBarChart = 5;

    React.useEffect
    (
        () =>
        {
            var searchParams = new URLSearchParams(window.location.search);
            let dateStart: Date | null = null;
            let dateEnd: Date | null = null;
            var dateStartStr = searchParams.get('dateStart');
            var dateEndStr = searchParams.get('dateEnd');
            var app = searchParams.get("app");
            var wsType = searchParams.get("ws_type");
            if(dateStartStr)
            {
                var parts = dateStartStr.split('-');
                if(parts.length >= 3)
                {
                    dateStart = new Date(Date.UTC(Number(parts[0]), Number(parts[1]), Number(parts[2])));
                }
            }
            if(dateEndStr)
            {
                var parts2 = dateEndStr.split('-');
                if(parts2.length >= 3)
                {
                    dateEnd = new Date(Date.UTC(Number(parts2[0]), Number(parts2[1]), Number(parts2[2])));
                }
            }
            dateStart = dateStart ?? dateRangeFilterValue[0];
            dateEnd = dateEnd ?? dateRangeFilterValue[1];

            setDateRangeFilterValue
            (
                [
                    dateStart,
                    dateEnd,
                ]
            )
            setAnalyticsFilter(app ?? analyticsFilter);
            setSessionsPerCompanyType(wsType ?? 'Dev');
            getUsersAnalytics();
            getAnalyticsFilters();
            getSessionsPerCompanyAnalytics();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    function getUsersAnalytics()
    {
        setIsAnalyticsLoading(true);
        let dayFilter = '';
        if(!dateRangeFilterValue.includes(null))
        {   
            dateRangeFilterValue[0]?.toJSON().substring(0, dateRangeFilterValue[0]?.toJSON().indexOf('T'));

            dayFilter = `startDate=${dateRangeFilterValue[0]?.toJSON().substring(0, dateRangeFilterValue[0]?.toJSON().indexOf('T'))}&endDate=${dateRangeFilterValue[1]?.toJSON().substring(0, dateRangeFilterValue[1]?.toJSON().indexOf('T'))}`
        }
        fetch
        (
            `/api/google/users/app?streamId=${analyticsFilter}${dayFilter.length > 0 ? `&${dayFilter}` : ''}`, 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    var data = await response.json();
                    var dataArray: Array<IUsersAnalytics> = [];
                    
                    for(let i = 0; i < data.length; i++)
                    {
                        dataArray.push
                        (
                            {
                                platform: data[i].platform,
                                data: 
                                {
                                    userCount: data[i].data.userCount
                                }
                            }
                        )
                    }
                    
                    var arrayLabel = [];
                    var arrayData = [];
                    // var scatterData = [];
                    if(dataArray.length > 0)
                    {
                        dataArray.sort((a, b)=> Number(b.data.userCount) - Number(a.data.userCount));
                        
                        for(let i = 0; i < (maxBarChart > dataArray.length ? dataArray.length : maxBarChart) ; i++)
                        {
                            arrayLabel.push(dataArray[i].platform);
                            arrayData.push(Number(dataArray[i].data.userCount));
                            // scatterData.push
                            // (
                            //     {
                            //         x: Number(dataArray[i].screenPageViews),
                            //         y: Number(dataArray[i].totalUsers)
                            //     }
                            // )
                        }
                    }
                    setChartsAnalyticsData(arrayData);
                    setChartsAnalyticsLabel(arrayLabel);
                    // setChartsAnalyticsScatterData(scatterData);
                    // setUsersAnalytics(dataArray);
                    setIsAnalyticsLoading(false);
                }
            } 
        );
    }

    function getAnalyticsFilters()
    {
        setIsAnalyticsLoading(true);
        fetch
        (
            `/api/google/analytic/apps`, 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    var data = await response.json();
                    var dataArray: Array<IAnalyticsFilter> = [];
                    dataArray.push
                    (
                        {
                            app: 'Todos',
                            id: 'todos'
                        }
                    )
                    for(let i = 0; i < data.length; i++)
                    {
                        dataArray.push
                        (
                            {
                                app: data[i].app,
                                id: data[i].id,
                            }
                        )
                    }
                    setAnalyticsFilters(dataArray);
                    setIsAnalyticsLoading(false);
                }
            } 
        );
    }

    function getSessionsPerCompanyAnalytics()
    {
        setIsTableAnalyticsLoading(true);
        let dayFilter = '';
        if(!dateRangeFilterValue.includes(null))
        {   
            dateRangeFilterValue[0]?.toJSON().substring(0, dateRangeFilterValue[0]?.toJSON().indexOf('T'));

            dayFilter = `startDate=${dateRangeFilterValue[0]?.toJSON().substring(0, dateRangeFilterValue[0]?.toJSON().indexOf('T'))}&endDate=${dateRangeFilterValue[1]?.toJSON().substring(0, dateRangeFilterValue[1]?.toJSON().indexOf('T'))}`
        }
        fetch
        (
            `/api/internal/usuariosEmpresa?streamId=${analyticsFilter}${dayFilter.length > 0 ? `&${dayFilter}` : ''}`, 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    var data = await response.json();
                    var parsedData: {[key in string]:Array<UserPerCompanyAnalytic>} = {};
                    var dataKeys = Object.keys(data);
                    for(let i = 0; i < dataKeys.length; i++)
                    {
                        var arr: Array<UserPerCompanyAnalytic> = [];
                        for(let j = 0; j < data[dataKeys[i]].length; j++)
                        {
                            arr.push(UserPerCompanyAnalytic.fromJSON(data[dataKeys[i]][j]));
                        }
                        parsedData[dataKeys[i]] = arr;
                        if(dataKeys[i] === 'Prod')
                        {
                            setSessionsPerCompanyType(dataKeys[i]);
                        }
                    }
                    setSessionsPerCompanyAnalytics(parsedData);
                    setIsTableAnalyticsLoading(false);
                }
            } 
        );
    }

    function generatePlatformFilters()
    {
        var arr = [];
        var totalCount = chartsAnalyticsData.reduce
        (
            (accumulator, a) =>
            {
                return accumulator + a;
            },
            0
        );
        for(let i = 0; i < chartsAnalyticsData.length; i++)
        {
            arr.push
            (
                <Button
                    key={`plataform-filters-${i}`}
                    onClick=
                    {
                        () => {}
                    }
                    classes=
                    {
                        {
                            root: classes.platformButtonRoot
                        }
                    }
                >
                    <span className={classes.platformButtonTitle}>{chartsAnalyticsLabel[i]}</span>
                    <span className={classes.platformButtonPercentage}>{`${((chartsAnalyticsData[i]/totalCount)*100).toFixed(1)}%`}</span>
                </Button>
            )
        }
        return arr;
    }

    function getSessionsPerCompanyTable(): Array<UserPerCompanyAnalytic>
    {
        var data: Array<UserPerCompanyAnalytic> = sessionsPerCompanyAnalytics[sessionsPerCompanyType] ?? [];
        return data;
    }

    // const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    // const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => 
    // {
    //     onRequestSort(event, property);
    // };

    return (
        <div>
            <div className={classes.filtersArea}> 
                <Select
                    label="App"
                    value={analyticsFilter}
                    onChange=
                    {
                        (e) => 
                        {
                            // console.log(e.target.value);
                            setAnalyticsFilter(e.target.value);
                            if(props.history !== undefined)
                            {
                                var searchParams = new URLSearchParams(window.location.search);
                                var url = '';
                                if(e.target.value !== 'todos')
                                {
                                    searchParams.set("app", `${e.target.value}`);    
                                }
                                else
                                {
                                    searchParams.delete('app');
                                }
                                url = window.location.pathname + '?' + searchParams.toString();
                                props.history.push(url);
                            }
                            getUsersAnalytics();
                        }
                    }
                    classes=
                    {
                        {
                            root: classes.selectRoot,
                        }
                    }
                    MenuProps=
                    {
                        {
                            PopoverClasses:
                            {
                                paper: classes.menu
                            }
                        }
                    }
                >
                    {
                        analyticsFilters.map
                        (
                            (filter) =>
                            {
                                // console.log(filter);
                                return ( 
                                    <MenuItem key={`analytics-filter-${filter.id}`} value={filter.id}>{filter.app}</MenuItem>
                                );
                            }
                        )
                    }
                </Select>
                <div
                    className={classes.dateRangePicker}
                >
                    <DesktopDateRangePicker 
                        startText="Inicio"
                        endText='Fim'
                        value={dateRangeFilterValue}
                        open={openDateRange}
                        PopperProps=
                        {
                            {
                                className: classes.dateRangePickerRoot,
                                // anchorOrigin: 
                                // {
                                //     vertical: -50,
                                //     horizontal: 198
                                // },
                                // PaperProps:
                                // {
                                //     classes:
                                //     {
                                //         root: this.classes.datePickerPaperRoot,
                                //     }
                                // }
                            }
                        }
                        onChange=
                        {
                            (newValue) => 
                            {
                                setDateRangeFilterValue(newValue);
                                // setDateRangeFilterHasChanged(true);
                                
                            }
                        }
                        onClose=
                        {
                            ()=>
                            {
                                setOpenDateRange(false);
                                // if(dateRangeFilterHasChanged)
                                // {
                                //     console.log(dateRangeFilterValue);
                                //     setDateRangeFilterHasChanged(false);
                                //     getScreenAnalytics();
                                // }
                            }
                        }
                        onAccept=
                        {
                            (dateRange)=> 
                            {
                                if(props.history !== undefined && dateRange.length >= 2)
                                {
                                    var searchParams = new URLSearchParams(window.location.search)
                                    var url = '';
                                    searchParams.set("dateStart", `${dateRange[0]?.toJSON().substring(0, dateRange[0]?.toJSON().indexOf('T'))}`); 
                                    searchParams.set("dateEnd", `${dateRange[1]?.toJSON().substring(0, dateRange[1]?.toJSON().indexOf('T'))}`); 
                                    url = window.location.pathname + '?' + searchParams.toString();
                                    props.history.push(url);
                                    getUsersAnalytics();
                                }
                            }
                        }
                        renderInput=
                        {
                            (startProps, endProps) => 
                            {
                                let formattedDate = '';
                                let dateStart = dateRangeFilterValue[0];
                                let dateEnd = dateRangeFilterValue[1];
                                if(dateStart?.getFullYear() !== dateEnd?.getFullYear())
                                {
                                    formattedDate = dateStart?.toLocaleString
                                    (
                                        'pt-BR', 
                                        {
                                            month: 'long', // numeric, 2-digit, long, short, narrow
                                            day: '2-digit', // numeric, 2-digit
                                            year: 'numeric', // numeric, 2-digit// numeric, 2-digit
                                        }
                                    ) ?? '';
                                }
                                else
                                {
                                    formattedDate = dateStart?.toLocaleString
                                    (
                                        'pt-BR', 
                                        {
                                            month: 'long', // numeric, 2-digit, long, short, narrow
                                            day: '2-digit', // numeric, 2-digit
                                        }
                                    ) ?? '';
                                }
                                formattedDate = `${formattedDate} - ${dateEnd?.toLocaleString
                                (
                                    'pt-BR', 
                                    {
                                        month: 'long', // numeric, 2-digit, long, short, narrow
                                        day: '2-digit', // numeric, 2-digit
                                        year: 'numeric', // numeric, 2-digit// numeric, 2-digit
                                    }
                                )}`;
                                return (
                                    <React.Fragment>
                                        <Button
                                            variant="text"
                                            style=
                                            {
                                                {
                                                    textTransform: 'capitalize',
                                                    color: 'white',
                                                    fontWeight: 'bold',
                                                    textAlign: 'right'
                                                }
                                            }
                                            onClick=
                                            {
                                                ()=>
                                                {
                                                    setOpenDateRange(true);
                                                }
                                            }
                                        >
                                            {
                                                formattedDate
                                            }
                                        </Button>

                                        {/* <TextField {...startProps} />
                                        <Box sx={{ mx: 2 }}> até </Box>
                                        <TextField {...endProps} /> */}
                                    </React.Fragment>
                                );
                            }
                        }
                        componentsProps=
                        {
                            {
                                leftArrowButton:
                                {
                                    classes:
                                    {
                                        root: classes.datePickerIconButton
                                    }
                                },
                                rightArrowButton:
                                {
                                    classes:
                                    {
                                        root: classes.datePickerIconButton
                                    }   
                                },
                                switchViewButton:
                                {
                                    classes:
                                    {
                                        root: classes.datePickerIconButton
                                    }   
                                }
                            }
                        }
                        renderDay=
                        {
                            (
                                date: Date,
                                dateRangePickerDayProps: DateRangePickerDayProps<Date>
                            ) => 
                            {
                                
                                return (
                                    <DateRangePickerDay 
                                        {...dateRangePickerDayProps as DateRangePickerDayProps<Date>}
                                        style=
                                        {
                                            dateRangePickerDayProps.selected
                                            ?
                                                {
                                                    color: 'rgb(232, 230, 227)',
                                                    backgroundColor: 'rgb(6, 66, 115)'
                                                        
                                                }
                                            :
                                                {
                                                    color: '#FFFFFF'
                                                }
                                        }
                                    />
                                );
                            }
                        }
                    />
                </div>
            </div>
            {
                
                <div className={classes.analytics}>
                    {
                        isAnalyticsLoading
                        ?
                            <Skeleton 
                                animation="wave" 
                                classes=
                                {
                                    {
                                        fitContent: classes.analyticChartSkeletonFitContent,
                                        text: classes.analyticChartSkeletonText
                                    }
                                }
                            >
                                <div 
                                    style=
                                    {
                                        {
                                            minHeight: '270px',
                                            borderRadius: '4px',
                                            minWidth: '100%'
                                        }
                                    }
                                >

                                </div>
                            </Skeleton> 
                        :   
                            (
                                <div id={'chartsection'} className={classes.chartRoot}>
                                    <div className={classes.innerRoot}>
                                        <div>
                                            <div className={classes.chartHeader}>
                                                <h3 className={classes.titleStyle}>
                                                    {`Usuários por Plataforma`}
                                                </h3>
                                            </div>
                                            <div
                                                className={classes.userChartLabelRoot}
                                            >
                                                <div
                                                    style=
                                                    {
                                                        {
                                                            margin: '5px'
                                                        }
                                                    }
                                                >
                                                    <Pie  
                                                        id={'users_per_platform'}
                                                        style=
                                                        {
                                                            {
                                                                maxHeight: '200px',
                                                                maxWidth: '200px'
                                                                // maxWidth: '50%'
                                                            }
                                                        }
                                                        // width={0}
                                                        height={200}
                                                        options=
                                                        {
                                                            {
                                                                maintainAspectRatio: true,
                                                                responsive: true,
                                                                indexAxis: 'y',
                                                                plugins:
                                                                {
                                                                    title:
                                                                    {
                                                                        display: false,
                                                                    },                      
                                                                    legend:
                                                                    {
                                                                        display: false,
                                                                    },
                                                                    // tooltip: 
                                                                    // {
                                                                    //     callbacks: 
                                                                    //     {
                                                                    //         label: function(context: any) 
                                                                    //         {
                                                                    //             return `${context.dataset.label} ${context.parsed.x}`;
                                                                    //         }
                                                                    //     }
                                                                    // },
                                                                    tooltip: 
                                                                    {
                                                                        footerFont:
                                                                        {
                                                                            weight: 'normal'
                                                                        },
                                                                        bodyFont:
                                                                        {
                                                                            weight: 'bold'
                                                                        },
                                                                        displayColors: false,
                                                                        bodySpacing: 10,
                                                                        titleFont: 
                                                                        {
                                                                            weight: 'normal'
                                                                        },
                                                                        titleSpacing: 0,
                                                                        callbacks: 
                                                                        {
                                                                            title: function(context: any)
                                                                            {
                                                                                return 'Plataforma/Usuários';
                                                                            },
                                                                            label: function(context: any) 
                                                                            {
                                                                                var totalCount = chartsAnalyticsData.reduce
                                                                                (
                                                                                    (accumulator, a) =>
                                                                                    {
                                                                                        return accumulator + a;
                                                                                    },
                                                                                    0
                                                                                );

                                                                                return `${context.label} ${context.parsed} (${((context.parsed/totalCount)*100).toFixed(1)}%)`;
                                                                            },
                                                                        }
                                                                    },
                                                                    
                                                                    // responsiveAnimationDuration: 0,
                                                                    // animation: 
                                                                    // {
                                                                    //     // animation: false,
                                                                    //     // duration: 0,
                                                                    //     onComplete: function(this: any) 
                                                                    //     {
                                                                    //         var chartInstance = this;
                                                                    //         var ctx = this.ctx;
                                                            
                                                                    //         // ctx.font = Chart.helpers.fontString
                                                                    //         // (
                                                                    //         //     18,
                                                                    //         //     Chart.defaults.global.defaultFontStyle,
                                                                    //         //     Chart.defaults.global.defaultFontFamily
                                                                    //         // );
                                                                    //         ctx.textAlign = "center";
                                                                    //         ctx.textBaseline = "bottom";
                                                            
                                                                    //         this.data.datasets.forEach
                                                                    //         (
                                                                    //             function(_dataset: any, i: any) 
                                                                    //             {
                                                                    //                 const meta = chartInstance.getDatasetMeta(i);
                                                                    //                 // console.log(meta);
                                                                    //                 // console.log(dataset);
                                                                    //                 meta.data.forEach
                                                                    //                 (
                                                                    //                     function(point: any, index: any) 
                                                                    //                     {
                                                                    //                         // const data = point[i];
                                                                    //                         if(usersAnalytics.length > 0)
                                                                    //                         {
                                                                    //                             ctx.fillStyle = "#fff";
                                                                    //                             ctx.fillText(usersAnalytics[index].platform, point.x, point.y + 20);
                                                                    //                         }
                                                                    //                     }
                                                                    //                 );
                                                                    //             }
                                                                    //         );
                                                                    //     }
                                                                    // },
                                                                },
                                                            }
                                                        }
                                                        data=
                                                        {
                                                            {
                                                                labels: chartsAnalyticsLabel,
                                                                datasets: 
                                                                [
                                                                    {
                                                                        data: chartsAnalyticsData,
                                                                        backgroundColor: '#A6CEE3',
                                                                        borderColor: [ '#A6CEE3' ],
                                                                        borderWidth: 1,
                                                                        label: 'Views'
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                        // data={this.state.data}
                                                    />
                                                </div>
                                                <div
                                                    style=
                                                    {
                                                        {
                                                            width: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'end'
                                                        }
                                                    }
                                                >
                                                    {
                                                        generatePlatformFilters()
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <UsersPerMinuteSection
                                                classes={classes}
                                                analyticsFilter={analyticsFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                    <Divider
                        classes=
                        {
                            {
                                root: classes.dividerRoot
                            }
                        }
                    />
                    {
                        isTableAnalyticsLoading
                        ?
                            <Skeleton 
                                animation="wave" 
                                classes=
                                {
                                    {
                                        fitContent: classes.analyticChartSkeletonFitContent,
                                        text: classes.analyticChartSkeletonText
                                    }
                                }
                            >
                                <div 
                                    style=
                                    {
                                        {
                                            minHeight: '500px',
                                            borderRadius: '4px',
                                            minWidth: '100%'
                                        }
                                    }
                                >

                                </div>
                            </Skeleton> 
                        :
                            (
                                <>
                                    <div className={classes.filtersArea} style={{marginTop: '10px'}}>
                                        <Select
                                            label="Prod/Dev"
                                            value={sessionsPerCompanyType}
                                            onChange=
                                            {
                                                (e) => 
                                                {
                                                    // console.log(e.target.value);
                                                    setSessionsPerCompanyType(e.target.value);
                                                    if(props.history !== undefined)
                                                    {
                                                        var searchParams = new URLSearchParams(window.location.search);
                                                        var url = '';
                                                        if(e.target.value !== '')
                                                        {
                                                            searchParams.set("ws_type", `${e.target.value}`);    
                                                        }
                                                        else
                                                        {
                                                            searchParams.delete('ws_type');
                                                        }
                                                        url = window.location.pathname + '?' + searchParams.toString();
                                                        props.history.push(url);
                                                    }
                                                }
                                            }
                                            classes=
                                            {
                                                {
                                                    root: classes.selectRoot,
                                                }
                                            }
                                            MenuProps=
                                            {
                                                {
                                                    PopoverClasses:
                                                    {
                                                        paper: classes.menu
                                                    }
                                                }
                                            }
                                        >
                                            {
                                                Object.keys(sessionsPerCompanyAnalytics).map
                                                (
                                                    (key) =>
                                                    {
                                                        // console.log(filter);
                                                        return ( 
                                                            <MenuItem key={`analytics-session-per-company=filter-${key}`} value={key}>{key}</MenuItem>
                                                        );
                                                    }
                                                )
                                            }
                                        </Select>
                                    </div>
                                    <UsersAnalyticsEnhancedTable
                                        rows=
                                        {
                                            getSessionsPerCompanyTable()
                                        }
                                    />
                                </>
                            )
                    }
                    
                </div>
                    
            }
            
        </div>
    );
}

interface UsersPerMinuteProps
{
    analyticsFilter: string,
    classes: any
}

function UsersPerMinuteSection(props: UsersPerMinuteProps)
{
    const [usersLast30MinAnalytics, setUsersLast30MinAnalytics] = useState<Array<IUsersRealTimeAnalytics>>([]);
    const chartID = 'last_users_chart';
    const chartLabels = Array.from({length: 30}, (_, i) => i);
    const [chartData, setChartData] = useState<Array<number>>(Array.from({length: 30}, (_, i) => 0));
    const chartRef = React.useRef();
    const [gotFirstData, setGotFirstData] = useState<boolean>(false);
    const usersSpan = React.useRef<HTMLSpanElement>(null);

    React.useEffect
    (
        () =>
        {
            getUsersPlatformAnalyticsLast30MinAnalytics();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    React.useEffect
    (
        () =>
        {
            const sec = 60;
            const interval = setInterval
            (
                () => 
                {
                    getUsersPlatformAnalyticsLast30MinAnalytics();
                }, 
                (sec*1000)
            );
            return () => clearInterval(interval);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    // function setUpSearchAgainTimer()
    // {
    //     const sec = 10;
    //     var timer = setInterval
    //     (
    //         function(timer2)
    //         {
    //             clearInterval(timer2);
    //             getUsersPlatformAnalyticsLast30MinAnalytics();
    //             // updateData([]);
    //             console.log('searching');
    //             setUpSearchAgainTimer();
    //             //console.log((Chart.instances as any as Array<any>).find(x=>x.canvas.id===chartID));
    //         }, 
    //         (sec * 1000),
    //         timer
    //     );
    // }

    function getUsersPlatformAnalyticsLast30MinAnalytics()
    {
        fetch
        (
            `/api/google/users_platform_30_min/app?streamId=${props.analyticsFilter}`, 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    var data = await response.json();
                    
                    var dataArray: Array<IUsersRealTimeAnalytics> = [];
                    for(let i = 0; i < data.length; i++)
                    {
                        dataArray.push
                        (
                            {
                                platform: data[i].platform,
                                data: 
                                {
                                    minutesAgo: data[i].data.minutesAgo,
                                    userCount: data[i].data.userCount
                                }
                            }
                        )
                    }

                    if(dataArray.length > 0)
                    {
                        dataArray.sort((a, b)=> Number(b.data.userCount) - Number(a.data.userCount));
                    }
                    setGotFirstData
                    (
                        (value)=>
                        {
                            // console.log(value);
                            if(!value)
                            {
                                setGotFirstData(true);
                                
                                // console.log(value);
                                setUsersLast30MinAnalytics(dataArray);
                                setChartData
                                (
                                    Array.from
                                    (
                                        {length: 30}, 
                                        (el, i) => 
                                        { 
                                            
                                            var idx = dataArray.findIndex(x=>Number(x.data.minutesAgo) === (29 - i));
                                            // console.log(idx);
                                            return idx >= 0 
                                            ?
                                                Number(dataArray[idx].data.userCount)
                                            :
                                                0
                                        }
                                    )
                                );
                    
                                // updateData(dataArray);
                            }
                            else
                            {
                                // setUsersLast30MinAnalytics(dataArray);
                                updateData(dataArray);
                            }
                            return value;
                        }
                    );
                    
                    //setUsersLast30MinAnalytics(dataArray);
                    //updateData();
                }
            } 
        );
    }

    const updateData = function (data: Array<IUsersRealTimeAnalytics>)
    {
        // console.log(chartRef.current);
        if(chartRef.current !== null && chartRef.current !== undefined)
        {
            // setUsersLast30MinAnalytics(data);
            // console.log(data);
            // console.log((chartRef.current as unknown as Chart).data);
            (chartRef.current as unknown as Chart).data?.labels?.shift();
            (chartRef.current as unknown as Chart).data.datasets.forEach
            (
                (dataset) => 
                {
                    dataset.data.shift();
                }
            );
            (chartRef.current as unknown as Chart).update();
            // console.log((chartRef.current as unknown as Chart).data.labels);
            var idx = data.findIndex(x=>Number(x.data.minutesAgo) === 0);
            (chartRef.current as unknown as Chart).data.labels?.push(0);
            // if(idx >= 0)
            // {
            //     // console.log(idx);
            //     (chartRef.current as unknown as Chart).data.labels?.push(data[idx].data.userCount);
            // }
            // else
            // {
            //     (chartRef.current as unknown as Chart).data.labels?.push(0);
            // }
            
            (chartRef.current as unknown as Chart).data.datasets.forEach
            (
                (dataset) => 
                {
                    dataset.data.push
                    (
                        idx > 0 
                        ? 
                            Number(data[idx].data.userCount) 
                        : 
                            0
                    );
                }
            );
            (chartRef.current as unknown as Chart).update();
            // console.log(usersSpan);
            if(usersSpan.current)
            {
                usersSpan.current.innerHTML = '0';
            }
        }
        // setChartData
        // (
        //     usersLast30MinAnalytics.length === 0 
        //     ? 
        //         Array.from({length: 30}, (_, i) => 0) 
        //     : 
        //         Array.from
        //         (
        //             {length: 30}, 
        //             (el, i) => 
        //             { 
                        
        //                 var idx = usersLast30MinAnalytics.findIndex(x=>Number(x.data.minutesAgo) === (29 - i));
        //                 return idx >= 0 
        //                 ?
        //                     Number(usersLast30MinAnalytics[idx].data.userCount)
        //                 :
        //                     0
        //             }
        //         )
        // )
    }

    return (
        <>
            <div className={props.classes.userLast30MinHeader}>
                <h5 className={props.classes.titleStyle}>
                    {`Usuários Nos Últimos 30 Minutos`}
                </h5>
                <span ref={usersSpan} className={props.classes.userLast30MinText}>
                    {
                        usersLast30MinAnalytics.reduce
                        (
                            (accumulator, a) =>
                            {
                                return accumulator + Number(a.data.userCount);
                            },
                            0
                        )
                    }
                </span>
            </div>
            <div className={props.classes.userLast30MinHeader}>
                <h5 className={props.classes.titleStyle}>
                    {`Usuários por minuto`}
                </h5>
            </div>
            <Bar  
                id={chartID}
                ref={chartRef}
                style=
                {
                    {
                        maxHeight: '100px',
                        // maxWidth: '50%'
                    }
                }
                width={0}
                height={100}
                options=
                {
                    {
                        maintainAspectRatio: true,
                        //animation,
                        transitions: 
                        {
                            show: 
                            {
                                animations: 
                                {
                                    x: 
                                    {
                                        from: 1
                                    },
                                    y: 
                                    {
                                        from: 1
                                    }
                                }
                            },
                            hide: 
                            {
                                animations: 
                                {
                                    x: 
                                    {
                                        to: 1
                                    },
                                    y: 
                                    {
                                        to: 1
                                    }
                                }
                            }
                        },
                        responsive: true,
                        plugins:
                        {
                            title:
                            {
                                display: false,
                            },                      
                            legend:
                            {
                                display: false,
                            },
                            tooltip: 
                            {
                                
                                callbacks: 
                                {
                                    title: function(context)
                                    {
                                        return `${29 - context[0].parsed.x} minutos atrás`;
                                    },
                                    label: function(context: any) 
                                    {
                                        return `${context.dataset.label} ${context.parsed.y}`;
                                    }
                                }
                            },
                            // tooltips: 
                            // {
                            //     enabled: false
                            // },
                        },
                        scales: 
                        {
                            y: 
                            {
                                min: 0,
                                display: false,
                                // suggestedMax: 100,
                                ticks: 
                                {
                                    stepSize: 1,
                                    color: '#FFF'
                                }
                            },
                            x:
                            {
                                display: false,
                                ticks:
                                {
                                    color: '#FFF'
                                }
                            }
                        },
                    }
                }
                data=
                {
                    {
                        labels: chartLabels,
                        datasets: 
                        [
                            {
                                data: chartData,
                                backgroundColor: '#A6CEE3',
                                borderColor: [ '#A6CEE3' ],
                                borderWidth: 1,
                                label: 'Usuários',
                                minBarLength: 2
                            }
                        ]
                    }
                    
                }
                // data={this.state.data}
            />
        </>
    );
}
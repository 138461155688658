import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'; 
import CreateBreakpoints from '@material-ui/core/styles/createBreakpoints';
import '../../../css/Doc.css';
import '../../../css/Home.css';
import MenuIcon from '@material-ui/icons/Menu';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/SaveRounded';
import AddIcon from '@material-ui/icons/AddRounded';
import TableIcon from '@material-ui/icons/TableChartRounded';
import HttpIcon from '@material-ui/icons/HttpRounded';
import ParagraphIcon from '@material-ui/icons/SubjectRounded';
import TitleIcon from '@material-ui/icons/TitleRounded';
import CodeIcon from '@material-ui/icons/CodeRounded';
import ImageIcon from '@material-ui/icons/PhotoSizeSelectActualRounded';
import TreeViewIcon from '@material-ui/icons/AccountTreeRounded'
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import ChevronRightIcon from '@material-ui/icons/ChevronRightRounded';
import DragHandle from '@material-ui/icons/DragHandleRounded';
import CloseRounded from '@material-ui/icons/CloseRounded';
import logoWhite from '../../../resources/logoWhite.png';
import { withSnackbar } from 'notistack';
import '../../../css/Resizable.css';
import { ResizableBox } from 'react-resizable';
import FileCopyRounded from '@material-ui/icons/FileCopyRounded';
import { withStyles } from '@material-ui/core/styles';
import 
{ 
    Drawer,
    AppBar,
    Divider,
    Hidden,
    IconButton,
    Toolbar, 
    Typography,
    List, 
    ListItem,
    Menu,
    MenuItem,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Snackbar,
    Select,
    Input,
    Tab 
} from '@material-ui/core';

import 
{
    Alert,
    TreeView,
    TreeItem,
    TabList,
    TabContext,
    TabPanel 
} from '@material-ui/lab';
import { History, LocationState } from "history";

import 
{
    SnackbarMessage,
    OptionsObject,
    SnackbarKey
} from 'notistack';
const ReactDragListView = require('react-drag-listview/lib/index.js');

const breakpoints = CreateBreakpoints
(
    {
        values:
        {
            xs: 0,
            sm: 1024,
            md: 1024,
            lg: 1280,
            xl: 1920,
        }
    }
);

interface IActions
{
    title: Function,
    titleH1: Function,
    titleH2: Function,
    titleH3: Function,
    titleH6: Function,
    paragraph: Function,
    table: Function,
    httpReq: Function,
    jsonCode: Function,
    image: Function,
    treeview: Function,
    requestExampleCode: Function
}

interface IDocPageProps
{
    history?: History<LocationState>,
    classes?: any,
    match?: 
    {
        params:
        {
            id: string
        }
    },
    enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
    closeSnackbar: (key?: SnackbarKey) => void,
}

interface IDocPageState 
{
    doc: any,
    canEdit: boolean,
    mobileOpen: boolean,
    editing: boolean,
    mouseX: number | null,
    mouseY: number | null,
    mouseComponentsX: number | null,
    mouseComponentsY: number | null,
    mouseTreeViewX: number | null,
    mouseTreeViewY: number | null,
    openComponentsDialog: boolean,
    summary: Array<any>,
    showSnackbar: boolean,
    contextMenuRef: any | null,
    contextMenuComponentsID: number | null,
    contextMenuCloseHandler: Function | null,
    contextMenuAnyClickHandler: Function | null,
    preferredCodeLanguage: string,
    rowId: number | null,
    columnId: number | null,
    elementId: number | null, 
    tableBody: Node | ParentNode | null,
    treeSelector: string | null
}

const directionUp = 0;
const directionDown = 1;

const useStyles = (theme: any) =>
(
    {
        treeViewRoot:
        {
            color: 'white',
        },
        treeItemRoot:
        {
            '&$selected > $content $label': 
            {
                backgroundColor: 'rgb(0 0 0 / 8%) !important'
            }
        }
    }
);

class DocPage extends React.Component<IDocPageProps, IDocPageState>
{
    classes: any;
    docID: string | null  = null;
    imageUploadButtonRef: React.RefObject<any> | null = null;
    docCopy: Array<any> = [];
    contentRef: React.RefObject<any> | null = null;

    constructor(props: IDocPageProps)
    {
        super(props);
        this.classes = props.classes;
        this.docID = props.match!.params.id;
        this.imageUploadButtonRef = React.createRef();
        this.contentRef = React.createRef();
        this.state = 
        {
            doc: {},
            canEdit: false,
            mobileOpen: false,
            editing: false,
            mouseX: null,
            mouseY: null,
            mouseComponentsX: null,
            mouseComponentsY: null,
            mouseTreeViewX: null,
            mouseTreeViewY: null,
            openComponentsDialog: false,
            summary: [],
            showSnackbar: false,
            contextMenuRef: null,
            contextMenuComponentsID: null,
            contextMenuCloseHandler: null,
            contextMenuAnyClickHandler: null,
            preferredCodeLanguage: localStorage['preferredCodeLanguage'] ?? 'curl',
            rowId: null,
            columnId: null,
            elementId: null,
            tableBody: null,
            treeSelector: null
        }

        //START TABLE
        this.buildTable = this.buildTable.bind(this);
        this.handleTableContextMenuClick = this.handleTableContextMenuClick.bind(this);
        this.handleCloseTableContextMenu = this.handleCloseTableContextMenu.bind(this);
        this.tableInsertRowAbove = this.tableInsertRowAbove.bind(this);
        this.tableInsertRowBelow = this.tableInsertRowBelow.bind(this);
        this.tableInsertColumnLeft = this.tableInsertColumnLeft.bind(this);
        this.tableInsertColumnRight = this.tableInsertColumnRight.bind(this);
        this.tableDeleteRow = this.tableDeleteRow.bind(this);
        this.tableDeleteColumn = this.tableDeleteColumn.bind(this);
        //END TABLE

        //START TITLES
        this.buildTitle = this.buildTitle.bind(this);
        this.buildTitle1 = this.buildTitle1.bind(this);
        this.buildTitle2 = this.buildTitle2.bind(this);
        this.buildTitle3 = this.buildTitle3.bind(this);
        this.buildTitle6 = this.buildTitle6.bind(this);
        //END TITLES

        //START PARAGRAPHS
        this.buildParagraph = this.buildParagraph.bind(this);
        //END PARAGRAPHS

        //START CODE
        this.buildJSONCode = this.buildJSONCode.bind(this);
        this.formatJSONCode = this.formatJSONCode.bind(this);
        this.JSONreplacer = this.JSONreplacer.bind(this);
        this.copyRequestExampleCode = this.copyRequestExampleCode.bind(this);
        this.buildRequestExampleCode = this.buildRequestExampleCode.bind(this);
        this.buildDelphiCode = this.buildDelphiCode.bind(this);
        this.buildCurlCode = this.buildCurlCode.bind(this);
        this.onPreferredCodeLanguageChanged = this.onPreferredCodeLanguageChanged.bind(this);
        this.buildRequestExampleHeaderTableKeys = this.buildRequestExampleHeaderTableKeys.bind(this);
        //END CODE

        //START HTTP_REQUEST
        this.buildHttpRequest = this.buildHttpRequest.bind(this);
        //END HTTP_REQUEST

        //START IMAGE
        this.buildImage = this.buildImage.bind(this);
        this.addNewImageFileSelected = this.addNewImageFileSelected.bind(this);
        //END IMAGE

        //START TREEVIEW
        this.buildTreeview = this.buildTreeview.bind(this);
        this.buildTreeItem = this.buildTreeItem.bind(this);
        this.treeviewInsertTreeViewChild = this.treeviewInsertTreeViewChild.bind(this);
        this.treeviewDeleteTreeViewItem = this.treeviewDeleteTreeViewItem.bind(this);
        this.handleCloseTreeViewContextMenu = this.handleCloseTreeViewContextMenu.bind(this);
        this.handleTreeViewContextMenuClick = this.handleTreeViewContextMenuClick.bind(this);
        this.handleAnyClickTreeViewMenu = this.handleAnyClickTreeViewMenu.bind(this);
        //END TREEVIEW

        //START SUMMARY
        this.buildSummary = this.buildSummary.bind(this);
        //END SUMMARY

        //START DIALOG CONTROLS
        this.addNewTitle = this.addNewTitle.bind(this);
        this.addNewTitle1 = this.addNewTitle1.bind(this);
        //this.addNewTitle2 = this.addNewTitle2.bind(this);
        this.addNewTitle3 = this.addNewTitle3.bind(this);
        this.addNewTitle6 = this.addNewTitle6.bind(this);
        this.addNewParagraph = this.addNewParagraph.bind(this);
        this.addNewTable = this.addNewTable.bind(this);
        this.addNewJsonCode = this.addNewJsonCode.bind(this);
        this.addNewRequest = this.addNewRequest.bind(this);
        this.addNewRequestExample = this.addNewRequestExample.bind(this);
        this.addNewImage = this.addNewImage.bind(this);
        this.addNewTreeview = this.addNewTreeview.bind(this);
        //END DIALOG CONTROLS

        this.handleCloseAddComponentsDialog = this.handleCloseAddComponentsDialog.bind(this);
        this.handleOpenAddComponentsDialog = this.handleOpenAddComponentsDialog.bind(this);
        this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);
        this.buildDocPage = this.buildDocPage.bind(this);
        this.changeComponentPosition = this.changeComponentPosition.bind(this);
        this.getDocInfo = this.getDocInfo.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.unsavedChanges = this.unsavedChanges.bind(this);
        this.onErrorSnackClose = this.onErrorSnackClose.bind(this);
        this.handleCloseComponentsContextMenu = this.handleCloseComponentsContextMenu.bind(this);
        this.openComponentsContextMenu = this.openComponentsContextMenu.bind(this);
        this.handleClickOutsideMenu = this.handleClickOutsideMenu.bind(this);
        this.handleInsertNewComponentContextMenu = this.handleInsertNewComponentContextMenu.bind(this);
        this.pasteWithoutFormatting = this.pasteWithoutFormatting.bind(this);
        this.ctrlSDetector = this.ctrlSDetector.bind(this);
        this.showSaveStatusNotification = this.showSaveStatusNotification.bind(this);
        this.deleteComponent = this.deleteComponent.bind(this);
        this.selectTreeViewObject = this.selectTreeViewObject.bind(this);

        this.handleAnyClickComponentsMenu = this.handleAnyClickComponentsMenu.bind(this);
        this.handleAnyClickTableMenu = this.handleAnyClickTableMenu.bind(this);

        this.getDocInfo();
    }

    componentDidMount()
    {
        window.onbeforeunload = this.unsavedChanges;
        document.addEventListener('keydown', this.ctrlSDetector, false);
    }

    componentWillUnmount()
    {
        window.onbeforeunload = null;
        document.removeEventListener('keydown', this.ctrlSDetector)
    }

    showSaveStatusNotification(success: boolean)
    {
        if(success)
        {
            this.props.enqueueSnackbar!('Alterações salvas com sucesso', { variant: 'success' });
        }
        else
        {
            this.props.enqueueSnackbar!('Ocorreu um erro ao salvar', { variant: 'error' });
        }
    }

    ctrlSDetector(e: any)
    {
        if ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)  && e.code === 'KeyS') 
        {
            e.preventDefault();
            this.saveChanges().then(this.showSaveStatusNotification); 
        }
    }

    unsavedChanges()
    {
        if(this.state.editing)
        {
            return "";
        }
    }

    menuTheme = createTheme
    (
        {
            overrides:
            {
                MuiMenu:
                {
                    paper:
                    {
                        backgroundColor: '#232427',
                        width: '300px',
                        color: 'white',
                    }
                },
                MuiDivider:
                {
                    root:
                    {
                        backgroundColor: 'rgb(255 255 255 / 12%)',
                        marginLeft: '16px',
                        marginRight: '5px'
                    }
                }
            }
        }
    );
    
    drawerTheme = createTheme
    (
        {
            overrides:
            {
                MuiPaper:
                {
                    root:
                    {
                        [breakpoints.up("md")]: 
                        {
                            flexShrink: 0
                        },
                        width: '240px',
                        backgroundColor: '#232427',   
                    },
                },
                MuiList:
                {
                    root:
                    {
                        padding: '0px',
                    }
                },
                MuiListSubheader:
                {
                    root:
                    {
                        color: 'white',
                        fontSize: '18pt'
                    }
                },
                MuiListItem:
                {
                    root:
                    {
                        width: '100%',
                        color: 'white'
                    }
                },
                MuiListItemIcon:
                {
                    root:
                    {
                        color: 'white'
                    }
                },
                MuiDivider:
                {
                    root:
                    {
                        backgroundColor: 'rgb(255 255 255 / 12%)'
                    }
                },
                MuiSvgIcon:
                {
                    colorPrimary:
                    {
                        color: 'white !important'
                    }
                }
                // MuiTreeView:
                // {
                //     root:
                //     {
                //         color: 'white',
                //     },
                // },
                // MuiTreeItem:
                // {
                //     root:
                //     {
                //         '&$selected > $content $label': 
                //         {
                //             backgroundColor: 'rgb(0 0 0 / 8%) !important'
                //         }
                //     }
                // }
            },
            breakpoints
        },
    );

    appbarTheme = createTheme
    (
        {
            overrides:
            {
                MuiAppBar:
                {
                    root:
                    {
                        [breakpoints.up("md")]: 
                        {
                            width: `calc(100vw - 240px)`,
                            marginLeft: '240px'
                        }
                    },
                    colorPrimary:
                    {
                        backgroundColor: '#212121',
                        color: 'white'
                    }
                },
                MuiIconButton:
                {
                    root:
                    {
                        [breakpoints.up("md")]: 
                        {
                            display: "none"
                        }
                    }
                }
            },
            breakpoints
        }
    );

    appbarEditIcon = createTheme
    (
        {
            overrides:
            {
                MuiIconButton:
                {
                    root:
                    {
                        display: 'flex'
                    }
                }
            }
        }
    );

    addNewElementsTheme = createTheme
    (
        {
            overrides:
            {
                MuiSvgIcon:
                {
                    colorPrimary:
                    {
                        color: '#FFFFFF',
                        fontSize: '48px'
                    },
                   
                }
            }
        }
    );

    dialogComponentsTheme = 
    {
        style:
        {
            backgroundColor: '#323232',
            color: '#FFFFFF'
        }
    };

    dialogComponentsContentTheme = createTheme
    (
        {
            overrides:
            {
                MuiTypography:
                {
                    colorTextSecondary:
                    {
                        color: 'rgb(255 255 255 / 90%)'
                    }
                },
                MuiButton:
                {
                    textPrimary:
                    {
                        color: 'white'
                    }
                },
                MuiFormLabel:
                {
                    root:
                    {
                        color: 'white',
                        "&$focused": 
                        {
                            color: 'white',
                        }
                    }
                },
                MuiListItem:
                {
                    gutters:
                    {
                        paddingLeft: 0
                    }
                },
                MuiDialogContentText:
                {
                    root:
                    {
                        display: 'flex',
                        justifyContent: 'space-between'
                    }
                }
            }
        }
    );

    arrowIconButtonTheme = createTheme
    (
        {
            overrides:
            {
                MuiIconButton:
                {
                    root:
                    {
                        padding: '5px'
                    },
                    colorPrimary:
                    {
                        color: 'white',
                        '&:disabled':
                        {
                            color: '#999999'
                        }
                    },
                },
            }
        }
    );

    getDocInfo()
    {
        fetch
        (
            `/api/doc/${this.docID}`, 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    var data = await response.json();
                    console.log(data)
                    try
                    {
                        data.data.doc = JSON.parse(data.data.doc);
                    }
                    catch(ex)
                    {
                        console.log(ex);
                        data.doc = [];
                    }
                    console.log(data.data.doc);
                    for(var i = 0; i < data.data.doc.length; i++)
                    {
                        data.data.doc[i].id = Math.ceil(Math.random()*10000000000000000);
                    }
                    // let test =
                    // {
                    //     "type":"requestExampleCode",
                    //     "data":
                    //     {
                    //        "url":"https://odindev.cron.com.br/app",
                    //        "method":"POST",
                    //        "selected_tab":"body",
                    //        "headers":
                    //         [
                    //           {
                    //             "key": "Authorization",
                    //             "value": "Bearer Something",
                    //           },
                    //           {
                    //             "key": "Content-Type",
                    //             "value": "application/json",
                    //           }
                    //         ],
                    //         "body":'{"campo-teste":"texto teste"}'
                    //     }
                    // };
                    // data.data.doc.splice(0, 0, test);
                    this.docCopy = data.data.doc;
                    this.setState
                    (
                        {
                            canEdit: data.canEdit,
                            doc: data.data
                        }
                    );
                }
                else
                {
                    if(this.props.history !== undefined)
                    {
                        this.props.history.push('/login');
                    }
                }
            } 
        );
    }

    async saveChanges()
    {
        var doc = this.state.doc;

        var response = await fetch
        (
            `/api/doc/${this.docID}`, 
            {
                method: "PATCH",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify
                (
                    {
                        doc: doc
                    }
                )
            }
        )
        return (response.status === 200);
    }

    handleDrawerToggle()
    {
        var mobileOpen = this.state.mobileOpen;
        this.setState
        (
            {
                mobileOpen: !mobileOpen
            }
        )
    }

    drawer()
    {
        var drawer = 
        (
            <div style={{width: '240px'}} className='doc_scroller'>
                <List style={{padding: '12px', paddingTop:'24px'}} >
                    <Box>
                        <a href='/home'>
                            <img src={logoWhite} style={{width: '90%', padding: '12px'}} alt='logo'></img>
                        </a>
                    </Box>
                    <Divider style={{marginBottom: '12px'}} />
                    {
                        this.state.doc.doc !== null && this.state.doc.doc !== undefined
                        ?
                            this.buildSummary(this.state.doc.doc)
                        :
                            null
                    }
                </List>
            </div>
        )
        return drawer;
    }

    //START TABLE
    buildTable(obj: any, index: number)
    {
        var table = 
        (
            <div className='doc_component_outter' data-id={index} key={this.docCopy[index].id}>
                <table style={{width:'100%'}} className='dark_table doc_component_inner' onContextMenu={(e)=>this.handleTableContextMenuClick(e, index)}>
                    <thead>
                        <tr>
                        {
                            this.buildTableHeader(obj.header, index)
                        }
                        </tr>
                    </thead>
                    <tbody>
                        {this.buildTableBody(obj.body, index)}
                    </tbody>    
                </table>
                {
                    // eslint-disable-next-line
                }<a className='doc_drag' style={{ display: !this.state.editing ? 'none': 'inline'}}>
                    <DragHandle color='primary'/>
                </a>
            </div>
            
        );
        return table;
    }

    buildTableHeader(header: any, index: number)
    {
        var columns = [];
        var sThis = this;
        for(var i = 0; i < header.length; i++)
        {
            (
                function (i) 
                {
                    columns.push
                    (
                        <th key={i} 
                            contentEditable={sThis.state.editing} 
                            onInput=
                            {
                                (e)=> e.currentTarget.innerText.length > 0 
                                ?
                                    sThis.docCopy[index].data.header[i] = e.currentTarget.textContent
                                :
                                    sThis.docCopy[index].data.header[i] = ' '
                            } 
                            onPaste=
                            {
                                (e)=>
                                {
                                    sThis.pasteWithoutFormatting(e);
                                    //sThis.docCopy[index].data.header[i] = result;
                                }
                            }
                            suppressContentEditableWarning={true}>
                            {header[i]}
                        </th>
                    );
                }
            )(i);
        }
        return columns;
    }

    buildTableBody(body: any, index: number)
    {
        var rows = [];
        var sThis = this;
        for(var i = 0; i < body.length; i++)
        {
            (
                function (i) 
                {
                    var columns = [];
                    for(var x = 0; x < body[i].length; x++)
                    {
                        (
                            function (x) 
                            {
                                columns.push
                                (
                                    <td key={x+i} 
                                        contentEditable={sThis.state.editing} 
                                        onPaste=
                                        {
                                            (e)=>
                                            {
                                                sThis.pasteWithoutFormatting(e);
                                                //sThis.docCopy[index].data.body[i][x] = result;
                                            }
                                        }
                                        onInput=
                                        {
                                            (e)=> e.currentTarget.innerText.length > 0 
                                            ?
                                                sThis.docCopy[index].data.body[i][x] = e.currentTarget.textContent!.replaceAll('\n', '<\\br>')
                                            :
                                                sThis.docCopy[index].data.body[i][x] = ' '
                                        } 
                                        suppressContentEditableWarning={true}>
                                        {body[i][x]}
                                    </td>
                                );
                            }
                        )(x);
                    }
                    var row = (<tr key={i}>{columns}</tr>);
                    rows.push(row);
                }
            )(i);
        }
        return rows;
    }

    handleTableContextMenuClick(event: any, id: number) 
    {
        if(this.state.editing)
        {
            event.stopPropagation();
            event.preventDefault();
            document.addEventListener('contextmenu', this.handleClickOutsideMenu);
            this.setState
            (
                {
                    mouseX: event.clientX - 2,
                    mouseY: event.clientY - 4,
                    contextMenuRef: React.createRef(),
                    contextMenuCloseHandler: this.handleCloseTableContextMenu,
                    contextMenuAnyClickHandler: this.handleAnyClickTableMenu,
                    rowId: event.target.parentNode.rowIndex,
                    columnId: event.target.cellIndex,
                    elementId: id,
                    tableBody: event.target.parentNode.parentNode
                }
            );
        }
    };

    handleAnyClickTableMenu(e: any)
    {
        this.setState
        (
            {
                mouseX: e.clientX - 2,
                mouseY: e.clientY - 4,
            }
        )
    }

    handleCloseTableContextMenu()
    {
        document.removeEventListener('contextmenu', this.handleClickOutsideMenu);
        this.setState
        (
            {
                mouseX: null,
                mouseY: null,
                contextMenuRef: null,
                contextMenuCloseHandler: null
            }
        );
    };

    tableInsertRowAbove(e: any)
    {
        var children = [];
        var id = this.state.elementId!;
        var doc = this.state.doc;
        var rowId = this.state.rowId! - 1;
        var columnCount = 0;
        if(rowId >= 0)
        {
            columnCount =  doc.doc[id].data.body[rowId].length;
        
            for(var i = 0; i < columnCount; i++)
            {
                children.push('');
            }
            
            doc.doc[id].data.body.splice(rowId, 0 , children);
            this.setState
            (
                {
                    doc: doc,
                    mouseX: null,
                    mouseY: null
                }
            )
        }
    }

    tableInsertRowBelow(e: any)
    {
        var children = [];
        var id = this.state.elementId!;
        var doc = this.state.doc;
        var rowId = this.state.rowId! - 1;
        var columnCount = 0;
        if(rowId >= 0)
        {
            columnCount =  doc.doc[id].data.body[rowId].length;
        
            for(var i = 0; i < columnCount; i++)
            {
                children.push('');
            }
            
            doc.doc[id].data.body.splice(rowId + 1, 0 , children);
            this.setState
            (
                {
                    doc: doc,
                    mouseX: null,
                    mouseY: null
                }
            )
        }
    }

    tableInsertColumnLeft(e: any)
    {
        var id = this.state.elementId!;
        var doc = this.state.doc;
        var columnId = this.state.columnId;
        var rowId = this.state.rowId! - 1;
        var rowCount = doc.doc[id].data.body.length;
        if(rowId >= 0)
        {
            doc.doc[id].data.header.splice(columnId, 0 , 'Nova coluna');
            
            for(var i = 0; i < rowCount; i++)
            {
                doc.doc[id].data.body[i].splice(columnId, 0, ' ');
            }
            
            this.setState
            (
                {
                    doc: doc,
                    mouseX: null,
                    mouseY: null
                }
            )
        }
    }

    tableInsertColumnRight(e: any)
    {
        var id = this.state.elementId!;
        var doc = this.state.doc;
        var columnId = this.state.columnId!;
        var rowId = this.state.rowId! - 1;
        var rowCount = doc.doc[id].data.body.length;
        if(rowId >= 0)
        {
            doc.doc[id].data.header.splice(columnId + 1, 0 , 'Nova coluna');
            
            for(var i = 0; i < rowCount; i++)
            {
                doc.doc[id].data.body[i].splice(columnId + 1, 0, ' ');
            }
            
            this.setState
            (
                {
                    doc: doc,
                    mouseX: null,
                    mouseY: null
                }
            )
        }
    }

    tableDeleteRow(e: any)
    {
        var id = this.state.elementId!;
        var doc = this.state.doc;
        var rowId = this.state.rowId! - 1;
        console.log(rowId);
        if(rowId >= 0)
        {
            doc.doc[id].data.body = doc.doc[id].data.body.filter((value: any, index: any, arr: any) => index !== rowId);
        }
        this.setState
        (
            {
                doc: doc,
                mouseX: null,
                mouseY: null
            }
        )
    }

    tableDeleteColumn(e: any)
    {
        var columnId = this.state.columnId;
        var id = this.state.elementId!;
        var doc = this.state.doc;
        if(id >= 0)
        {
            doc.doc[id].data.header = doc.doc[id].data.header.filter((value: any, index: any, arr: any) => index !== columnId);
            for(var i = 0; i < doc.doc[id].data.body.length; i++)
            {
                doc.doc[id].data.body[i] = doc.doc[id].data.body[i].filter((value: any, index: any, arr: any) => index !== columnId);
            }
            
            this.setState
            (
                {
                    doc: doc,
                    mouseX: null,
                    mouseY: null
                }
            );
        }
    }
    //END TABLE

    //START TITLES
    buildTitle(obj: any, index: number)
    {
        var id = obj.toLowerCase().replaceAll(' ', '-');
        var title =
        (
            <div className='doc_component_outter' data-id={index} key={this.docCopy[index].id}>
                <h1 className='doc_title' id={id} 
                    contentEditable={this.state.editing} 
                    onPaste=
                    {
                        (e)=>
                        {
                            this.pasteWithoutFormatting(e);
                            //this.docCopy[index].data = result;
                        }
                    }
                    suppressContentEditableWarning={true} 
                    onInput=
                    {
                        (e)=> e.currentTarget.innerText.length > 0 
                        ?
                            this.docCopy[index].data = e.currentTarget.textContent
                        :
                            this.docCopy[index].data = ' '
                    } 
                >
                    {obj}
                </h1>
                {
                    // eslint-disable-next-line
                }<a className='doc_drag title' style={{display: !this.state.editing ? 'none': 'inline'}}>
                    <DragHandle color='primary'/>
                </a>
            </div>
            
        );
        return title;
    }

    buildTitle1(obj: any, index: number)
    {
        var id = obj.toLowerCase().replaceAll(' ', '-');
        var title =
        (
            <div className='doc_component_outter' data-id={index} key={this.docCopy[index].id}>
                <h1 
                    className='doc_h1' 
                    id={id} 
                    contentEditable={this.state.editing} 
                    onPaste=
                    {
                        (e)=>
                        {
                            this.pasteWithoutFormatting(e);
                            //this.docCopy[index].data = result;
                        }
                    }
                    suppressContentEditableWarning={true}
                    onInput=
                    {
                        (e)=> e.currentTarget.innerText.length > 0 
                        ?
                            this.docCopy[index].data = e.currentTarget.textContent
                        :
                            this.docCopy[index].data = ' '
                    } 
                >
                    {obj}
                </h1>
                {
                    // eslint-disable-next-line
                }<a className='doc_drag' style={{display: !this.state.editing ? 'none': 'inline'}}>
                    <DragHandle color='primary'/>
                </a>
            </div>
        );
        return title;
    }

    buildTitle2(obj: any, index: number)
    {
        var title =
        (
            <div className='doc_component_outter' data-id={index} key={this.docCopy[index].id}>
                <h2 className='doc_h2' 
                    contentEditable={this.state.editing} 
                    onPaste=
                    {
                        (e)=>
                        {
                            this.pasteWithoutFormatting(e);
                            //this.docCopy[index].data.title = result;
                        }
                    }
                    suppressContentEditableWarning={true}
                    onInput=
                    {
                        (e)=> e.currentTarget.innerText.length > 0 
                        ?
                            this.docCopy[index].data.title = e.currentTarget.textContent
                        :
                            this.docCopy[index].data.title = ' '
                    } 
                >
                    {obj}
                </h2>
            </div>
        );
        return title;
    }
    
    buildTitle3(obj: any, index: number)
    {
        var title =
        (
            <div className='doc_component_outter' data-id={index} key={this.docCopy[index].id}>
                <h3 className='doc_h3' 
                    contentEditable={this.state.editing} 
                    onPaste=
                    {
                        (e)=>
                        {
                            this.pasteWithoutFormatting(e);
                            //this.docCopy[index].data = result;
                        }
                    }
                    suppressContentEditableWarning={true}
                    onInput=
                    {
                        (e)=> e.currentTarget.innerText.length > 0 
                        ?
                            this.docCopy[index].data = e.currentTarget.textContent
                        :
                            this.docCopy[index].data = ' '
                    } 
                >
                    {obj}
                </h3>
                {
                    // eslint-disable-next-line
                }<a className='doc_drag titleH3' style={{display: !this.state.editing ? 'none': 'inline'}}>
                    <DragHandle color='primary'/>
                </a>
            </div>
        );
        return title;
    }

    buildTitle6(obj: any, index: number)
    {
        var title =
        (
            <div className='doc_component_outter' data-id={index} key={this.docCopy[index].id}>
                <h6 className='doc_h6' 
                    contentEditable={this.state.editing} 
                    onPaste=
                    {
                        (e)=>
                        {
                            this.pasteWithoutFormatting(e);
                            //this.docCopy[index].data = result;
                        }
                    }
                    suppressContentEditableWarning={true}
                    onInput=
                    {
                        (e)=> e.currentTarget.innerText.length > 0 
                        ?
                            this.docCopy[index].data = e.currentTarget.textContent
                        :
                            this.docCopy[index].data = ' '
                    } 
                >
                    {obj}
                </h6>
                {
                    // eslint-disable-next-line
                }<a className='doc_drag titleH6' style={{display: !this.state.editing ? 'none' : 'inline' }}>
                    <DragHandle color='primary'/>
                </a>
            </div>
            
        );
        return title;
    }
    //END TITLES

    //START PARAGRAPHS
    buildParagraph(obj: any, index: number)
    {
        //key={id.concat(Math.ceil(Math.random()*10000000000000000))}
        var paragraph =
        (
            <div className='doc_component_outter' data-id={index} key={this.docCopy[index].id}>
                <span className='doc_paragraph doc_component_outter'
                    contentEditable={this.state.editing} 
                    onPaste=
                    {
                        (e)=>
                        {
                            this.pasteWithoutFormatting(e);
                            //this.docCopy[index].data = result;
                        }
                    }
                    suppressContentEditableWarning={true}
                    onKeyDown=
                    {
                        (e)=>
                        {
                            if(e.key === 'Enter' && !e.shiftKey)
                            {
                                e.preventDefault();
                            }
                        }
                    }
                    onInput=
                    {
                        (e)=> 
                        {
                            e.currentTarget.innerHTML.length > 0 
                            ? 
                                this.docCopy[index].data = e.currentTarget.innerHTML.replaceAll('\n', '<br>').replace(/<((?!(br>|b>)\s*\/?)(?!(\/br>|\/b>)))[^>]+>/gi, '')
                            :
                                this.docCopy[index].data = ' '
                        }
                    } 
                    dangerouslySetInnerHTML={{__html: obj}}
                >
                </span>
                {
                    // eslint-disable-next-line
                }<a className='doc_drag' style={{display: !this.state.editing ? 'none': 'inline'}}>
                    <DragHandle color='primary'/>
                </a>
            </div>
            
        );
        return paragraph;
    }
    //END PARAGRAPHS

    //START CODE
    JSONreplacer(key: any, value: any)
    {
        if(key !== undefined && key !== null && key !== '')
        {
            if(typeof value === 'object')
            {
                return value;
            }
            if(typeof value === 'number')
            {
                return value;
            }
            return "<span class='doc_string'>".concat(value).concat("</span>");
        }
        return value;  
    }

    formatJSONCode(obj: any)
    {
        var returnValue = '';
        let json = obj;
        try
        {
            json = JSON.stringify(JSON.parse(obj), this.JSONreplacer, 2);
        }
        catch(err)
        {
            console.log(err);
            // returnValue = JSON.stringify({error: 'Ocorreu um erro ao formatar o json'}, this.JSONreplacer, 2);
            // returnValue = returnValue.replace
            // (
            //     /("[^,\n\r}:\]]+|[0-9]+)(?=:)/gi,
            //     function(match, $1, index, end)
            //     {
            //         return (
            //             "<span class='doc_attr'>"+match.substring(0,match.length)+"</span>"
            //         );
            //     }
            // );
        }
        try
        {
            returnValue = json.replace
            (
                //[^{\[\":]((?!":).)+(?=,|$|}|]|\n)[^}\]]
                ///[^{\[\":]("[^,\n\r}\]]+|[0-9]+)(?=,|$|}|]|\n)/gi,
                /[0-9]+(?=,|\n|}^|]|$)/g,
                function(match: any, $1: any)
                {
                    return (
                        "<span class='doc_number'>"+$1+"</span>"
                    );
                }
            );

            returnValue = returnValue.replace
            (
                /("[^,\n\r}:\]]+|[0-9]+)(?=:)/gi,
                function(match: any, $1: any, index: any, end: any)
                {
                    return (
                        "<span class='doc_attr'>"+match.substring(0,match.length)+"</span>"
                    );
                }
            );
        }
        catch(err)
        {
            console.error(err);
        }
        return returnValue;
    }

    buildJSONCode(obj: any, index: number)
    {
        var json = this.formatJSONCode(obj);
        //this.docCopy[index].data = e.currentTarget.innerText.replaceAll('\n', '<br>').replace(/<(?!br\s*\/?)[^>]+>/g, '')
        var code =
        (
            <div className='doc_component_outter' data-id={index} key={this.docCopy[index].id}>
                <pre className='doc_pre doc_component_inner'>
                    <code className='doc_code doc_codeblock  doc_scroller' 
                        contentEditable={this.state.editing} 
                        suppressContentEditableWarning={true}
                        onPaste=
                        {
                            (e)=>
                            {
                                this.pasteWithoutFormatting(e);
                            }
                        }
                        onInput=
                        {
                            (e) => e.currentTarget.innerText.length > 0 
                            ?
                                this.docCopy[index].data = e.currentTarget.textContent
                            :
                                this.docCopy[index].data = '{}'
                        }
                        dangerouslySetInnerHTML={{__html: json}}
                    >
                        {/* {ReactHtmlParser(json)} */}
                    </code>
                
                </pre>
                {
                    // eslint-disable-next-line
                }<a className='doc_drag' style={{display: !this.state.editing ? 'none': 'inline'}}>
                    <DragHandle color='primary'/>
                </a>
            </div>
        );
        return code;
    }

    copyRequestExampleCode(id: string)
    {        
        //TODO copy right text;
        let el = document.createElement('textarea');
        el.value = document.getElementById(id)!.innerText.replaceAll(' \n', ' ')
                                                        .replaceAll('\n\n', '')
                                                        .replaceAll('\n;', ';')
                                                        .replaceAll('\n:', ':');
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    buildCurlCode(data: any)
    {
        let formattedCode = '';
        formattedCode += `<span>
                            <span class="command">curl </span> 
                            <span class="variable">--location </span>
                            <span class="variable">--request </span>
                            <span class="value">${data.method} </span>
                            <span class="string">"${data.url}" </span>
                            <span class="break">\\</span>
                          </span></br>`;
        if(data.headers)
        {
            for(let i = 0; i < data.headers.length; i++)
            {
                formattedCode += `<span>
                                    <span class="variable">--header </span>
                                    <span class="string">"${data.headers[i].key}: ${data.headers[i].value}" </span>
                                    <span class="break">\\</span>
                                  </span></br>`;
            }
        }
        let body = {};
        try
        {
            JSON.parse(data.body);
        }
        catch(err)
        {
            console.error(err);
        }
        if(body)
        {
            let contentType = data.headers.find((x: any) => x.key === "Content-Type")?.value;
            if(contentType === 'application/json')
            {
                formattedCode += `<span>
                                    <span class="variable">--data-raw </span>
                                    <span class="string">"${JSON.stringify(body)}"</span>
                                  </span></br>`;
            }
        }
        formattedCode = formattedCode.replaceAll('\n', '').replace(/( )+/g, ' ');
        formattedCode = formattedCode.replace(/<\/br>$/gi, '');
        formattedCode = formattedCode.replace(/ <span class="break">\\<\/span> <\/span>$/gi, '');
        return formattedCode;
    }

    buildDelphiCode(data: any)
    {
        let formattedCode = '';
        formattedCode += `<span>
                            <span class="variable">var </span> 
                            <span class="code">httpRequest</span>
                            <span class="operator">: </span>
                            <span class="type">TclHttpRequest</span>
                            <span class="operator">;</span>
                          </span></br>`;
        formattedCode += `<span>
                            <span class="variable">var </span>
                            <span class="code">httpClient</span>
                            <span class="operator">: </span>
                            <span class="type">TclHttp</span>
                            <span class="operator">;</span>
                          </span></br>`;
        formattedCode += `<span>
                            <span class="variable">var </span>
                            <span class="code">responseBody</span>
                            <span class="operator">: </span>
                            <span class="type">TStringStream</span>
                            <span class="operator">;</span>
                          </span></br>`;

        if(data.body)
        {
            formattedCode += `<span>
                                <span class="variable">var </span> 
                                <span class="code">jsonBody</span>
                                <span class="operator">: </span> 
                                <span class="type">TclJSONObject</span>
                                <span class="operator">;</span>
                              </span></br></br>`;
            formattedCode += `<span>
                                <span class="code">jsonBody </span>
                                <span class="operator">:= </span>
                                <span class="code">TclJSONObject.Create()</span>
                                <span class="operator">;</span>
                              </span></br>`;
            let bodyKeys = Object.keys(data.body);
            for(let i = 0; i < bodyKeys.length; i++)
            {
                formattedCode += `<span>
                                    <span class="code">jsonBody.AddString(</span>
                                        <span class="string">'${bodyKeys[i]}'</span>, 
                                        <span class="string">'${data.body[bodyKeys[i]]}'</span>)
                                    <span class="operator">;</span>
                                  </span></br>`;
            }
        }

        formattedCode = formattedCode.replaceAll('\n', '').replace(/( )+/g, ' ');
        formattedCode = formattedCode.replace(/<\/br>$/gi, '');
        //console.log(formattedCode);

        return formattedCode;
    }

    onPreferredCodeLanguageChanged(event: any)
    {
        localStorage['preferredCodeLanguage'] = event.target.value;
        this.setState
        (
            {
                preferredCodeLanguage: event.target.value
            }
        )
    }

    buildRequestExampleHeaderTableKeys(headers: any, index: number)
    {
        let headersElement = [];
        for(let i = 0; i < headers.length; i++)
        {
            headersElement.push
            (
                <tr className='request_code_example_editor_table_existing_row'>
                    <td onClick={(e)=>{ (e.currentTarget?.firstChild?.firstChild as HTMLElement)?.focus()}}>
                        <div className='request_code_example_editor_table_row'>
                            <span 
                                suppressContentEditableWarning={true}
                                contentEditable={true}
                                onInput=
                                {
                                    (e)=>
                                    {
                                        // this.docCopy[index].data.headers[e.currentTarget.innerText] = this.docCopy[index].data.headers[e.currentTarget.innerText]
                                        // console.log(this.docCopy[index]);
                                    }
                                }
                            >
                                {headers[i].key}
                            </span>
                        </div>
                    </td>
                    <td onClick={(e)=>{ (e.currentTarget?.firstChild?.firstChild as HTMLElement)?.focus()}}>
                        <div className='request_code_example_editor_table_row'>
                            <span 
                                suppressContentEditableWarning={true}
                                contentEditable={true}
                                onInput=
                                {
                                    (e)=> console.log(e)
                                }
                            >
                                {headers[i].value}
                            </span>
                            <div 
                                className='request_code_example_editor_row_delete' 
                                onClick=
                                {
                                    ()=> 
                                    { 
                                        console.log(`Clicked at ${index}-${i}`);
                                        this.docCopy[index].data.headers.splice(i, 1);
                                        this.setState({});
                                    }
                                }
                            >
                                <CloseRounded style={{fontSize: '1em'}}/>
                            </div>
                        </div>

                    </td>
                    
                </tr>
            )
        }
        return headersElement;
    }

    buildRequestExampleCode(codeData: any, index: number)
    {
        let formatted = '';
        switch(this.state.preferredCodeLanguage)
        {
            case 'delphi':
                formatted = this.buildDelphiCode(codeData);
                break;
            case 'curl':
                formatted = this.buildCurlCode(codeData);
                break;
        }

        var codeEditor =
        (
            <div style={{width: '100%'}}>
                <header className='request_code_example_header'>
                    <div>
                        <Select
                            defaultValue='GET'
                            value={codeData.method}
                            onChange=
                            {
                                (event)=> 
                                {
                                    this.docCopy[index].data.method = event.target.value;
                                    this.setState({});
                                }
                            }
                            MenuProps=
                            {
                                {
                                    classes:
                                    {
                                        paper: 'select_menu_paper',
                                        list: 'select_menu_editor_list'
                                    }
                                }
                            }
                            input=
                            {
                                <Input classes=
                                    {
                                        {
                                            root: 'select_editor_root',
                                            underline: 'select_underline',
                                        }
                                    }
                                />
                            }
                            classes=
                            {
                                {
                                    icon: 'select_icon',
                                }
                            }
                            IconComponent={ExpandMoreIcon}
                        >
                            <MenuItem 
                                classes={{root: 'select_editor_menu_item_root'}} 
                                value={'GET'}
                            >
                                GET
                            </MenuItem>
                            <MenuItem 
                                classes={{root: 'select_editor_menu_item_root'}} 
                                value={'POST'}
                            >
                                POST
                            </MenuItem>
                            <MenuItem 
                                classes={{root: 'select_editor_menu_item_root'}} 
                                value={'PUT'}
                            >
                                PUT
                            </MenuItem>
                            <MenuItem 
                                classes={{root: 'select_editor_menu_item_root'}} 
                                value={'PATCH'}>
                                PATCH
                            </MenuItem>
                            <MenuItem 
                                classes={{root: 'select_editor_menu_item_root'}} 
                                value={'DELETE'}
                            >
                                DELETE
                            </MenuItem>
                        </Select>
                    </div>
                </header>
                <div className='request_code_example_editor_body'>
                    <TabContext value={codeData.selected_tab}>
                        <TabList 
                            classes={{indicator: 'request_code_example_editor_tab_indicator'}}
                            value={'headers'}
                            onChange=
                            {
                                (event, value)=> 
                                {
                                    this.docCopy[index].data.selected_tab = value;
                                    console.log(this.docCopy[index].data);
                                    this.setState({});
                                }
                            }
                        >
                            <Tab 
                                key={'headers'}
                                className={'request_code_example_editor_tab_root'} 
                                classes={{wrapper: 'request_code_example_editor_tab_wrapper'}} 
                                label="Headers" 
                                value={'headers'}
                            />
                            <Tab 
                                key={'body'}
                                className={'request_code_example_editor_tab_root'} 
                                classes={{wrapper: 'request_code_example_editor_tab_wrapper'}} 
                                label="Body" 
                                value={'body'} 
                            />
                        </TabList>
                        <TabPanel value={'headers'}>
                            <table 
                                style={{width:'100%'}} 
                                className='request_code_example_editor_table' 
                            >
                                <thead>
                                    <tr>
                                        <th>
                                            Key
                                        </th>
                                        <th>
                                            Value
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.buildRequestExampleHeaderTableKeys(this.docCopy[index].data.headers, index)
                                    }
                                    <tr className='request_code_example_editor_table_new_row'>
                                        <td onClick={(e)=>{ (e.currentTarget?.firstChild?.firstChild as HTMLElement)?.focus() }}>
                                            <div className='request_code_example_editor_table_row'>
                                                <span 
                                                    suppressContentEditableWarning={true}
                                                    contentEditable={true}
                                                    onInput=
                                                    {
                                                        (e)=>
                                                        {
                                                            this.docCopy[index].data.headers.push
                                                            (
                                                                {
                                                                    key: e.currentTarget.innerText,
                                                                    value: ''
                                                                }
                                                            );
                                                            e.currentTarget.innerHTML = '';
                                                            let tableBody = e.currentTarget!.parentNode!.parentNode!.parentNode!.parentNode!;
                                                            this.setState
                                                            (
                                                                {},
                                                                () =>
                                                                {
                                                                    (tableBody!.children[tableBody!.children.length - 2]!.firstChild!.firstChild as HTMLElement)!.click();
                                                                }
                                                            );
                                                        }
                                                    }
                                                />
                                                <span className='request_code_example_editor_table_new_row_placeholder'>Key</span>
                                            </div>
                                        </td>
                                        <td onClick={(e)=>{ (e.currentTarget?.firstChild?.firstChild as HTMLElement)?.focus() }}>
                                            <div className='request_code_example_editor_table_row'>
                                                <span 
                                                    suppressContentEditableWarning={true}
                                                    contentEditable={true}
                                                    onInput=
                                                    {
                                                        (e)=>
                                                        {
                                                            this.docCopy[index].data.headers.push
                                                            (
                                                                {
                                                                    key: '',
                                                                    value: e.currentTarget.innerText
                                                                }
                                                            );
                                                            e.currentTarget.innerHTML = '';
                                                            let tableBody = e.currentTarget!.parentNode!.parentNode!.parentNode!.parentNode!;
                                                            this.setState
                                                            (
                                                                {},
                                                                () =>
                                                                {
                                                                    (tableBody.children[tableBody.children.length - 2]!.lastChild!.firstChild as HTMLElement)!.click();
                                                                }
                                                            );                                                        
                                                        }
                                                    }
                                                />                                                    
                                                <span className='request_code_example_editor_table_new_row_placeholder'>Value</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>    
                            </table>
                        </TabPanel>
                        <TabPanel value={'body'}>
                            <pre className='request_code_example_editor_body_code'>
                                <code 
                                    className='doc_code request_code_example_editor_body_codeblock doc_scroller no_border' 
                                    contentEditable={this.state.editing} 
                                    suppressContentEditableWarning={true}
                                    onPaste=
                                    {
                                        (e) =>
                                        {
                                            this.pasteWithoutFormatting(e);
                                        }
                                    }
                                    onInput=
                                    {
                                        (e) => 
                                        {
                                            console.log(e.currentTarget.innerText);
                                            e.currentTarget.innerText.length > 0 
                                            ?
                                                this.docCopy[index].data.body = e.currentTarget.textContent
                                            :
                                                this.docCopy[index].data.body = '{}'
                                        }
                                    }
                                    dangerouslySetInnerHTML={{__html: this.formatJSONCode(codeData.body)}}
                                />
                            </pre>        
                        </TabPanel>
                    </TabContext>
                </div>
            </div>
        );

        var codeTypes = 
        [
            <MenuItem value={'curl'}>Curl</MenuItem>,
            <MenuItem value={'delphi'}>Delphi</MenuItem>
        ];
         
        var code =
        (
            <div className='doc_component_outter' data-id={index} key={this.docCopy[index].id}>
                <div className='doc_code_example doc_component_inner' style={{width: '100%'}}>
                    <div className='doc_code_example header'>
                        <div style={{marginLeft: '12px'}}>
                            <a 
                                href={'/#'} 
                                className='copy_content_code_example' 
                                disabled={this.state.editing}
                                onClick=
                                {
                                    ()=>
                                    {
                                        if(!this.state.editing)
                                        {
                                            this.copyRequestExampleCode(`example-code-id-${index}`)
                                        }
                                    }
                                }>
                                <span style={{marginLeft: '5px'}}>Copiar</span>
                                <FileCopyRounded style={{fontSize: '14px'}}/>
                            </a>
                        </div>
                        <Select
                            value={this.state.editing ? 'editor' : this.state.preferredCodeLanguage}
                            onChange={this.state.editing ? ()=>{} : this.onPreferredCodeLanguageChanged}
                            MenuProps=
                            {
                                {
                                    classes:
                                    {
                                        paper: 'select_menu_paper',
                                        list: 'select_menu_list'
                                    }
                                }
                            }
                            input=
                            {
                                <Input classes=
                                    {
                                        {
                                            root: 'select_root',
                                            underline: 'select_underline',
                                        }
                                    }
                                />
                            }
                            classes=
                            {
                                {
                                    icon: 'select_icon',
                                }
                            }
                            IconComponent={ExpandMoreIcon}
                            name='code_language'
                        >
                            {
                                this.state.editing
                                ?
                                    <MenuItem value={'editor'}>Editor</MenuItem>
                                :
                                    codeTypes
                            }
                        </Select>
                    </div>
                    <div className='doc_code_example body'>
                        {
                            this.state.editing
                            ?
                                codeEditor
                            :
                                <code
                                    onCopy={()=>this.copyRequestExampleCode(`example-code-id-${index}`)}
                                    id={`example-code-id-${index}`}
                                    className={`doc_code_example_block ${this.state.preferredCodeLanguage}`} 
                                    dangerouslySetInnerHTML={{__html: formatted}}>
                                
                                </code>
                        }
                    </div>
                </div>
            </div>
        );
        return code;
    }

    //END CODE

    //START HTTP_REQUEST
    buildHttpRequest(obj: any, index: number)
    {
        /*
            {
                "title": "Get Guild Prune Count",
                "data": 
                {
                    "verb": "get",
                    "url": "/guilds/:id/prune"
                }
            }
        */
        var title = this.buildTitle2(obj.title, index);
        var id = obj.title.toLowerCase().replaceAll(' ', '-');
        var http_request =
        (
            <div className='doc_component_outter' data-id={index} key={this.docCopy[index].id}>
                <div className='doc_http_req doc_compontent_inner' id={id}>
                    {title}
                    <span className='doc_http_req_verb' 
                        contentEditable={this.state.editing} 
                        onPaste=
                        {
                            (e)=>
                            {
                                this.pasteWithoutFormatting(e);
                                //this.docCopy[index].data.data.verb = result;
                            }
                        }
                        suppressContentEditableWarning={true}
                        onInput=
                        {
                            (e) => e.currentTarget.innerText.length > 0 
                            ?
                                this.docCopy[index].data.data.verb = e.currentTarget.textContent
                            :
                                this.docCopy[index].data.data.verb = ' '
                        } 
                    >
                        {obj.data.verb}
                    </span>
                    <span className='doc_http_req_url' 
                        contentEditable={this.state.editing} 
                        onPaste={this.pasteWithoutFormatting}
                        suppressContentEditableWarning={true}
                        onInput=
                        {
                            (e)=> e.currentTarget.innerText.length > 0 
                            ?
                                this.docCopy[index].data.data.url = e.currentTarget.textContent
                            :
                                this.docCopy[index].data.data.url = ' '
                        } 
                    >
                        {obj.data.url}
                    </span>
                </div>
                {
                    // eslint-disable-next-line
                }<a className='doc_drag httpReq' style={{display: !this.state.editing ? 'none': 'inline'}}>
                    <DragHandle color='primary'/>
                </a>
            </div>            
        );
        return http_request;
    }
    //END HTTP_REQUEST

    //START IMAGE
    buildImage(obj: any, index: number)
    {
        //type: image
        // var obj =
        // {
        //     url: 'https://www.imgacademy.com/themes/custom/imgacademy/images/helpbox-contact.jpg',
        //     orginalWidth: 1600,
        //     originalHeight: 668,
        //     actualWidth: 753,
        //     actualHeight: 314
        // };

        var img = 
        (
            <div className='doc_component_outter' data-id={index} key={this.docCopy[index].id}>
                <div className='doc_compontent_inner'>
                    <ResizableBox 
                        className=
                        {
                            this.state.editing 
                            ? 
                                'editing' 
                            : 
                                ''
                        }
                        onResizeStop=
                        {
                            (e, data)=>
                            {
                                this.docCopy[index].data.actualWidth = data.size.width;
                                this.docCopy[index].data.actualHeight = data.size.height;
                            }                            
                        }
                        width={obj.actualWidth}
                        height={obj.actualHeight}
                        minConstraints={[100, 100]}
                        maxConstraints={[1024, 1024]}
                        resizeHandles=
                        {
                            this.state.editing 
                            ?
                                ['s','w','e','n', 'sw', 'nw', 'se', 'ne'] 
                            : 
                                []
                        }
                    >
                        <img 
                            alt=''
                            style={{width: '100%', height: '100%'}} 
                            src={obj.url}/>     
                    </ResizableBox>
                </div>
                {
                    // eslint-disable-next-line
                }<a className='doc_drag' style={{display: !this.state.editing ? 'none': 'inline'}}>
                    <DragHandle color='primary'/>
                </a>
            </div>

            
        );
        return img;
    }
    //END IMAGE

    //START TREEVIEW
    selectTreeViewObject(obj: any, selector: any, pos = 0) : any
    {
        if(pos + 1 >= selector.length)
        {
            return obj.children[selector[pos]];
        }
        return this.selectTreeViewObject(obj.children[selector[pos]], selector, pos+1);
    }

    buildTreeview(obj: any, index: number)
    {
        //Type treeview
        /*
            {
                "id": "root",
                "name": "Parent",
                "children":[
                    "name": "aaa"
                ]
            }
        */

        var treeview =
        (
            <div className='doc_component_outter' data-id={index} key={this.docCopy[index].id}>
                <div className='doc_compontent_inner'>
                    <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        onContextMenu={(e)=>this.handleTreeViewContextMenuClick(e, index)}
                        classes=
                        {
                            {
                                root: this.classes.treeViewRoot
                            }
                        }
                    >      
                        {this.buildTreeItem(obj, [])}
                    </TreeView>
                </div>
                {
                    // eslint-disable-next-line
                }<a className='doc_drag' style={{display: !this.state.editing ? 'none': 'inline'}}>
                    <DragHandle color='primary'/>
                </a>
            </div>            
        );
        return treeview;
    }

    buildTreeItem(obj: any, indexes: any)
    {
        var treeItems = null;
        if(obj.children)
        {
            treeItems = [];
            if(Array.isArray(obj.children))
            {
                for(var i = 0; i < obj.children.length; i++)
                {
                    var indexCopy = JSON.parse(JSON.stringify(indexes));
                    indexCopy.push(i);
                    treeItems.push(this.buildTreeItem(obj.children[i], indexCopy));
                }
            }
        }

        var treeItem = 
        (
            <TreeItem 
                data-treeselector={indexes}
                classes=
                {
                    {
                        root: this.classes.treeItemRoot
                    }
                }
                onLabelClick=
                {
                    (e)=>
                    {
                        if(this.state.editing)
                        {
                            e.preventDefault();
                        }
                    }
                }
                key={obj.id} 
                nodeId={obj.id.toString()} 
                label=
                {
                    !this.state.editing 
                    ?
                        obj.name
                    :
                        <div
                            contentEditable={true} 
                            onPaste={this.pasteWithoutFormatting}
                            suppressContentEditableWarning={true}
                            onInput=
                            {
                                (e) => 
                                {
                                    e.currentTarget!.textContent!.length > 0 
                                    ?
                                        obj.name = e.currentTarget.textContent
                                    :
                                        obj.name = ' '
                                }
                            } 
                        >
                            {
                                obj.name
                            }
                        </div>
                } 
            >
                {
                    treeItems
                }
            </TreeItem>
        );
        return treeItem;
    }

    treeviewInsertTreeViewChild()
    {
        var obj = this.docCopy[this.state.elementId!].data;
        var itemTemplate =
        {
            id: Math.ceil(Math.random()*10000000000000000),
            name: 'Child',
            children: []
        };
        if(this.state.treeSelector !== '')
        {
            var selector: Array<string> | Array<number> = this.state.treeSelector!.split(',');
            selector = selector.map(Number);
            obj = this.selectTreeViewObject(obj, selector);
        }
        obj.children.push(itemTemplate);
        this.handleCloseTreeViewContextMenu();
    }

    treeviewDeleteTreeViewItem()
    {
        var obj = this.docCopy[this.state.elementId!].data;
        var toDeleteIndex = -1;
        if(this.state.treeSelector !== '')
        {
            var selector: Array<string> | Array<number> = this.state.treeSelector!.split(',');
            selector = selector.map(Number);
            toDeleteIndex = selector[selector.length - 1];
            if(selector.length !== 1)
            {
                selector.pop();
                obj = this.selectTreeViewObject(obj, selector);
            }
        }
        
        if(toDeleteIndex !== -1)
        {
            obj.children.splice(toDeleteIndex, 1);
        }
        else
        {
            this.docCopy.splice(this.state.elementId!, 1);
        }
        this.handleCloseTreeViewContextMenu();
    }

    handleTreeViewContextMenuClick(e: any, id: number)
    {
        if(this.state.editing)
        {
            e.stopPropagation();
            e.preventDefault();
            document.addEventListener('contextmenu', this.handleClickOutsideMenu);
            this.setState
            (
                {
                    mouseTreeViewX: e.clientX - 2,
                    mouseTreeViewY: e.clientY - 4,
                    elementId: id,
                    treeSelector: e.target.closest('[data-treeselector]').getAttribute('data-treeselector'),
                    contextMenuRef: React.createRef(),
                    contextMenuCloseHandler: this.handleTreeViewContextMenuClick,
                    contextMenuAnyClickHandler: this.handleAnyClickTreeViewMenu,
                }
            );
        }
    }
    
    handleCloseTreeViewContextMenu()
    {
        this.setState
        (
            {
                mouseTreeViewX: null,
                mouseTreeViewY: null,
                treeSelector: null,
                contextMenuRef: null,
                contextMenuCloseHandler: null
            }
        )
    }

    handleAnyClickTreeViewMenu(e: any)
    {
        this.setState
        (
            {
                mouseTreeViewX: e.clientX - 2,
                mouseTreeViewY: e.clientY - 4,
            }
        )
    }
    //END TREEVIEW

    //START SUMMARY
    buildSummary(doc: any)
    {
        var summary = doc.filter((obj: any)=>obj.type === 'title' || obj.type === 'httpReq');
        var children = [];
        for(var i = 0; i < summary.length; i++)
        {  
            (
                function (i) 
                {
                    var title = '';
                    var id = '';
                    var classes = 'doc_summary';
                    if(summary[i].type === 'title')
                    {
                        title = summary[i].data;
                    }
                    else
                    {
                        classes = classes.concat(' doc_summary_sub');
                        title = summary[i].data.title;
                    }
                    if(title !== undefined)
                    {
                        id = title.toLowerCase().replaceAll(' ', '-');
                    }

                    var listItem = 
                    (
                        <li key={summary[i].id}>
                            <a href={'#'.concat(id)} className={classes}>
                                {title}
                            </a>
                        </li>
                       
                    );
                    children.push(listItem);
                }
            )(i);
        }
        return children;
    }
    //END SUMMARY

    //START BUILD DOC
    changeComponentPosition(direction: any)
    {
        var doc = this.state.doc;
        var docData = this.docCopy;
        var prevIndex = this.state.contextMenuComponentsID!;
        var newIndex = null;

        if(direction === directionUp)
        {
            newIndex = prevIndex - 1;
        }
        else
        {
            newIndex = prevIndex + 1;
        }
        if(prevIndex < 0)
        {
            prevIndex = 0;
        }
        docData.splice(newIndex, 0, docData.splice(prevIndex, 1)[0]);
        doc.doc = docData;
        this.setState
        (
            {
                doc: doc
            }
        )
        this.state.contextMenuCloseHandler!();
    }

    buildDocPage(docList: any)
    {
        var children = [];
        
        const actions: IActions = 
        {
            title: this.buildTitle,
            titleH1: this.buildTitle1,
            titleH2: this.buildTitle2,
            titleH3: this.buildTitle3,
            titleH6: this.buildTitle6,
            paragraph: this.buildParagraph,
            table: this.buildTable,
            httpReq: this.buildHttpRequest,
            jsonCode: this.buildJSONCode,
            image: this.buildImage,
            treeview: this.buildTreeview,
            requestExampleCode: this.buildRequestExampleCode
        }
        for(var i = 0; i < docList.length; i++)
        {
            let actionKey = docList[i].type as keyof IActions;
            var action = actions[actionKey];
            children.push
            (
                action(docList[i].data, i)
            )
        }
        
        return (
            <ReactDragListView 
                onDragEnd=
                {
                    (fromIndex: number, toIndex: number) =>
                    {
                        var doc = this.state.doc;
                        const item = this.docCopy.splice(fromIndex, 1)[0];
                        this.docCopy.splice(toIndex, 0, item);
                        doc.doc = this.docCopy;
                        this.setState
                        (
                            { 
                                doc: doc 
                            }
                        );
                    }
                }
                nodeSelector='div'
                handleSelector='a' 
                key={'doc_list_content'}
            >
                {children}
            </ReactDragListView>
        );
    }
    //END BUILD DOC

    //START DIALOG CONTROLS
    async toggleEditing()
    {
        var editing = this.state.editing;
        if(editing)
        {
            this.saveChanges().then(this.showSaveStatusNotification); 
        }
        
        this.setState
        (
            {
                editing: !editing,
            }
        )
    }

    handleCloseAddComponentsDialog()
    {
        this.setState
        (
            {
                openComponentsDialog: false,
                contextMenuComponentsID: null
            }
        );
    }
    
    handleOpenAddComponentsDialog()
    {
        this.setState
        (
            {
                openComponentsDialog: true
            }
        );
    }

    addNewTitle()
    {
        var doc = this.state.doc;
        var titleObj = 
        {
            id: Math.ceil(Math.random()*10000000000000000),
            type: 'title',
            data: 'Novo título'
        };
        if(this.state.contextMenuComponentsID === null)
        {
            doc.doc.push(titleObj);
        }
        else
        {
            doc.doc.splice(this.state.contextMenuComponentsID, 0, titleObj);
        }
        this.setState
        (
            {
                doc: doc,
                openComponentsDialog: false,
                contextMenuComponentsID: null
            }
        )
    }

    addNewTitle1()
    {
        var doc = this.state.doc;
        var titleObj = 
        {
            id: Math.ceil(Math.random()*10000000000000000),
            type: 'titleH1',
            data: 'Novo título 1'
        };
        if(this.state.contextMenuComponentsID === null)
        {
            doc.doc.push(titleObj);
        }
        else
        {
            doc.doc.splice(this.state.contextMenuComponentsID, 0, titleObj);
        }
        this.setState
        (
            {
                doc: doc,
                openComponentsDialog: false,
                contextMenuComponentsID: null
            }
        )
    }

    addNewTitle3()
    {
        var doc = this.state.doc;
        var titleObj = 
        {
            id: Math.ceil(Math.random()*10000000000000000),
            type: 'titleH3',
            data: 'Novo título 3'
        };
        if(this.state.contextMenuComponentsID === null)
        {
            doc.doc.push(titleObj);
        }
        else
        {
            doc.doc.splice(this.state.contextMenuComponentsID, 0, titleObj);
        }
        this.setState
        (
            {
                doc: doc,
                openComponentsDialog: false,
                contextMenuComponentsID: null
            }
        )
    }

    addNewTitle6()
    {
        var doc = this.state.doc;
        var titleObj = 
        {
            id: Math.ceil(Math.random()*10000000000000000),
            type: 'titleH6',
            data: 'Novo título 6'
        };
        if(this.state.contextMenuComponentsID === null)
        {
            doc.doc.push(titleObj);
        }
        else
        {
            doc.doc.splice(this.state.contextMenuComponentsID, 0, titleObj);
        }
        this.setState
        (
            {
                doc: doc,
                openComponentsDialog: false,
                contextMenuComponentsID: null
            }
        )
    }

    addNewParagraph()
    {
        var doc = this.state.doc;
        var paragraphObj = 
        {
            id: Math.ceil(Math.random()*10000000000000000),
            type: 'paragraph',
            data: 'Novo parágrafo'
        };
        if(this.state.contextMenuComponentsID === null)
        {
            doc.doc.push(paragraphObj);
        }
        else
        {
            doc.doc.splice(this.state.contextMenuComponentsID, 0, paragraphObj);
        }
        this.setState
        (
            {
                doc: doc,
                openComponentsDialog: false,
                contextMenuComponentsID: null
            }
        )
    }

    addNewTable()
    {
        var doc = this.state.doc;
        var tableObj = 
        {
            id: Math.ceil(Math.random()*10000000000000000),
            type: 'table',
            data: 
            {
                "header":
                [
                    "Campo",
                    "Tipo",
                    "Descrição"
                ],
                "body":
                [
                    [" "," "," "]
                ]
            }
        };
        if(this.state.contextMenuComponentsID === null)
        {
            doc.doc.push(tableObj);
        }
        else
        {
            doc.doc.splice(this.state.contextMenuComponentsID, 0, tableObj);
        }
        this.setState
        (
            {
                doc: doc,
                openComponentsDialog: false,
                contextMenuComponentsID: null
            }
        )
    }

    addNewJsonCode()
    {
        var doc = this.state.doc;
        var jsonCodeObj = 
        {
            id: Math.ceil(Math.random()*10000000000000000),
            type: 'jsonCode',
            data: "{}"
        };
        if(this.state.contextMenuComponentsID === null)
        {
            doc.doc.push(jsonCodeObj);
        }
        else
        {
            doc.doc.splice(this.state.contextMenuComponentsID, 0, jsonCodeObj);
        }
        this.setState
        (
            {
                doc: doc,
                openComponentsDialog: false,
                contextMenuComponentsID: null
            }
        )
    }

    addNewRequest()
    {
        var doc = this.state.doc;
        var httpReqObj = 
        {
            id: Math.ceil(Math.random()*10000000000000000),
            type: 'httpReq',
            data: 
            {
                title: "Request",
                data:
                {
                    verb: "get",
                    url: "/"
                }
            }
        };
        if(this.state.contextMenuComponentsID === null)
        {
            doc.doc.push(httpReqObj);
        }
        else
        {
            doc.doc.splice(this.state.contextMenuComponentsID, 0, httpReqObj);
        }
        this.setState
        (
            {
                doc: doc,
                openComponentsDialog: false,
                contextMenuComponentsID: null
            }
        )
    }

    addNewRequestExample()
    {
        var doc = this.state.doc;
        var httpReqExampleObj = 
        {
            'type': 'requestExampleCode',
            'data':
            {
                method: 'POST',
                selected_tab: 'body',
                headers: [],
                body:'{}'
            }
        };
        if(this.state.contextMenuComponentsID === null)
        {
            doc.doc.push(httpReqExampleObj);
        }
        else
        {
            doc.doc.splice(this.state.contextMenuComponentsID, 0, httpReqExampleObj);
        }
        this.setState
        (
            {
                doc: doc,
                openComponentsDialog: false,
                contextMenuComponentsID: null
            }
        )
    }

    addNewImage()
    {
        this.imageUploadButtonRef!.current.click();
    }

    async addNewImageFileSelected()
    {
        if(this.imageUploadButtonRef!.current.value)
        {
            var doc = this.state.doc;
            let formData = new FormData();           
            formData.append("file", this.imageUploadButtonRef!.current.files[0]);
            var response = await fetch
            (
                '/api/file/upload', 
                {
                    method: "POST", 
                    headers: 
                    {
                        'Accept': 'application/json, text/plain, */*',
                    },
                    body: formData
                }
            );    
            if(response.status === 200)
            {
                var jsonResp = await response.json();
                var actualWidth = this.contentRef!.current.getBoundingClientRect().width - 65;
                var actualHeight = (jsonResp.options.originalHeight / jsonResp.options.originalWidth) * actualWidth;
                var imgObj = 
                {
                    id: Math.ceil(Math.random()*10000000000000000),
                    type: 'image',
                    data: 
                    {
                        url: '/api/file/:identifier'.replace(':identifier', jsonResp.identifier),
                        originalWidth: jsonResp.options.originalWidth,
                        originalHeight: jsonResp.options.originalHeight,
                        actualWidth: actualWidth,
                        actualHeight: actualHeight
                    }
                };
                if(this.state.contextMenuComponentsID === null)
                {
                    doc.doc.push(imgObj);
                }
                else
                {
                    doc.doc.splice(this.state.contextMenuComponentsID, 0, imgObj);
                }
                this.setState
                (
                    {
                        doc: doc,
                        openComponentsDialog: false,
                        contextMenuComponentsID: null
                    }
                )
            }
        }
    }

    addNewTreeview()
    {
        var doc = this.state.doc;
        var treeviewObj = 
        {
            id: Math.ceil(Math.random()*10000000000000000),
            type: 'treeview',
            data: 
            {
                id: Math.ceil(Math.random()*10000000000000000),
                name: 'Parent',
                children:
                [
                    {
                        id: Math.ceil(Math.random()*10000000000000000),
                        name: 'Child',
                        children: []
                    },
                ],
            }
        };
        if(this.state.contextMenuComponentsID === null)
        {
            doc.doc.push(treeviewObj);
        }
        else
        {
            doc.doc.splice(this.state.contextMenuComponentsID, 0, treeviewObj);
        }
        this.setState
        (
            {
                doc: doc,
                openComponentsDialog: false,
                contextMenuComponentsID: null
            }
        );
    }

    //END DIALOG CONTROLS

    onErrorSnackClose()
    {
        this.setState
        (
            {
                showSnackbar: false
            }
        )
    }

    handleClickOutsideMenu(e: any)
    {
        if(e.which === 3)
        {
            e.preventDefault();
            this.state.contextMenuAnyClickHandler!(e);
        }
        else
        {
            this.state.contextMenuCloseHandler!(e);
        }
    }

    openComponentsContextMenu(e: any)
    {
        if(this.state.editing)
        {
            e.preventDefault();
            e.stopPropagation();
            document.addEventListener('contextmenu', this.handleClickOutsideMenu);
            var id = Number(e.target.closest("[data-id]").getAttribute('data-id'));
            this.setState
            (
                {
                    mouseComponentsX: e.clientX - 2,
                    mouseComponentsY: e.clientY - 4,
                    contextMenuRef: React.createRef(),
                    contextMenuComponentsID: id,
                    contextMenuAnyClickHandler: this.handleAnyClickComponentsMenu,
                    contextMenuCloseHandler: this.handleCloseComponentsContextMenu
                }
            );
        }
    }

    handleAnyClickComponentsMenu(e: any)
    {
        this.setState
        (
            {
                mouseComponentsX: e.clientX - 2,
                mouseComponentsY: e.clientY - 4,
            }
        )
    }

    handleCloseComponentsContextMenu()
    {
        document.removeEventListener('contextmenu', this.handleClickOutsideMenu);
        this.setState
        (
            {
                mouseComponentsX: null,
                mouseComponentsY: null,
                contextMenuRef: null,
                contextMenuCloseHandler: null
            }
        );
    }

    handleInsertNewComponentContextMenu(direction: any)
    {
        var id = this.state.contextMenuComponentsID!;
        
        if(direction === directionDown)
        {
            id = id + 1;
        }

        if(id === -1)
        {
            id = 0;
        }

        this.setState
        (
            {
                contextMenuComponentsID: id
            }
        )
        this.handleOpenAddComponentsDialog();
        this.handleCloseComponentsContextMenu();
    }

    deleteComponent()
    {
        var doc = this.state.doc;
        this.docCopy.splice(this.state.contextMenuComponentsID!, 1);
        doc.doc = this.docCopy;
        this.setState
        (
            {
                doc: doc
            }
        )
        this.handleCloseComponentsContextMenu();
    }

    pasteWithoutFormatting(e: any)
    {
        e.preventDefault();
        var content = null;
        console.log(e);
        if (e.clipboardData)
        {
            content = (e.originalEvent || e).clipboardData.getData('text/plain');
            document.execCommand('insertText', false, content);
        }
        else if (e.originalEvent.clipboardData) 
        {
            content = (e.originalEvent || e).clipboardData.getData('text/plain');
            document.execCommand('insertText', false, content);
        }
        // else if(window.clipboardData)
        // {
        //     content = window.clipboardData.getData('Text');
        //     document.selection.createRange().pasteHTML(content);
        // }
        return content;
    }
    
    render()
    {   
        return(
            <div style={{display: 'flex'}} className='doc_custom_scrollbar'>
                <ThemeProvider theme={this.drawerTheme}>
                    <ThemeProvider theme={this.appbarTheme}>
                        <AppBar position="fixed" key={'appbar'}>
                            <Toolbar>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={this.handleDrawerToggle}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Typography variant="h6" noWrap>
                                {                            
                                    this.state.doc.name !== null && this.state.doc.name !== undefined
                                    ?
                                        'Documentação - '.concat(this.state.doc.name)
                                    :
                                        'Documentação - '
                                }
                                </Typography>
                                <ThemeProvider theme={this.appbarEditIcon}>
                                    <div style={{right: '0', position: 'absolute'}}>
                                        {
                                            this.state.canEdit 
                                            ?   
                                                <IconButton
                                                    color="inherit"
                                                    aria-label="open drawer"
                                                    edge="start"
                                                    onClick={this.toggleEditing}
                                                >
                                                    {
                                                        this.state.editing 
                                                        ?
                                                            <SaveIcon/>
                                                        :
                                                            <EditIcon/>
                                                    }
                                                </IconButton>
                                            :
                                                null
                                        }
                                    </div>
                                </ThemeProvider>
                            </Toolbar>
                        </AppBar>
                    </ThemeProvider>
                    <nav className='docs_nav' key={'drawer'}>
                        <Hidden mdUp implementation="css">
                            <Drawer
                                variant="temporary"
                                anchor={'left'}
                                open={this.state.mobileOpen}
                                onClose={this.handleDrawerToggle}
                                ModalProps={{keepMounted: true}}
                            >
                                {this.drawer()}
                            </Drawer>
                        </Hidden>
                        <Hidden xsDown implementation="css">
                            <Drawer
                                variant="permanent"
                                open
                            >
                                {this.drawer()}
                            </Drawer>
                        </Hidden>
                    </nav>
                    <div id='content' className='doc_content_outter' onContextMenu={this.openComponentsContextMenu}>
                        <Toolbar /> {/*NEEDED TO MAKE THE SPACING*/}
                        <div className='doc_scroller doc_content' ref={this.contentRef}>
                            {
                                this.state.doc.doc !== null && this.state.doc.doc !== undefined
                                ?
                                    this.buildDocPage(this.state.doc.doc)
                                :
                                    null
                            }
                            <div 
                                key={'add-new'}
                                id='add-new' 
                                className='doc_addNewElement' 
                                onClick={this.handleOpenAddComponentsDialog} 
                                style={{display: this.state.editing ? 'block' : 'none'}}
                            >
                                <ThemeProvider theme={this.addNewElementsTheme}>
                                    <AddIcon color='primary'/>
                                    {'Adicionar novo conteúdo'}
                                </ThemeProvider>
                            </div>
                        </div>
                        {/*START COMPONENTS DIALOG*/}
                        <ThemeProvider theme={this.dialogComponentsContentTheme}>
                            <Dialog
                                key={'dialog_content_menu'}
                                open={this.state.openComponentsDialog}
                                aria-labelledby="dialog-title"
                                aria-describedby="dialog-description"
                                onClose={this.handleCloseAddComponentsDialog}
                                PaperProps={this.dialogComponentsTheme}
                            >
                                <DialogTitle id="alert-dialog-title">{'Escolha o componente que deseja utilizar'}</DialogTitle>
                                <DialogContent>
                                    <List>
                                        <ListItem button onClick={this.addNewTitle}>
                                            <TitleIcon/>
                                            <p className='doc_addNewElement_text'>
                                                {'Título'}
                                            </p>
                                        </ListItem>
                                        <ListItem button onClick={this.addNewTitle1}>
                                            <TitleIcon/>
                                            <p className='doc_addNewElement_text'>
                                                {'Título 1'}
                                            </p>
                                        </ListItem>
                                        <ListItem button onClick={this.addNewTitle3}>
                                            <TitleIcon/>
                                            <p className='doc_addNewElement_text'>
                                                {'Título 3'}
                                            </p>
                                        </ListItem>
                                        <ListItem button onClick={this.addNewTitle6}>
                                            <TitleIcon/>
                                            <p className='doc_addNewElement_text'>
                                                {'Título 6'}
                                            </p>
                                        </ListItem>
                                        <ListItem button onClick={this.addNewParagraph}>
                                            <ParagraphIcon/>
                                            <p className='doc_addNewElement_text'>
                                                {'Parágrafo (Utilizado para qualquer tipo texto genérico)'}
                                            </p>
                                        </ListItem>
                                        <ListItem button onClick={this.addNewTable}>
                                            <TableIcon/>
                                            <p className='doc_addNewElement_text'>
                                                {'Tabela (Normalmente utilizado para mostrar campos e tipos de dado)'}
                                            </p>
                                        </ListItem>
                                        <ListItem button onClick={this.addNewJsonCode}>
                                            <CodeIcon/>
                                            <p className='doc_addNewElement_text'>
                                                {'Código (Utilizado para exemplificar um objeto json)'}
                                            </p>
                                        </ListItem> 
                                        <ListItem button onClick={this.addNewRequest}>
                                            <HttpIcon/>
                                            <p className='doc_addNewElement_text'>
                                                {'Request (Utilizado para demonstrar a url e o verbo da request)'}
                                            </p>
                                        </ListItem>    
                                        <ListItem button onClick={this.addNewRequestExample}>
                                            <span className="material-icons">
                                                integration_instructions
                                            </span>
                                            <p className='doc_addNewElement_text'>
                                                {'Integração (Utilizado para exemplificar um request)'}
                                            </p>
                                        </ListItem> 
                                        <ListItem button onClick={this.addNewImage}>
                                            <ImageIcon/>
                                            <input onChange={this.addNewImageFileSelected} type='file' ref={this.imageUploadButtonRef} accept='image/jpg,image/jpeg,image/png' hidden/>
                                            <p className='doc_addNewElement_text'>
                                                {'Imagem'}
                                            </p>
                                        </ListItem>
                                        <ListItem button onClick={this.addNewTreeview}>
                                            <TreeViewIcon/>
                                            <p className='doc_addNewElement_text'>
                                                {'TreeView (Utilizado para estruturas em árvore)'}
                                            </p>
                                        </ListItem>                      
                                    </List> 
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleCloseAddComponentsDialog} color="primary">
                                        {'Cancelar'}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </ThemeProvider>
                    </div>
                    <ThemeProvider theme={this.menuTheme}>
                        {/* END COMPONENTS DIALOG */}
                        {/*START TABLE*/}
                        <Menu
                            key={'table_menu'}
                            ref=
                            {
                                this.state.contextMenuRef 
                            }
                            open={this.state.mouseY !== null}
                            onClose={this.handleCloseTableContextMenu.bind(this)}
                            anchorReference="anchorPosition"
                            anchorPosition=
                            {
                                this.state.mouseY !== null && this.state.mouseX !== null
                                ? 
                                    { 
                                        top: this.state.mouseY, 
                                        left: this.state.mouseX 
                                    }
                                : 
                                    undefined
                            }
                        >
                            <MenuItem onClick={this.tableInsertRowAbove}>
                                {'Inserir linha acima'}
                            </MenuItem>
                            <MenuItem onClick={this.tableInsertRowBelow}>
                                {'Inserir linha abaixo'}
                            </MenuItem>
                            <MenuItem onClick={this.tableInsertColumnLeft}>
                                {'Inserir coluna à esquerda'}
                            </MenuItem>
                            <MenuItem onClick={this.tableInsertColumnRight}>
                                {'Inserir coluna à direita'}
                            </MenuItem>
                            <Divider/>
                            <MenuItem onClick={this.tableDeleteRow}>
                                {'Deletar linha'}
                            </MenuItem>
                            <MenuItem onClick={this.tableDeleteColumn}>
                                {'Deletar coluna'}
                            </MenuItem>
                        </Menu>
                        {/* END TABLE */}
                        {/*START TREEVIEW*/}
                        <Menu
                            key={'treeview_menu'}
                            ref=
                            {
                                this.state.contextMenuRef 
                            }
                            open={this.state.mouseTreeViewY !== null}
                            onClose={this.handleCloseTreeViewContextMenu.bind(this)}
                            anchorReference="anchorPosition"
                            anchorPosition=
                            {
                                this.state.mouseTreeViewY !== null && this.state.mouseTreeViewX !== null
                                ? 
                                    { 
                                        top: this.state.mouseTreeViewY, 
                                        left: this.state.mouseTreeViewX 
                                    }
                                : 
                                    undefined
                            }
                        >
                            <MenuItem onClick={this.treeviewInsertTreeViewChild}>
                                {'Inserir child'}
                            </MenuItem>
                            <MenuItem onClick={this.treeviewDeleteTreeViewItem}>
                                {
                                    this.state.treeSelector !== ''
                                    ?
                                        'Deletar item'
                                    :
                                        'Deletar árvore'
                                }
                            </MenuItem>
                        </Menu>
                        {/* END TREEVIEW */}
                        {/*START COMPONENTS MENU*/}
                        <Menu
                            key={'general_menu'}
                            ref={this.state.contextMenuRef}
                            open={this.state.mouseComponentsY !== null}
                            onClose={this.handleCloseComponentsContextMenu.bind(this)}
                            anchorReference="anchorPosition"
                            anchorPosition=
                            {
                                this.state.mouseComponentsY !== null && this.state.mouseComponentsX !== null
                                ? 
                                    { 
                                        top: this.state.mouseComponentsY, 
                                        left: this.state.mouseComponentsX 
                                    }
                                : 
                                    undefined
                            }
                        >
                            <MenuItem onClick={()=>this.handleInsertNewComponentContextMenu(directionUp)}>
                                {'Inserir componente acima'}
                            </MenuItem>
                            <MenuItem onClick={()=>this.handleInsertNewComponentContextMenu(directionDown)}>
                                {'Inserir componente abaixo'}
                            </MenuItem>
                            <Divider/>
                            <MenuItem disabled={this.state.contextMenuComponentsID === 0} onClick={()=>this.changeComponentPosition(directionUp)}>
                                {'Mover componente para cima'}
                            </MenuItem>
                            <MenuItem disabled={this.state.contextMenuComponentsID === this.docCopy.length - 1} onClick={()=>this.changeComponentPosition(directionDown)}>
                                {'Mover componente para baixo'}
                            </MenuItem>
                            <Divider/>
                            <MenuItem onClick={()=>this.deleteComponent()}>
                                {'Apagar componente'}
                            </MenuItem>
                        </Menu>
                        {/* END COMPONENTS MENU */}
                    </ThemeProvider>
                </ThemeProvider>
                <Snackbar 
                    open={this.state.showSnackbar} 
                    onClose={this.onErrorSnackClose} 
                    autoHideDuration={2000}>
                    <Alert severity="error">
                        {'Ocorreu um erro ao salvar as alterações'}
                    </Alert>
                </Snackbar>
            </div>
        );
    }
}

export default withSnackbar(withStyles(useStyles)(DocPage));
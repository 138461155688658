import React from 'react';  
import '../../css/popup/Popup.css';  
import 
{  
    Dialog,
	DialogContent,
    Button
} from '@material-ui/core';
import { createTheme, makeStyles } from '@material-ui/core/styles'; 
import { History, LocationState } from "history";

interface ISyncLogPopupProps
{
    history?: History<LocationState>,
    show: boolean,
    data: string | null,
    closePopup: () => void,
}

class SyncLogPopup extends React.Component<ISyncLogPopupProps, any>
{  

    buttonTheme = createTheme
    (
        {
            overrides:
            {
                MuiIconButton: 
                {
                    root:
                    {
                        color: 'white !important'
                    }
                }
            }
        }
    );

    // dialogTheme = createTheme
	// (
	// 	{
	// 		overrides:
	// 		{
	// 			MuiDialog:
	// 			{
    //                 paper:
    //                 {
    //                     borderRadius: 5,
    //                     width: '75vw',
    //                     height: '50vw',
    //                     padding: '10px'
    //                 }
    //             },
	// 		}
	// 	}
	// );

    onPopupInnerClick(event: any)
    {
        event.stopPropagation();
    }

    render() : JSX.Element | null
    {  
        if(this.props.show) 
        {
            return (  
                // <ThemeProvider theme={this.dialogTheme}>
                    <Dialog
                        onClick={(event)=> event.stopPropagation()}
                        onClose={()=>this.props.closePopup()}
                        open={this.props.show}
                    >
                        <DialogContent>
                            <h2>Objeto</h2>
                            <div className='info_section' style={{flexDirection: 'column'}}>
                                <h1>
                                    {'Data:'}
                                </h1>
                                <SyncLogData
                                    data=
                                    {
                                        this.props.data ?? ''
                                    }
                                />
                                <br></br>
                            </div>
                        </DialogContent>
                    </Dialog>
                // </ThemeProvider>
            );
        }
        else
        {
            return(null);
        }
    }  
}  

const useStyles = makeStyles
(
    {
        copyButtonRoot:
        {
            backgroundColor: 'rgba(0,0,0,.3)',
            transition: 'opacity .2s ease-in-out',
            fontSize: '10pt',
            minWidth: '0',
            textTransform: 'none',
            color: 'white',
            '&:hover':
            {
                backgroundColor: 'rgba(0,0,0,.3)'
            }
        }
    }
)

interface ISyncLogDataProps
{
    data: string
}

function SyncLogData(props: ISyncLogDataProps)
{
    const classes = useStyles();
    const [isMouseOver, setIsMouseOver] = React.useState<boolean>(false);
    const [copyButtonText, setCopyButtonText] = React.useState<string>('Copiar');
    const copiedText = 'Copiado!';
    const copyText = 'Copiar';

    return (
        <div
            style=
            {
                {
                    display: 'flex'
                }
            }
            onMouseOver=
            {
                (e) =>
                {
                    setIsMouseOver(true);
                    setCopyButtonText(copyText);
                }
            }
            onMouseLeave=
            {
                (e) =>
                {
                    setIsMouseOver(false);
                    setCopyButtonText(copyText);
                }
            }
        >
            <pre 
                style=
                {
                    {
                        overflow: 'auto',
                        flex: '1 1 auto',
                        fontSize: '11pt',
                    }
                }
            >
                {
                    JSON.stringify(JSON.parse(props.data ?? ''), null, 4)
                }
            </pre>             
            <div
                style=
                {
                    {
                        display: 'flex',
                        flexDirection: 'row-reverse' as const,
                    }
                }
            >
                <Button
                    style=
                    {
                        {
                            position: 'absolute',
                            opacity: isMouseOver ?  1 : 0,
                            marginRight: '15px'
                        }
                    }
                    classes=
                    {
                        {
                            root: classes.copyButtonRoot
                        }
                    }
                    onClick=
                    {
                        (e) =>
                        {
                            navigator.clipboard.writeText(props.data);
                            setCopyButtonText(copiedText);
                        }
                    }
                >
                    {
                        copyButtonText
                    }
                </Button>
            </div>
        </div>
    );
}

export default SyncLogPopup;
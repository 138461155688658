import React from 'react';  
import '../../css/popup/Popup.css';  
import LogoTopOn from '../../resources/LogoTopOn.png';
import 
{  
    Button, 
    TextField,
    Select,
    MenuItem,
    IconButton
} from '@material-ui/core';
import PasswordVisibile from '@material-ui/icons/Visibility'
import PasswordVisibileOff from '@material-ui/icons/VisibilityOff'
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'; 
import User from '../../model/user';

const DEFAULT_USER = '001';
const ADMIN_USER = '000';

interface IPopupAddEditUserProps
{
    data: User | null,
    closePopup: (reload: boolean) => void,
    show: boolean
}

interface IPopupAddEditUserState 
{
    title: string,
    buttonAddModify: string,
    showPassword: boolean,
    nameError: boolean,
    nameHelper: string,
    usernameError: boolean,
    usernameHelper: string,
    passwordError: boolean,
    passwordHelper: string,
    passwordConfirmError: boolean,
    passwordConfirmHelper: string,
    passwordRequired: boolean,
    role: string,
    name: string,
    username: string
}

class PopupAddEditUser extends React.Component<IPopupAddEditUserProps, IPopupAddEditUserState>
{  
    constructor(props: IPopupAddEditUserProps)
    {
        super(props);
        var title = 'Adicionar um usuário:';
        var buttonAddModify = 'Adicionar';
        var state = 
        {
            role: DEFAULT_USER,
            name: '',
            username: '',
            passwordRequired: true,
        }
        if(props.data != null)
        {
            title = 'Modificar usuário:';
            buttonAddModify = 'Modificar';
            state.role = props.data.role;
            state.name = props.data.name;
            state.username = props.data.username;
            state.passwordRequired = false;
        }
        this.state = 
        {
            title: title,
            buttonAddModify: buttonAddModify,
            showPassword: false,
            nameError: false,
            nameHelper: '',
            usernameError: false,
            usernameHelper: '',
            passwordError: false,
            passwordHelper: '',
            passwordConfirmError: false,
            passwordConfirmHelper: '',
            passwordRequired: state.passwordRequired,
            role: state.role,
            name: state.name,
            username: state.username
        }

        
    }

    UNSAFE_componentWillReceiveProps(props: IPopupAddEditUserProps)
    {
        var title = 'Adicionar um usuário:';
        var buttonAddModify = 'Adicionar';
        var state = 
        {
            role: DEFAULT_USER,
            name: '',
            username: '',
            passwordRequired: true
        }
        if(props.data != null)
        {
            title = 'Modificar usuário:';
            buttonAddModify = 'Modificar';
            state.role = props.data.role;
            state.name = props.data.name;
            state.username = props.data.username;
            state.passwordRequired = false;

        }
        this.setState
        (
            {
                title: title,
                buttonAddModify: buttonAddModify,
                role: state.role,
                name: state.name,
                username: state.username,
                passwordRequired: state.passwordRequired
            }
        );
    }

    buttonsTheme = createTheme
    (
        {
            overrides:
            {
                MuiButton:
                {
                    root:
                    {
                        borderRadius: '24px',
                        color: 'white !important',
                        marginLeft: '32px'
                    },
                    containedPrimary: 
                    {
                        backgroundColor: '#008EB4',
                        '&:hover':
                        {
                            backgroundColor: '#005E77'
                        }
                    },
                    containedSecondary:
                    {
                        backgroundColor: '#686D76',
                        '&:hover':
                        {
                            backgroundColor: '#444444'
                        }
                    }
                },
                MuiIconButton:
                {
                    colorPrimary:
                    {
                        color: 'grey'
                    }
                }
            }
        }
    );

    popupTheme = createTheme
    (
        {
            overrides:
            {
                MuiFormLabel:
                {
                    root:
                    {
                        "&$focused": 
                        {
                            color: 'black',
                        }
                    }
                },
                MuiInput:
                {
                    underline:
                    {
                        '&:before':
                        {
                            color: 'black',
                            borderColor: 'black'
                        },
                        '&:hover':
                        {
                            color: 'black',
                            borderColor: 'black'
                        },
                        '&:after':
                        {
                            color: 'black',
                            borderColor: 'black'
                        }
                    }
                }
            }
        }
    );

    onPopupInnerClick(event: any)
    {
        event.stopPropagation();
    }

    passwordVisibilityToggle(event: any)
    {
        var show = this.state.showPassword;
        this.setState
        (
            {
                showPassword: !show
            }
        )
    }

    onRoleChange(event: any)
    {
        this.setState
        (
            {
                role: event.target.value
            }
        )
    }

    onFormSubmit(event: any)
    {
        var username = document.querySelector<HTMLInputElement>("#username")!.value;
        var password = document.querySelector<HTMLInputElement>("#password")!.value;
        var passwordConfirm = document.querySelector<HTMLInputElement>("#password-confirm")!.value;
        var name = document.querySelector<HTMLInputElement>("#name")!.value;
        var role = this.state.role;

        var errors =
        {
            usernameError: false,
            usernameHelper: '',
            nameError: false,
            nameHelper: '',
            passwordError: false,
            passwordHelper: '',
            passwordConfirmError: false,
            passwordConfirmHelper: '',
        }

        if(name.length <= 0)
        {
            errors.nameError = true;
            errors.nameHelper = 'O nome não pode ser nulo';
        }

        if(!username.match(/(?![0-9A-Z_]).*/gi))
        {
            errors.usernameError = true;
            errors.usernameHelper = 'Nome de usuário inválido!';
        }
        
        if(password === passwordConfirm)
        {
            if(this.state.passwordRequired || password.trim().length > 0)
            {
                if(password.length >= 12)
                {
                    if(password.match(/((?!ç)[., À-ú])/gi))
                    {
                        errors.passwordError = true;
                        errors.passwordHelper = 'Senha inválida!';
                        errors.passwordConfirmError = true;
                        errors.passwordConfirmHelper = 'Senha inválida!';
                    }
                }
                else
                {
                    errors.passwordError = true;
                    errors.passwordHelper = 'Senha inválida!';
                }
                
            }            
        }
        else
        {
            errors.passwordError = true;
            errors.passwordHelper = 'As senhas não coincidem';
            errors.passwordConfirmError = true;
            errors.passwordConfirmHelper = 'As senhas não coincidem'
        }
        
        this.setState
        (
            {
                nameError: errors.nameError,
                nameHelper: errors.nameHelper,
                usernameError: errors.usernameError,
                usernameHelper: errors.usernameHelper,
                passwordError: errors.passwordError,
                passwordHelper: errors.passwordHelper,
                passwordConfirmError: errors.passwordConfirmError,
                passwordConfirmHelper: errors.passwordConfirmHelper
            }
        );

        if(!errors.nameError && !errors.usernameError && !errors.passwordError && !errors.passwordConfirmError)
        {
            var url = '/api/user';
            var method = 'POST';
            if(this.props.data != null)
            {
                url = `/api/user/${this.props.data.id}`;
                method = 'PATCH';
            }
            let user = 
            {   
                username: username,
                name: name,
                role: role
            }

            if(password.trim().length > 0)
            {
                Object.assign(user, {password: password});
            }

            fetch
            (
                url, 
                {
                    method: method,
                    headers: 
                    {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify
                    (
                        {
                            user: user,
                        }
                    ),
                }
            )
            .then
            (
                (resp) =>
                {
                    this.props.closePopup(true);
                }
            );   
        }
        event.preventDefault();
    }

    nameChange(e: any)
    {
        this.setState
        (
            {
                name: e.target.value
            }
        )
    }

    usernameChange(e: any)
    {
        this.setState
        (
            {
                username: e.target.value
            }
        )
    }

    render() 
    {  
        if(this.props.show)
        {
            return (  
                <div className='popup' onClick={()=>this.props.closePopup(false)}>  
                    <div className='popup_with_logo_inner' onClick={this.onPopupInnerClick}>  
                        <ThemeProvider theme={this.popupTheme}>
                            <h2>{this.state.title}</h2>
                            <div className='add_edit_popup'>
                                <form 
                                    autoComplete="on"
                                    onSubmit={this.onFormSubmit.bind(this)}
                                >
                                    <div className='column'>
                                        <div className='row'>
                                            <TextField className='inputwidth' onChange={this.nameChange.bind(this)} value={this.state.name} id='name' error={this.state.nameError} helperText={this.state.nameHelper} label="Nome" required></TextField>
                                            <div className='input marginleft'>
                                                <Select id='role' onChange={this.onRoleChange.bind(this)} className='inputwidth' label="Tipo de usuário" value={this.state.role} required>
                                                    <MenuItem value={DEFAULT_USER}>Padrão</MenuItem>
                                                    <MenuItem value={ADMIN_USER}>Admin</MenuItem>
                                                </Select>
                                            </div>
                                        </div>
                                        <TextField value={this.state.username} onChange={this.usernameChange.bind(this)} id='username' error={this.state.usernameError} helperText={this.state.usernameHelper} className='biggerinput' label="Username" required></TextField>
                                        <div className='row'>
                                            <TextField id='password' error={this.state.passwordError} helperText={this.state.passwordHelper} label="Senha" className='inputwidth' type={this.state.showPassword ? 'text' : 'password'} required={this.state.passwordRequired}></TextField>
                                            <TextField id='password-confirm' error={this.state.passwordConfirmError} helperText={this.state.passwordConfirmHelper} className='confirmpass marginleft' type={this.state.showPassword ? 'text' : 'password'} label="Confirmar" required={this.state.passwordRequired}></TextField>
                                            <ThemeProvider theme={this.buttonsTheme}>
                                                <IconButton color='primary' onClick={this.passwordVisibilityToggle.bind(this)}>
                                                    {
                                                        this.state.showPassword 
                                                        ?
                                                            <PasswordVisibile/>
                                                        :
                                                            <PasswordVisibileOff/>
                                                    }
                                                    
                                                </IconButton>
                                            </ThemeProvider>
                                        </div>
                                        <div className='buttoncontainer'>
                                            <ThemeProvider theme={this.buttonsTheme}>
                                                    <Button className='button' onClick={()=>this.props.closePopup(false)} variant='contained' color="secondary">
                                                        Cancelar
                                                    </Button>
                                                    <Button className='button' variant='contained' color="primary" type='submit'>
                                                        {this.state.buttonAddModify}
                                                    </Button>
                                            </ThemeProvider>
                                        </div>
                                    </div>
                                </form>
                                <div className='column logo'>
                                    <img src={LogoTopOn} width='100%' height='auto' alt='logo'></img>
                                </div>
                            </div>
                        </ThemeProvider>
                    </div>
                </div>  
            );  
        }
        else
        {
            return(null);
        }
    }  
}  

export default PopupAddEditUser;
import React from 'react';
import 
{ 
	Button,
	ButtonGroup,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	LinearProgress,
	IconButton,
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'; 
import '../../css/materialUiIconPicker/materialUiIconPicker.css';
import { Grid, AutoSizer } from 'react-virtualized';
import LocalIcon from '../../model/localIcon';
import defer from 'defer-promise';

interface IMaterialUiIconPickerProps
{
	onClose: (icon: string) => void,
	open: boolean
}

interface IMaterialUiIconPickerState 
{
    searchText: string,
	_icons: Array<LocalIcon>,
	icons: Array<LocalIcon>,
	icon: string | null,
	iconRowOffset: number,
	selected: LocalIcon | null | undefined,
	didSearch: boolean
}

class MaterialUiIconPicker extends React.Component<IMaterialUiIconPickerProps, IMaterialUiIconPickerState>
{
	icons: Array<LocalIcon> = [];
	isLoadingIcons: boolean = false;
	requestWaitingForIcons: Array<any> = [];

	constructor(props: IMaterialUiIconPickerProps)
	{
		super(props);
		this.iconRender = this.iconRender.bind(this);
		this.state = 
		{
			searchText: '',
			_icons: [],
			icons: [],
			icon: null,
			iconRowOffset: 0,
			selected: null,
			didSearch: false
		};
	}

	getIcons() : Promise<Array<LocalIcon>>
	{
		if (this.icons) 
		{
			return Promise.resolve(this.icons);
		}

		if (this.isLoadingIcons) 
		{
			const p = defer<Array<LocalIcon>>();

			this.requestWaitingForIcons.push(p);
			return p.promise;
		}

		this.isLoadingIcons = true;

		return new Promise<Array<LocalIcon>>
		(
			(resolve, reject) =>
			{
				fetch
				(
					'https://raw.githubusercontent.com/google/material-design-icons/3.0.2/iconfont/codepoints'
				)
				.then
				(
					response => response.text()
				)
				.then
				(
					data => data.split('\n')
				)
				.then
				(
					namesAndCodes => namesAndCodes.map
					(
						nameAndCode => 
						{
							const parts = nameAndCode.split(' ');
							return new LocalIcon
							(
								parts[0],
								parts[1]
							);
						}
					)
				)
				.then
				(
					(icons) => 
					{
						this.icons = icons;
						this.isLoadingIcons = false;
						if (this.requestWaitingForIcons.length > 0) 
						{
							this.requestWaitingForIcons.map
							(
								awaitingPromise => 
								{
									return awaitingPromise.resolve(icons);
								}
							);
						}
						resolve(icons);
					}
				)
			}
		);
	}

	buttonsTheme = createTheme
    (
        {
            overrides:
            {
                MuiButton:
                {
					root:
					{
						border: '0 !important',
					},
					textPrimary:
					{
						color: 'white',
					},
					textSecondary:
					{
						color: '#008EB4',
						backgroundColor: '#FFFFFF',
						'&:hover':
                        {
                            backgroundColor: '#EBEBEB'
                        }
					},
					contained:
					{
						'&:disabled':
                        {
                            color: '#A0A0A0',
							backgroundColor: '#F5F5F5',
                        }
					},
                    containedPrimary: 
                    {
						backgroundColor: '#008EB4',
                        '&:hover':
                        {
                            backgroundColor: '#006682'
                        }
					},
                },
            }
        }
	);
	
	dialogTheme = createTheme
	(
		{
			overrides:
			{
				MuiDialog:
				{
					paperWidthSm:
					{
						width: '600px',
						height: 'calc(100% - 64px)'
					}
				}
			}
		}
	);

	UNSAFE_componentWillMount()
	{
		if (!document.querySelector('[href="https://fonts.googleapis.com/icon?family=Material+Icons"]')) 
		{
			var link = document.createElement('link');
			link.setAttribute('href', 'https://fonts.googleapis.com/icon?family=Material+Icons');
			link.setAttribute('rel', 'stylesheet');
			document.querySelector('head')?.appendChild(link);
		}
	}

	componentDidMount() 
	{
		var _this = this;
		var iconsPromise = this.getIcons();
		iconsPromise.then
		(
			function (icons) 
			{
				return _this.showIcons(icons);
			}
		);
	}

	// isBottom(el) 
	// {
	// 	return el.getBoundingClientRect().bottom - 664 <= 0;
	// }

	// rowsFromBottom(el)
	// {
	// 	return (el.getBoundingClientRect().bottom-664) / 120;
	// }

	// rowsFromTop(el)
	// {
	// 	return Math.abs((document.getElementById('iconsGrid').getBoundingClientRect().bottom - 3363) / 120);
	// }

	
	// trackScrolling() 
	// {
	// 	// const grid = document.getElementById('iconsGrid');
	// 	// const offsetRows = 5;
	// 	// var rowsFromTop = this.rowsFromTop(grid);
	// 	// var rowsFromBottom = this.rowsFromBottom(grid);
	// 	// if (rowsFromTop >= offsetRows && rowsFromTop <= offsetRows+0.5) 
	// 	// {
	// 	// 	var iconRowOffset = this.state.iconRowOffset + (4*offsetRows);
	// 	// 	console.log('Load more top rows');
	// 	// 	// this.setState
	// 	//   	// (
	// 	// 	// 	{
	// 	// 	// 		iconRowOffset: iconRowOffset,
	// 	// 	// 	}
	// 	// 	// )
	// 	// }
	// 	// else if (rowsFromBottom >= offsetRows && rowsFromBottom <= offsetRows+0.5) 
	// 	// {
	// 	// 	console.log('Load more bottom rows');
	// 	// }
	// };

	handleClose(event: any, icon: LocalIcon | null | undefined)
	{
		event.stopPropagation();
		let iconName = '';
		if(!icon)
		{
			iconName = '';
		}
		else if(iconName === 'backdropClick')
		{
			iconName = ''
		}
		else
		{
			iconName = icon!.name;
		}
		this.props.onClose(iconName);
	}

	showIcons(icons: Array<LocalIcon>)
	{
		this.setState
		(
			{ 
				_icons: icons, 
			 	icons: icons 
			}
		);
	}

	pickAndClose(event: any) 
	{
		this.handleClose(event, this.state.selected);
	}

	select(icon: LocalIcon) 
	{
		this.setState
		(
			{
				selected: icon,
			}
		);
	}

	filterList(event: any) 
	{
		if (event.target.value.toLowerCase().length === 0) 
		{
			this.clearSearch(undefined);
		} 
		else 
		{
			var updatedList = this.state._icons;
			updatedList = updatedList.filter
			(
				function (item) 
				{
					var searches = item.name.split('_').map
					(
						function (namePiece) 
						{
							return namePiece.search(event.target.value.toLowerCase()) !== -1;
						}
					);
					return searches.indexOf(true) > -1;
				}
			);			
			this.setState
			(
				{
					searchText: event.target.value,
					_icons: this.state._icons,
					icons: updatedList,
					selected: this.state.selected,
					didSearch: true,
					iconRowOffset: 0
				}
			);
		}
	}

	clearSearch(event : any) 
	{
		if(event !== undefined)
		{
			event.stopPropagation();
		}
		this.setState
		(
			{
				searchText: '',
				_icons: this.state._icons,
				icons: this.state._icons,
				selected: this.state.selected,
				didSearch: false
			}
		);
	}

	//iconRender(icon, index)
	iconRender(params: {columnIndex: number, key: any, rowIndex: number, style: any})
	{
		var index = params.columnIndex + (params.rowIndex * 4);
		var icon = this.state.icons[index];
		return(		
			<div
				key={params.key}
				style={params.style}
				className='iconsItem'
				onClick={(event)=>{this.select(icon); event.stopPropagation();}}
			>
				<div
					className=
					{
						this.state.selected && this.state.selected.name === icon.name 
						? 
							'selectedBackgroundBox '
						: 
							'backgroundBox'
					}
				>
				</div>
				<Icon className='material-icons iconsItemIcon'>
					{icon.name}
				</Icon>
				<div className='iconsItemCaption'>
					{icon.name.split('_').join(' ')}
				</div>
			</div>
		);
	}

	render() 
	{
		console.log(Math.floor(this.state.icons.length/4));
		var actions = 
		(
			<ThemeProvider theme={this.buttonsTheme}>
				<ButtonGroup variant='text'>
					<Button
						color='secondary'
						onClick={(e: any) => this.handleClose(e, null)}
					>
						{'Cancelar'}
					</Button>
					<Button 
						variant='contained'
						color='primary'
						disabled={!this.state.selected}
						onClick={this.pickAndClose.bind(this)}>
						<div style={{display: 'flex', flexDirection: 'row'}}>
							{
								this.state.selected 
								? 
									<Icon className='material-icons'>
										{this.state.selected.name}
									</Icon>
								: 
									null
							}
							{'Escolher'}
						</div>
					</Button>
				</ButtonGroup>
			</ThemeProvider>
		);
							
		return (
			<ThemeProvider theme={this.dialogTheme}>
				<Dialog
					onClick={(event)=> event.stopPropagation()}
					onClose={(event: any) => this.handleClose(event, null)}
					open={this.props.open}
				>
					<DialogTitle className='header wrapper'>
						<div className='header search'>				
							<Icon style={{paddingLeft:10}} className='material-icons'>
								{'search'}
							</Icon>
							<TextField
								id='searchInput'
								label='Search' 
								className='header input'
								value={this.state.searchText}
								onChange={this.filterList.bind(this)}
							>
							</TextField>
							{
								this.state.didSearch 
								? 
									<IconButton onClick={this.clearSearch.bind(this)}>
										<Icon className='material-icons'>
											{'close'}
										</Icon>
									</IconButton>
								:
									null
							}
						</div>
					</DialogTitle>
					<DialogContent id='dialogContent' style={{padding:0}}>
						{
							this.state.icons.length > 0
							?
								<AutoSizer>
									{
										({height, width}) => 
										{
											return(
												<Grid
													style={{outline: '0'}}
													cellRenderer={this.iconRender.bind(this)}
													height={height}
													width={width}
													columnWidth={(width*0.24)}
													rowHeight={120}
													columnCount={4}
													rowCount={Math.floor(this.state.icons.length/4)}
												/>
											)
										}
									}
								</AutoSizer>
								
							:
								<LinearProgress 
									variant='indeterminate'
								></LinearProgress>
						}
					</DialogContent>
					<DialogActions>
						{actions}
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		);
	}
}
//<div className='iconsGrid' id='iconsGrid'>
// {this.generateIconRendered()}
// </div>
export default MaterialUiIconPicker;
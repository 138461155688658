export class WebserviceStatus
{
    webservice: string;
    ws: string;
    running: boolean;

    constructor(webservice: string, ws: string, running: boolean)
    {
        this.webservice = webservice;
        this.ws = ws;
        this.running = running;
    }
}
import React from 'react';
import '../../css/chart/PieChart.css';
import { Pie } from 'react-chartjs-2';

interface IPieChartProps
{
    title: string,
    chartData: Array<number>,
    labelData: Array<string>,
    colors: Array<string>   
}

interface IPieChartState 
{
    data: 
    {
        labels: Array<string>,
        datasets: Array<
            {
                data: Array<number>,
                backgroundColor: Array<string> ,
                borderColor: Array<string>,
                borderWidth: number,
            }
        >
    },
}

class PieChart extends React.Component<IPieChartProps, IPieChartState>
{
    constructor(props: IPieChartProps)
    {
        super(props);
        var backgroundColors = [];
        for(var i = 0; i < props.colors.length; i++)
        {
            backgroundColors.push(props.colors[i].concat('55'));
        }
        var data =
        {
            labels: props.labelData,
            datasets: 
            [
                {
                    data: props.chartData,
                    backgroundColor: backgroundColors,
                    borderColor: props.colors,
                    borderWidth: 1,
                },
            ],
        }
        this.state =
        {
            data: data,
            // chartOptions: chartOptions
        };
    }

    UNSAFE_componentWillReceiveProps(props: IPieChartProps)
    {
        var backgroundColors = [];
        for(var i = 0; i < props.colors.length; i++)
        {
            backgroundColors.push(props.colors[i].concat('55'));
        }
        var data =
        {
            labels: props.labelData,
            datasets: 
            [
                {
                    data: props.chartData,
                    backgroundColor: backgroundColors,
                    borderColor: props.colors,
                    borderWidth: 1,
                },
            ],
        }
        this.setState
        (
            {
                data: data,
                // chartOptions: chartOptions
            }
        );
    }

    render()
    {
        return(
            <div id={'chartsection'} className='chart_section'>
                <div style={{height: '100%', padding: '15px'}}>
                    <Pie
                        options=
                        {
                            {
                                responsive: true,
                                maintainAspectRatio: true,
                                plugins:
                                {
                                    
                                    // aspectRatio: 1/2,
                                    title:
                                    {
                                        display: true,
                                        color: 'black',
                                        font:
                                        {
                                            size: 30,
                                        },
                                        text: this.props.title
                                    },
                                    legend:
                                    {
                                        display: true,
                                        position: 'right',
                                        onClick: (event: any)=>{}
                                    }   
                                }
                                
                            }
                            //this.state.chartOptions
                        }
                        data={this.state.data}
                    />
                </div>
            </div>
        );
    }
}

export default PieChart;
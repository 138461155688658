import React from 'react';
import { History, LocationState } from "history";
import 
{
    CloseRounded as CloseIcon,
    ExpandMoreRounded as ExpandMoreIcon,
    ChevronRightRounded
} from '@material-ui/icons'
import 
{
    withStyles,
    createStyles,
    Theme,
    createTheme,
    ThemeProvider,
    
} from '@material-ui/core/styles';

import 
{
    Typography,
    InputBase,
    FormControl,
    InputLabel,
    Select,
    Input,
    Menu,
    MenuItem,
    Button,
    Table,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Switch,
    TextField,
    ListItemIcon,
    
} from '@material-ui/core';

import
{
    Stepper,
    Step,
    StepLabel,
    StepContent,
    StepButton
} from '@mui/material'

import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import 
{
    TextField as MuiTextField,
} from '@mui/material';

import 
{
    withSnackbar,
    SnackbarMessage,
    OptionsObject,
    SnackbarKey
} from 'notistack';

import 
{
    HOME_ID,
    HOME_TITLE
} from '../LoggedHome';
import { PickersDay, PickersDayProps } from '@mui/lab';

interface ICondition
{
    topic: string | null,
    operator: Operators
}

enum Operators
{
    equals = '=',
    different = '!=',
    none = 'none'
}

enum Filters
{
    version = 'Versão',
    none = 'none'
}

interface Template 
{
    id: string,
    name: string,
    title: string,
    body: string,
    clickAction?: string,
    topic?: string,
    condition?: string,
    scheduledTime?: Date
}

interface App 
{
    name: string,
    // package: string,
    id: string
}

interface IConditionRow
{
    condition: ICondition,
    filter: Filters
}

interface IPushNotificationData
{
    title: string | null, 
    body: string | null, 
    app: string | null, 
    toProd: boolean,
    at: Date | null,
    conditionRows: Array<IConditionRow>
}

interface IPushNotificationPageProps
{
    history?: History<LocationState>,
    classes: any,
    enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
    closeSnackbar: (key?: SnackbarKey) => void,
}

interface IPushNotificationPageState 
{
    expandedPanel: 'notification' | 'target' | 'scheduling',
    sendButtonEnabled: boolean,
    targetAppSelectorRef: React.RefObject<HTMLInputElement> | null,
    pushNotification: IPushNotificationData | null,
    topics: any | null,
    apps: Array<App>,
    scheduleSendMenuAnchor: HTMLElement | null,
    scheduleSendMenuItemAnchor: HTMLElement | null,
    templates: Array<Template>,
    activeStep: number,
    nextButtonEnabledInNotification: boolean,
    nextButtonEnabledInTarget: boolean
    completedSteppers: Set<number>
}

const filterOptions = 
[
    {
        filterId: Filters.version,
        condition: 'v1.0.0'
    },
    {
        filterId: Filters.version,
        condition: 'v1.0.1'
    }
]

const filterItems = 
[
    {
        filter: Filters.version,
        inUse: false
    },
]

const useStyles = (theme: any) =>
(
    {
        root:
        {
            justifyContent: 'center',
            display: 'flex'
        },
        content:
        {
            width: '70vw',
            backgroundColor: '#202225',
            padding: '10px',

        },
        circle:
        {
            height: '25px',
            width: '25px',
            backgroundColor: '#35383C',
            color: '#FFFFFF',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            placeItems: 'center',
        },
        accordionTitle:
        {
            display: 'flex',
            '& :last-child':
            {
                marginLeft: '12px'
            }
        },
        accordionTitleText:
        {
            alignSelf: 'center',
            fontSize: '16px',
            fontWeight: 700,
        },
        stepperRoot:
        {
            color: 'white'
        },
        stepperLabelRoot:
        {
            color: 'rgba(255, 255, 255, 0.7) !important',
            '& .MuiSvgIcon-root':
            {
                color: 'rgba(255, 255, 255, 0.7) !important'
            },
            '& .Mui-active':
            {
                color: 'rgb(144, 202, 249) !important'
            },
            '& .Mui-completed':
            {
                color: 'rgb(144, 202, 249) !important'
            }
        },
        stepperLabelContainerRoot:
        {
            color: 'rgba(255, 255, 255, 0.7) !important',
            '& .Mui-active':
            {
                color: 'rgba(255, 255, 255, 1) !important'
            },
            '& .Mui-completed':
            {
                color: 'rgba(255, 255, 255, 1) !important'
            }
        },
        stepperLabelTextActive:
        {
            color: 'rgb(255, 255, 255)',
        },
        stepperLabelTextCompleted:
        {
            color: 'rgb(255, 255, 255)',
        },
        stepperLabelIconActive:
        {
            color: '#00F'
        },
        stepperLabelIconCompleted:
        {
            color: '#F00'
        },
        accordionRoot:
        {
            backgroundColor: 'transparent',
            color: '#FFFFFF',
            border: 'none',
            boxShadow: 'none',
            '&:not(:last-child)': 
            {
                borderBottom: 0,
            },
            '&:before': 
            {
                display: 'none',
            },
            // '&$expanded': 
            // {
            //     margin: 'auto',
            // },
        },
        accordionNotificationContent:
        {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between'
        },
        accordionContentSection:
        {
            flexGrow: 1,
            marginRight: '30px',
            display: 'flex',
            flexDirection: 'column' as const,
            '& > :not(:first-child)': 
            {
                marginTop: '10px',
            },
        },
        accordionTemplateSection:
        {
            flexGrow: 0.5
        },
        productionOrDebugSwitchText:
        {
            alignSelf: 'center',
            fontSize: '14px',
            fontWeight: 700,
            marginRight: '5px'
        },
        productionOrDebugSwitchColor:
        {
            '&.Mui-checked':
            {
                color: '#008EB4'
            },
            '&.Mui-checked + .MuiSwitch-track':
            {
                backgroundColor: '#008EB4'
            }
        },
        inputLabel:
        {
            color: '#FFFFFF !important'
        },
        inputLabelSelectShrink:
        {
            display: 'none',
            transform: 'translate(0, 1.5px) scale(0.75)'
        },
        inputLabelSelectAnimated:
        {
            transition: '0s !important'
        },
        selectMenuPaper:
        {
            color: '#FFFFFF',
            backgroundColor: '#3A3A3A',

            padding: '5px',
            // width: '100px',
            '& option':
            {
                padding: '5px',
                cursor: 'pointer'
            },
            '& option:hover':
            {
                backgroundColor: '#00000022'
            },
        },
        selectIcon:
        {
            color: '#FFFFFF'
        },
        selectSelectMenu:
        {
            backgroundColor: '#000000'
        },
        selectUnderline:
        {
            '&:before':
            {
                border: 'none !important'
            },
            '&:after':
            {
                border: 'none !important'
            }
        },
        selectMenuEditorList:
        {
            color: '#FFFFFF',
            padding: '0 !important',
            minWidth: '100px',
            fontSize: '1.12em',
            '& option:hover':
            {
                backgroundColor: '#00000022'
            }
        },
        selectEditorRoot:
        {
            color: '#FFF',
            fontWeight: 700,
            fontSize: '14px',
        },
        selectNoTopMarginFormControl:
        {
            transform: 'translate(0, 10px) scale(1)'
        },
        selectEditorRootNoTopMargin:
        {
            marginTop: '0px !important',
            // 'label + &':
            // {
                
            // }
        },
        selectEditorMenuItemRoot:
        {
            fontSize: '0.85em'
        },
        notificationCreationSection:
        {
            display: 'flex',
            flexDirection: 'column' as const
        },
        notificationCreationActions:
        {
            display: 'flex',
            flexDirection: 'row-reverse' as const
        },
        sendButton:
        {
            color: '#C5C3C1',
            backgroundColor: '#125AB8',
            textTransform: 'none' as const, 
            fontFamily: 'Roboto,"Helvetica Neue",sans-serif',
            '&:hover':
            {
                backgroundColor: '#0F458B'
            },
            '&:disabled':
            {
                color: '#C5C3C142',
            }
        },
        sendButtonDisabled:
        {
            color: '#C5C3C142',
            backgroundColor: '#0000000F',
        },
        nextStepperButton:
        {
            color: '#e8e6e3',
            backgroundColor: '#125AB8',
            textTransform: 'none' as const, 
            fontFamily: 'Roboto,"Helvetica Neue",sans-serif',
            '&:hover':
            {
                backgroundColor: '#0F458B'
            },
            '&:disabled':
            {
                color: '#C5C3C142',
                backgroundColor: '#0000000F'
            }
        },
        addMoreConditionsButton:
        {
            color: '#297CC7',
            fontWeight: 'bold' as const,
            fontFamily: 'Roboto,"Helvetica Neue",sans-serif;',
            '&:hover':
            {
                backgroundColor: '#0F4D9C1F'
            },
            '&:disabled':
            {
                color: '#C5C3C142',
            }
        },
        addMoreConditionsButtonDisabled:
        {
            color:  '#C5C3C142'
        },
        tableRoot:
        {
            border: '1px solid #303436',
            borderRadius: '4px',
            borderCollapse: 'separate' as const,
            borderSpacing: 'revert',
            width: '100%'
        },
        tableRow:
        {
            
            '&:not(:last-child) > *': 
            {
                borderBottom: '1px solid #303436',
            },
            // '& > :first-child:after': 
            // {
            //     display: 'block',
            //     content: '""',
            //     borderLeft: '1px solid #303436',
            //     bottom: '6px',
            //     position: 'absolute',
            //     right: '0',
            //     top: '6px'
            // },
        },
        tableCellBorder:
        {
            borderRight: '1px solid #303436',
        },
        tableCell:
        {
            borderBottom: 'none',
            color: '#FFFFFF',
            minHeight: '44px',
            padding: '0 16px'
        },
        tableCellButton:
        {
            borderBottom: 'none',
            width: '40px',
            height: '40px',
            padding: '0px'
        },
        iconRoot:
        {
            color: '#FFFFFF'
        },
        pushNotificationScheduleDateSection:
        {
            display: 'flex',
            flexDirection: 'row' as const,
            alignItems: 'flex-start' as const
        },
        pushNotificationDateTimePickerRoot:
        {
            color: '#C5C3C1DE',
        },
        pushNotificationDateTimePickerNotchedOutline:
        {
            borderColor: 'rgb(255 255 255 / 27%) !important',
            '&:hover':
            {
                borderColor: 'rgb(255 255 255 / 27%) !important'
            }
        },
        pushNotificationDateTimePickerRipple:
        {
            backgroundColor: '#00000000'
        },
        datePickerPaperRoot:
        {
            '& .MuiPaper-root':
            {
                color: '#FFFFFF',
                backgroundColor: '#424242',
            },
            '& .MuiPickersCalendarHeader-dayLabel':
            {
                color: '#FFFFFF80'
            },
            '& .MuiPickersDay-root':
            {
                backgroundColor: 'transparent'
            },
            '& .MuiTypography-root':
            {
                color: 'rgba(255, 255, 255, 0.6)'
            },
            '& .PrivatePickersYear-yearButton:disabled':
            {
                color: 'rgba(255, 255, 255, 0.54) !important',
            }
        },
        datePickerIconButton:
        {
            color: '#FFFFFF !important',
            backgroundColor: '#424242 !important',
            '&:disabled':
            {
                color: 'rgba(255, 255, 255, 0.54) !important',
            }
        },
        datePickerDay:
        {
            color: '#FFFFFF'
        },
        pushNotificationDateTimePickerMenuPaperRoot:
        {
            backgroundColor: '#424242'
        },
        pushNotificationDateTimePickerMenuItem:
        {
            color: '#FFFFFF'
        },        
    }
);

const BootstrapInput = withStyles
(
    (theme: Theme) => createStyles
    (
        {
            root: 
            {
                'label + &': 
                {
                    marginTop: theme.spacing(3),
                },
            },
            input: 
            {
                borderRadius: 4,
                position: 'relative',
                backgroundColor: '#292929',
                border: '1px solid #707070',
                color: '#FFFFFF',
                fontSize: 16,
                width: 'auto',
                padding: '10px 12px',
                transition: theme.transitions.create(['border-color', 'box-shadow']),
                // Use the system font instead of the default Roboto font.
                fontFamily: 
                [
                    '-apple-system',
                    'BlinkMacSystemFont',
                    '"Segoe UI"',
                    'Roboto',
                    '"Helvetica Neue"',
                    'Arial',
                    'sans-serif',
                    '"Apple Color Emoji"',
                    '"Segoe UI Emoji"',
                    '"Segoe UI Symbol"',
                ].join(','),
                '&:focus': 
                {
                    borderColor: '#404040',
                },
            },
        }
    ),
)(InputBase);

// const CustomStepLabel = withStyles
// (
//     (theme: Theme) => createStyles
//     (
//         {
//             root:
//             {
//                 color: 'white',
//                 '& .Mui-active':
//                 {
//                     color: 'white'
//                 }
//             },
//             active: 
//             {
//                 color: 'white'
//             },
//             completed:
//             {
//                 color: 'white'
//             }

//         }
//     ),
// )(StepLabel);

const datePickerTheme = createTheme
(
    {
        overrides: 
        {
            MuiPickersToolbar: 
            {
                toolbar:
                {
                    backgroundColor: '#2B2B2B'
                }
            }
        },
    }
);

enum Steps
{
    Notification = 0,
    Target = 1,
    Schedule = 2
}

class PushNotificationPage extends React.Component<IPushNotificationPageProps, IPushNotificationPageState>
{
    classes: any;

    constructor(props: IPushNotificationPageProps)
    {
        super(props);
        this.classes = props.classes;
        this.handleNotificationTitleChange = this.handleNotificationTitleChange.bind(this);
        this.handleNotificationTextChange = this.handleNotificationTextChange.bind(this);
        this.handleShouldEnableSendButton = this.handleShouldEnableSendButton.bind(this);
        this.handleShouldEnableNextButtonInNotificationSection = this.handleShouldEnableNextButtonInNotificationSection.bind(this);
        this.handleShouldEnableNextButtonInTargetSection = this.handleShouldEnableNextButtonInTargetSection.bind(this);
        this.sendPushNotification = this.sendPushNotification.bind(this);
        this.notificationSection = this.notificationSection.bind(this);
        this.targetSection = this.targetSection.bind(this);
        this.schedulingSection = this.schedulingSection.bind(this);
        this.buildTemplateMenu = this.buildTemplateMenu.bind(this);
        this.buildNotificationTargetConditions = this.buildNotificationTargetConditions.bind(this);
        this.buildAppMenu = this.buildAppMenu.bind(this);
        this.addNewCondition = this.addNewCondition.bind(this);
        this.buildFilterMenu = this.buildFilterMenu.bind(this);
        this.getApps = this.getApps.bind(this);
        this.getTemplates = this.getTemplates.bind(this);
        this.openSchedulePopup = this.openSchedulePopup.bind(this);
        this.onCloseSchedulePopup = this.onCloseSchedulePopup.bind(this);
        this.openScheduleDatePicker = this.openScheduleDatePicker.bind(this);
        this.onScheduledDateSelect = this.onScheduledDateSelect.bind(this);
        this.onScheduleNowClick = this.onScheduleNowClick.bind(this);

        this.state =
        {
            expandedPanel: 'notification',
            sendButtonEnabled: false,
            targetAppSelectorRef: null,
            pushNotification: null,
            topics: null,
            scheduleSendMenuAnchor: null,
            scheduleSendMenuItemAnchor: null,
            apps: [],
            templates: [],
            activeStep: 0,
            nextButtonEnabledInNotification: false,
            nextButtonEnabledInTarget: false,
            completedSteppers: new Set<number>()
        }
        // this.state.completedSteppers.add(Steps.Notification);
        this.getApps();
        this.getTemplates();
    }

    getApps()
    {
        fetch
        (
            '/api/push-notification/apps',
            {
                method: "GET", 
                headers: 
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                let jsonResp = await response.json();
                if(response.status === 200)
                {
                    let localApps: Array<App> = [];
                    for(let i = 0; i < jsonResp.apps.length; i++)
                    {
                        localApps.push
                        (
                            {
                                name: jsonResp.apps[i].name,
                                // package: jsonResp.apps[i].package,
                                id: jsonResp.apps[i].id
                            },
                        )
                    }
                    // console.log(localApps);
                    this.setState
                    (
                        {
                            apps: localApps
                        }
                    )
                }
            }
        );
    }

    getTemplates()
    {
        fetch
        (
            '/api/push-notification/templates',
            {
                method: "GET", 
                headers: 
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                let jsonResp = await response.json();
                if(response.status === 200)
                {
                    let localTemplates: Array<Template> = [];
                    for(let i = 0; i < jsonResp.templates.length; i++)
                    {
                        localTemplates.push
                        (
                            {
                                id: jsonResp.templates[i].id,
                                name: jsonResp.templates[i].name,
                                title: jsonResp.templates[i].title,
                                body: jsonResp.templates[i].body,
                                condition: jsonResp.templates[i].condition
                            },
                        )
                    }
                    // console.log(localApps);
                    this.setState
                    (
                        {
                            templates: localTemplates
                        }
                    )
                }
            }
        );
    }

    handleStepper(activeStep: number)
    {
        if(!this.state.completedSteppers.has(this.state.activeStep))
        {
            this.state.completedSteppers.add(this.state.activeStep);
        }
        this.setState
        (
            {
                completedSteppers: this.state.completedSteppers,
                activeStep: activeStep,
            }
        )
    }

    handleShouldEnableNextButtonInNotificationSection()
    {
        let nextButtonEnabledInNotification = false;
        let localPush = this.state.pushNotification;
        if(localPush !== null)
        {
            if(localPush.title !== null && localPush.body !== null)
            {
                nextButtonEnabledInNotification = 
                (
                    localPush!.title!.length > 0 &&
                    localPush!.body!.length > 0
                );
            }
        }
        this.setState
        (
            {
                nextButtonEnabledInNotification: nextButtonEnabledInNotification
            },
            () => this.handleShouldEnableSendButton()
        )
    }

    handleShouldEnableNextButtonInTargetSection()
    {
        let nextButtonEnabledInTarget = false;
        let localPush = this.state.pushNotification;
        if(localPush !== null)
        {
            nextButtonEnabledInTarget = 
            (
                (localPush.conditionRows.every(x=> x.filter !== Filters.none && x.condition !== null)) &&
                localPush.app !== null && localPush.app !== undefined
            );
        }
        this.setState
        (
            {
                nextButtonEnabledInTarget: nextButtonEnabledInTarget
            },
            () => this.handleShouldEnableSendButton()
        )
    }

    handleShouldEnableSendButton()
    {
        let sendButtonEnabled = false;
        sendButtonEnabled = 
        (
            this.state.nextButtonEnabledInNotification &&
            this.state.nextButtonEnabledInTarget 
        );
        console.log('Test:'+sendButtonEnabled);
        this.setState
        (
            {
                sendButtonEnabled: sendButtonEnabled
            }
        )
    }

    handleNotificationTitleChange(e: React.ChangeEvent<HTMLInputElement>)
    {
        let localPush = this.state.pushNotification;
        if(localPush === null)
        {
            localPush = 
            {
                title: null,
                body: null,
                app: null,
                toProd: false,
                at: null,
                conditionRows: []
            }
        }
        localPush.title = e.target.value as string;
        this.setState
        (
            {
                //notificationTitle: e.target.value
                pushNotification: localPush
            },
            () => 
            {
                this.handleShouldEnableNextButtonInNotificationSection();
            }
        );    
    }

    handleNotificationTextChange(e: React.ChangeEvent<HTMLInputElement>)
    {
        let localPush = this.state.pushNotification;
        if(localPush === null)
        {
            localPush = 
            {
                title: null,
                body: null,
                app: null,
                toProd: false,
                at: null,
                conditionRows: []
            }
        }
        localPush.body = e.target.value as string;
        this.setState
        (
            {
                // notificationText: e.target.value,
                pushNotification: localPush
            },
            () => 
            {
                this.handleShouldEnableNextButtonInNotificationSection();
            }
        );
    }

    addNewCondition(e: React.MouseEvent<HTMLButtonElement>)
    {
        let localPush = this.state.pushNotification;
        if(localPush !== null)
        {
            localPush.conditionRows.push
            (
                {
                    condition: 
                    {
                        operator: Operators.none,
                        topic: null
                    },
                    filter: Filters.none
                }
            );

            this.setState
            (
                {
                    // notificationText: e.target.value,
                    pushNotification: localPush
                },
                () => 
                {
                    this.handleShouldEnableNextButtonInTargetSection();
                }
            ); 
        }
        
    }

    sendPushNotification()
    {
        fetch
        (
            '/api/push-notification/send',
            {
                method: "POST", 
                headers: 
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify
                (
                    {
                        pushNotification:
                        {
                            notification:
                            {
                                title: this.state.pushNotification!.title,
                                body: this.state.pushNotification!.body
                            },
                            app: this.state.pushNotification!.app,
                            // at: '2021-05-17 14:35:00',
                            toProd: this.state.pushNotification!.toProd,
                            at: this.state.pushNotification!.at,
                            // condition: '{\"or\":{\"conditions\":[{\"and\":{\"conditions\":[{\"appId\":{\"targetGmpAppId\":\"1:589830665559:android:be4429e93c4ab2752d3a12\"}},{\"appVersion\":{\"operator\":\"NUMERIC_LESS_EQUAL\",\"targetAppVersions\":[\"1.0.0\"],\"targetValue\":\"1.0.0\"}}]}}]}}'
                            // condition: `'news' in topics ${this.generateConditionText()}`
                            //condition: "app.id == '1:589830665559:android:be4429e93c4ab2752d3a12' && app.version.\u003c=(['1.0.0'])"
                        }
                        
                    }
                ), 
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    this.props.enqueueSnackbar!('Notificação enviada com sucesso', { variant: 'success' });
                    sessionStorage['page'] = HOME_ID;
                    sessionStorage['pageTitle'] = HOME_TITLE;
                    this.props.history!.push('/home');
                }
                else
                {
                    this.props.enqueueSnackbar!('Ocorreu um erro ao enviar a notificação', { variant: 'error' });
                }
            }
        );
    }

    generateConditionText()
    {
        //&& !('v1.0.0' in topics)
        let conditionText: string = '';
        let localPush = this.state.pushNotification!;
        for(let i = 0; i < localPush.conditionRows.length; i++)
        {
            switch(localPush.conditionRows[i].condition.operator)
            {
                case Operators.equals:
                    conditionText += `&& ('${localPush.conditionRows[i].condition.topic}' in topics)`;
                    break;
                case Operators.different:
                    conditionText += `&& !('${localPush.conditionRows[i].condition.topic}' in topics)`;
                    break;
            }
        }
        return conditionText;
    }

    buildAppMenu()
    {
        let items = [];
        for(let i = 0; i < this.state.apps.length; i++)
        {
            items.push
            (
                <MenuItem 
                    classes={{root: this.classes.selectEditorMenuItemRoot}} 
                    value={this.state.apps[i].id}
                >
                    <span
                        style=
                        {
                            {
                                display: 'inline-block',
                                flexGrow: 1,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }
                        }
                    >
                        {this.state.apps[i].id}
                        <span
                            style=
                            {
                                {
                                    color: '#C5C3C18A',
                                    fontSize: '12px'
                                }
                            }
                        >
                            {`(${this.state.apps[i].name})`}
                        </span>
                    </span>
                </MenuItem>
            )
        }
        return items;
    }

    buildFilterMenu(list: Array<any>)
    {
        let items = [];
        for(let i = 0; i < list.length; i++)
        {
            items.push
            (
                <MenuItem 
                    classes={{root: this.classes.selectEditorMenuItemRoot}} 
                    value={list[i].filter}
                    disabled=
                    {
                        list[i].inUse
                        // this.state.pushNotification!.conditionRows.findIndex(x => x.filter === list[i].filter) < row && row != 0
                        // // this.state.pushNotification!.conditionRows[row].filter != Filters.none
                        // // this.state.pushNotification!.conditionRows.every(x=>x.filter !== list[i].filter)
                    }
                >
                    <span
                        style=
                        {
                            {
                                display: 'inline-block',
                                flexGrow: 1,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                color: '#FFFFFF'
                            }
                        }
                    >
                        {list[i].filter}
                    </span>
                </MenuItem>
            )
        }
        return items;
    }

    buildFilterTopicMenu(list: Array<any>)
    {
        let items = [];
        for(let i = 0; i < list.length; i++)
        {
            items.push
            (
                <MenuItem 
                    classes={{root: this.classes.selectEditorMenuItemRoot}} 
                    value={list[i].condition}
                >
                    <span
                        style=
                        {
                            {
                                display: 'inline-block',
                                flexGrow: 1,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                color: '#FFFFFF'
                            }
                        }
                    >
                        {list[i].condition}
                    </span>
                </MenuItem>
            )
        }
        return items;
    }

    buildFilterOperatorMenu(list: Array<any>)
    {
        let items = [];
        for(let i = 0; i < list.length; i++)
        {
            items.push
            (
                <MenuItem 
                    classes={{root: this.classes.selectEditorMenuItemRoot}} 
                    value={list[i]}
                >
                    <span
                        style=
                        {
                            {
                                display: 'inline-block',
                                flexGrow: 1,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                color: '#FFFFFF'
                            }
                        }
                    >
                        {list[i]}
                    </span>
                </MenuItem>
            )
        }
        return items;
    }

    buildTemplateMenu()
    {
        let menuItems = 
        [
            (
                <MenuItem 
                    classes={{root: this.classes.selectEditorMenuItemRoot}} 
                    value={'none'}
                >
                    Nenhum
                </MenuItem>
            )
        ];
        for(let i = 0; i < this.state.templates.length; i++)
        {
            menuItems.push
            (
                <MenuItem 
                    classes={{root: this.classes.selectEditorMenuItemRoot}} 
                    value={this.state.templates[i].id}
                >
                    {this.state.templates[i].name}
                </MenuItem>
            );
        }
        return menuItems;
    }

    openSchedulePopup(e: React.MouseEvent<HTMLDivElement>)
    {
        // e.nativeEvent.stopImmediatePropagation();
        e.stopPropagation();
        // e.nativeEvent.preventDefault();
        
        this.setState
        (
            {
                scheduleSendMenuAnchor: e.target as HTMLElement
            }   
        )
        // console.log('Clicked');
    }

    onCloseSchedulePopup()
    {
        // console.log('Closing');
        this.setState
        (
            {
                scheduleSendMenuAnchor: null
            }   
        )
    }

    openScheduleDatePicker(e: React.MouseEvent<HTMLElement>)
    {
        this.setState
        (
            {
                scheduleSendMenuItemAnchor: e.target as HTMLElement
            }
        )
    }

    onScheduleNowClick()
    {
        let localPush = this.state.pushNotification;
        if(localPush === null)
        {
            localPush = 
            {
                title: null,
                body: null,
                app: null,
                toProd: false,
                at: null,
                conditionRows: []
            }
        }
        if(localPush !== null)
        {
            localPush.at = null;
            this.setState
            (
                {
                    scheduleSendMenuAnchor: null,
                    pushNotification: localPush
                }
            )
        }
    }

    onScheduledDateSelect(date: Date | null)
    {
        let localPush = this.state.pushNotification;
        if(localPush === null)
        {
            localPush = 
            {
                title: null,
                body: null,
                app: null,
                toProd: false,
                at: null,
                conditionRows: []
            }
        }
        if(localPush !== null && date !== null)
        {
            localPush.at = date!;
            this.setState
            (
                {
                    scheduleSendMenuAnchor: null,
                    scheduleSendMenuItemAnchor: null,
                    pushNotification: localPush
                }
            )
        }
    }

    buildNotificationTargetConditions()
    {
        let rows = [];
        let rowCount = this.state.pushNotification?.conditionRows?.length ?? 0;
        for(let i = 0; i < rowCount; i++)
        {
            rows.push
            (
                <TableRow
                    classes=
                    {
                        {
                            root: this.classes.tableRow
                        }
                    }
                >
                    <TableCell 
                        align="left"
                        className={this.classes.tableCellBorder}
                        classes=
                        {
                            {
                                root: this.classes.tableCell
                            }
                        }
                    >
                        <FormControl 
                            style={{minWidth: '200px'}}
                        >
                            <InputLabel 
                                id={`notification-condition-selector-label-${i}`}
                                style=
                                {
                                    {
                                        width: '150px',
                                        display: this.state.pushNotification!.conditionRows[i].filter !== Filters.none ? 'none' : 'block'
                                    }
                                }
                                classes=
                                {
                                    {
                                        root: this.classes.inputLabel,
                                        focused: this.classes.inputLabel,
                                        animated: this.classes.inputLabelSelectAnimated,
                                        formControl: this.classes.selectNoTopMarginFormControl
                                    }
                                }
                            >
                                {'Selecione...'}
                            </InputLabel>
                            <Select
                                ref={this.state.targetAppSelectorRef}
                                labelId={`notification-condition-selector-label-${i}`}
                                defaultValue=''
                                inputProps=
                                {
                                    {
                                        id: `notification-condition-selector-${i}`
                                    }
                                }
                                onChange=
                                {
                                    (event)=> 
                                    {
                                        let localPush = this.state.pushNotification;
                                        // let index: number = filterItems.findIndex(x=>x.filter === localPush!.conditionRows[i].filter);
                                        // if(index >= 0)
                                        // {
                                        //     filterItems[index].inUse = false;
                                        // }
                                        localPush!.conditionRows[i].filter = event.target.value as Filters;
                                        // index = filterItems.findIndex(x=>x.filter === localPush!.conditionRows[i].filter);
                                        // if(index >= 0)
                                        // {
                                        //     filterItems[index].inUse = true;
                                        // }                                        
                                        this.setState
                                        (
                                            {
                                                pushNotification: localPush 
                                            },
                                            () => 
                                            {
                                                this.handleShouldEnableNextButtonInTargetSection();
                                            }
                                        )
                                    }
                                }
                                MenuProps=
                                {
                                    {
                                        classes:
                                        {
                                            paper: this.classes.selectMenuPaper,
                                            list: this.classes.selectMenuEditorList
                                        }
                                    }
                                }
                                input=
                                {
                                    <Input classes=
                                        {
                                            {
                                                root: this.classes.selectEditorRootNoTopMargin,
                                                underline: this.classes.selectUnderline,
                                            }
                                        }
                                    />
                                }
                                classes=
                                {
                                    {
                                        icon: this.classes.selectIcon,
                                    }
                                }
                                IconComponent={ExpandMoreIcon}
                            >
                                {
                                    this.buildFilterMenu(filterItems)
                                }
                            </Select>
                        </FormControl>
                    </TableCell>
                    <TableCell 
                        align="left"
                        className={this.classes.tableCellBorder}
                        classes=
                        {
                            {
                                root: this.classes.tableCell
                            }
                        }
                    >
                        <FormControl 
                            style={{minWidth: '200px'}}
                        >
                            <InputLabel 
                                id={`notification-topic-selector-label-${i}`}
                                style=
                                {
                                    {
                                        width: '150px',
                                        display: this.state.pushNotification!.conditionRows[i].condition.topic != null ? 'none' : 'block'
                                    }
                                }
                                classes=
                                {
                                    {
                                        root: this.classes.inputLabel,
                                        focused: this.classes.inputLabel,
                                        animated: this.classes.inputLabelSelectAnimated,
                                        formControl: this.classes.selectNoTopMarginFormControl
                                    }
                                }
                            >
                                {'Selecione...'}
                            </InputLabel>
                            <Select
                                ref={this.state.targetAppSelectorRef}
                                labelId={`notification-topic-selector-label-${i}`}
                                defaultValue=''
                                inputProps=
                                {
                                    {
                                        id: `notification-topic-selector-${i}`
                                    }
                                }
                                onChange=
                                {
                                    (event)=> 
                                    {
                                        let localPush = this.state.pushNotification;
                                        localPush!.conditionRows[i].condition.topic = event.target.value as string;
                                        
                                        this.setState
                                        (
                                            {
                                                pushNotification: localPush 
                                            },
                                            () => 
                                            {
                                                this.handleShouldEnableNextButtonInTargetSection();
                                            }
                                        )
                                    }
                                }
                                MenuProps=
                                {
                                    {
                                        classes:
                                        {
                                            paper: this.classes.selectMenuPaper,
                                            list: this.classes.selectMenuEditorList
                                        }
                                    }
                                }
                                input=
                                {
                                    <Input classes=
                                        {
                                            {
                                                root: this.classes.selectEditorRootNoTopMargin,
                                                underline: this.classes.selectUnderline,
                                            }
                                        }
                                    />
                                }
                                classes=
                                {
                                    {
                                        icon: this.classes.selectIcon,
                                    }
                                }
                                IconComponent={ExpandMoreIcon}
                            >
                                {
                                    this.buildFilterTopicMenu
                                    (
                                        filterOptions.filter(x => x.filterId === this.state.pushNotification!.conditionRows[i].filter)
                                    )
                                }
                            </Select>
                        </FormControl>
                    </TableCell>
                    <TableCell 
                        align="left"
                        className={this.classes.tableCellBorder}
                        classes=
                        {
                            {
                                root: this.classes.tableCell
                            }
                        }
                    >
                        <FormControl 
                            style={{minWidth: '200px'}}
                        >
                            <InputLabel 
                                id={`notification-operator-selector-label-${i}`}
                                style=
                                {
                                    {
                                        width: '150px',
                                        display: this.state.pushNotification!.conditionRows[i].condition.operator !== Operators.none ? 'none' : 'block'
                                    }
                                }
                                classes=
                                {
                                    {
                                        root: this.classes.inputLabel,
                                        focused: this.classes.inputLabel,
                                        animated: this.classes.inputLabelSelectAnimated,
                                        formControl: this.classes.selectNoTopMarginFormControl
                                    }
                                }
                            >
                                {'Selecione...'}
                            </InputLabel>
                            <Select
                                ref={this.state.targetAppSelectorRef}
                                labelId={`notification-operator-selector-label-${i}`}
                                defaultValue=''
                                inputProps=
                                {
                                    {
                                        id: `notification-operator-selector-${i}`
                                    }
                                }
                                onChange=
                                {
                                    (event)=> 
                                    {
                                        let localPush = this.state.pushNotification;
                                        localPush!.conditionRows[i].condition.operator = event.target.value as Operators;
                                        
                                        this.setState
                                        (
                                            {
                                                pushNotification: localPush 
                                            },
                                            () =>
                                            {
                                                this.handleShouldEnableNextButtonInTargetSection();
                                            }
                                        )
                                    }
                                }
                                MenuProps=
                                {
                                    {
                                        classes:
                                        {
                                            paper: this.classes.selectMenuPaper,
                                            list: this.classes.selectMenuEditorList
                                        }
                                    }
                                }
                                input=
                                {
                                    <Input classes=
                                        {
                                            {
                                                root: this.classes.selectEditorRootNoTopMargin,
                                                underline: this.classes.selectUnderline,
                                            }
                                        }
                                    />
                                }
                                classes=
                                {
                                    {
                                        icon: this.classes.selectIcon,
                                    }
                                }
                                IconComponent={ExpandMoreIcon}
                            >
                                {
                                    this.buildFilterOperatorMenu
                                    (
                                        Object.values(Operators).filter(x => x !== Operators.none)
                                    )
                                }
                            </Select>
                        </FormControl>
                    </TableCell>
                    <TableCell
                        classes=
                        {
                            {
                                root: this.classes.tableCellButton
                            }
                        }
                    >
                        <Button
                            onClick={this.addNewCondition}
                            classes=
                            {
                                {
                                    root: this.classes.addMoreConditionsButton,
                                    disabled: this.classes.addMoreConditionsButtonDisabled
                                }
                            }
                            style=
                            {
                                {
                                    display: i !== this.state.pushNotification!.conditionRows.length - 1 ? 'none' : 'inline-flex'
                                }
                            }
                            disabled=
                            {
                                this.state.pushNotification!.conditionRows[i].filter === Filters.none || 
                                this.state.pushNotification!.conditionRows[i].condition.operator === 'none' || 
                                this.state.pushNotification!.conditionRows[i].condition.topic === null
                            }
                        >
                            {'E'}
                        </Button>
                    </TableCell>
                    <TableCell
                        classes=
                        {
                            {
                                root: this.classes.tableCellButton
                            }
                        }
                    >
                        <IconButton
                            size='small'
                            classes=
                            {
                                {
                                    root: this.classes.iconRoot
                                }
                            }
                            onClick=
                            {
                                () =>
                                {
                                    let localPush = this.state.pushNotification;
                                    localPush!.conditionRows!.splice(i, 1);
                                    
                                    this.setState
                                    (
                                        {
                                            pushNotification: localPush 
                                        },
                                        () => 
                                        {
                                            this.handleShouldEnableNextButtonInTargetSection();
                                        }
                                    )
                                }
                            }
                        >
                            <CloseIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        }
        return rows;
    }

    notificationSection()
    {
        // console.log(this.state.completedSteppers);
        // console.log(this.state.completedSteppers.has(Steps.Notification));
        return (
            <Step 
                completed={this.state.completedSteppers.has(Steps.Notification)}
                classes=
                {
                    {
                        root: this.classes.stepperRoot
                    }
                }
            >
                <StepButton

                    onClick={(e)=>this.handleStepper(Steps.Notification)}
                >
                    <StepLabel 
                        aria-controls="notification-content" 
                        id="notification-header"
                        classes=
                        {
                            {
                                root: this.classes.stepperLabelRoot,
                                labelContainer: this.classes.stepperLabelContainerRoot,
                            }
                        }
                    >
                        {'Notificação'}
                    </StepLabel>
                </StepButton>
                <StepContent>
                    <div className={this.classes.accordionNotificationContent}>
                        <div className={this.classes.accordionContentSection}>
                            <FormControl>
                                <InputLabel 
                                    shrink 
                                    classes=
                                    {
                                        {
                                            root: this.classes.inputLabel,
                                            focused: this.classes.inputLabel,
                                        }
                                    }
                                    htmlFor='notification-title-input'>
                                    {'Titulo'}
                                </InputLabel>
                                <BootstrapInput 
                                    id='notification-title-input'
                                    onChange={this.handleNotificationTitleChange}
                                    value={this.state.pushNotification?.title ?? ''}
                                    inputProps=
                                    {
                                        {
                                            style:
                                            {
                                                width: '100%'
                                            }
                                        }
                                    }
                                />
                            </FormControl>
                            <FormControl>
                                <InputLabel 
                                    shrink 
                                    classes=
                                    {
                                        {
                                            root: this.classes.inputLabel,
                                            focused: this.classes.inputLabel,
                                        }
                                    }
                                    htmlFor='notification-text-input'>
                                    {'Texto'}
                                </InputLabel>
                                <BootstrapInput 
                                    id='notification-text-input'
                                    multiline
                                    onChange={this.handleNotificationTextChange}
                                    value={this.state.pushNotification?.body ?? ''}
                                    inputProps=
                                    {
                                        {
                                            style:
                                            {
                                                width: '100%'
                                            }
                                        }
                                    }
                                />
                            </FormControl>
                        </div>
                        <div className={this.classes.accordionTemplateSection}>
                            <Select
                                defaultValue='none'
                                onChange=
                                {
                                    (event)=> 
                                    {
                                        let localPush = this.state.pushNotification;
                                        if(localPush === null)
                                        {
                                            localPush = 
                                            {
                                                title: null,
                                                body: null,
                                                app: null,
                                                toProd: false,
                                                at: null,
                                                conditionRows: []
                                            }
                                        }
                                        let template = this.state.templates.find(x=>x.id === event.target.value)!;
                                        if(template)
                                        {
                                            localPush.title = template.title;
                                            localPush.body = template.body;
                                        }
                                        else
                                        {
                                            localPush.title = '';
                                            localPush.body = '';
                                        }
                                        

                                        this.setState
                                        (
                                            {
                                                pushNotification: localPush 
                                            },
                                            () =>
                                            {
                                                this.handleShouldEnableNextButtonInTargetSection();
                                            }
                                        )
                                    }
                                }
                                MenuProps=
                                {
                                    {
                                        classes:
                                        {
                                            paper: this.classes.selectMenuPaper,
                                            list: this.classes.selectMenuEditorList
                                        }
                                    }
                                }
                                input=
                                {
                                    <Input classes=
                                        {
                                            {
                                                root: this.classes.selectEditorRoot,
                                                underline: this.classes.selectUnderline,
                                            }
                                        }
                                    />
                                }
                                classes=
                                {
                                    {
                                        icon: this.classes.selectIcon,
                                    }
                                }
                                IconComponent={ExpandMoreIcon}
                            >
                                {
                                    this.buildTemplateMenu()
                                }
                            </Select>
                            <div>
                                <span className={this.classes.productionOrDebugSwitchText}>{'P/ Produção?'}</span>
                                <Switch
                                    size={'small'}
                                    classes=
                                    {
                                        {
                                            colorSecondary: this.classes.productionOrDebugSwitchColor
                                        }
                                    }
                                    checked={this.state.pushNotification?.toProd ?? false}
                                    onChange=
                                    {
                                        (_e, value) =>
                                        {
                                            let localPush = this.state.pushNotification;
                                            if(localPush === null)
                                            {
                                                localPush = 
                                                {
                                                    title: null,
                                                    body: null,
                                                    app: null,
                                                    toProd: false,
                                                    at: null,
                                                    conditionRows: []
                                                }
                                            }
                                            localPush.toProd = value;
                                            this.setState
                                            (
                                                {
                                                    pushNotification: localPush 
                                                }
                                            )
                                        }
                                    }
                                />  
                            </div>
                        </div>
                    </div>
                    <Button
                        disabled={!this.state.nextButtonEnabledInNotification}
                        onClick={(e)=>this.handleStepper(this.state.activeStep + 1)}
                        classes=
                        {
                            {
                                root: this.classes.nextStepperButton
                            }
                        }
                    >
                        {'Próximo'}
                    </Button>
                </StepContent>
            </Step>
        )
    }

    targetSection()
    {
        return(
            <Step 
                completed={this.state.completedSteppers.has(Steps.Target)}
                classes=
                {
                    {
                        root: this.classes.stepperRoot
                    }
                }
            >
                <StepButton
                    onClick={(e)=>this.handleStepper(Steps.Target)}
                >
                    <StepLabel 
                        aria-controls="target-content" 
                        id="target-header"
                        classes=
                        {
                            {
                                root: this.classes.stepperLabelRoot,
                                labelContainer: this.classes.stepperLabelContainerRoot,
                            }
                        }
                    >
                        {'Alvo'}
                    </StepLabel>
                </StepButton>
                <StepContent>
                    <div style={{width: '100%'}}>
                        <Table
                            classes=
                            {
                                {
                                    root: this.classes.tableRoot,
                                }
                            }
                        >
                            <TableBody
                                classes=
                                {
                                    {
                                        root: this.classes.tableBody
                                    }
                                }
                            >
                                <TableRow
                                    classes=
                                    {
                                        {
                                            root: this.classes.tableRow
                                        }
                                    }
                                >
                                    <TableCell 
                                        align="left"
                                        className={this.classes.tableCellBorder}
                                        classes=
                                        {
                                            {
                                                root: this.classes.tableCell
                                            }
                                        }
                                    >
                                        <span>{'App'}</span>
                                    </TableCell>
                                    <TableCell 
                                        align="right"
                                        classes=
                                        {
                                            {
                                                root: this.classes.tableCell
                                            }
                                        }
                                    >
                                        <FormControl 
                                            style={{minWidth: '200px'}}
                                        >
                                            <InputLabel 
                                                id='notification-app-selector-label'
                                                style=
                                                {
                                                    {
                                                        width: '150px',
                                                        display: this.state.pushNotification?.app ? 'none' : 'block'
                                                    }
                                                }
                                                classes=
                                                {
                                                    {
                                                        root: this.classes.inputLabel,
                                                        focused: this.classes.inputLabel,
                                                        animated: this.classes.inputLabelSelectAnimated,
                                                        formControl: this.classes.selectNoTopMarginFormControl
                                                    }
                                                }
                                            >
                                                {'Selecione um app...'}
                                            </InputLabel>
                                            <Select
                                                ref={this.state.targetAppSelectorRef}
                                                labelId='notification-app-selector-label'
                                                defaultValue=''
                                                value=
                                                {
                                                    this.state.pushNotification?.app ?? ''
                                                }
                                                inputProps=
                                                {
                                                    {
                                                        id: 'notification-app-selector'
                                                    }
                                                }
                                                onChange=
                                                {
                                                    (event)=> 
                                                    {
                                                        let localPush = this.state.pushNotification;
                                                        if(localPush === null)
                                                        {
                                                            localPush = 
                                                            {
                                                                title: null,
                                                                body: null,
                                                                app: null,
                                                                toProd: false,
                                                                at: null,
                                                                conditionRows: []
                                                            }
                                                        }
                                                        localPush.app = event.target.value as string;
                                                        this.setState
                                                        (
                                                            {
                                                                pushNotification: localPush 
                                                            },
                                                            () => 
                                                            {
                                                                this.handleShouldEnableNextButtonInTargetSection();
                                                            }
                                                        )
                                                    }
                                                }
                                                MenuProps=
                                                {
                                                    {
                                                        classes:
                                                        {
                                                            paper: this.classes.selectMenuPaper,
                                                            list: this.classes.selectMenuEditorList
                                                        }
                                                    }
                                                }
                                                input=
                                                {
                                                    <Input classes=
                                                        {
                                                            {
                                                                root: this.classes.selectEditorRootNoTopMargin,
                                                                underline: this.classes.selectUnderline,
                                                            }
                                                        }
                                                    />
                                                }
                                                classes=
                                                {
                                                    {
                                                        icon: this.classes.selectIcon,
                                                    }
                                                }
                                                IconComponent={ExpandMoreIcon}
                                                renderValue=
                                                {
                                                    (value: any) : React.ReactNode =>
                                                    {
                                                        // let pkg = apps.find(x => x.id === value)?.package;
                                                        return(
                                                            <span
                                                                style=
                                                                {
                                                                    {
                                                                        color: '#C5C3C1DE',
                                                                        marginRight: '5px'
                                                                    }
                                                                }
                                                            >
                                                                {value}
                                                            </span>
                                                        );
                                                    }
                                                }
                                            >
                                                {this.buildAppMenu()}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell
                                        classes=
                                        {
                                            {
                                                root: this.classes.tableCellButton
                                            }
                                        }
                                    >
                                        <Button
                                            onClick={this.addNewCondition}
                                            classes=
                                            {
                                                {
                                                    root: this.classes.addMoreConditionsButton,
                                                    disabled: this.classes.addMoreConditionsButtonDisabled
                                                }
                                            }
                                            style=
                                            {
                                                {
                                                    display: this.state.pushNotification?.conditionRows.length !== 0 ? 'none' : 'inline-flex'
                                                }
                                            }
                                            disabled
                                            // disabled=
                                            // {
                                            //     this.state.pushNotification?.conditionRows.length !== 0 ||
                                            //     (this.state.pushNotification?.app === null || this.state.pushNotification?.app === undefined)
                                            // }
                                        >
                                            {'E'}
                                        </Button>
                                    </TableCell>
                                    <TableCell
                                        classes=
                                        {
                                            {
                                                root: this.classes.tableCellButton
                                            }
                                        }
                                    >
                                        
                                    </TableCell>
                                </TableRow>
                                {
                                    this.buildNotificationTargetConditions()
                                }
                            </TableBody>
                        </Table>
                        
                    </div>
                    <Button
                        disabled={!this.state.nextButtonEnabledInTarget}
                        onClick={(e)=>this.handleStepper(this.state.activeStep + 1)}
                        classes=
                        {
                            {
                                root: this.classes.nextStepperButton
                            }
                        }
                        style=
                        {
                            {
                                marginTop: '5px'
                            }
                        }
                    >
                        {'Próximo'}
                    </Button>
                </StepContent>
            </Step>
        )
    }

    schedulingSection()
    {
        return(
            <Step 
                completed={this.state.completedSteppers.has(Steps.Schedule)}
                classes=
                {
                    {
                        root: this.classes.stepperRoot
                    }
                }
            >
                <StepButton
                    onClick={(e)=>this.handleStepper(Steps.Schedule)}
                >
                    <StepLabel 
                        aria-controls="scheduling-content" 
                        id="scheduling-header"
                        classes=
                        {
                            {
                                root: this.classes.stepperLabelRoot,
                                labelContainer: this.classes.stepperLabelContainerRoot,
                            }
                        }
                    >
                        {'Programar'}
                    </StepLabel>
                </StepButton>
                <StepContent>
                    <Typography>
                        <ThemeProvider
                            theme=
                            {
                                datePickerTheme
                            }
                        >

                        </ThemeProvider>
                        {/* <KeyboardDatePicker
                            // renderInput=
                            // {
                            //     (params) => 
                            //     (
                            //         <TextField {...params} margin="normal" variant="standard" />
                            //     )
                            // }
                            value={new Date().toISOString().substring(0, 16).replace('T', ' ')}
                            onChange=
                            {
                                (newValue) => 
                                {
                                    console.log(newValue);
                                }
                            }
                        /> */}
                        <div
                            className={this.classes.pushNotificationScheduleDateSection}
                        >
                            <div
                                onMouseDown={this.openSchedulePopup}
                            >
                                <TextField
                                    variant='outlined'
                                    // classes=
                                    // {
                                    //     {
                                    //         root: this.classes.pushNotificationDateTimePickerRoot,

                                    //     }
                                    // }
                                    InputProps=
                                    {
                                        {
                                            classes:
                                            {
                                                root: this.classes.pushNotificationDateTimePickerRoot,
                                                notchedOutline: this.classes.pushNotificationDateTimePickerNotchedOutline,
                                            }
                                        }
                                    }
                                    value=
                                    {
                                        this.state.pushNotification?.at 
                                        ?
                                            this.state.pushNotification!.at.toLocaleDateString()
                                        :
                                            'Agora'
                                    }
                                />
                                
                            </div>
                            <TextField
                                id="scheduled-time-picker"
                                type="time"
                                variant='outlined'
                                defaultValue="12:00"
                                style=
                                {
                                    {
                                        marginLeft: '5px',
                                        display: this.state.pushNotification?.at ? 'block' : 'none'
                                    }
                                }
                                InputLabelProps=
                                {
                                    {
                                        shrink: true,
                                    }
                                }
                                InputProps=
                                {
                                    {
                                        classes:
                                        {
                                            root: this.classes.pushNotificationDateTimePickerRoot,
                                            notchedOutline: this.classes.pushNotificationDateTimePickerNotchedOutline,
                                        }
                                    }
                                }
                                inputProps=
                                {
                                    {
                                        step: 300, // 5 min
                                    }
                                }
                                onChange=
                                {
                                    (e) =>
                                    {
                                        let localPush = this.state.pushNotification;
                                        let hours = e.target.value.split(':');
                                        if(localPush !== null)
                                        {
                                            localPush.at = new Date(localPush.at!.setHours(parseInt(hours[0]), parseInt(hours[1])));
                                            this.setState
                                            (
                                                {
                                                    pushNotification: localPush
                                                }
                                            )
                                        }
                                    }
                                }
                            />  
                        </div>
                        <Menu
                            id="schedule-send-menu"
                            anchorEl={this.state.scheduleSendMenuAnchor}
                            keepMounted
                            open={Boolean(this.state.scheduleSendMenuAnchor)}
                            onClose={this.onCloseSchedulePopup}
                            classes=
                            {
                                {
                                    paper: this.classes.pushNotificationDateTimePickerMenuPaperRoot
                                }
                            }
                        >
                            <MenuItem 
                                onClick={this.onScheduleNowClick}
                                classes=
                                {
                                    {
                                        root: this.classes.pushNotificationDateTimePickerMenuItem
                                    }
                                }
                            >
                                Agora
                            </MenuItem>
                            <MenuItem 
                                onClick={this.openScheduleDatePicker}
                                classes=
                                {
                                    {
                                        root: this.classes.pushNotificationDateTimePickerMenuItem
                                    }
                                }
                                
                            >
                                <span>Programado</span>
                                <ListItemIcon 
                                    style=
                                    {
                                        {
                                            color: '#FFFFFF',
                                            flexFlow: 'row-reverse'
                                        }
                                    }
                                >
                                    <ChevronRightRounded />
                                </ListItemIcon>
                            </MenuItem>
                        </Menu>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker 
                                // classesName={this.classes.datePicker}
                                inputFormat="dd/MM/yyyy"
                                // margin="normal"
                                // id="push-date-picker"
                                // variant="inline"
                                // label="Data"
                                onClose=
                                {
                                    ()=>
                                    {
                                        this.setState
                                        (
                                            {
                                                scheduleSendMenuAnchor: null,
                                                scheduleSendMenuItemAnchor: null,
                                            }
                                        )
                                    }
                                }
                                value={this.state.pushNotification?.at ? this.state.pushNotification!.at : new Date()}
                                open={Boolean(this.state.scheduleSendMenuItemAnchor)}
                                componentsProps=
                                {
                                    {
                                        leftArrowButton:
                                        {
                                            classes:
                                            {
                                                root: this.classes.datePickerIconButton
                                            }
                                        },
                                        rightArrowButton:
                                        {
                                            classes:
                                            {
                                                root: this.classes.datePickerIconButton
                                            }   
                                        },
                                        switchViewButton:
                                        {
                                            classes:
                                            {
                                                root: this.classes.datePickerIconButton
                                            }   
                                        }
                                    }
                                }
                                renderInput=
                                {
                                    (params) => 
                                    (
                                        <MuiTextField 
                                            {...params}
                                            margin="normal" 
                                            variant="standard" 
                                            style=
                                            {
                                                {
                                                    display: 'none'
                                                }
                                            }
                                        />
                                    )
                                }
                                PopperProps=
                                {
                                    {
                                        className: this.classes.datePickerPaperRoot,
                                        anchorEl: this.state.scheduleSendMenuItemAnchor,
                                        // anchorOrigin: 
                                        // {
                                        //     vertical: -50,
                                        //     horizontal: 198
                                        // },
                                        // PaperProps:
                                        // {
                                        //     classes:
                                        //     {
                                        //         root: this.classes.datePickerPaperRoot,
                                        //     }
                                        // }
                                    }
                                }
                                disablePast={true}
                                // DialogProps=
                                // {
                                //     {
                                //         style:
                                //         {
                                //             backgroundColor: '#000'
                                //         },
                                //         classes:
                                //         {
                                //             paper: this.classes.datePickerPaperRoot,
                                //             root: this.classes.datePickerPaperRoot,
                                //         },
                                //         onBackdropClick: () =>
                                //         {
                                //             console.log('clicked');
                                //         },
                                //         fullScreen: true,
                                //         PaperComponent: (props) =>
                                //         {
                                //             console.log(props);
                                //             return <Paper
                                //                         {...props}
                                //                     />
                                //         },
                                //         PaperProps:
                                //         {
                                //             classes:
                                //             {

                                //                 rounded: this.classes.datePickerPaperRoot,
                                                
                                //             }
                                //         }
                                //     }
                                // }
                                renderDay=
                                {
                                    (
                                        // day: Date | null,
                                        // selectedDate: Array<Date | null>,
                                        // pickerDayProps: PickersDayProps<Date>,
                                        day: unknown,
                                        selectedDate: unknown[],
                                        pickerDayProps: PickersDayProps<unknown>
                                        // dayInCurrentMonth: boolean,
                                        // dayComponent: JSX.Element,
                                    ) => 
                                    {
                                        return (
                                            <PickersDay
                                                {...pickerDayProps as PickersDayProps<Date>}
                                                style=
                                                {
                                                    (day as Date | null)?.getTime() === (selectedDate as Array<Date | null>)[0]?.getTime()
                                                    ?
                                                        {
                                                            color: '#000000DE',
                                                            backgroundColor: '#90CAF9'
                                                                
                                                        }
                                                    :
                                                        {
                                                            color: '#FFFFFF'
                                                        }
                                                }
                                            />
                                        );
                                    }
                                }
                                onChange=
                                {
                                    (e: unknown, keyboardValue?: string) =>
                                    {
                                        return this.onScheduledDateSelect(e as Date | null);
                                    }
                                }

                            />
                        </LocalizationProvider>
                        {/* <TextField
                            id="datetime-local"
                            label="Next appointment"
                            type="datetime-local"
                            defaultValue={new Date(Date.now()+(-(new Date().getTimezoneOffset() / 60)*(60*60*1000))).toISOString().replace(/(:[0-9]{2}\.[0-9]+Z)/gi, '')}
                            //defaultValue={new Date(Date.now()+(-(new Date().getTimezoneOffset() / 60)*(60*60*1000))).toISOString().replace('Z', '')}
                            // className={classes.textField}
                            InputLabelProps=
                            {
                                {
                                    shrink: true,
                                }
                            }
                        /> */}
                    </Typography>
                </StepContent>
            </Step>
        );
    }

    render()
    {
        return(
            <div className={this.classes.root}>
                <div className={this.classes.content}>
                    <div 
                        id='notification-creation-actions'
                        className={this.classes.notificationCreationSection}
                    >
                        
                        <Stepper
                            activeStep={this.state.activeStep} 
                            orientation="vertical"
                            nonLinear={this.state.sendButtonEnabled}
                        >
                            {
                                this.notificationSection()
                            }
                            {
                                this.targetSection()
                            }
                            {
                                this.schedulingSection()
                            }
                        </Stepper>
                        
                        {/* {
                            this.notificationSection()
                        }
                        {
                            this.targetSection()
                        }
                        {
                            this.schedulingSection()
                        } */}
                    </div>
                    <div className={this.classes.notificationCreationActions}>
                        <Button
                            disabled={!this.state.sendButtonEnabled}
                            onClick={this.sendPushNotification}
                            classes=
                            {
                                {
                                    root: this.classes.sendButton,
                                    disabled: this.classes.sendButtonDisabled
                                }
                            }
                        >
                            {'Enviar'}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withSnackbar(withStyles(useStyles)(PushNotificationPage));
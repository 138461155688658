import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'; 
import '../../../css/Doc.css';
import AddIcon from '@material-ui/icons/AddRounded';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';

import 
{ 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Snackbar,
    Menu,
    MenuItem
} from '@material-ui/core';

import
{
    Alert
} from '@material-ui/lab';
import { History, LocationState } from "history";

interface IMainDocProps
{
    history?: History<LocationState>,
}

interface IMainDocState 
{
    showAddDocDialog: boolean,
    docNameOk: boolean,
    docName: string | null,
    showErrorSnackbar: boolean,
    showConfirmationDialog: boolean,
    docNameHelper: string | null,
    docNameError: boolean,
    contextDoc: any | null,
    mouseX: number | null,
    mouseY: number | null,
    docsList: Array<any>
}

class MainDoc extends React.Component<IMainDocProps, IMainDocState>
{
    constructor(props: IMainDocProps)
    {
        super(props);
        this.state =
        {
            showAddDocDialog: false,
            docNameOk: true,
            docName: '',
            showErrorSnackbar: false,
            showConfirmationDialog: false,
            docNameHelper: '',
            docNameError: false,
            contextDoc: null,
            mouseX: null,
            mouseY: null,
            docsList: [],
        };
        this.handleAddDoc = this.handleAddDoc.bind(this);
        this.handleCloseAddDocDialog = this.handleCloseAddDocDialog.bind(this);
        this.handleSubmitAddDocDialog = this.handleSubmitAddDocDialog.bind(this);
        this.openAddDocDialog = this.openAddDocDialog.bind(this);
        this.docNameChange = this.docNameChange.bind(this);
        this.onErrorSnackClose = this.onErrorSnackClose.bind(this);
        this.handleContextMenuClick = this.handleContextMenuClick.bind(this);
        this.handleCloseConfirmationDialog = this.handleCloseConfirmationDialog.bind(this);
        this.handleCloseContextMenu = this.handleCloseContextMenu.bind(this);
        this.handleFirstDelete = this.handleFirstDelete.bind(this);
        this.handleSecondDelete = this.handleSecondDelete.bind(this);
        this.deleteDoc = this.deleteDoc.bind(this);
        this.openDoc = this.openDoc.bind(this);
        this.getList();
    }
    
    docsButtons = createTheme
    (
        {
            overrides:
            {
                MuiButton:
                {
                    containedPrimary:
                    {
                        backgroundColor: '#202225',
                        color: '#FFFFFF',
                        fontFamily: 'Nunito, sans-serif',
                        '&:hover':
                        {
                            backgroundColor: '#51565E'
                        }
                    },
                }
            }
        }
    );

    contextMenuTheme = createTheme
    (
        {
            overrides:
            {
                MuiMenu:
                {
                    paper:
                    {
                        borderRadius: 0
                    },
                    list:
                    {
                        backgroundColor: '#232427',   
                        color: 'white',
                    }
                },
            }
        }
    )

    dialogTheme = 
    {
        style:
        {
            backgroundColor: '#323232',
            color: '#FFFFFF'
        }
    };

    dialogContentTheme = createTheme
    (
        {
            overrides:
            {
                MuiTypography:
                {
                    colorTextSecondary:
                    {
                        color: 'rgb(255 255 255 / 90%)'
                    }
                },
                MuiButton:
                {
                    textPrimary:
                    {
                        color: 'white'
                    }
                },
                MuiFormLabel:
                {
                    root:
                    {
                        color: 'white',
                        "&$focused": 
                        {
                            color: 'white',
                        }
                    }
                },
                MuiInputBase:
                {
                    root:
                    {
                        color: 'white'
                    }
                },
                MuiInput:
                {
                    underline:
                    {
                        borderColor: 'white  !important',
                        '&:before':
                        {
                            color: 'white',
                            borderColor: 'white !important'
                        },
                        '&:hover':
                        {
                            color: 'white',
                            borderColor: 'white !important'
                        },
                        '&:after':
                        {
                            color: 'white',
                            borderColor: 'white  !important'
                        }
                    }
                },
            }
        }
    );

    getList()
    {
        fetch
        (
            '/api/docs?overview=true', 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    var data = await response.json();
                    this.setState
                    (
                        {
                            docsList: data
                        }
                    );
                }
                else
                {
                    if(this.props.history !== undefined)
                    {
                        this.props.history.push('/login');
                    }
                }
            } 
        );
    }

    async handleAddDoc(name: string)
    {
        var response = await fetch
        (
            '/api/doc', 
            {
                method: 'POST',
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify
                (
                    {
                        doc:
                        {
                            name: name
                        }
                    }
                ),
            }
        )
        return (await response.json());
    }

    async handleSubmitAddDocDialog(e: any)
    {
        e.preventDefault();
        var name = this.state.docName!;
        var resp = await this.handleAddDoc(name); 
        var successful = resp.hasOwnProperty('id') ? true : false;
 
        this.setState
        (
            {
                showAddDocDialog: !successful,
                showErrorSnackbar: !successful
            }
        );
        if(successful)
        {
            var docList: Array<any> = this.state.docsList;
            
            docList.push
            (
                {
                    id: resp.id,
                    name: name
                } 
            );
            this.setState
            (
                {
                   docName: '',
                   docsList: docList
                }
            )
            this.openDoc(resp.id, name)
        }
    }

    openDoc(id: number, name: string)
    {
        sessionStorage['pageTitle'] = 'Documentação - '.concat(name);
        sessionStorage['docID'] = id;
        if(this.props.history !== undefined)
        {
            this.props.history.push(`/docs/${id}`);
        }
    }

    onErrorSnackClose()
    {
        this.setState
        (
            {
                showErrorSnackbar: false
            }
        )
    }

    handleCloseAddDocDialog()
    {
        this.setState
        (
            {
                showAddDocDialog: false,
                docNameError: false,
                docNameHelper: null,
                docNameOk: false
            }
        );
    }

    openAddDocDialog()
    {
        this.setState
        (
            {
                showAddDocDialog: true
            }
        );
    } 

    docNameChange(e: any)
    {
        var error = false;
        var helperText = null;
        if(!e.target.value.match(/^(.){4,100}$/gi))
        {
           error = true;
           helperText = 'O nome da documentação deve ser entre 4 e 100 caracteres'
        }
        this.setState
        (
            {
                docName: e.target.value,
                docNameError: error,
                docNameHelper: helperText,
                docNameOk: error
            }
        )
    }

    async deleteDoc(docID: number)
    {
        var response = await fetch
        (
            `/api/doc/${docID}`,
            {
                method: "DELETE",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        );
        return (response.status === 200);
    }

    handleCloseContextMenu()
    {
        this.setState
        (
            {
                mouseX: null,
                mouseY: null,
                contextDoc: null
            }
        );
    }

    handleContextMenuClick(event: any, doc: any) 
    {
        event.preventDefault();
        this.setState
        (
            {
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4,
                contextDoc: doc
            }
        );
    };

    handleFirstDelete()
    {
        this.setState
        (
            {
                showConfirmationDialog: true
            }
        )
    };

    async handleSecondDelete()
    {
        var docID = this.state.contextDoc.id;
        var deleted = await this.deleteDoc(docID);
        if(deleted)
        {
            var tempDocsList = this.state.docsList.filter((element)=> element.id !== docID);
            this.setState
            (
                {
                    docsList: tempDocsList
                }
            )
        }
    };

    handleCloseConfirmationDialog(shouldDelete: boolean)
    {
        this.setState
        (
            {
                showConfirmationDialog: false
            }
        )
        if(shouldDelete)
        {
            this.handleSecondDelete();
        }
        this.handleCloseContextMenu();
    }

    buildDocsList(list: Array<any>)
    {   
        var children = [];
        var sThis = this;
        for(var i = list.length - 1; i >= 0; i--)
        {
            (
                function (i) 
                {
                    var listItem = 
                    (
                        <Button key={i} variant='contained' color='primary' 
                                onClick={(e)=> sThis.openDoc(list[i].id, list[i].name)} 
                                onContextMenu={(e)=>sThis.handleContextMenuClick(e, list[i])}
                            >
                            {list[i].name}
                        </Button>
                    );
                    children.push(listItem);
                }
            )(i);
        }
        return children
    }

    render(){
        return (
            <div>
                <ThemeProvider theme={this.docsButtons}>            
                    <h2 className='main_doc_title'>
                        {'Crie e edite documentações nesta página'}
                    </h2>
                    <div className='main_doc_section'>        
                        {this.buildDocsList(this.state.docsList)}
                        <Button size='small' color='primary' variant='contained' onClick={this.openAddDocDialog}>
                            <AddIcon/>
                        </Button>
                    </div>
                    <ThemeProvider theme={this.dialogContentTheme}>
                        <Dialog
                            open={this.state.showAddDocDialog}
                            onClose={this.handleCloseAddDocDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            PaperProps={this.dialogTheme}
                        >
                            <form 
                                onSubmit={this.handleSubmitAddDocDialog}
                            >
                                <DialogTitle id="alert-dialog-title">{'Nome da documentação'}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {'Opa! Primeiro precisamos dar um nome para a documentação. Escolha algo bem claro e que fique fácil para identificar futuramente.'}
                                    </DialogContentText>
                                    <TextField
                                        value={this.state.docName} 
                                        onChange={this.docNameChange}
                                        error={this.state.docNameError} 
                                        helperText={this.state.docNameHelper} 
                                        autoFocus
                                        margin="dense"
                                        id="doc_name_textfield"
                                        label="Nome da documentação"
                                        type="text"
                                        fullWidth
                                        required
                                    />                        
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleCloseAddDocDialog} color="primary">
                                        {'Cancelar'}
                                    </Button>
                                    <Button type='submit' color="primary" disabled={this.state.docNameOk}>
                                        {'Continuar'}
                                    </Button>
                                </DialogActions>
                            </form>
                        </Dialog>
                    </ThemeProvider>
                    <ThemeProvider theme={this.contextMenuTheme}>
                        <Menu
                            open={this.state.mouseY !== null}
                            onClose={this.handleCloseContextMenu.bind(this)}
                            anchorReference="anchorPosition"
                            anchorPosition=
                            {
                                this.state.mouseY !== null && this.state.mouseX !== null
                                ? 
                                    { 
                                        top: this.state.mouseY, 
                                        left: this.state.mouseX 
                                    }
                                : 
                                    undefined
                            }
                        >
                            <MenuItem onClick={this.handleFirstDelete}>
                                <DeleteIcon style={{color:'#CE2727'}}/>Apagar
                            </MenuItem>
                        </Menu>
                    </ThemeProvider>
                    <ThemeProvider theme={this.dialogContentTheme}>
                        <Dialog
                            open={this.state.showConfirmationDialog}
                            onClose={()=>this.handleCloseConfirmationDialog(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            PaperProps={this.dialogTheme}
                        >
                            <DialogTitle id="alert-dialog-title">{"Tens certeza que deseja apagar o usuário?"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {'Tem certeza que deseja deletar a documentação '.concat(this.state.contextDoc !== null ? this.state.contextDoc.name : 'null').concat('?\nApagá-la resultará na exclusão completa e não será possivel recuperá-la.')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={()=>this.handleCloseConfirmationDialog(false)} color="primary">
                                    {'Cancelar'}
                                </Button>
                                <Button onClick={()=>this.handleCloseConfirmationDialog(true)} color="primary">
                                    {'Apagar'}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </ThemeProvider>
                    <Snackbar open={this.state.showErrorSnackbar} onClose={this.onErrorSnackClose} autoHideDuration={2000}>
                        <Alert severity="error">
                            {'Ocorreu um erro ao adicionar a documentação'}
                        </Alert>
                    </Snackbar>
                </ThemeProvider>
            </div>
        )
    }
}

export default MainDoc;
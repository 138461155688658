
export default class CompanyFilters
{
	companyName: string;
	token: string;

	constructor(companyName: string, token: string)
	{
		this.companyName = companyName;
		this.token = token;
	}
}
export default class Chart
{
    title: string;
    description?: string;
    data: Array<ChartData>;
    unit: string;
    appId?: string;
    isProd?: boolean;

    constructor(data: { title: string, description?: string, data: Array<ChartData>, unit: string, appId?: string, isProd?: boolean })
    {
        this.title = data.title;
        this.description = data.description;
        this.data = data.data;
        this.unit = data.unit;
        this.appId = data.appId;
        this.isProd = data.isProd;
    }

    static fromJSON(json: any) : Chart
    {
        var data: Array<ChartData> = [];
        for(let i = 0; i < json['data'].length; i++)
        {
            data.push(ChartData.fromJSON(json['data'][i]));
        }
        return new Chart
        (
            {
                title: json['title'],
                description: json['description'],
                data: data,
                unit: json['unit'],
                appId: json['appId'],
                isProd: json['isProd']
            }
        );
    }
}

export class ChartData
{
    label: string;
    value: number;

    constructor(data: { label: string, value: number })
    {
        this.label = data.label;
        this.value = data.value;
    }

    public static fromJSON(json: any) : ChartData
    {
        return new ChartData
        (
            {
                label: json['label'],
                value: json['value']
            }
        );
    }
}

// export class ChartFilters
// {
//     type: ChartTypes;
//     production?: boolean;
//     appId?: string;
//     hasProductionFilter: boolean;

//     constructor(data: {type: ChartTypes, hasProductionFilter: boolean, production?: boolean,  appId?: string } = { hasProductionFilter: false, type: ChartTypes.Unknown})
//     {
//         this.type = data.type;
//         this.production = data.production;
//         this.appId = data.appId;
//         this.hasProductionFilter = data.hasProductionFilter;
//     }
// }

export enum ChartTypes
{
    ResponseTime = 'response-type',
    BusyTime = 'busy-time',
    Unknown = 'unknown'
}
import React from 'react';
// import clsx from 'clsx';
import { createStyles, /*lighten,*/ makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
// import DeleteIcon from '@material-ui/icons/Delete';
// import FilterListIcon from '@material-ui/icons/FilterList';

export interface IScreenAnalytics 
{
    screen_name: string,
    // data: 
    // {
    userEngagementDuration: string | number,
    totalUsers: string | number,
    screenPageViews: string | number
    // }
}

// function createData
// (
//     screen_name: string,
//     // data: 
//     // {
//     userEngagementDuration: string,
//     screenPageViews: string
//     // }
// ) : IScreenAnalytics 
// {
//   return { screen_name, userEngagementDuration, screenPageViews };
// }

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) 
{
    if(orderBy === 'views_per_user')
    {
        var bVal = parseInt((b as any).screenPageViews) / parseInt((b as any).totalUsers);
        var aVal = parseInt((a as any).screenPageViews) / parseInt((a as any).totalUsers);
        if (bVal < aVal) 
        {
            return -1;
        }
        if (bVal > aVal)
        {
            return 1;
        }
        return 0;
    }
    // console.log(typeof b[orderBy] );
    if(typeof b[orderBy] === 'string' && typeof a[orderBy] === 'string')
    {
        return (b[orderBy] as unknown as string).localeCompare(a[orderBy] as unknown as string);
    }
    if (b[orderBy] < a[orderBy]) 
    {
        return -1;
    }
    if (b[orderBy] > a[orderBy])
    {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>
(
  order: Order,
  orderBy: Key,
) : (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number 
{
    return order === 'desc'
    ? 
        (a, b) => descendingComparator(a, b, orderBy)
    : 
        (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) 
{
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort
    (
        (a, b) => 
        {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        }
    );
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell 
{
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = 
[
    { 
        id: 'screen_name', 
        numeric: false, 
        disablePadding: true, 
        label: 'Rotas' 
    },
    { 
        id: 'screenPageViews', 
        numeric: true, 
        disablePadding: false, 
        label: 'Views'
    },
    { 
        id: 'views_per_user', 
        numeric: true, 
        disablePadding: false,
        label: 'Visualizações/Usuário' 
    },
    { 
        id: 'totalUsers', 
        numeric: true, 
        disablePadding: false,
        label: 'Usuários' 
    },
    { 
        id: 'userEngagementDuration', 
        numeric: true, 
        disablePadding: false, 
        label: 'Tempo de uso médio' 
    },
//   { id: 'avarage_engagement_time', numeric: true, disablePadding: false, label: 'Protein (g)' },
];

interface IEnhancedTableProps
{
  classes?: ReturnType<typeof useStyles>;
  numSelected?: number;
  onRequestSort?: (event: any, property: string) => void;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order?: Order;
  orderBy?: string;
  rowCount?: number;
  rows: IScreenAnalytics[];
}

function EnhancedTableHead(props: IEnhancedTableProps) 
{
    const classes = useStyles();
    function createSortHandler(property: string) 
    {
        if(props.onRequestSort)
        {
            props.onRequestSort(null, property);
        }
    };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox">
                <Checkbox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                />
                </TableCell> */}
                {
                    headCells.map
                    (
                        (headCell) => 
                        {
                            // console.log(props.order, props.orderBy);

                            return (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.numeric ? 'right' : 'left'}
                                    // padding={headCell.disablePadding ? 'none' : 'normal'}
                                    sortDirection={props.orderBy === headCell.id ? props.order : false}
                                    classes=
                                    {
                                        {
                                            root: classes.tableHeadRoot
                                        }
                                    }
                                >
                                    <TableSortLabel
                                        active={props.orderBy === headCell.id}
                                        direction={props.orderBy === headCell.id ? props.order : 'asc'}
                                        onClick={(e)=>createSortHandler(headCell.id)}
                                        classes=
                                        {
                                            {
                                                root: classes.tableSelectSortRoot,
                                                active: classes.tableSelectSortActive,
                                            }
                                        }
                                    >
                                        {headCell.label}
                                        {
                                            props.orderBy === headCell.id 
                                            ? 
                                                (
                                                    <span className={classes.visuallyHidden}>
                                                        {
                                                            props.order === 'desc' 
                                                            ? 
                                                                'sorted descending'
                                                            : 
                                                                'sorted ascending'
                                                        }
                                                    </span>
                                                ) 
                                            : 
                                                null
                                        }
                                    </TableSortLabel>
                                </TableCell>
                            )
                        }
                    )
                }
            </TableRow>
        </TableHead>
    );
}

// const useToolbarStyles = makeStyles
// (
//     (theme: Theme) => createStyles
//     (
//         {
//             root: 
//             {
//                 paddingLeft: theme.spacing(2),
//                 paddingRight: theme.spacing(1),
//             },
//             highlight: theme.palette.type === 'light'
//             ? 
//                 {
//                     color: theme.palette.secondary.main,
//                     backgroundColor: lighten(theme.palette.secondary.light, 0.85),
//                 }
//             : 
//                 {
//                     color: theme.palette.text.primary,
//                     backgroundColor: theme.palette.secondary.dark,
//                 },
//             title: 
//             {
//                 flex: '1 1 100%',
//                 color: '#FFFFFF'
//             },
//         }
//     ),
// );

// interface EnhancedTableToolbarProps 
// {
//   numSelected: number;
// }

// function EnhancedTableToolbar(props: EnhancedTableToolbarProps)
// {
//     const classes = useToolbarStyles();
//     const { numSelected } = props;

//     return (
//         <Toolbar
//             className=
//             {
//                 clsx
//                 (
//                     classes.root, 
//                     {
//                         [classes.highlight]: numSelected > 0,
//                     }
//                 )
//             }
//         >
//             <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
//                 Telas
//             </Typography>
//         </Toolbar>
//     );
// };

const useStyles = makeStyles
(
    (theme: Theme) => createStyles
    (
        {
            root:
            {
                width: '100%',
            },
            paper:
            {
                width: '100%',
                marginBottom: theme.spacing(2),
                backgroundColor: '#202225',
            },
            table: 
            {
                minWidth: 750,
                borderColor: 'transparent',
            },
            tableCellRoot:
            {
                color: 'hsla(0,0%,100%,.8)',
                border: 'transparent',
                borderBottom: '1px solid rgb(81, 81, 81)'
            },
            tableHeadRoot:
            {
                color: 'hsla(0,0%,100%,.8)',
                border: 'transparent',
                borderBottom: '1px solid rgb(81, 81, 81)'
            },
            tableSelectSortRoot:
            {
                color: 'hsla(0,0%,100%,.8)',
                '&:hover':
                {
                    color: 'rgba(255,255,255, 0.54)'
                },
                '&$active': 
                {
                    color: "yellow !important",
                    backgroundColor: "yellow",
                },
                // active: 
                // {
                //     color: 'hsla(0,0%,100%,.8)',
                // },
            },
            tableSelectSortActive:
            {
                color: 'hsla(0,0%,100%,.9) !important',
                '& svg':
                {
                    color: 'hsla(0,0%,100%,.9) !important',
                }
            },
            visuallyHidden: 
            {
                border: 0,
                clip: 'rect(0 0 0 0)',
                height: 1,
                margin: -1,
                overflow: 'hidden',
                padding: 0,
                position: 'absolute',
                top: 20,
                width: 1,
            },
            tablePaginationActions:
            {
                color: '#FFFFFF',
                '& [disabled]':
                {
                    color: 'hsla(0,0%,100%,.5)'
                }
            },
            tablePaginationCaption:
            {
                color: '#FFFFFF'
            },
            tablePaginationSelect:
            {
                color: '#FFFFFF'
            },
            tablePaginationSelectIcon:
            {
                color: '#FFFFFF'
            }
        }
    ),
);

export default function ScreenAnalyticsEnhancedTable(props: IEnhancedTableProps) 
{
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof IScreenAnalytics>('screenPageViews');
    // const [selected, setSelected] = React.useState<string[]>([]);
    const [page, setPage] = React.useState(0);
    // const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    function handleRequestSort(event: any, property: keyof IScreenAnalytics) 
    {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // function handleClick (event: React.MouseEvent<unknown>, name: string)
    // {
    //     const selectedIndex = selected.indexOf(name);
    //     let newSelected: string[] = [];

    //     if (selectedIndex === -1) 
    //     {
    //         newSelected = newSelected.concat(selected, name);
    //     } 
    //     else if (selectedIndex === 0) 
    //     {
    //         newSelected = newSelected.concat(selected.slice(1));
    //     } 
    //     else if (selectedIndex === selected.length - 1) 
    //     {
    //         newSelected = newSelected.concat(selected.slice(0, -1));
    //     } 
    //     else if (selectedIndex > 0) 
    //     {
    //         newSelected = newSelected.concat
    //         (
    //             selected.slice(0, selectedIndex),
    //             selected.slice(selectedIndex + 1),
    //         );
    //     }
    //     // setSelected(newSelected);
    // };

    function handleChangePage(event: unknown, newPage: number)
    {
        setPage(newPage);
    };

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>)
    {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.rows.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper 
                className={classes.paper}
                elevation={0}
            >
                {/* <EnhancedTableToolbar 
                    // numSelected={selected.length} 
                /> */}
                <TableContainer>
                    <Table
                        className={classes.table}
                        classes=
                        {
                            {

                            }
                        }
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            classes={classes}
                            // numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={(e, property)=>handleRequestSort(e, property as keyof IScreenAnalytics)}
                            rowCount={props.rows.length}
                            rows={props.rows}
                        />
                        <TableBody>
                            {
                                stableSort
                                (
                                    props.rows, 
                                    getComparator(order, orderBy)
                                )
                                .slice
                                (
                                    page * rowsPerPage, 
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map
                                (
                                    (row, index) => 
                                    {
                                        // const isItemSelected = isSelected(row.screen_name);
                                        const labelId = `screen-analytic-table-${index}`;
                                        var userEngagementDuration = parseInt(row.userEngagementDuration.toString());

                                        return (
                                            <TableRow
                                                hover
                                                // onClick={(event) => handleClick(event, row.screen_name)}
                                                role="checkbox"
                                                // aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.screen_name}
                                                // selected={isItemSelected}
                                            >
                                                {/* <TableCell padding="checkbox">
                                                    <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </TableCell> */}
                                                <TableCell 
                                                    component="th" 
                                                    id={labelId} 
                                                    scope="row"
                                                    classes=
                                                    {
                                                        {
                                                            root: classes.tableCellRoot
                                                        }
                                                    }
                                                >
                                                    {row.screen_name}
                                                </TableCell>
                                                <TableCell 
                                                    align="right"
                                                    classes=
                                                    {
                                                        {
                                                            root: classes.tableCellRoot
                                                        }
                                                    }
                                                >
                                                    {row.screenPageViews}
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    classes=
                                                    {
                                                        {
                                                            root: classes.tableCellRoot
                                                        }
                                                    }
                                                >
                                                    {
                                                        (
                                                            Math.round
                                                            (
                                                                (
                                                                    (
                                                                        parseInt(row.screenPageViews.toString()) / parseInt(row.totalUsers.toString()) 
                                                                    ) + Number.EPSILON
                                                                ) * 100
                                                            ) / 100
                                                        )
                                                    }
                                                </TableCell>
                                                <TableCell 
                                                    align="right"
                                                    classes=
                                                    {
                                                        {
                                                            root: classes.tableCellRoot
                                                        }
                                                    }
                                                >
                                                    {
                                                        row.totalUsers
                                                    }
                                                </TableCell>
                                                <TableCell 
                                                    align="right"
                                                    classes=
                                                    {
                                                        {
                                                            root: classes.tableCellRoot
                                                        }
                                                    }
                                                >
                                                    {
                                                        secondsToHms(userEngagementDuration)
                                                    }
                                                </TableCell>
                                                {/* <TableCell align="right">{row.carbs}</TableCell>
                                                <TableCell align="right">{row.protein}</TableCell> */}
                                            </TableRow>
                                        );
                                    }
                                )
                            }
                            {
                                emptyRows > 0 && 
                                (
                                    <TableRow style={{ height: (53) * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    classes=
                    {
                        {
                            actions: classes.tablePaginationActions,
                            caption: classes.tablePaginationCaption,
                            select: classes.tablePaginationSelect,
                            selectIcon: classes.tablePaginationSelectIcon,
                        }
                    }
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={props.rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={'Linhas por página'}
                    labelDisplayedRows=
                    {
                        (data) => <p>{data.from}-{data.to} de {data.count}</p>
                    }
                />
            </Paper>
            {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />   */}
        </div>
    );
}

function secondsToHms(d: number) 
{
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + "h " : "";
    var mDisplay = m > 0 ? m + "m " : "";
    var sDisplay = s > 0 ? s + "s" : "";
    return hDisplay + mDisplay + sDisplay; 
}
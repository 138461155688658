import React from 'react';
import PieChart from '../charts/PieChart';
import '../../css/Overview.css';
import WebServiceAnalytics from './WebServiceAnalytics';
import { History, LocationState } from "history";
import { withStyles } from '@material-ui/core/styles';

import 
{
    ListItem,
    Button,
    ButtonGroup
} from '@material-ui/core';
import { WebserviceStatus } from '../../model/webserviceStatus';
import { ChartTypes } from '../../model/chart';
import Chart from '../../model/chart';
import AnalyticsChart from '../charts/AnalyticsChart';
import Skeleton from '@material-ui/lab/Skeleton';

const CHART_LASTWEEK_QUERY = 'lastweek';
const CHART_ALL_NOT_SOLVED_QUERY = 'allnotsolved';

interface IChartObjectData 
{
    colors: Array<string>,
    chartData: Array<number>,
    labelData: Array<string>,
}

interface IWebserviceData
{
    title: string,
    type: 'dev' | 'prod',
    showControlsAndLogs: boolean,
    data: Array<WebserviceStatus>
}

interface IOverviewProps
{
    history?: History<LocationState>,
    classes?: any,
}

interface IOverviewState 
{
    allNotSolvedData: IChartObjectData | null,
    lastWeekData: IChartObjectData | null,
    analyticsCharts: Array<Chart>,
    analyticsChartType: ChartTypes,
    isAnalyticsLoading: boolean,
    wsStatusList: null |
    {
        dev: IWebserviceData,
        prod: IWebserviceData
    }
}

const useStyles = (theme: any) =>
(
    {
        section_title:
        {
            color: '#FFFFFF',
            margin: '16px 0px',
            paddingLeft: '16px',
            paddingRight: '16px'
        },
        section_title_actions:
        {
            margin: '5px 0',
            alignSelf: 'center'
        },
        section_title_and_actions:
        {
            display: 'flex',
            flexDirection: 'row' as const,
            justifyContent: 'space-between'
        },
        textButtonGroupedTextHorizontal:
        {
            '&:not(:last-child)':
            {
                borderRight: '1px solid rgba(255, 255, 255, 0.23)'
            }
        },
        textButtonRoot:
        {
            color: '#FFFFFF',
            '&:hover':
            {
                textDecoration: 'none',
                backgroundColor: '#00000000'
            },
            "& .MuiTouchRipple-root span":
            {
                backgroundColor: '#00000000',
                opacity: .3,
            },
        },
        textButtonRootNotSelected:
        {
            color: '#B1B1B1',
            '&:hover':
            {
                textDecoration: 'none',
                backgroundColor: '#00000000'
            },
            "& .MuiTouchRipple-root span":
            {
                backgroundColor: '#00000000',
                opacity: .3,
            },
        },
        analyticChartSkeletonFitContent:
        {
            maxWidth: '100%',
            width: '100%'
        },
        analyticChartSkeletonText:
        {
            height: 'auto',
            transform: 'scale(1, 1)',
            marginTop: 0,
            borderRadius: '4px',
            marginBottom: 0,
            transformOrigin: '0 60%'
        }
    }
);

class Overview extends React.Component<IOverviewProps, IOverviewState>
{
    classes: any;
    constructor(props: IOverviewProps)
    {
        super(props);
        this.state = 
        {
            allNotSolvedData: null,
            lastWeekData: null,
            wsStatusList: null,
            analyticsCharts: [],
            analyticsChartType: ChartTypes.ResponseTime,
            isAnalyticsLoading: true
        }
        this.classes = props.classes;
        
    }

    componentDidMount()
    {
        this.getChartData(CHART_ALL_NOT_SOLVED_QUERY);
        this.getChartData(CHART_LASTWEEK_QUERY);
        this.getWSStatus();
        this.getWsAnalyticsData(this.state.analyticsChartType);
    }

    getWSStatus()
    {
        fetch
        (
            "/api/webservice/status", 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response: Response) =>
            {
                if(response.status === 200)
                {
                    var data = await response.json();
                    let devWsStatus: Array<WebserviceStatus> = [];
                    let prodWsStatus: Array<WebserviceStatus> = [];

                    for(let i = 0; i < data.dev.data.length; i++)
                    {
                        devWsStatus.push
                        (
                            new WebserviceStatus
                            (
                                data.dev.data[i].webservice,
                                data.dev.data[i].ws,
                                data.dev.data[i].running
                            )
                        );
                    }

                    for(let i = 0; i < data.prod.data.length; i++)
                    {
                        prodWsStatus.push
                        (
                            new WebserviceStatus
                            (
                                data.prod.data[i].webservice,
                                data.prod.data[i].ws,
                                data.prod.data[i].running
                            )
                        );
                    }

                    let dev = 
                    {
                        title: data.dev.title, 
                        type: data.dev.type,
                        showControlsAndLogs: data.dev.showControlsAndLogs,
                        data: devWsStatus
                    };
                    let prod = 
                    {
                        title: data.prod.title, 
                        type: data.prod.type,
                        showControlsAndLogs: data.prod.showControlsAndLogs,
                        data: prodWsStatus
                    };

                    this.setState
                    (
                        {
                            wsStatusList: 
                            {
                                dev: dev,
                                prod: prod
                            }
                        }
                    );
                }
                else
                {
                    if(this.props.history !== undefined)
                    {
                        this.props.history.push('/login');
                    }
                }
            } 
        );
    }

    getChartData(chartType: string)
    {
        if(chartType === CHART_ALL_NOT_SOLVED_QUERY || chartType === CHART_LASTWEEK_QUERY)
        {            
            fetch
            (
                `/api/logs/charts?${chartType}=true`, 
                {
                    method: "GET"
                }
            )
            .then
            (
                async (response: Response) =>
                {
                    if(response.status === 200)
                    {
                        var data = await response.json();
                        var chartData: Array<number> = [];
                        var labelData: Array<string> = [];
                        var colors: Array<string> = [];
                        for(var i = 0; i < data.length; i++)
                        {
                            chartData.push(data[i].logCount);
                            colors.push(data[i].color);
                            labelData.push(data[i].name);
                        }

                        let dataObject: IChartObjectData | null = 
                        {
                            colors: colors,
                            chartData: chartData,
                            labelData: labelData,
                        }

                        if(dataObject.colors.length === 0)
                        {
                            dataObject = null;
                        }
 
                        switch(chartType)
                        {
                            case CHART_ALL_NOT_SOLVED_QUERY: 
                                this.setState
                                (
                                    {
                                        allNotSolvedData: dataObject
                                    }
                                );
                                break;
                            case CHART_LASTWEEK_QUERY: 
                                this.setState
                                (
                                    {
                                        lastWeekData: dataObject
                                    }
                                );
                                break;
                            default:
                                break;
                        }

                        
                    }
                    else
                    {
                        if(this.props.history !== undefined)
                        {
                            this.props.history.push('/login');
                        }
                    }
                } 
            );
        }
    }

    getWsAnalyticsData(chartType: ChartTypes)
    {
        this.setState
        (
            {
                isAnalyticsLoading: true,
            },
        );
        fetch
        (
            `/api/charts/${chartType}`, 
            {
                method: "GET"
            }
        )
        .then
        (
            async (response: Response) =>
            {
                if(response.status === 200)
                {
                    var data = await response.json();
                    var charts: Array<Chart> = [];
                    for(let i = 0; i < data.length; i++)
                    {
                        charts.push(Chart.fromJSON(data[i]));
                    }

                    this.setState
                    (
                        {
                            analyticsCharts: charts,
                            isAnalyticsLoading: false
                        }
                    )
                    
                }
                else
                {
                    if(this.props.history !== undefined)
                    {
                        this.props.history.push('/login');
                    }
                }
            } 
        );
    }

    buildWebServiceInfo()
    {
        if(!this.state.wsStatusList)
        {
            return [];
        }
        var children = 
        [
            (
                <ListItem key={'dev'}
                    style=
                    {
                        {
                            display: 'flex',
                            'boxSizing': 'border-box'
                        }
                    }
                >
                    {/* <div className='webservice_status_item'> */}
                        <WebServiceAnalytics 
                            key={'dev'} 
                            title={this.state.wsStatusList!.dev.title} 
                            data={this.state.wsStatusList!.dev.data}
                            showControlsAndLog={this.state.wsStatusList!.dev.showControlsAndLogs}
                            type={this.state.wsStatusList!.dev.type}/>
                    {/* </div> */}
                </ListItem>
            ),
            (
                <ListItem key={'prod'}
                    style=
                    {
                        {
                            display: 'flex',
                            'boxSizing': 'border-box'
                        }
                    }
                >
                    {/* <div className='webservice_status_item'> */}
                        <WebServiceAnalytics 
                            key={'prod'} 
                            title={this.state.wsStatusList!.prod.title} 
                            data={this.state.wsStatusList!.prod.data}
                            showControlsAndLog={this.state.wsStatusList!.prod.showControlsAndLogs}
                            type={this.state.wsStatusList!.prod.type}/>
                    {/* </div> */}
                </ListItem>
            ),
        ];
        return children;
    }

    buildAnalyticsInfo()
    {
        if(!this.state.analyticsCharts)
        {
            return [];
        }
        var children: Array<any> = [];
        this.state.analyticsCharts.forEach
        (
            chart => 
            {
                children.push
                (
                    <ListItem key={`chart-analytics-id-${chart.appId}`}>
                        <AnalyticsChart
                            chart={chart}
                        />
                    </ListItem>  
                );
            }
        );
        return children;
    }

    getRandomInt(min: number, max: number) 
    {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min);
    }

    render()
    {
        return(
            <div> 
                <div className='charts_section'>  
                    {
                        this.state.allNotSolvedData !== null
                        ?
                            <PieChart 
                                title={'Erros não resolvidos'}
                                chartData={this.state.allNotSolvedData.chartData}
                                labelData={this.state.allNotSolvedData.labelData}
                                colors={this.state.allNotSolvedData.colors}
                            >
                            </PieChart>
                        :
                            null
                    }
                    {
                        this.state.lastWeekData  !== null
                        ?
                            <PieChart 
                                title={'Erros na ultima semana'}
                                chartData={this.state.lastWeekData.chartData}
                                labelData={this.state.lastWeekData.labelData}
                                colors={this.state.lastWeekData.colors}
                            >
                            </PieChart>
                        :
                            null
                    }
                </div>
                <div>
                    <div>
                        <h3 className={this.classes.section_title}>
                            {'Controle de APIs'}
                        </h3>
                    </div>
                    <div className='webservice_status_section'>
                        {
                            this.state.wsStatusList !== null
                            ? 
                                this.buildWebServiceInfo()
                            :
                                null
                        }
                    </div>
                </div>
                <div>
                    <div
                        className={this.classes.section_title_and_actions}
                    >
                        <h3 className={this.classes.section_title}>
                            {'Métricas do Sistema'}
                        </h3>
                        <div className={this.classes.section_title_actions}>
                            <ButtonGroup 
                                variant={'text'}
                                classes=
                                {
                                    {
                                        groupedTextHorizontal: this.classes.textButtonGroupedTextHorizontal
                                    }
                                }
                            >
                                <Button 
                                    onClick=
                                    {
                                        () =>
                                        {
                                            this.setState
                                            (
                                                {
                                                    analyticsChartType: ChartTypes.ResponseTime,
                                                    analyticsCharts: []
                                                },
                                                () =>
                                                {
                                                    this.getWsAnalyticsData(ChartTypes.ResponseTime)
                                                }
                                            );
                                        }
                                    } 
                                    classes=
                                    {
                                        {
                                            root: this.state.analyticsChartType === ChartTypes.ResponseTime
                                            ?
                                                this.classes.textButtonRoot
                                            :
                                                this.classes.textButtonRootNotSelected
                                        }
                                    }
                                >
                                    {'Tempo de resposta'}
                                </Button>
                                <Button 
                                    onClick=
                                    {
                                        () =>
                                        {
                                            this.setState
                                            (
                                                {
                                                    analyticsChartType: ChartTypes.BusyTime,
                                                    analyticsCharts: []
                                                },
                                                () =>
                                                {
                                                    this.getWsAnalyticsData(ChartTypes.BusyTime)
                                                }
                                            );
                                        }
                                    } 
                                    classes=
                                    {
                                        {
                                            root: this.state.analyticsChartType === ChartTypes.BusyTime
                                            ?
                                                this.classes.textButtonRoot
                                            :
                                                this.classes.textButtonRootNotSelected
                                        }
                                    }
                                >    
                                    {'Uso'}
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                    {
                        this.state.isAnalyticsLoading
                        ?
                            Array.from
                            (
                                {
                                    length: this.getRandomInt(1, 7)
                                }, 
                                (x, el) =>
                                {
                                    return (
                                        <ListItem key={`skeleton-${el}`}>
                                            <Skeleton 
                                                animation="wave" 
                                                classes=
                                                {
                                                    {
                                                        fitContent: this.classes.analyticChartSkeletonFitContent,
                                                        text: this.classes.analyticChartSkeletonText
                                                    }
                                                }
                                            >
                                                <div 
                                                    style=
                                                    {
                                                        {
                                                            minHeight: '300px',
                                                            borderRadius: '10px',
                                                            minWidth: '100%'
                                                        }
                                                    }
                                                >

                                                </div>
                                            </Skeleton> 
                                        </ListItem>
                                    );
                                } 
                            )
                        :
                            this.state.analyticsCharts !== null
                            ?
                                this.buildAnalyticsInfo()
                            :
                                null
                    }
                </div>
            </div>
        );
    }
}

export default withStyles(useStyles)(Overview);
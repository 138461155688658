import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'; 
import SyncLogPopup from '../popup/SyncLogPopup';
import { withStyles } from '@material-ui/core/styles';
import '../../css/Log.css';
import 
{ 
    Card, 
    List, 
    ListItem,
    Button
} from '@material-ui/core';
import 
{ 
    Pagination 
} from '@material-ui/lab';
import { History, LocationState } from "history";
import SyncLogData from '../../model/syncLogData';
import CompanyFilters from '../../model/companyFilters';
import Skeleton from '@material-ui/lab/Skeleton';
import ISyncLogFilter from '../../model/isyncLogFilter';
import SyncLogPopupFilter from '../popup/SyncLogPopupFilter';
import IActionFilter from '../../model/IActionFilter';

interface ISyncLogsProps
{
    history?: History<LocationState>,
    classes?: any,
    match?: 
    {
        params:
        {
            id: string
        }
    }
}

interface ISyncLogsState 
{
    appId: string,
    logList: Array<SyncLogData>,
    companyFilters: Array<CompanyFilters>,
    currentPage: number,
    pageCount: number,
    showPopup: boolean,
    popupLogData: string | null,
    isLogsLoading: boolean,
    showSyncFilterPopup: boolean,
    syncLogFilters?: ISyncLogFilter | null,
    actionsFilters: Array<IActionFilter>
}

const useStyles = (theme: any) =>
(
    {
        analyticChartSkeletonFitContent:
        {
            maxWidth: '100%',
            width: '100%'
        },
        analyticChartSkeletonText:
        {
            height: 'auto',
            transform: 'scale(1, 1)',
            marginTop: 0,
            borderRadius: '4px',
            marginBottom: 0,
            transformOrigin: '0 60%'
        },
        paginationRoot:
        {
            '& .MuiPaginationItem-root':
            {
                color: 'white'
            }
        },
        cardBackgroundRoot:
        {
            width: '100%',
            padding:'1%',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row' as const,
            cursor: 'pointer',
            backgroundColor: 'white !important'
        }
    }
);

class SyncLogs extends React.Component<ISyncLogsProps, ISyncLogsState>
{
    classes: any;
    logTheme = createTheme
    (
        {
            overrides:
            {
                MuiButtonBase:
                {
                    root:
                    {
                        color: 'white'
                    }
                },
                // MuiPaginationItem:
                // {
                //     root:
                //     {
                //         color: 'white !important',
                //     },
                //     page:
                //     {
                //         "&$selected": 
                //         {      
                //             backgroundColor: "rgba(255, 255, 255, 0.08)",
                //         },
                //     }
                // },
            }
        }
    );

    filtersTheme = createTheme
    (
        {
            overrides:
            {
                MuiButton:
                {
                    root:
                    {
                        color: 'white'
                    },
                    containedPrimary: 
                    {
                        backgroundColor: '#666666',
                        '&:hover':
                        {
                            backgroundColor: '#005E77'
                        }
                    },
                    containedSecondary:
                    {
                        backgroundColor: '#008EB4',
                        '&:hover':
                        {
                            backgroundColor: '#444444'
                        }
                    }
                },
                MuiSelect:
                {
                    root:
                    {
                        color: 'white',
                        borderBottom: '1px solid rgba(255, 255, 255, 0.42)',
                    },
                    icon:
                    {
                        color: 'white'
                    },
                },
                MuiInput:
                {
                    underline:
                    {
                        '&:hover':
                        {
                            borderBottom: '1px solid rgba(255, 255, 255, 0.87)',
                        },
                        '&:after':
                        {
                            borderBottom: '1px solid rgba(255, 255, 255, 0.87)',
                        }
                    }
                }
            }
        }
    );

    
    constructor(props: ISyncLogsProps)
    {
        super(props);
        this.classes = props.classes;
        var searchParams = new URLSearchParams(window.location.search);
        var page: number | string | null = searchParams.get('page');
        var action: string | undefined = searchParams.get('action') ?? undefined;
        var company: string | undefined = searchParams.get('company') ?? undefined;
        var responseCode: string | undefined = searchParams.get('responseCode') ?? undefined;
        var startDate: string | undefined = searchParams.get('startDate') ?? undefined;
        var endDate: string | undefined = searchParams.get('endDate') ?? undefined;
        var syncLogFilters: ISyncLogFilter | null = null;
        let app = props.match!.params.id;

        if(page)
        {
            page = parseInt(page); 
        }
        else
        {
            page = -1;
        }

        if(page! <= 0)
        {
            page = 1;
        }

        if(action || company || responseCode || startDate || endDate)
        {
            syncLogFilters = 
            {
                action: action,
                companyToken: company,
                startDate: startDate,
                endDate: endDate,
            }
        }
       
        this.state = 
        {
            logList: [],
            companyFilters: [],
            currentPage: page,
            pageCount: 1,
            showPopup: false,
            popupLogData: null,
            appId: app,
            isLogsLoading: true,
            showSyncFilterPopup: false,
            syncLogFilters: syncLogFilters,
            actionsFilters: []
        }
        this.handleCardClick = this.handleCardClick.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.openFilterPopup = this.openFilterPopup.bind(this);
        this.closeFilterPopup = this.closeFilterPopup.bind(this);
        this.getList(page);
    }

    UNSAFE_componentWillReceiveProps(props: ISyncLogsProps)
    {
        this.classes = props.classes;
        var searchParams = new URLSearchParams(window.location.search);
        var page: number | string | null = searchParams.get('page');
        var action: string | undefined = searchParams.get('action') ?? undefined;
        var company: string | undefined = searchParams.get('company') ?? undefined;
        var responseCode: string | undefined = searchParams.get('responseCode') ?? undefined;
        var startDate: string | undefined = searchParams.get('startDate') ?? undefined;
        var endDate: string | undefined = searchParams.get('endDate') ?? undefined;
        var syncLogFilters: ISyncLogFilter | null = null;
        let app = props.match!.params.id;

        if(page)
        {
            page = parseInt(page); 
        }
        else
        {
            page = -1;
        }

        if(page <= 0)
        {
            page = 1;
        }
        
        if(action || company || responseCode || startDate || endDate)
        {
            syncLogFilters = 
            {
                action: action,
                companyToken: company,
                startDate: startDate,
                endDate: endDate,
            }
        }

        //var query = new URLSearchParams();
        this.onPageChange = this.onPageChange.bind(this);
        this.openFilterPopup = this.openFilterPopup.bind(this);
        this.closeFilterPopup = this.closeFilterPopup.bind(this);

        this.setState
        ( 
            {
                logList: [],
                companyFilters: [],
                showPopup: false,
                currentPage: page,
                pageCount: 1,
                appId: app,
                isLogsLoading: false,
                showSyncFilterPopup: false, 
                syncLogFilters: syncLogFilters
            },
            () =>
            {
                this.getList(this.state.currentPage);
            }
        );
    }

    listBuilder(list: Array<SyncLogData>)
    {
        var children = [];
        var sThis = this;
        for(var i = 0; i < list.length; i++)
        {
            (
                function (i) 
                {
                    var listItem = 
                    (
                        <ListItem key={i} onClick={(e)=>(list[i].object !== 'null' && list[i].object !== null) ? sThis.handleCardClick(list[i].object) : null}>
                            <Card
                                classes=
                                {
                                    {
                                        root: sThis.classes.cardBackgroundRoot
                                    }
                                }
                            >
                                <div className='synclog_message'>
                                    {
                                        `Sincronização da empresa ${list[i].companyName} por ${list[i].ip}` 
                                    }
                                    <br/>
                                    {
                                        `Ação: ${list[i].action}`
                                    }
                                </div>
                                <div className='plataform'>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <div style={{color: '#9F9F9F'}}>
                                            {
                                                list[i].timestamp
                                            }
                                            <br/>
                                            {
                                                `Status HTTP ${list[i].statusCode}`
                                            }
                                        </div>
                                    </div>
                                    {
                                        list[i].isDev
                                        ?
                                            <div>
                                                <div style={{width: "20px"}}></div>
                                                <div style={{width: "20px", height: '50px'}}>
                                                    <div style={{display: 'flex', alignItems: 'center', background: "#770909", width: "100%", height: "120px", transform: "rotate(-35deg) translateX(35px) translateY(-20px)"}}>
                                                        <div style={{position: 'relative', justifySelf: 'center', transform: "rotate(90deg) translateY(4px)", color: 'white', fontSize: '10pt'}}>
                                                            DEV
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                            null
                                    }
                                   
                                </div>
                            </Card>
                        </ListItem>
                    );
                    children.push(listItem);
                }
            )(i);

        }
        return children;
    } 

    getList(page:number)
    {
        this.setState
        (
            {
                isLogsLoading: true,
            },
        );
        fetch
        (
            `/api/synclogs?page=${page}&company=${this.state.syncLogFilters?.companyToken ?? ''}&appId=${this.state.appId}&action=${this.state.syncLogFilters?.action ?? ''}&dateStart=${this.state.syncLogFilters?.startDate ?? ''}&dateEnd=${this.state.syncLogFilters?.endDate ?? ''}&statusCode=${this.state.syncLogFilters?.responseCode ?? ''}`, 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    var data = await response.json();
                    data.filters.companies.push
                    (
                        new CompanyFilters('Todas', 'todas')
                    );
                    data.filters.actions.push
                    (
                        {
                            action: 'Todas'
                        }
                    );
                    this.setState
                    (
                        {
                            pageCount: data.pages,
                            companyFilters: data.filters.companies,
                            actionsFilters: data.filters.actions,
                            logList: data.items,
                            isLogsLoading: false
                        }
                    );
                }
                else
                {
                    if(this.props.history !== undefined)
                    {
                        this.props.history.push('/login');
                    }
                }
            } 
        );
    }

    handleCardClick(logData: string | null)
    {
        if(logData !== null)
        {
            this.setState
            (
                {
                    showPopup: true,
                    popupLogData: logData
                }
            );
        }
    }

    closePopup()
    {
        this.setState
        (
            {
                showPopup: false,
                popupLogData: null
            },
        )
    }

    onPageChange(event: any, page: number)
    {
        var searchParams = new URLSearchParams(window.location.search)
        var url = '';
        searchParams.set("page", `${page}`);
        url = window.location.pathname + '?' + searchParams.toString();
        if(this.props.history !== undefined)
        {
            this.props.history.push(url);
            this.setState
            (
                {
                    currentPage: page,
                }
            );
            this.getList(page);
        }
        //window.location.replace(url);
    }

    openFilterPopup(e: any)
    {
        this.setState
        (
            {
                showSyncFilterPopup: true
            }
        );
    }

    closeFilterPopup(event: any, filters?: ISyncLogFilter )
    {
        // if(filters)
        // {
        //     console.log(filters);
        // }
        this.setState
        (
            {
                showSyncFilterPopup: false,
                syncLogFilters: filters
            },
            () =>
            {
                if(filters)
                {
                    var searchParams = new URLSearchParams(window.location.search)
                    var url = '';
                    searchParams.set("page", `${this.state.currentPage}`);
                    if(this.state.syncLogFilters?.action)
                    {
                        searchParams.set("action", `${this.state.syncLogFilters!.action}`);
                    }
                    if(this.state.syncLogFilters?.companyToken)
                    {
                        searchParams.set("company", `${this.state.syncLogFilters!.companyToken}`);
                    }
                    if(this.state.syncLogFilters?.responseCode)
                    {
                        searchParams.set("responseCode", `${this.state.syncLogFilters!.responseCode}`);
                    }
                    if(this.state.syncLogFilters?.startDate)
                    {
                        searchParams.set("startDate", `${this.state.syncLogFilters!.startDate}`);
                    }
                    if(this.state.syncLogFilters?.endDate)
                    {
                        searchParams.set("endDate", `${this.state.syncLogFilters!.endDate}`);
                    }
                    url = window.location.pathname + '?' + searchParams.toString();
                    if(this.props.history !== undefined)
                    {
                        this.props.history.push(url);
                        this.getList(this.state.currentPage);
                    }
                }
            }
        )
    }

    getRandomInt(min: number, max: number) 
    {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min);
    }

    render()
    {
        return(
            <div>
                {/* <ThemeProvider theme={this.logTheme}> */}
                    <div style={{padding: '0% 1%'}}>
                        <div className='filters'>
                            <ThemeProvider theme={this.filtersTheme}>
                                <Button 
                                    onClick={this.openFilterPopup}
                                >
                                    {'Filtrar'}
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                    <List id='logList'>
                        {/* {this.listBuilder(this.state.logList)} */}
                        {
                            this.state.isLogsLoading
                            ?
                                Array.from
                                (
                                    {
                                        length: this.getRandomInt(1, 7)
                                    }, 
                                    () =>
                                    {
                                        return (
                                            <ListItem>
                                                <Skeleton 
                                                    animation="wave" 
                                                    classes=
                                                    {
                                                        {
                                                            fitContent: this.classes.analyticChartSkeletonFitContent,
                                                            text: this.classes.analyticChartSkeletonText
                                                        }
                                                    }
                                                >
                                                    <div 
                                                        style=
                                                        {
                                                            {
                                                                minHeight: '75px',
                                                                borderRadius: '10px',
                                                                minWidth: '100%'
                                                            }
                                                        }
                                                    >

                                                    </div>
                                                </Skeleton> 
                                            </ListItem>
                                        );
                                    } 
                                )
                            :
                                this.listBuilder(this.state.logList)
                        }
                    </List>
                    <div style={{width:'100%', paddingTop: '1%'}}>
                        <div style={{left:0, right: 0, display: 'flex', justifyContent: 'center'}}>
                            <Pagination 
                                onChange={this.onPageChange} 
                                page={this.state.currentPage} 
                                count={this.state.pageCount} 
                                classes=
                                {
                                    {
                                        root: this.classes.paginationRoot,
                                    }
                                }
                            />
                        </div>
                    </div>
                    <SyncLogPopup show={this.state.showPopup} data={this.state.popupLogData} closePopup={this.closePopup.bind(this)}/>
                {/* </ThemeProvider> */}
                <SyncLogPopupFilter 
                    open={this.state.showSyncFilterPopup}
                    closeHandler={this.closeFilterPopup}
                    companiesDataset={this.state.companyFilters}
                    actionsDataset={this.state.actionsFilters}
                    filters={this.state.syncLogFilters}
                /> 
            </div>
        );
    }
}

export default withStyles(useStyles)(SyncLogs);
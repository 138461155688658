import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'; 
// import Circle from '../Circle';
import AppLogPopup from '../popup/AppLogPopup';
import { withStyles } from '@material-ui/core/styles';
import '../../css/Log.css';
import 
{ 
    Card, 
    List, 
    ListItem,
    Button,
    ButtonGroup
} from '@material-ui/core';
import 
{ 
    Pagination 
} from '@material-ui/lab';
import LogData from '../../model/logData';

import { History, LocationState } from "history";
import Skeleton from '@material-ui/lab/Skeleton';

const SOLVED_FILTER = 0;
const NOTSOLVED_FILTER = 1;

interface IAppLogsProps
{
    history?: History<LocationState>,
    classes?: any,
    match?: 
    {
        params:
        {
            id: string
        }
    }
}

interface IAppLogsState 
{
    logList: Array<LogData>,
    showPopup: boolean,
    currentPage: number,
    pageCount: number,
    orderBy: number,
    popupLogData: LogData | null,
    isLogsLoading: boolean
}

const useStyles = (theme: any) =>
(
    {
        analyticChartSkeletonFitContent:
        {
            maxWidth: '100%',
            width: '100%'
        },
        analyticChartSkeletonText:
        {
            height: 'auto',
            transform: 'scale(1, 1)',
            marginTop: 0,
            borderRadius: '4px',
            marginBottom: 0,
            transformOrigin: '0 60%'
        },
        cardBackgroundRoot:
        {
            width: '100%',
            padding:'1%',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row' as const,
            cursor: 'pointer',
            backgroundColor: 'white !important'
        }
    }
);

class AppLogs extends React.Component<IAppLogsProps, IAppLogsState>
{
    classes: any;

    logTheme = createTheme
    (
        {
            overrides:
            {
                MuiButtonBase:
                {
                    root:
                    {
                        color: 'white !important'
                    }
                },
                // MuiPaginationItem:
                // {
                //     root:
                //     {
                //         color: 'white !important',
                //     },
                //     page:
                //     {
                //         "&$selected": 
                //         {      
                //             backgroundColor: "rgba(255, 255, 255, 0.08)",
                //         },
                //     }
                // },
            }
        }
    );

    filtersTheme = createTheme
    (
        {
            overrides:
            {
                MuiButton:
                {
                    root:
                    {
                        color: 'white'
                    },
                    containedPrimary: 
                    {
                        backgroundColor: '#666666',
                        '&:hover':
                        {
                            backgroundColor: '#005E77'
                        }
                    },
                    containedSecondary:
                    {
                        backgroundColor: '#008EB4',
                        '&:hover':
                        {
                            backgroundColor: '#444444'
                        }
                    }
                },
            }
        }
    );

    markAsSolved = createTheme
    (
        {
            overrides:
            {
                MuiButton:
                {
                    root:
                    {
                        color: 'white'
                    },
                    containedPrimary:
                    {
                        backgroundColor: '#036D19',
                        '&:hover':
                        {
                            backgroundColor: '#3D6746'
                        }
                    }
                },
            }
        }
    );
    
    constructor(props: IAppLogsProps)
    {
        super(props);
        this.classes = props.classes;
        var searchParams = new URLSearchParams(window.location.search);
        let page: string | number | null = searchParams.get('page') ;
        let orderBy: string | number | null = searchParams.get('orderby');
        let app: string = props.match!.params.id;
        page = page ? parseInt(page!.toString()) : -1;
        if(page <= 0)
        {
            page = 1;
        }
        if(orderBy === null)
        {
            orderBy = NOTSOLVED_FILTER;
        }
        else
        {
            orderBy = Number(orderBy);
            if(orderBy !== NOTSOLVED_FILTER && orderBy !== SOLVED_FILTER)
            {
                orderBy = NOTSOLVED_FILTER;
            }
        }
        this.state = 
        {
            logList: [],
            showPopup: false,
            currentPage: page,
            pageCount: 1,
            orderBy: orderBy,
            popupLogData: null,
            isLogsLoading: false,
        }
        //var query = new URLSearchParams();
        this.changeOrderBy = this.changeOrderBy.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.getList(page, orderBy, app);
    }

    UNSAFE_componentWillReceiveProps(props: IAppLogsProps)
    {
        this.classes = props.classes;
        var searchParams = new URLSearchParams(window.location.search);
        let page: string | number | null = searchParams.get('page');
        let orderBy: string | number | null = searchParams.get('orderby');
        let app = this.props.match!.params.id;
        page = page ? parseInt(page!.toString()) : -1;
        if(page <= 0)
        {
            page = 1;
        }
        if(orderBy === null)
        {
            orderBy = NOTSOLVED_FILTER;
        }
        else
        {
            orderBy = Number(orderBy);
            if(orderBy !== NOTSOLVED_FILTER && orderBy !== SOLVED_FILTER)
            {
                orderBy = NOTSOLVED_FILTER;
            }
        }
        this.setState
        ( 
            {
                logList: [],
                showPopup: false,
                currentPage: page,
                pageCount: 1,
                orderBy: orderBy,
                isLogsLoading: false
            }
        );
        //var query = new URLSearchParams();
        this.changeOrderBy = this.changeOrderBy.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.getList(page, orderBy, app);
    }

    handleCardClick(log: LogData)
    {
        this.setState
        (
            {
                showPopup: true,
                popupLogData: log
            }
        );
    }

    listBuilder(list: Array<LogData>)
    {
        var children = [];
        var sThis = this;
        for(var i = 0; i < list.length; i++)
        {
            (
                function (i) 
                {
                    var color = '';
                    if(!list[i].solved)
                    {
                        color = '#980000';
                    }
                    else
                    {
                        color = '#277D15';
                    }
                    var listItem = 
                    (
                        <ListItem key={i} onClick={ (ev) => sThis.handleCardClick(list[i])}>
                            <Card
                                style=
                                {
                                    {
                                        padding: '0', 
                                        alignItems: 'normal'
                                    }
                                }
                                classes=
                                {
                                    {
                                        root: sThis.classes.cardBackgroundRoot
                                    }
                                }
                            >
                                <div style={{backgroundColor: color, color: 'white', width: '10vw', alignItems: 'center', display: 'flex', padding: '5px'}} >
                                    {list[i].id}
                                </div>
                                <div style={{padding: '1%', width: '100%', cursor: 'pointer', display: 'flex', alignItems: 'center', overflow: 'hidden', flexDirection: 'row'}}>
                                    <div className='exception'>
                                        {list[i].exception}
                                    </div>
                                    <div className='plataform'>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <div>{list[i].plataform}</div>
                                            <div style={{color: '#9F9F9F'}}>{list[i].timestamp}</div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </ListItem>
                    );
                    children.push(listItem);
                }
            )(i);
        }
        return children;
    } 

    getList(page: number, orderby: number, app: string)
    {
        this.setState
        (
            {
                isLogsLoading: true,
            },
        );
        fetch
        (
            `/api/logs?page=${page}&orderby=${orderby}&appId=${app}`, 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    var data = await response.json();
                    this.setState
                    (
                        {
                            pageCount: data.pages,
                            logList: data.items,
                            isLogsLoading: false
                        }
                    );
                }
                else
                {
                    if(this.props.history !== undefined)
                    {
                        this.props.history.push('/login');
                    }
                }
            } 
        );
    }

    closePopup(log: LogData | null)
    {
        this.setState
        (
            {
                showPopup: false,
                popupLogData: null
            },
            () =>
            {
                if(log !== null)
                {
                    var logs = this.state.logList;
                    var idx = logs.findIndex(x => x.id === log.id);
                    logs[idx].solved = log.solved;
                    this.setState
                    (
                        {
                            logList: logs
                        }
                    );
                    //window.location.reload();
                }
            }
        )
    }

    onPageChange(event: any, page: number)
    {
        var searchParams = new URLSearchParams(window.location.search)
        var url = '';
        searchParams.set("page", `${page}`);
        url = window.location.pathname + '?' + searchParams.toString();
        if(this.props.history !== undefined)
        {
            this.props.history.push(url);
            this.setState
            (
                {
                    currentPage: page,
                }
            );
            this.getList(page, this.state.orderBy,  this.props.match!.params.id);
        }
        //window.location.replace(url);
    }

    changeOrderBy(orderBy: number)
    {
        var searchParams = new URLSearchParams(window.location.search)
        var url = '';
        searchParams.set("orderby", `${orderBy}`);
        url = window.location.pathname + '?' + searchParams.toString();
        if(this.props.history !== undefined)
        {
            this.props.history.push(url);
            this.setState
            (
                {
                    orderBy: orderBy,
                }
            );
            this.getList(this.state.currentPage, orderBy, this.props.match!.params.id);
        }
        //window.location.replace(url);
    }
    
    async markAllAsSolved()
    {
        var logs = this.state.logList;
        for(var i = 0; i < logs.length; i++)
        {
            var id = logs[i].id;
            var response  = await fetch
            (
                `/api/log/${id}/`, 
                {
                    method: "PATCH",
                    headers: 
                    {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify
                    (
                        {
                            solved: true
                        }
                    )
                    
                }
            );
            if(response.status === 200)
            {
                logs[i].solved = true;
            }
        }
        this.setState
        (
            {
                logList: logs
            }
        )
    }

    getRandomInt(min: number, max: number) 
    {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min);
    }

    render()
    {
        return(
            <div>
                
                <ThemeProvider theme={this.logTheme}>
                    <div style={{padding: '0% 1%'}}>
                        <ThemeProvider theme={this.markAsSolved}>
                            <div style={{float: 'left'}}>
                                <Button variant="contained" color="primary" onClick={()=> this.markAllAsSolved()}>
                                    {'Marcar todos como resolvido'}
                                </Button>
                            </div>
                        </ThemeProvider>
                        <div className='filters'>
                            <ThemeProvider theme={this.filtersTheme}>
                                <ButtonGroup color="primary" aria-label="outlined primary button group">
                                    <Button variant="contained" onClick={() => this.changeOrderBy(SOLVED_FILTER)} color={this.state.orderBy === SOLVED_FILTER ? 'secondary' : 'primary'}>
                                        Resolvidos
                                    </Button>
                                    <Button variant="contained" onClick={() => this.changeOrderBy(NOTSOLVED_FILTER)} color={this.state.orderBy === NOTSOLVED_FILTER ? 'secondary' : 'primary'}>
                                        Não resolvidos
                                    </Button>
                                </ButtonGroup>
                            </ThemeProvider>
                        </div>
                    </div>
                    <List id='logList'>
                        {
                            this.state.isLogsLoading
                            ?
                                Array.from
                                (
                                    {
                                        length: this.getRandomInt(1, 7)
                                    }, 
                                    () =>
                                    {
                                        return (
                                            <ListItem>
                                                <Skeleton 
                                                    animation="wave" 
                                                    classes=
                                                    {
                                                        {
                                                            fitContent: this.classes.analyticChartSkeletonFitContent,
                                                            text: this.classes.analyticChartSkeletonText
                                                        }
                                                    }
                                                >
                                                    <div 
                                                        style=
                                                        {
                                                            {
                                                                minHeight: '75px',
                                                                borderRadius: '10px',
                                                                minWidth: '100%'
                                                            }
                                                        }
                                                    >

                                                    </div>
                                                </Skeleton> 
                                            </ListItem>
                                        );
                                    } 
                                )
                            :
                                this.listBuilder(this.state.logList)
                        }
                    </List>
                    
                    <div style={{width:'100%', paddingTop: '1%'}}>
                        <div style={{left:0, right: 0, display: 'flex', justifyContent: 'center'}}>
                            <Pagination onChange={this.onPageChange} page={this.state.currentPage} count={this.state.pageCount} />
                        </div>
                    </div>
                    <AppLogPopup show={this.state.showPopup} data={this.state.popupLogData} closePopup={this.closePopup.bind(this)}/>
                </ThemeProvider>
            </div>
        );
    }
}

export default withStyles(useStyles)(AppLogs);
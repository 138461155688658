import React from 'react';  
import '../../css/popup/Popup.css';  
import Circle from '../Circle';
import Checkmark from '@material-ui/icons/Check';
import Close from '@material-ui/icons/CloseRounded';
import 
{  
    IconButton,
    Dialog,
	DialogContent,
    Button
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core';
import { createTheme , makeStyles } from '@material-ui/core/styles'; 
import LogData from '../../model/logData';


interface IAppLogPopupProps
{
    data: LogData | null,
    closePopup: (log: LogData | null) => void,
    show: boolean
}

interface IAppLogPopupState 
{
    
}

class AppLogPopup extends React.Component<IAppLogPopupProps, IAppLogPopupState>
{  

    buttonTheme = createTheme
    (
        {
            overrides:
            {
                MuiIconButton: 
                {
                    root:
                    {
                        color: 'white !important'
                    }
                }
            }
        }
    );

    onPopupInnerClick(event: any)
    {
        event.stopPropagation();
    }

    markAsSolved(event: any)
    {
        var id = this.props.data!.id;
        var solved = this.props.data!.solved;
        fetch
        (
            `/api/log/${id}/`, 
            {
                method: "PATCH",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify
                (
                    {
                        solved: !solved
                    }
                )
                
            }
        )
        .then
        (
            (response) =>
            {
                if(this.props.data != null)
                {
                    if(response.status === 200)
                    {
                        this.props.data.solved = !solved;
                    }
                    this.props.closePopup(this.props.data);
                }
            }
        )
        
    }

    render() 
    {  
        if(this.props.show)
        {
            return (  
                <Dialog
                    onClick={(event)=> event.stopPropagation()}
                    onClose={()=>this.props.closePopup(null)}
                    open={this.props.show}
                >
                    <DialogContent>
                        <div style={{position: 'absolute', right: '5%'}}>
                            <Circle backgroundColor={this.props.data?.solved ? '#980000' : '#277D15'}>
                                <ThemeProvider theme={this.buttonTheme}>
                                    <IconButton color='primary' onClick={this.markAsSolved.bind(this)}>
                                        {
                                            this.props.data?.solved
                                            ?
                                                <Close/>
                                            :
                                                <Checkmark/>
                                        }
                                    </IconButton>
                                </ThemeProvider>
                            </Circle>
                        </div>

                        <h2>Dispositivo</h2>
                        <div className='info_section' style={{flexDirection: 'row'}}>
                            <div style={{flexDirection: 'column'}}>
                                <h1>
                                    {'Modelo:'}
                                    <div>
                                        {this.props.data?.model}
                                    </div> 
                                </h1>
                                <br></br>
                                <h1>
                                    {'Tela:'}
                                    <div>
                                        {this.props.data?.screenSize}
                                    </div> 
                                </h1>
                                <h1>
                                    {'Resolução:'}
                                    <div>
                                        {this.props.data?.resolution}
                                    </div> 
                                </h1>
                                <br></br>
                                <h1>
                                    {'DP:'}
                                    <div>
                                        {this.props.data?.dp}
                                    </div> 
                                </h1>
                                <br></br>
                            </div>
                            <div style={{flexDirection: 'column', marginLeft: '10px'}}>
                                <h1>
                                    {'Sistema:'}
                                    <div>
                                        {this.props.data?.plataform}
                                    </div> 
                                </h1>
                                <h1>
                                    {'Versão:'}
                                    <div>
                                        {this.props.data?.osVersion}
                                    </div> 
                                </h1>
                                <h1>
                                    {'Versão App:'}
                                    <div>
                                        {this.props.data?.appVersion}
                                    </div> 
                                </h1>
                                <br></br>
                            </div>
                        </div>
                        <h2>Log</h2>
                        <div className='info_section' style={{flexDirection: 'column'}}>
                            <h1>
                                {'Exception:'}
                                <div>
                                    {this.props.data?.exception}
                                </div> 
                            </h1>
                            <br></br>
                            <h1>
                            {'StackTrace:'}
                            </h1>
                            <AppLogStackTrace
                                stacktrace={this.props.data?.stacktrace.replaceAll('\\n', '\n') ?? ''}
                            />
                            <br></br>
                        </div>
                    </DialogContent>
                </Dialog>                
            );
        }
        else
        {
            return(null);
        }
    }  
}  

const useStyles = makeStyles
(
    {
        copyButtonRoot:
        {
            backgroundColor: 'rgba(0,0,0,.3)',
            transition: 'opacity .2s ease-in-out',
            fontSize: '10pt',
            minWidth: '0',
            textTransform: 'none',
            '&:hover':
            {
                backgroundColor: 'rgba(0,0,0,.3)'
            }
        }
    }
)

interface IAppLogStackTraceProps
{
    stacktrace: string
}

function AppLogStackTrace(props: IAppLogStackTraceProps)
{
    const classes = useStyles();
    const [isMouseOver, setIsMouseOver] = React.useState<boolean>(false);
    const [copyButtonText, setCopyButtonText] = React.useState<string>('Copiar');
    const copiedText = 'Copiado!';
    const copyText = 'Copiar';

    return (
        <div
            style=
            {
                {
                    display: 'flex'
                }
            }
            onMouseOver=
            {
                (e) =>
                {
                    setIsMouseOver(true);
                    setCopyButtonText(copyText);
                }
            }
            onMouseLeave=
            {
                (e) =>
                {
                    setIsMouseOver(false);
                    setCopyButtonText(copyText);
                }
            }
        >
            <code 
                style=
                {
                    {
                        overflow: 'auto',
                        height: '25vh',
                        fontSize: '11pt',
                        whiteSpace: 'pre-line'
                    }
                }
            >
                {
                    props.stacktrace
                }
            </code> 
            <div
                style=
                {
                    {
                        display: 'flex',
                        flexDirection: 'row-reverse' as const,
                    }
                }
            >
                <Button
                    style=
                    {
                        {
                            position: 'absolute',
                            opacity: isMouseOver ?  1 : 0,
                            marginRight: '15px'
                        }
                    }
                    classes=
                    {
                        {
                            root: classes.copyButtonRoot
                        }
                    }
                    onClick=
                    {
                        (e) =>
                        {
                            navigator.clipboard.writeText(props.stacktrace);
                            setCopyButtonText(copiedText);
                        }
                    }
                >
                    {
                        copyButtonText
                    }
                </Button>
            </div>
        </div>
    );
}

export default AppLogPopup;
import React from 'react';
import { Line, /*Chart as ReactCharts*/ } from 'react-chartjs-2';
import Chart from '../../model/chart';
import 
{
    withStyles,
    // createStyles,
    // Theme,
    // createTheme,
    // ThemeProvider
} from '@material-ui/core/styles';

interface IAnalyticsChartProps
{
    chart: Chart,
    classes?: any,
}

interface IAnalyticsChartState 
{
    avg?: number,
    data: 
    {
        labels: Array<string>,
        datasets: Array<
            {
                data: Array<number>,
                backgroundColor: string,
                borderColor: Array<string>,
                borderWidth: number,
                label?: string
            }
        >
    },
}

const useStyles = (theme: any) =>
(
    {
        root:
        {
            width: '100%',
            maxHeight: '300px',
            backgroundColor: 'white',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column' as const
        },
        innerRoot:
        {
            display: 'flex',
            flexDirection: 'column' as const,
            padding: '15px'
        },
        chartHeader:
        {
            display: 'flex',
            flexDirection: 'row' as const,
            justifyContent: 'space-between',
            '& h3':
            {
                margin: '0'
            }
        },
        titleStyle:
        {
            color: 'black',
            fontSize: '1em',
            paddingTop: '10px',            
        }
    }
);
const defaultColor = '#A6CEE3';

class AnalyticsChart extends React.Component<IAnalyticsChartProps, IAnalyticsChartState>
{
    classes: any;

    constructor(props: IAnalyticsChartProps)
    {
        super(props);
        this.classes = props.classes;
        var labels: Array<string> = [];
        var datas: Array<number> = [];
        var sum = 0;
        
        const dayCount = 31;
        for(let i = 0; i <= dayCount; i++)
        {
            var date = new Date(Date.now()-(((dayCount-i)*24)*60*60*1000));
            labels.push(`${date.getDate()}. ${date.toLocaleString('default', {month: 'short'})}`);
            if(props.chart.data.length > (dayCount-i))
            {
                datas.push(parseInt(props.chart.data[dayCount-i].value.toString()));
            }
            else
            {
                datas.push(0);
            }
            sum += datas[i];
        }


        // props.chart.data.forEach
        // (
        //     element =>
        //     {
        //         var date = new Date(parseInt(element.label));
        //         labels.push(`${date.getDate()} .${date.toLocaleString('default', {month: 'short'})}`);
        //     }
        // );
        // props.chart.data.forEach
        // (
        //     element =>
        //     {
        //         datas.push(element.value);
        //     }
        // );
        this.state = 
        {
            avg: sum/(props.chart.data.length > 0 ? props.chart.data.length : 1),
            data:
            {
                labels: labels,
                datasets: 
                [
                    {
                        data: datas,
                        backgroundColor: defaultColor,
                        borderColor: [ defaultColor ],
                        borderWidth: 1,
                        label: props.chart.unit
                    }
                ]
            }
        }
    }

    UNSAFE_componentWillReceiveProps(props: IAnalyticsChartProps)
    {
        this.classes = props.classes;
        var labels: Array<string> = [];
        var datas: Array<number> = [];
        var sum = 0;
        const dayCount = 31;

        for(let i = 0; i < dayCount; i++)
        {
            var date = new Date(Date.now()-(((dayCount-i)*24)*60*60*1000));
            labels.push(`${date.getDate()}. ${date.toLocaleString('default', {month: 'short'})}`);
            if(props.chart.data.length > (dayCount-i))
            {
                datas.push(props.chart.data[dayCount-i].value);
            }
            else
            {
                datas.push(0);
            }
        }

        this.setState
        (
            {
                avg: sum/(props.chart.data.length > 0 ? props.chart.data.length : 1),
                data:
                {
                    labels: labels,
                    datasets: 
                    [
                        {
                            data: datas,
                            backgroundColor: defaultColor,
                            borderColor: [ defaultColor ],
                            borderWidth: 1,
                            label: props.chart.unit
                        }
                    ]
                }
            }
        );
    }

    render()
    {
        return(
            <div id={'chartsection'} className={this.classes.root}>
                <div className={this.classes.innerRoot}>
                    <div className={this.classes.chartHeader}>
                        <h3 className={this.classes.titleStyle}>
                            {`${this.props.chart.title}(${this.props.chart.isProd === true ? 'Prod' : 'Dev'})`}
                        </h3>
                        {
                            this.state.avg !== null && this.state.avg !== undefined
                            ?
                                <h3 className={this.classes.titleStyle}>
                                    {`${Math.round(this.state.avg!)} ${this.props.chart.unit}`}
                                </h3>
                            :
                                null
                        }
                    </div>
                    <Line
                        style=
                        {
                            {
                                maxHeight: '250px'
                            }
                        }
                        height={200}
                        options=
                        {
                            {
                                maintainAspectRatio: true,
                                responsive: true,
                                plugins:
                                {
                                    // height: 200,
                                    title:
                                    {
                                        display: false,
                                        // color: 'black',
                                        // font:
                                        // {
                                        //     size: '20pt',
                                        // },
                                        // text: `${this.props.chart.title}(${this.props.chart.isProd === true ? 'Prod' : 'Dev'})`,
                                        // align: 'start',
                                        // padding: 
                                        // {
                                        //     top: 10,
                                        //     bottom: 30
                                        // }
                                    },                      
                                    legend:
                                    {
                                        display: false,
                                    },
                                    tooltip: 
                                    {
                                        callbacks: 
                                        {
                                            label: function(context: any) 
                                            {
                                                return `${context.parsed.y} ${context.dataset.label}`;
                                            }
                                        }
                                    },
                                },
                                scales: 
                                {
                                    y: 
                                    {
                                        min: 0,
                                        ticks: 
                                        {
                                            stepSize: 1,
                                        }
                                    }
                                },
                            }
                        }
                        data={this.state.data}
                    />
                </div>
            </div>
        );
    }
}

export default withStyles(useStyles)(AnalyticsChart);
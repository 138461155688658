import React from 'react';
import '../../css/Home.css';
import AppLogs from './AppLogs';
import Settings from './Settings';
import Overview from './Overview';
import MainDoc from './Doc/MainDoc';
import DocPage from './Doc/DocPage';
import SyncLogs from './SyncLogs';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';
import LogoutIcon from '@material-ui/icons/ExitToAppRounded';
import DescriptionIcon from '@material-ui/icons/DescriptionRounded';
import SendIcon from '@material-ui/icons/SendRounded';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import 
{
    AddToHomeScreenRounded, 
    DomainRounded
} from '@mui/icons-material';

// import SyncIcon from '@material-ui/icons/SyncRounded';
// import MaterialUiIconPicker from 'react-material-ui-icon-picker';
import 
{
  Switch,
  Route,  
} from "react-router-dom";

import 
{  
    SwipeableDrawer, 
    AppBar, 
    Toolbar, 
    IconButton,
    Typography, 
    List, 
    ListSubheader,
    ListItem,
    ListItemText,
    ListItemIcon
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'; 
import { History, LocationState } from "history";
import App from '../../model/app';
import PopupChooseLogType from '../popup/PopupChooseLogType';
import PushNotificationPage from '../pages/PushNotification/PushNotificationPage';
import { withStyles } from '@material-ui/core/styles';
import AppScreenAnalytics from '../pages/Analytics/AppScreenAnalytics';
import AppUsersAnalytics from '../pages/Analytics/AppUsersAnalytics';

import 
{  
    AnalyticsRounded,
    PeopleRounded
} from '@mui/icons-material';
import 
{ 
    Collapse,
    ListItemButton
} from '@mui/material';
import PublicAnalytics from './Analytics/PublicAnalytics';

export const HOME_ID = '/home';
const SETTINGS_ID = '/settings';
const DOCS_ID = '/docs';
const LOGS_ID = '/logs';
const PUSH_NOTIFICATION_ID = '/push-notification';
const ANALYTICS_ID = '/analytics';
const ANALYTICS_SCREENS_ID = `${ANALYTICS_ID}/screens`;
const ANALYTICS_USERS_ID = `${ANALYTICS_ID}/users`;
const ANALYTICS_INTERNAL_ID = `${ANALYTICS_ID}/internal`;

export const HOME_TITLE = 'Home';
const SETTINGS_TITLE = 'Configurações';
const DOCS_TITLE = 'Documentações';
const APPLOGS_TITLE = 'Logs do App';
const SYNCLOGS_TITLE = 'Logs de sincronização';
const PUSH_NOTIFICATION_TITLE = 'Push Notifications';
const ANALYTICS_SCREENS_TITLE = 'Analytics - Telas';
const ANALYTICS_USERS_TITLE = 'Analytics - Usuários';
const ANALYTICS_INTERNAL_TITLE = 'Analytics - Interno';

interface IRouteChache
{
    //type: '/home' | '/settings' | '/docs' | '/docs/:id' | '/logs/app/:id' | '/logs/sync/:id' | 'none',
    url: string,
    cached: JSX.Element | null
}

interface ILoggedHomeProps
{
    classes?: any,
    history?: History<LocationState>,
}

interface ILoggedHomeState 
{
    isDrawerOpen: boolean,
    title: string,
    openPopupChooseLogType: boolean,
    appLogId: string | null,
    openAnalyticsCollapse: boolean,
    menuDataInfo: 
    {
        dev: Array<App>,
        prod: Array<App>,
    }
}

const useStyles = (theme: any) =>
(
    {
        paperRoot:
        {
            backgroundColor: '#323232',
        },
        drawerListItemText:
        {
            color: 'white'
        }
    }
);

const sleep = (ms: number) => new Promise(res => setTimeout(res, ms));

class LoggedHome extends React.Component<ILoggedHomeProps, ILoggedHomeState>
{
    classes: any;
    routeCache: IRouteChache = 
    {
        //type: 'none',
        url: '',
        cached: null
    };

    constructor(props: ILoggedHomeProps)
    {
        super(props);
        this.classes = props.classes;

        var pathName = this.props.history?.location?.pathname ?? '';
        var id = sessionStorage['page'];
        //var app = sessionStorage['app'];
        var title = sessionStorage['pageTitle'];
        let path = '';
        this.getAppsMenuInfo();
        if(pathName !== '/')
        {
            var endIndex = pathName.length ?? 0;
            if(pathName.match(/(\/)/gi)!.length >= 2)
            {
                endIndex = pathName?.indexOf('/', 1);
            }
            path = pathName?.substr(0, endIndex);
            id = path;
        }
        path = '';
        // console.log(id);
        switch(id)
        {
            case SETTINGS_ID:
                title = SETTINGS_TITLE;
                if(this.props.history !== undefined)
                {
                    this.props.history.push(SETTINGS_ID);
                }
                break;
            case PUSH_NOTIFICATION_ID:
                title = PUSH_NOTIFICATION_TITLE;
                if(this.props.history !== undefined)
                {
                    this.props.history.push(PUSH_NOTIFICATION_ID);
                }
                break;
            case ANALYTICS_ID:
                if(pathName.match(ANALYTICS_SCREENS_ID))
                {
                    title = ANALYTICS_SCREENS_TITLE;
                    if(this.props.history !== undefined)
                    {
                        this.props.history.push(ANALYTICS_SCREENS_ID);
                    }
                }
                break;
            case LOGS_ID:
                let matches = pathName.match(/^\/logs\/(?<type>[A-Z]+)\/(?<id>[0-9]+)\/?$/i);
                if(matches)
                {
                    switch(matches!.groups!.type!)
                    {
                        case 'app':
                            title = APPLOGS_TITLE;
                            break;
                        case 'sync':
                            title = SYNCLOGS_TITLE;
                            break;
                        default:
                            title = HOME_TITLE;
                            if(this.props.history !== undefined)
                            {
                                this.props.history.push('/home');
                            }
                            break;
                    }
                }
                // if(this.props.history !== undefined)
                // {
                //     this.props.history.push(SYNCLOGS_ID);
                // }
                break;
            case DOCS_ID:
                var docID = sessionStorage['docID'];
                if(pathName.match(/(\/)/gi)!.length < 2)
                {
                    docID = null;
                    title = DOCS_TITLE;
                }   
                if(docID !== null && docID !== undefined && docID !== '')
                {
                    path = path!.concat('/').concat(docID);
                }

                if(this.props.history !== undefined)
                {
                    this.props.history.push(path);
                }
                break;
            default:
                title = HOME_TITLE;
                if(this.props.history !== undefined)
                {
                    this.props.history.push('/home');
                }
                break;
        }
        this.openAppLogTypePopup = this.openAppLogTypePopup.bind(this);
        this.onCloseAppLogTypePopup = this.onCloseAppLogTypePopup.bind(this);
        this.state = 
        {
            title: title,
            isDrawerOpen: false,
            openPopupChooseLogType: false,
            appLogId: null,
            openAnalyticsCollapse: false,
            menuDataInfo: 
            {
                dev: [],
                prod: []
            }
        }

        if(this.isPushNotificationSupported())
        {
            this.askUserPermission().then
            (
                async (value) =>
                {
                    console.log(value);
                    if(value === 'granted')
                    {
                        var registration = await this.registerServiceWorker();
                        var subscription = await this.createNotificationSubscription(registration);
                        console.log('Sending push');
                        await fetch
                        (
                            '/api/push-notification/subscribe', 
                            {
                                method: 'POST',
                                body: JSON.stringify(subscription),
                                headers: 
                                {
                                    'content-type': 'application/json'
                                }
                            }
                        );
                    }
                }
            )
        }
        
    }

    appbarTheme = createTheme
    (
        {
            overrides:
            {
                MuiAppBar:
                {
                    colorPrimary:
                    {
                        backgroundColor: '#212121',
                    }
                },
            }
        }
    );

    drawerTheme = createTheme
    (
        {
            overrides:
            {
                MuiPaper:
                {
                    root:
                    {
                        backgroundColor: '#323232',
                    }
                },
                MuiList:
                {
                    root:
                    {
                        padding: '0px',
                    }
                },
                MuiListSubheader:
                {
                    root:
                    {
                        color: 'white',
                        fontSize: '18pt'
                    }
                },
                MuiListItem:
                {
                    root:
                    {
                        width: '100%',
                        color: 'white'
                    }
                },
                MuiListItemIcon:
                {
                    root:
                    {
                        color: 'white'
                    }
                }
            }
        }
    );

    isPushNotificationSupported() 
    {
        return "serviceWorker" in navigator && "PushManager" in window;
    }

    async askUserPermission() 
    {
        return await Notification.requestPermission();
    }

    async registerServiceWorker() : Promise<ServiceWorkerRegistration | null>
    {
        var url = new URL(process.env.PUBLIC_URL, window.location.href);
        if(url.origin !== window.location.origin)
        {
            return null;
        }

        return await navigator.serviceWorker.register
        (
            `${process.env.PUBLIC_URL}/push-sw.js`,
            {
                scope: '/',
                updateViaCache: 'none'
            }
        );
    }

    urlBase64ToUint8Array(base64String: string) 
    {
        var padding = '='.repeat((4 - base64String.length % 4) % 4);
        var base64 = (base64String + padding)
            .replace(/-/g, '+')
            .replace(/_/g, '/');

        var rawData = window.atob(base64);
        var outputArray = new Uint8Array(rawData.length);

        for (var i = 0; i < rawData.length; ++i) 
        {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    async createNotificationSubscription(registration: ServiceWorkerRegistration | null) 
    {
        return new Promise
        (
            async (resolve) =>
            {
                //wait for service worker installation to be ready
                // const serviceWorker = await navigator.serviceWorker.ready;
                // subscribe and return the subscription
                if(!registration)
                {
                    return;
                }
                var isActive: boolean = false;
                do
                {
                    if(registration.active)
                    {
                        isActive = true;
                    }
                    else
                    {
                        await sleep(500);
                    }
                } while(!isActive)

                resolve
                (
                    registration.pushManager.subscribe
                    (
                        {
                            userVisibleOnly: true,
                            applicationServerKey: this.urlBase64ToUint8Array('BIXHS3CzSuoxfVudtD97kDSbXDz-yJjCLhW2rFmiveAWME0lIsbudeAbm3t03MrC8kgmIvv8f7aErRASs5oGqP8')
                        }
                    )
                );
            }
        )
    }

    getAppsMenuInfo()
    {
        fetch
        (
            "/api/apps?menuInfo=true", 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    var data = await response.json();
                    let devMenuInfo: Array<App> = [];
                    let prodMenuInfo: Array<App> = [];

                    for(let i = 0; i < data.dev.length; i++)
                    {
                        devMenuInfo.push
                        (
                            new App
                            (
                                data.dev[i].appID,
                                data.dev[i].icon,
                                data.dev[i].name,
                                data.dev[i].color
                            )
                        );
                    }

                    for(let i = 0; i < data.prod.length; i++)
                    {
                        prodMenuInfo.push
                        (
                            new App
                            (
                                data.prod[i].appID,
                                data.prod[i].icon,
                                data.prod[i].name,
                                data.prod[i].color
                            )
                        );
                    }

                    let menuInfo =
                    {
                        dev: devMenuInfo,
                        prod: prodMenuInfo
                    }

                    this.setState
                    (
                        {
                            menuDataInfo: menuInfo
                        }
                    );
                }
                else
                {
                    if(this.props.history !== undefined)
                    {
                        this.props.history.push('/login');
                    }
                }
            } 
        );
    }

    openAppLogTypePopup(appId: string)
    {
        this.setState
        (
            {
                appLogId: appId,
                openPopupChooseLogType: true
            }
        )
    }

    onCloseAppLogTypePopup(logType: 'app' | 'sync' | null)
    {
        let appId: string = this.state.appLogId!;
        this.setState
        (
            {
                appLogId: null,
                openPopupChooseLogType: false
            },
            ()=>
            {
                switch(logType)
                {
                    case 'app':
                        this.routeCache.cached = null;
                        this.setState
                        (
                            {
                                title: APPLOGS_TITLE
                            }
                        );
                        this.props.history?.push(`/logs/app/${appId}`)
                        break;
                    case 'sync':
                        this.routeCache.cached = null;
                        this.setState
                        (
                            {
                                title: SYNCLOGS_TITLE
                            }
                        );
                        this.props.history?.push(`/logs/sync/${appId}`)
                        break;
                    default:
                        break;

                }
            }
        )
    }

    buildAppMenuItems(list: Array<App>, keyPrefix: string)
    {
        var children = [];
        var sThis = this;
        for(var i = 0; i < list.length; i++)
        {  
            (
                function (i) 
                {
                    //sThis.onDrawerAppItemClick(event, list[i].appID, 'Logs - '.concat(list[i].name))}
                    //sThis.props.history?.push(`/logs/app/${list[i].appID}`)
                    var listItem = 
                    (
                        <ListItem key={`${keyPrefix}-${i}`} button onClick={() => {sThis.openAppLogTypePopup(list[i].appID!) }}>
                            <ListItemIcon>
                                <Icon className='material-icons'>
                                    {list[i].icon}
                                </Icon>
                            </ListItemIcon>
                            <ListItemText primary={list[i].name}/>
                        </ListItem>
                    );
                    children.push(listItem);
                }
            )(i);
        }
        return children;
    }
    
    onAppBarMenuClick = (event: any) =>
    {
        var isDrawerOpen = this.state.isDrawerOpen;
        this.setState
        (
            {
                isDrawerOpen: !isDrawerOpen
            }
        );
    }

    logout()
    {
        fetch
        (
            '/api/logout', 
            {
                method: "DELETE",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    if(this.props.history !== undefined)
                    {
                        this.props.history.push('/login');
                    }
                }
            } 
        );
    }

    onDrawerAppItemClick = (event: any, id: string, title: string | null) =>
    {
        sessionStorage['page'] = id;
        sessionStorage['pageTitle'] = title;
        sessionStorage['app'] = '';
        sessionStorage['docID'] = '';
        if(this.props.history !== undefined)
        {
            this.props.history.push(id);
        }
        // switch(id)
        // {
        //     case HOME_ID:
        //         if(this.props.history !== undefined)
        //         {
        //             this.props.history.push(HOME_ID);
        //         }
        //         break;
        //     case SETTINGS_ID:
        //         if(this.props.history !== undefined)
        //         {
        //             this.props.history.push(SETTINGS_ID);
        //         }
        //         break;
        //     case DOCS_ID:  
        //         if(this.props.history !== undefined)
        //         {
        //             this.props.history.push(DOCS_ID);
        //         }
        //         break;
        //     case PUSH_NOTIFICATION_ID:
        //         if(this.props.history !== undefined)
        //         {
        //             this.props.history.push(PUSH_NOTIFICATION_ID);
        //         }
        //         break;
        //     case LOGOUT_ID:
        //         this.logout();
        //         break;
        // }
        if(title != null)
        {
            this.setState
            (
                {
                    title: title,
                    isDrawerOpen: false
                }
            );
        }
    }

    render ()
    {
        return (
            <div>
                <ThemeProvider theme={this.appbarTheme}>
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton edge="start" color="inherit" aria-label="menu" onClick={this.onAppBarMenuClick}>
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6">
                                {this.state.title}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </ThemeProvider>
                <ThemeProvider theme={this.drawerTheme}>
                    <SwipeableDrawer 
                        classes=
                        {
                            {
                                paper: this.classes.paperRoot
                            }
                        }
                        anchor='left' 
                        open={this.state.isDrawerOpen}
                        onClose={this.onAppBarMenuClick}
                        onOpen={this.onAppBarMenuClick}
                    >
                        <List>
                            <ListSubheader>
                                {'Principais'}
                            </ListSubheader>
                            <ListItem button onClick={(event) => this.onDrawerAppItemClick(event, HOME_ID, HOME_TITLE)}>
                                <ListItemIcon>
                                    <HomeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Home"/>
                            </ListItem>
                            <ListItem button onClick={(event) => this.onDrawerAppItemClick(event, SETTINGS_ID, SETTINGS_TITLE)}>
                                <ListItemIcon>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Configurações"/>
                            </ListItem>
                            <ListItem button onClick={(event) => this.onDrawerAppItemClick(event, DOCS_ID, DOCS_TITLE)}>
                                <ListItemIcon>
                                    <DescriptionIcon />
                                </ListItemIcon>
                                <ListItemText primary="Docs"/>
                            </ListItem>
                            <ListItem button onClick={(event) => this.onDrawerAppItemClick(event, PUSH_NOTIFICATION_ID, PUSH_NOTIFICATION_TITLE)}>
                                <ListItemIcon>
                                    <SendIcon />
                                </ListItemIcon>
                                <ListItemText primary="Push Notifications"/>
                            </ListItem>
                            <ListItem button onClick=
                                {
                                    () => 
                                    {
                                        this.setState
                                        (
                                            {
                                                openAnalyticsCollapse: !this.state.openAnalyticsCollapse
                                            }
                                        )
                                    }
                                }>
                                <ListItemIcon>
                                    <AnalyticsRounded />
                                </ListItemIcon>
                                <ListItemText primary="Analytics"/>
                                {
                                    this.state.openAnalyticsCollapse 
                                    ? 
                                        <ExpandLess /> 
                                    : 
                                        <ExpandMore />
                                }
                            </ListItem>
                            <Collapse in={this.state.openAnalyticsCollapse} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton 
                                        sx={{ pl: 4 }}
                                        onClick={(event) => this.onDrawerAppItemClick(event, ANALYTICS_SCREENS_ID, ANALYTICS_SCREENS_TITLE)}
                                    >
                                        <ListItemIcon>
                                            <AddToHomeScreenRounded />
                                        </ListItemIcon>
                                        <ListItemText 
                                            classes=
                                            {
                                                {
                                                    root: this.classes.drawerListItemText
                                                }
                                            }
                                            primary="Telas"
                                        />
                                    </ListItemButton>
                                    <ListItemButton 
                                        sx={{ pl: 4 }}
                                        onClick={(event) => this.onDrawerAppItemClick(event, ANALYTICS_USERS_ID, ANALYTICS_USERS_TITLE)}
                                    >
                                        <ListItemIcon>
                                            <PeopleRounded />
                                        </ListItemIcon>
                                        <ListItemText 
                                            classes=
                                            {
                                                {
                                                    root: this.classes.drawerListItemText
                                                }
                                            }
                                            primary="Usuários"
                                        />
                                    </ListItemButton>
                                    <ListItemButton 
                                        sx={{ pl: 4 }}
                                        onClick={(event) => this.onDrawerAppItemClick(event, ANALYTICS_INTERNAL_ID, ANALYTICS_INTERNAL_TITLE)}
                                    >
                                        <ListItemIcon>
                                            <DomainRounded />
                                        </ListItemIcon>
                                        <ListItemText 
                                            classes=
                                            {
                                                {
                                                    root: this.classes.drawerListItemText
                                                }
                                            }
                                            primary="Interno"
                                        />
                                    </ListItemButton>
                                </List>
                            </Collapse>

                            <ListSubheader>
                                {'Apps'}
                            </ListSubheader>
                                <ListSubheader disableSticky style={{fontSize: '12pt', color: '#B1B1B1'}}>
                                    {'Dev'}
                                </ListSubheader>
                                    {this.buildAppMenuItems(this.state.menuDataInfo.dev, 'dev')}
                                <ListSubheader disableSticky style={{fontSize: '12pt', color: '#B1B1B1'}}>
                                    {'Prod'}
                                </ListSubheader>
                                    {this.buildAppMenuItems(this.state.menuDataInfo.prod, 'prod')}
                            <ListSubheader>
                                {'Outros'}
                            </ListSubheader>
                            <ListItem button onClick={(event) => this.logout()}>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText primary="Logout"/>
                            </ListItem>
                        </List>
                    </SwipeableDrawer>
                </ThemeProvider>
                <div id='content' style={{padding: '2%'}}>
                    <Switch>
                        <Route exact path={HOME_ID} render=
                            {
                                (props) =>
                                {
                                    if(this.routeCache.cached == null || this.routeCache.url !== props.match.url)
                                    {
                                        this.routeCache.url = props.match.url;
                                        this.routeCache.cached = <Overview {...props} history={this.props.history}/>
                                    }
                                    return this.routeCache.cached;
                                } 
                                
                            }
                        />
                        <Route exact path={SETTINGS_ID} render=
                            {
                                (props) => 
                                {
                                    if(this.routeCache.cached == null || this.routeCache.url !== props.match.url)
                                    {
                                        this.routeCache.url = props.match.url;
                                        this.routeCache.cached = <Settings {...props} history={this.props.history}/>
                                    }
                                    return this.routeCache.cached;
                                }
                            }
                        />
                        <Route exact path={`/docs/:id`} render=
                            {
                                (props) => 
                                {
                                    if(this.routeCache.cached == null || this.routeCache.url !== props.match.url)
                                    {
                                        this.routeCache.url = props.match.url;
                                        this.routeCache.cached = <DocPage match={props.match} history={this.props.history}  />
                                    }
                                    return this.routeCache.cached;
                                }
                                    
                            }
                        />
                        <Route exact path={DOCS_ID} render=
                            {
                                (props) => 
                                {
                                    if(this.routeCache.cached == null || this.routeCache.url !== props.match.url)
                                    {
                                        this.routeCache.url = props.match.url;
                                        this.routeCache.cached = <MainDoc {...props} history={this.props.history}/>
                                    }
                                    return this.routeCache.cached;
                                }
                            }
                        />
                        <Route exact path={`/logs/sync/:id`} render=
                            {
                                (props) => 
                                {  
                                    if(this.routeCache.cached == null || this.routeCache.url !== props.match.url)
                                    {
                                        this.routeCache.url = props.match.url;
                                        this.routeCache.cached = <SyncLogs {...props}  history={this.props.history}/>
                                    }
                                    return this.routeCache.cached;
                                    
                                }
                            }
                        />
                        <Route exact path={'/logs/app/:id'} render=
                            {
                                (props) => 
                                {
                                    if(this.routeCache.cached === null || this.routeCache.url !== props.match.url)
                                    {
                                        this.routeCache.url = props.match.url;
                                        this.routeCache.cached =  <AppLogs {...props} history={this.props.history}/>
                                    }
                                    return this.routeCache.cached;
                                }
                            }
                        />
                        <Route exact path={ANALYTICS_SCREENS_ID} render=
                            {
                                (props) => 
                                {
                                    if(this.routeCache.cached === null || this.routeCache.url !== props.match.url)
                                    {
                                        this.routeCache.url = props.match.url;
                                        this.routeCache.cached =  <AppScreenAnalytics {...props} history={this.props.history}/>
                                    }
                                    return this.routeCache.cached;
                                }
                            }
                        />
                        <Route exact path={ANALYTICS_USERS_ID} render=
                            {
                                (props) => 
                                {
                                    if(this.routeCache.cached === null || this.routeCache.url !== props.match.url)
                                    {
                                        this.routeCache.url = props.match.url;
                                        this.routeCache.cached =  <AppUsersAnalytics {...props} history={this.props.history}/>
                                    }
                                    return this.routeCache.cached;
                                }
                            }
                        />
                        <Route exact path={ANALYTICS_INTERNAL_ID} render=
                            {
                                (props) => 
                                {
                                    if(this.routeCache.cached === null || this.routeCache.url !== props.match.url)
                                    {
                                        this.routeCache.url = props.match.url;
                                        this.routeCache.cached =  <PublicAnalytics {...props} history={this.props.history}/>
                                    }
                                    return this.routeCache.cached;
                                }
                            }
                        />
                        <Route exact path={PUSH_NOTIFICATION_ID} render=
                            {
                                (props) => 
                                {
                                    if(this.routeCache.cached === null || this.routeCache.url !== props.match.url)
                                    {
                                        this.routeCache.url = props.match.url;
                                        this.routeCache.cached =  <PushNotificationPage {...props} history={this.props.history}/>
                                    }
                                    return this.routeCache.cached;
                                }
                            }
                        />
                    </Switch>
                </div>
                <PopupChooseLogType 
                    showAppLogs={this.state.appLogId !== '827239445446000600' && this.state.appLogId !== '827237348294000600'} 
                    open={this.state.openPopupChooseLogType} 
                    onClose={this.onCloseAppLogTypePopup}/>
            </div>
        );
    }
}

export default withStyles(useStyles)(LoggedHome);
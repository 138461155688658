// import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Login from './elements/pages/Login';
import LoggedHome from './elements/pages/LoggedHome';
import DocPage from './elements/pages/Doc/DocPage';
import 
{
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import reportWebVitals from './reportWebVitals';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AuthorizeAppPage from './elements/pages/oauth/authorize_app';

ReactDOM.render
(
  <LocalizationProvider dateAdapter={AdapterDateFns }>
    <SnackbarProvider
      maxSnack={5}
      anchorOrigin=
      {
        {
          vertical: 'bottom',
          horizontal: 'right',
        }
      }
      autoHideDuration={1500}
    >
        <Router>
          <Switch>
            <Route path="/oauth/authorize" component={AuthorizeAppPage}/>
            {/* <Route path="/analytics/screens/:app_id" component={AppScreenAnalytics}/> */}
            <Route path="/docs/:id" component={DocPage}/>
            <Route path="/login" component={Login} />
            <Route path="/" component={LoggedHome} />
          </Switch>
        </Router>
    </SnackbarProvider>
  </LocalizationProvider>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

import React from 'react';
import { Button, ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'; 
import '../../css/Log.css';
import '../../css/Analytics.css';
import 
{ 
    List, 
    ListItem,
} from '@material-ui/core';
import Circle from '../Circle';
import { History, LocationState } from "history";
import { WebserviceStatus } from '../../model/webserviceStatus';

interface IWebServiceAnalyticsProps
{
    history?: History<LocationState>,
    title: string,
    type: 'dev' | 'prod',
    showControlsAndLog: boolean,
    data: Array<WebserviceStatus>
}

interface IWebServiceAnalyticsState 
{
    
}

class WebServiceAnalytics extends React.Component<IWebServiceAnalyticsProps, IWebServiceAnalyticsState>
{
    webserviceStatusTheme = createTheme
    (
        {
            overrides:
            {
                MuiButton:
                {
                    containedPrimary:
                    {
                        backgroundColor: '#008EB4',
                        '&:hover':
                        {
                            backgroundColor: '#006682'
                        }
                    },
                    containedSecondary:
                    {
                        backgroundColor: '#980000',
                        '&:hover':
                        {
                            backgroundColor: '#7B0000'
                        }
                    }
                }
            }
        }
    );

    patchStatus(type: 'prod' | 'dev', ws: string, running: boolean, index: number)
    {
        fetch
        (
            '/api/webservice/status/:type/:ws'.replace(':type', type).replace(':ws', ws), 
            {
                method: 'PATCH',
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify
                (
                    {
                        action: running ? 'stop' : 'start',
                    }
                ),
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    this.props.data[index].running = !running;
                    this.setState({});
                }
            }
        );
    }

    infoListBuilder(list: Array<WebserviceStatus>)
    {
        var children = [];
        var sThis = this;
        for(var i = 0; i < list.length; i++)
        {
            (
                function (i) 
                {
                    var listItem = 
                    (
                        <ListItem key={i} className='webserviceListItem'>
                            <Circle 
                                backgroundColor=
                                {
                                    !list[i].running
                                    ?
                                        '#980000'
                                    :
                                        '#277D15'
                                } 
                                color={'#000000'}/>
                            <div className='settingsFlexGrow'>
                                <span className='settingsRowTitle settingsAppRow'>
                                    {list[i].webservice}
                                </span>
                                {
                                    sThis.props.showControlsAndLog
                                    ?
                                        <a style={{fontSize:'8pt', marginLeft: '5px'}} href={'/api/webservice/logs/:type/:ws'.replace(':type', sThis.props.type).replace(':ws', list[i].ws)}>
                                            {'Ver logs'}
                                        </a>
                                    :
                                        null
                                }
                                
                            </div>
                            {
                                sThis.props.showControlsAndLog
                                ?
                                    <Button
                                        variant='contained'
                                        onClick={()=> sThis.patchStatus(sThis.props.type, list[i].ws, list[i].running, i)}
                                        color=
                                        {
                                            !list[i].running
                                            ?
                                                'primary'
                                            :
                                                'secondary'
                                        }>
                                        {
                                            list[i].running
                                            ?
                                                'Desligar'
                                            :
                                                'Ligar'
                                        }
                                    </Button>
                                :
                                    null
                            }
                        </ListItem>
                    );
                    children.push(listItem);
                }
            )(i);
        }
        return children;
    } 

    render()
    {
        return(
            // <div>
                <ThemeProvider theme={this.webserviceStatusTheme}>
                    {/* <div
                    > */}
                    {/* <div className='analyticsFlexPage'> */}
                        <div className='webserviceStatusSection'>
                            <div className='webserviceStatusSubSection'>
                                <h1 className='analyticsTitleDark center'>
                                   {this.props.title}
                                </h1>
                                <div className='webserviceStatusList'>
                                    <List>
                                        {this.infoListBuilder(this.props.data)}
                                    </List>
                                </div>
                            </div>
                        </div>
                    {/* </div> */}
                    {/* </div> */}
                </ThemeProvider>
            // </div>
        );
    }
}

export default WebServiceAnalytics;
export default class App
{
	appID?: string;
	icon?: string;
	name?: string;
	color?: string;

	constructor(appID?: string, icon?: string, name?: string, color?: string)
	{
		this.appID = appID;
		this.icon = icon;
		this.name = name;
		this.color = color;
	}
}
// import { Box } from '@mui/material';
import 
{
    Box 
} from '@material-ui/core';
import React from 'react';
import '../css/Circle.css';

interface ICircleProps
{
    backgroundColor?: string,
    color?: string,
}

interface ICircleState 
{
    circleRef: React.RefObject<any>,
    size: number
}

class Circle extends React.Component<ICircleProps, ICircleState>
{
    constructor(props: ICircleProps)
    {
        super(props);
        this.state = 
        {
            circleRef: React.createRef(),
            size: 0
        };
    }

    componentDidMount()
    {
        const sizeX = this.state.circleRef.current.scrollWidth;
        const sizeY = this.state.circleRef.current.scrollHeight;
        var size = 0;
        if(sizeX > sizeY)
        {
            size = sizeX;
        }
        else
        {
            size = sizeY;
        }

        this.setState
        (
            {
                size 
            }
        );
    }

    render ()
    {
        return (
            <Box 
                
            >
                <div 
                    ref={this.state.circleRef} 
                    className={'circle' } 
                    style=
                    {
                        {
                            height: this.state.size, 
                            width: this.state.size,  
                            backgroundColor: this.props.backgroundColor, 
                            color: this.props.color, 
                            padding:'5px'
                        }
                    }
                >
                    {this.props.children}
                </div>  
            </Box>
        );
    }
}

export default Circle;
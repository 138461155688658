export default class LocalIcon
{
	name: string;
	code: string;

	constructor(name: string, code: string)
	{
		this.name = name;
		this.code = code;
	}
}
import React from 'react';
import '../../css/Home.css';
import '../../css/Login.css';
import logo from '../../resources/logo.png';
import 
{ 
    Button, 
    TextField,
    Snackbar,
    IconButton,
} from '@material-ui/core';
import
{
    Alert,
} from '@material-ui/lab';
import PasswordVisibile from '@material-ui/icons/Visibility'
import PasswordVisibileOff from '@material-ui/icons/VisibilityOff'
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'; 
import { History, LocationState } from "history";

interface ILoginProps
{
    history?: History<LocationState>,
}

interface ILoginState 
{
    showPassword: boolean,
    showSnackbar: boolean
}

class Login extends React.Component<ILoginProps, ILoginState>
{ 
    constructor(props: ILoginProps)
    {
        super(props);
        this.state =
        {
            showSnackbar: false,
            showPassword: false
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.handleLoginResult = this.handleLoginResult.bind(this);
        this.onErrorSnackClose = this.onErrorSnackClose.bind(this);
        this.handleDetectLogged = this.handleDetectLogged.bind(this);
        this.handleDetectLogged();
    }

    buttonsTheme = createTheme
    (
        {
            overrides:
            {
                MuiIconButton:
                {
                    colorPrimary:
                    {
                        color: 'white'
                    }
                }
            }
        }
    );

    buttonTheme = createTheme
    (
        {
            overrides:
            {
                MuiButton:
                {
                    root:
                    {
                        backgroundColor: '#004659',
                        color: 'white',
                        borderRadius: '100px',
                        height: '15% !important',
                        '&:hover':
                        {
                            backgroundColor: '#006682'
                        }
                    }
                },
            }
        }
    );

    formTheme = createTheme
    (
        {
            overrides:
            {
                MuiFormLabel:
                {
                    root:
                    {
                        color: 'white',
                        "&$focused": 
                        {
                            color: 'white',
                        }
                    }
                },
                MuiInput:
                {
                    underline:
                    {
                        borderColor: 'white  !important',
                        '&:before':
                        {
                            color: 'white',
                            borderColor: 'white !important'
                        },
                        '&:hover':
                        {
                            color: 'white',
                            borderColor: 'white !important'
                        },
                        '&:after':
                        {
                            color: 'white',
                            borderColor: 'white  !important'
                        }
                    }
                }
            }
        }
    );

    handleDetectLogged()
    {
        fetch
        (
            "/api/validate", 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            (response)=>
            {
                if(response.status === 200)
                {
                    var url = new URL(window.location.href);
                    var redirectTo = url.searchParams.get('redirect_to');
                    if(redirectTo && redirectTo.match(/^.+$/g))
                    {
                        window.location.href = redirectTo;
                    }
                    else
                    {
                        console.log('aa');
                        window.location.href = '/home';
                    }
                }
            }
        );
    }

    onFormSubmit(event: any)
    {
        var username = document.querySelector<HTMLInputElement>("#username")?.value;
        var password = document.querySelector<HTMLInputElement>("#password")?.value;
        fetch
        (
            "/api/login", 
            {
                method: "POST",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify
                (
                    {
                        username: username,
                        password: password
                    }
                ),
            }
        ).then(this.handleLoginResult);   
        event.preventDefault();
    }

    handleLoginResult(response: Response)
    {
        if(response.status === 200)
        {
            var url = new URL(window.location.href);
            var redirectTo = url.searchParams.get('redirect_to');
            if(redirectTo && redirectTo.match(/^.+$/g))
            {
                window.location.href = redirectTo;
            }
            else
            {
                this.props.history!.push('/');
            }
        }
        else
        {
            this.setState
            (
                {
                    showSnackbar: true
                }
            )
        }
    }

    onErrorSnackClose()
    {
        this.setState
        (
            {
                showSnackbar: false
            }
        )
    }

    passwordVisibilityToggle(event: any)
    {
        var show = this.state.showPassword;
        this.setState
        (
            {
                showPassword: !show
            }
        )
    }

    render() 
    {
        return (
            <div className="background">
                <div className="centered">
                    <div className="loginContainer">
                        <img src={logo} alt='logo'></img>
                        <ThemeProvider theme={this.formTheme}>
                            <form 
                                autoComplete="on"
                                onSubmit={this.onFormSubmit}
                            >
                                <div>
                                    <TextField id="username" required label="Usuário"/>
                                    <div style={{height:'10%'}}></div>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <TextField id="password" type={this.state.showPassword ? 'text' : 'password'} autoComplete="on" required label="Senha"/>
                                        <ThemeProvider theme={this.buttonsTheme}>
                                            <IconButton color='primary' onClick={this.passwordVisibilityToggle.bind(this)}>
                                                {
                                                    this.state.showPassword 
                                                    ?
                                                        <PasswordVisibile/>
                                                    :
                                                        <PasswordVisibileOff/>
                                                }    
                                            </IconButton>
                                        </ThemeProvider>
                                    </div>
                                    
                                </div>
                                <ThemeProvider theme={this.buttonTheme}>
                                    <Button type="submit">
                                        ENTRAR
                                    </Button>
                                </ThemeProvider>
                            </form>
                        </ThemeProvider>
                    </div>
                </div>
                <Snackbar open={this.state.showSnackbar} onClose={this.onErrorSnackClose} autoHideDuration={2000}>
                    <Alert severity="error">
                        Login inválido! Verifique suas credenciais.
                    </Alert>
                </Snackbar>
            </div>
        );
    }
}

export default Login;

import { Bar } from 'react-chartjs-2';

import 
{
    Select,
    MenuItem,
    Divider,
    Button,
    IconButton
} from '@mui/material';
import Skeleton from '@material-ui/lab/Skeleton';

import 
{
    DownloadRounded
} from '@mui/icons-material';

import React,
{ 
    useState,
} from 'react';
import { History, LocationState } from "history";

import 
{ 
    makeStyles 
} from '@material-ui/core/styles';


import
{
    PickersDayProps,
    DesktopDatePicker,
    PickersDay
} from '@mui/lab';

import CSVSerializable from '../../../model/csv_serializable';

interface IPublicAnalyticsProps
{
    history?: History<LocationState>,
    classes?: any,
}

class SyncAnalytics extends CSVSerializable
{
    public companyKey: string;
    public count: number;
    public date: string;

    constructor
    (
        params:
        {
            companyKey: string,
            count: number,
            date: string,
        }
    )
    {
        super();
        this.companyKey = params.companyKey;
        this.count = params.count;
        this.date = params.date;
    }

    csvHeaders() : Array<string> 
    {
        return [
            'qtd',
            'data'
        ]
    }

    toCSV() : string 
    {
        const replacer = (key: string, value: any) => value === null || value === undefined ? 'null' : value;
        return [
            JSON.stringify(this.count, replacer),
            JSON.stringify(this.date, replacer)
        ].join(',');
    }
}

class UserPerAppAnalytic extends CSVSerializable
{
    public companyKey: string;
    public companyName: string;
    public appId: string;
    public appName: string;
    public userCount: number;

    constructor
    (
        params: 
        {
            companyKey: string;
            companyName: string;
            appId: string;
            appName: string;
            userCount: number;
        }
    )
    {
        super();
        this.companyKey = params.companyKey;
        this.companyName = params.companyName;
        this.userCount = params.userCount;
        this.appName = params.appName;
        this.appId = params.appId;
    }

    csvHeaders() : Array<string>
    {
        return [
            'app',
            'qtd'
        ]
    }

    toCSV() : string 
    {
        const replacer = (key: string, value: any) => value === null || value === undefined ? 'null' : value;
        return [
            JSON.stringify(this.appName, replacer),
            JSON.stringify(this.userCount, replacer)
        ].join(',');
    }
}

class ScheduleAnalytics extends CSVSerializable
{
    public companyKey: string;
    public count: number;
    public date: string;

    constructor
    (
        params:
        {
            companyKey: string,
            count: number,
            date: string,
        }
    )
    {
        super();
        this.companyKey = params.companyKey;
        this.count = params.count;
        this.date = params.date;
    }

    csvHeaders() : Array<string>
    {
        return [
            'data',
            'qtd'
        ]
    }

    toCSV() : string 
    {
        const replacer = (key: string, value: any) => value === null || value === undefined ? 'null' : value;
        return [
            JSON.stringify(this.date, replacer),
            JSON.stringify(this.count, replacer)
        ].join(',');
    }
}

const useStyles = makeStyles
(
    {
        root: 
        {
            padding: '10px',
            height: '100%',
            // width: '100%'
        },
        visuallyHidden: 
        {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        selectRoot:
        {
            color: '#FFFFFF !important',
            '& fieldset':
            {
                borderColor: 'rgba(255, 255, 255, 0.23) !important'
            },
            '& svg':
            {
                color: 'white',
            }
        },
        filtersArea:
        {
            marginBottom: '5px',
            display: 'flex',
            flexDirection: 'row-reverse',
            '& *:not(:first-child)':
            {
                marginLeft: '5px'
            }
        },
        menu:
        {
            backgroundColor: '#232427 !important',
            color: '#FFF !important'
        },
        analyticChartSkeletonFitContent:
        {
            maxWidth: '100%',
            width: '100%'
        },
        analyticChartSkeletonText:
        {
            height: 'auto',
            transform: 'scale(1, 1)',
            marginTop: 0,
            borderRadius: '4px',
            marginBottom: 0,
            transformOrigin: '0 60%'
        },
        analytics:
        {
            backgroundColor: '#202225',
            border: '1px #1B1B1B solid',
            borderRadius: '5px',
            padding: '5px',
            // '& * div:not(:first-child)':
            // {
            //     marginTop: '10px'
            // }
        },
        chartRoot:
        {
            width: '100%',
            display: 'flex',
            flexDirection: 'column' as const
        },
        innerRoot:
        {
            display: 'flex',
            flexDirection: 'row' as const,
            padding: '15px',
            '& > *':
            {
                width: '100%'
            },
        },
        chartHeader:
        {
            display: 'flex',
            flexDirection: 'row' as const,
            justifyContent: 'space-between',
            '& h3':
            {
                margin: '0'
            }
        },
        titleStyle:
        {
            color: 'white',
            fontSize: '1em',
            paddingTop: '10px',            
        },
        dividerRoot:
        {
            backgroundColor: 'rgba(255, 255, 255, 0.12)'
        },
        dateRangePicker:
        {
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center'
        },
        dateRangePickerRoot:
        {
            '& .MuiPaper-root':
            {
                color: '#FFFFFF',
                backgroundColor: 'rgb(14, 15, 15)',
            },
            '& .MuiPickersCalendarHeader-dayLabel':
            {
                color: '#FFFFFF80'
            },
            '& .MuiPickersDay-root':
            {
                backgroundColor: 'transparent'
            },
            '& .MuiTypography-root':
            {
                color: 'rgb(232, 230, 227)'
            },
            '& .PrivatePickersYear-yearButton:disabled':
            {
                color: 'rgba(255, 255, 255, 0.54) !important',
            },
            '& .MuiPaper-root > div > div > div:not(:last-of-type)':
            {
                borderRight: '2px solid rgba(48, 52, 54, 0.12) !important'
            }
        },
        datePickerIconButton:
        {
            color: '#FFFFFF !important',
            // backgroundColor: '#424242 !important',
            '&:disabled':
            {
                color: 'rgba(255, 255, 255, 0.54) !important',
            }
        },
        datePickerDay:
        {
            color: '#FFFFFF'
        },
        userChartLabelRoot:
        {
            display: 'flex',
            flexDirection: 'row',
            paddingRight: '15px'
        },
        platformButtonRoot:
        {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start !important',
            cursor: 'auto !important'
        },
        platformButtonTitle:
        {
            color: '#bdbdbd',
            fontSize: '11px',
            lineHeight: '16px',
            letterSpacing: '.8px',
            fontWeight: 500,
            fontFamily: 'Roboto, Arial, Helvetica, sans-serif'
        },
        platformButtonPercentage:
        {
            color: 'white',
            fontSize: '22px',
            lineHeight: '28px',
            fontWeight: 400,
            fontFamily: '"Google Sans", Arial, Helvetica, sans-serif'
        },
        chartHeaderDownloadDatasetHeader:
        {
            display: 'flex',
            flexDirection: 'row' as const,
            color: 'white',
            justifyContent: 'space-between',
            '& h5':
            {
                color: '#bdbdbd',
                fontSize: '0.83em',
                margin: '0'
            },
        },
        chartHeaderDownloadDataset:
        {
            fontSize: '32px',
        },
        chartHeaderDownloadDatasetButtonRoot:
        {
            color: 'white !important'
        }
        // chartsRoot:
        // {
        //     display: 'flex',
        //     flexDirection: 'row',
        //     // '& *':
        //     // {
        //     //     width: '50%'
        //     // },
        //     // '&:not(:first-child)':
        //     // {
        //     //     marginLeft: '50px'
        //     // }
        // }
    }
);

interface IAnalyticsFilter
{
    id: string,
    app: string
}

export default function PublicAnalytics(props: IPublicAnalyticsProps)
{
    const classes = useStyles();
    const [isAnalyticsLoading, setIsAnalyticsLoading] = useState<boolean>(false);
    const [analyticsFilters, setAnalyticsFilters] = useState<Array<IAnalyticsFilter>>([]);
    const [analyticsFilter, setAnalyticsFilter] = useState<string>('');
    const [syncAnalyticsData, setSyncAnalyticsData] = useState<Array<SyncAnalytics>>([]);
    const [userAppDevAnalyticsData, setUserAppDevAnalyticsData] = useState<Array<UserPerAppAnalytic>>([]);
    const [userAppProdAnalyticsData, setUserAppProdAnalyticsData] = useState<Array<UserPerAppAnalytic>>([]);
    const [scheduleAnalyticsData, setScheduleAnalyticsData] = useState<Array<ScheduleAnalytics>>([]);
    const [updatedScheduleAnalyticsData, setUpdatedScheduleAnalyticsData] = useState<Array<ScheduleAnalytics>>([]);
    const [dateFilterValue, setDateFilterValue] = React.useState<Date|null>(null);
    const [openDateRange, setOpenDateRange] = useState<boolean>(false);

    React.useEffect
    (
        () =>
        {
            var searchParams = new URLSearchParams(window.location.search);
            let date: Date | null = null;
            var dateStr = searchParams.get('date');
            var app = searchParams.get("app");
            if(dateStr)
            {
                var parts = dateStr.split('-');
                if(parts.length >= 3)
                {
                    date = new Date(Date.UTC(Number(parts[0]), Number(parts[1]), Number(parts[2])));
                }
            }
            date = date ?? dateFilterValue;

            setDateFilterValue(date);
            setAnalyticsFilter(app ?? analyticsFilter);
            getAnalyticsFilters();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    function getSchedulesAnalytics(analyticsFilter: string)
    {
        setIsAnalyticsLoading(true);
        // let dayFilter = '';
        // if(!dateRangeFilterValue.includes(null))
        // {   
        //     dateRangeFilterValue[0]?.toJSON().substring(0, dateRangeFilterValue[0]?.toJSON().indexOf('T'));

        //     dayFilter = `startDate=${dateRangeFilterValue[0]?.toJSON().substring(0, dateRangeFilterValue[0]?.toJSON().indexOf('T'))}&endDate=${dateRangeFilterValue[1]?.toJSON().substring(0, dateRangeFilterValue[1]?.toJSON().indexOf('T'))}`
        // }
        fetch
        (
            `/api/internal/${analyticsFilter}/agendamentos-insercoes`, 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    var data = await response.json();
                    var dataArray: Array<ScheduleAnalytics> = [];
                    
                    for(let i = 0; i < data.length; i++)
                    {
                        dataArray.push
                        (
                            new ScheduleAnalytics
                            (
                                {
                                    companyKey: data[i].companyKey,
                                    count: data[i].count,
                                    date: data[i].date
                                }
                            )
                        )
                    }
                    
                    setScheduleAnalyticsData(dataArray);
                    setIsAnalyticsLoading(false);
                }
            } 
        );
    }

    function getUpdatedSchedulesAnalytics(analyticsFilter: string)
    {
        setIsAnalyticsLoading(true);
        // let dayFilter = '';
        // if(!dateRangeFilterValue.includes(null))
        // {   
        //     dateRangeFilterValue[0]?.toJSON().substring(0, dateRangeFilterValue[0]?.toJSON().indexOf('T'));

        //     dayFilter = `startDate=${dateRangeFilterValue[0]?.toJSON().substring(0, dateRangeFilterValue[0]?.toJSON().indexOf('T'))}&endDate=${dateRangeFilterValue[1]?.toJSON().substring(0, dateRangeFilterValue[1]?.toJSON().indexOf('T'))}`
        // }
        fetch
        (
            `/api/internal/${analyticsFilter}/agendamentos-atualizacoes`, 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    var data = await response.json();
                    var dataArray: Array<ScheduleAnalytics> = [];
                    
                    for(let i = 0; i < data.length; i++)
                    {
                        dataArray.push
                        (
                            new ScheduleAnalytics
                            (
                                {
                                    companyKey: data[i].companyKey,
                                    count: data[i].count,
                                    date: data[i].date
                                }
                            )
                        )
                    }
                    
                    setUpdatedScheduleAnalyticsData(dataArray);
                    setIsAnalyticsLoading(false);
                }
            } 
        );
    }

    function getSyncAnalytics(analyticsFilter: string)
    {
        setIsAnalyticsLoading(true);
        // let dayFilter = '';
        // if(!dateRangeFilterValue.includes(null))
        // {   
        //     dateRangeFilterValue[0]?.toJSON().substring(0, dateRangeFilterValue[0]?.toJSON().indexOf('T'));

        //     dayFilter = `startDate=${dateRangeFilterValue[0]?.toJSON().substring(0, dateRangeFilterValue[0]?.toJSON().indexOf('T'))}&endDate=${dateRangeFilterValue[1]?.toJSON().substring(0, dateRangeFilterValue[1]?.toJSON().indexOf('T'))}`
        // }
        fetch
        (
            `/api/internal/${analyticsFilter}/sincronizacoes`, 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    var data = await response.json();
                    var dataArray: Array<SyncAnalytics> = [];
                    
                    for(let i = 0; i < data.length; i++)
                    {
                        dataArray.push
                        (
                            new SyncAnalytics
                            (
                                {
                                    companyKey: data[i].companyKey,
                                    count: data[i].count,
                                    date: data[i].date
                                }
                            )
                        )
                    }
                    
                    setSyncAnalyticsData(dataArray);
                    setIsAnalyticsLoading(false);
                }
            } 
        );
    }

    function getUserAppAnalytics()
    {
        setIsAnalyticsLoading(true);

        fetch
        (
            `/api/internal/usuariosPorApp`, 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    var data = await response.json();
                    var dataArrayProd: Array<UserPerAppAnalytic> = [];
                    var dataArrayDev: Array<UserPerAppAnalytic> = [];
                    if(data.Prod)
                    {
                        for(let i = 0; i < data.Prod.length; i++)
                        {
                            var idx = dataArrayProd.findIndex(x=> x.appId === data.Prod[i].appId);
                            if(idx === -1)
                            {
                                dataArrayProd.push
                                (
                                    new UserPerAppAnalytic
                                    (
                                        {
                                            companyKey: '',
                                            companyName: '',
                                            userCount: parseInt(data.Prod[i].userCount),
                                            appId: data.Prod[i].appId,
                                            appName: data.Prod[i].appName,
                                        }
                                    )
                                )
                            }
                            else
                            {
                                dataArrayProd[idx].userCount += parseInt(data.Prod[i].userCount);
                            }
                        }
                    }
                    if(data.Dev)
                    {
                        for(let i = 0; i < data.Dev.length; i++)
                        {
                            var idx2 = dataArrayDev.findIndex(x=> x.appId === data.Dev[i].appId);
                            if(idx2 === -1)
                            {
                                dataArrayDev.push
                                (
                                    new UserPerAppAnalytic
                                    (
                                        {
                                            companyKey: '',
                                            companyName: '',
                                            userCount: parseInt(data.Dev[i].userCount),
                                            appId: data.Dev[i].appId,
                                            appName: data.Dev[i].appName,
                                        }
                                    )
                                )
                            }
                            else
                            {
                                dataArrayDev[idx2].userCount += parseInt(data.Dev[i].userCount);
                            }
                        }
                    }
                    setUserAppProdAnalyticsData(dataArrayProd);
                    setUserAppDevAnalyticsData(dataArrayDev);
                    setIsAnalyticsLoading(false);
                }
            } 
        );
    }

    function getAnalyticsFilters()
    {
        setIsAnalyticsLoading(true);
        fetch
        (
            `/api/internal/analytic/apps`, 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    var data = await response.json();
                    var dataArray: Array<IAnalyticsFilter> = [];
                    for(let i = 0; i < data.length; i++)
                    {
                        dataArray.push
                        (
                            {
                                app: data[i].app,
                                id: data[i].id,
                            }
                        )
                    }
                    setAnalyticsFilters(dataArray);
                    if(dataArray.length > 0)
                    {
                        setAnalyticsFilter(dataArray[0].id);
                    }
                    getAnalytics(dataArray[0].id);
                    setIsAnalyticsLoading(false);
                }
            } 
        );
    }

    function getAnalytics(analyticsFilter: string)
    {
        getSyncAnalytics(analyticsFilter);
        getSchedulesAnalytics(analyticsFilter);
        getUpdatedSchedulesAnalytics(analyticsFilter);
        getUserAppAnalytics();
    }

    function onDatasetClick(fileName: string, dataset: Array<CSVSerializable>)
    {
        if(dataset.length <= 0)
        {
            return;
        }
        const header = dataset[0].csvHeaders();
        const csv = 
        [
            header.join(','), // Headers in first row
            ...dataset.map
            (
                row => row.toCSV()
            )
        ].join('\r\n');

        var blob = new Blob([csv], {type: 'text/plain'});

        var dlink = document.createElement('a');
        dlink.download = fileName;
        var url = window.URL.createObjectURL(blob);
        dlink.href = url;
        dlink.onclick = function(this: GlobalEventHandlers, e) 
        {
            setTimeout
            (
                function() 
                {
                    window.URL.revokeObjectURL(url);
                }, 1500
            );
        };
        dlink.click();
        dlink.remove();
    }

    return (
        <div>
            <div className={classes.filtersArea}> 
                <Select
                    label="App"
                    value={analyticsFilter}
                    onChange=
                    {
                        (e) => 
                        {
                            // console.log(e.target.value);
                            setAnalyticsFilter(e.target.value);
                            if(props.history !== undefined)
                            {
                                var searchParams = new URLSearchParams(window.location.search);
                                var url = '';
                                if(e.target.value !== 'todos')
                                {
                                    searchParams.set("app", `${e.target.value}`);    
                                }
                                else
                                {
                                    searchParams.delete('app');
                                }
                                url = window.location.pathname + '?' + searchParams.toString();
                                props.history.push(url);
                            }
                            getAnalytics(e.target.value);
                            // getUsersAnalytics();
                        }
                    }
                    classes=
                    {
                        {
                            root: classes.selectRoot,
                        }
                    }
                    MenuProps=
                    {
                        {
                            PopoverClasses:
                            {
                                paper: classes.menu
                            }
                        }
                    }
                >
                    {
                        analyticsFilters.map
                        (
                            (filter) =>
                            {
                                // console.log(filter);
                                return ( 
                                    <MenuItem key={`analytics-filter-${filter.id}`} value={filter.id}>{filter.app}</MenuItem>
                                );
                            }
                        )
                    }
                </Select>
                <div
                    className={classes.dateRangePicker}
                >
                    <DesktopDatePicker 
                        value={dateFilterValue}
                        open={openDateRange}
                        PopperProps=
                        {
                            {
                                className: classes.dateRangePickerRoot,
                                // anchorOrigin: 
                                // {
                                //     vertical: -50,
                                //     horizontal: 198
                                // },
                                // PaperProps:
                                // {
                                //     classes:
                                //     {
                                //         root: this.classes.datePickerPaperRoot,
                                //     }
                                // }
                            }
                        }
                        onChange=
                        {
                            (newValue) => 
                            {
                                setDateFilterValue(newValue);                                
                            }
                        }
                        onClose=
                        {
                            ()=>
                            {
                                setOpenDateRange(false);
                            }
                        }
                        onAccept=
                        {
                            (date)=> 
                            {
                                if(props.history !== undefined && date != null)
                                {
                                    var searchParams = new URLSearchParams(window.location.search)
                                    var url = '';
                                    searchParams.set("date", `${date?.toJSON().substring(0, date?.toJSON().indexOf('T'))}`); 
                                    url = window.location.pathname + '?' + searchParams.toString();
                                    props.history.push(url);
                                    // getUsersAnalytics();
                                }
                            }
                        }
                        renderInput=
                        {
                            (_props) => 
                            {
                                let formattedDate = '';
                                
                                formattedDate = dateFilterValue?.toLocaleString
                                (
                                    'pt-BR', 
                                    {
                                        month: 'long', // numeric, 2-digit, long, short, narrow
                                        day: '2-digit', // numeric, 2-digit
                                        year: 'numeric', // numeric, 2-digit// numeric, 2-digit
                                    }
                                ) ?? 'Selecionar Data';
                               
                                return (
                                    <React.Fragment>
                                        <Button
                                            variant="text"
                                            style=
                                            {
                                                {
                                                    textTransform: 'capitalize',
                                                    color: 'white',
                                                    fontWeight: 'bold',
                                                    textAlign: 'right'
                                                }
                                            }
                                            onClick=
                                            {
                                                ()=>
                                                {
                                                    setOpenDateRange(true);
                                                }
                                            }
                                        >
                                            {
                                                formattedDate
                                            }
                                        </Button>
                                    </React.Fragment>
                                );
                            }
                        }
                        componentsProps=
                        {
                            {
                                leftArrowButton:
                                {
                                    classes:
                                    {
                                        root: classes.datePickerIconButton
                                    }
                                },
                                rightArrowButton:
                                {
                                    classes:
                                    {
                                        root: classes.datePickerIconButton
                                    }   
                                },
                                switchViewButton:
                                {
                                    classes:
                                    {
                                        root: classes.datePickerIconButton
                                    }   
                                }
                            }
                        }
                        renderDay=
                        {
                            (
                                day: Date,
                                selectedDates: (Date|null)[],
                                datePickerDayProps: PickersDayProps<Date>
                            ) => 
                            {
                                
                                return (
                                    <PickersDay 
                                        {...datePickerDayProps}
                                        style=
                                        {
                                            selectedDates.includes(day)
                                            ?
                                                {
                                                    color: 'rgb(232, 230, 227)',
                                                    backgroundColor: 'rgb(6, 66, 115)'
                                                        
                                                }
                                            :
                                                {
                                                    color: '#FFFFFF'
                                                }
                                        }
                                    />
                                );
                            }
                        }
                    />
                </div>
            </div>
            {
                
                <div className={classes.analytics}>
                    {
                        isAnalyticsLoading
                        ?
                            <Skeleton 
                                animation="wave" 
                                classes=
                                {
                                    {
                                        fitContent: classes.analyticChartSkeletonFitContent,
                                        text: classes.analyticChartSkeletonText
                                    }
                                }
                            >
                                <div 
                                    style=
                                    {
                                        {
                                            minHeight: '270px',
                                            borderRadius: '4px',
                                            minWidth: '100%'
                                        }
                                    }
                                >

                                </div>
                            </Skeleton> 
                        :   
                            (
                                <div id={'chartsection'} className={classes.chartRoot}>
                                    <div className={classes.innerRoot}>
                                        <div>
                                            <div className={classes.chartHeaderDownloadDatasetHeader}>
                                                <h5 className={classes.titleStyle}>
                                                    {`Sincronizações - ${analyticsFilters.find(x=>x.id===analyticsFilter)?.app ?? ''}`}
                                                </h5>
                                                <span className={classes.chartHeaderDownloadDataset}>
                                                    <IconButton
                                                        onClick={(el) => onDatasetClick(`sincronizacoes_${new Date().toJSON()}.csv`, syncAnalyticsData)}
                                                        classes=
                                                        {
                                                            {
                                                                root: classes.chartHeaderDownloadDatasetButtonRoot,
                                                            }
                                                        }
                                                    >
                                                        <DownloadRounded/>
                                                    </IconButton>
                                                </span>
                                            </div>
                                            <Bar  
                                                // id={chartID}
                                                // ref={chartRef}
                                                style=
                                                {
                                                    {
                                                        maxHeight: '200px',
                                                        // maxWidth: '50%'
                                                    }
                                                }
                                                width={0}
                                                height={200}
                                                options=
                                                {
                                                    {
                                                        //animation,
                                                        transitions: 
                                                        {
                                                            show: 
                                                            {
                                                                animations: 
                                                                {
                                                                    x: 
                                                                    {
                                                                        from: 1
                                                                    },
                                                                    y: 
                                                                    {
                                                                        from: 1
                                                                    }
                                                                }
                                                            },
                                                            hide: 
                                                            {
                                                                animations: 
                                                                {
                                                                    x: 
                                                                    {
                                                                        to: 1
                                                                    },
                                                                    y: 
                                                                    {
                                                                        to: 1
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        responsive: true,
                                                        plugins:
                                                        {
                                                            title:
                                                            {
                                                                display: false,
                                                            },                      
                                                            legend:
                                                            {
                                                                display: false,
                                                            },
                                                            tooltip: 
                                                            {
                                                                
                                                                callbacks: 
                                                                {
                                                                    // title: function(context)
                                                                    // {
                                                                    //     return `${29 - context[0].parsed.x} minutos atrás`;
                                                                    // },
                                                                    label: function(context: any) 
                                                                    {
                                                                        return `${context.dataset.label} ${context.parsed.y}`;
                                                                    }
                                                                }
                                                            },
                                                        },
                                                        scales: 
                                                        {
                                                            y: 
                                                            {
                                                                min: 0,
                                                                display: true,
                                                                ticks: 
                                                                {
                                                                    stepSize: 1,
                                                                    color: '#FFF'
                                                                }
                                                            },
                                                            x:
                                                            {
                                                                display: true,
                                                                ticks:
                                                                {
                                                                    color: '#FFF'
                                                                }
                                                            }
                                                        },
                                                    }
                                                }
                                                data=
                                                {
                                                    {
                                                        labels: Array.from
                                                        (
                                                            {length: syncAnalyticsData.length},
                                                            (_, i ) =>
                                                            {
                                                                var date = new Date(`${syncAnalyticsData[i].date}-01 00:00`);
                                                                return `${date.toLocaleString('pt-BR', {month: 'short'}).replace('.', '')}/${date.toLocaleString('pt-BR', {year: '2-digit'})}`;
                                                            }
                                                        ),
                                                        datasets: 
                                                        [
                                                            {
                                                                data: Array.from
                                                                (
                                                                    {length: syncAnalyticsData.length},
                                                                    (_, i ) =>
                                                                    {
                                                                        return syncAnalyticsData[i].count
                                                                    }
                                                                ),
                                                                backgroundColor: '#A6CEE3',
                                                                borderColor: [ '#A6CEE3' ],
                                                                borderWidth: 1,
                                                                label: 'Sincronizações',
                                                                minBarLength: 2,
                                                                maxBarThickness: 50
                                                            }
                                                        ]
                                                    }
                                                    
                                                }
                                            />
        
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                    <Divider
                        classes=
                        {
                            {
                                root: classes.dividerRoot
                            }
                        }
                    />
                    {
                        isAnalyticsLoading
                        ?
                            <Skeleton 
                                animation="wave" 
                                classes=
                                {
                                    {
                                        fitContent: classes.analyticChartSkeletonFitContent,
                                        text: classes.analyticChartSkeletonText
                                    }
                                }
                            >
                                <div 
                                    style=
                                    {
                                        {
                                            minHeight: '270px',
                                            borderRadius: '4px',
                                            minWidth: '100%'
                                        }
                                    }
                                >

                                </div>
                            </Skeleton> 
                        :   
                            (
                                <div id={'chartsection'} className={classes.chartRoot}>
                                    <div className={classes.innerRoot}>
                                        <div>
                                            <div className={classes.chartHeaderDownloadDatasetHeader}>
                                                <h5 className={classes.titleStyle}>
                                                    {`Novos Agendamentos - ${analyticsFilters.find(x=>x.id===analyticsFilter)?.app ?? ''}`}
                                                </h5>
                                                <span className={classes.chartHeaderDownloadDataset}>
                                                    <IconButton
                                                        onClick={(el) => onDatasetClick(`novos_agendamentos_opme_${new Date().toJSON()}.csv`, scheduleAnalyticsData)}
                                                        classes=
                                                        {
                                                            {
                                                                root: classes.chartHeaderDownloadDatasetButtonRoot,
                                                            }
                                                        }
                                                    >
                                                        <DownloadRounded/>
                                                    </IconButton>
                                                </span>
                                            </div>
                                            <Bar  
                                                // id={chartID}
                                                // ref={chartRef}
                                                style=
                                                {
                                                    {
                                                        maxHeight: '200px',
                                                        // maxWidth: '50%'
                                                    }
                                                }
                                                width={0}
                                                height={200}
                                                options=
                                                {
                                                    {
                                                        //animation,
                                                        transitions: 
                                                        {
                                                            show: 
                                                            {
                                                                animations: 
                                                                {
                                                                    x: 
                                                                    {
                                                                        from: 1
                                                                    },
                                                                    y: 
                                                                    {
                                                                        from: 1
                                                                    }
                                                                }
                                                            },
                                                            hide: 
                                                            {
                                                                animations: 
                                                                {
                                                                    x: 
                                                                    {
                                                                        to: 1
                                                                    },
                                                                    y: 
                                                                    {
                                                                        to: 1
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        responsive: true,
                                                        plugins:
                                                        {
                                                            title:
                                                            {
                                                                display: false,
                                                            },                      
                                                            legend:
                                                            {
                                                                display: false,
                                                            },
                                                            tooltip: 
                                                            {
                                                                
                                                                callbacks: 
                                                                {
                                                                    // title: function(context)
                                                                    // {
                                                                    //     return `${29 - context[0].parsed.x} minutos atrás`;
                                                                    // },
                                                                    label: function(context: any) 
                                                                    {
                                                                        return `${context.dataset.label} ${context.parsed.y}`;
                                                                    }
                                                                }
                                                            },
                                                        },
                                                        scales: 
                                                        {
                                                            y: 
                                                            {
                                                                min: 0,
                                                                display: true,
                                                                ticks: 
                                                                {
                                                                    stepSize: 1,
                                                                    color: '#FFF'
                                                                }
                                                            },
                                                            x:
                                                            {
                                                                display: true,
                                                                ticks:
                                                                {
                                                                    color: '#FFF'
                                                                }
                                                            }
                                                        },
                                                    }
                                                }
                                                data=
                                                {
                                                    {
                                                        labels: Array.from
                                                        (
                                                            {length: scheduleAnalyticsData.length},
                                                            (_, i ) =>
                                                            {
                                                                var date = new Date(`${scheduleAnalyticsData[i].date}-01 00:00`);
                                                                return `${date.toLocaleString('pt-BR', {month: 'short'}).replace('.', '')}/${date.toLocaleString('pt-BR', {year: '2-digit'})}`;
                                                            }
                                                        ),
                                                        datasets: 
                                                        [
                                                            {
                                                                data: Array.from
                                                                (
                                                                    {length: scheduleAnalyticsData.length},
                                                                    (_, i ) =>
                                                                    {
                                                                        return scheduleAnalyticsData[i].count
                                                                    }
                                                                ),
                                                                backgroundColor: '#A6CEE3',
                                                                borderColor: [ '#A6CEE3' ],
                                                                borderWidth: 1,
                                                                label: 'Agendamentos',
                                                                minBarLength: 2,
                                                                maxBarThickness: 50
                                                            }
                                                        ]
                                                    }
                                                    
                                                }
                                            />
        
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                    <Divider
                        classes=
                        {
                            {
                                root: classes.dividerRoot
                            }
                        }
                    />
                    {
                        isAnalyticsLoading
                        ?
                            <Skeleton 
                                animation="wave" 
                                classes=
                                {
                                    {
                                        fitContent: classes.analyticChartSkeletonFitContent,
                                        text: classes.analyticChartSkeletonText
                                    }
                                }
                            >
                                <div 
                                    style=
                                    {
                                        {
                                            minHeight: '270px',
                                            borderRadius: '4px',
                                            minWidth: '100%'
                                        }
                                    }
                                >

                                </div>
                            </Skeleton> 
                        :   
                            (
                                <div id={'chartsection'} className={classes.chartRoot}>
                                    <div className={classes.innerRoot}>
                                        <div>
                                            <div className={classes.chartHeaderDownloadDatasetHeader}>
                                                <h5 className={classes.titleStyle}>
                                                    {`Atualizações Agendamentos - ${analyticsFilters.find(x=>x.id===analyticsFilter)?.app ?? ''}`}
                                                </h5>
                                                <span className={classes.chartHeaderDownloadDataset}>
                                                    <IconButton
                                                        onClick={(el) => onDatasetClick(`agendamentos_atualizados_opme_${new Date().toJSON()}.csv`, updatedScheduleAnalyticsData)}
                                                        classes=
                                                        {
                                                            {
                                                                root: classes.chartHeaderDownloadDatasetButtonRoot,
                                                            }
                                                        }
                                                    >
                                                        <DownloadRounded/>
                                                    </IconButton>
                                                </span>
                                            </div>
                                            <Bar  
                                                // id={chartID}
                                                // ref={chartRef}
                                                style=
                                                {
                                                    {
                                                        maxHeight: '200px',
                                                        // maxWidth: '50%'
                                                    }
                                                }
                                                width={0}
                                                height={200}
                                                options=
                                                {
                                                    {
                                                        //animation,
                                                        transitions: 
                                                        {
                                                            show: 
                                                            {
                                                                animations: 
                                                                {
                                                                    x: 
                                                                    {
                                                                        from: 1
                                                                    },
                                                                    y: 
                                                                    {
                                                                        from: 1
                                                                    }
                                                                }
                                                            },
                                                            hide: 
                                                            {
                                                                animations: 
                                                                {
                                                                    x: 
                                                                    {
                                                                        to: 1
                                                                    },
                                                                    y: 
                                                                    {
                                                                        to: 1
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        responsive: true,
                                                        plugins:
                                                        {
                                                            title:
                                                            {
                                                                display: false,
                                                            },                      
                                                            legend:
                                                            {
                                                                display: false,
                                                            },
                                                            tooltip: 
                                                            {
                                                                
                                                                callbacks: 
                                                                {
                                                                    // title: function(context)
                                                                    // {
                                                                    //     return `${29 - context[0].parsed.x} minutos atrás`;
                                                                    // },
                                                                    label: function(context: any) 
                                                                    {
                                                                        return `${context.dataset.label} ${context.parsed.y}`;
                                                                    }
                                                                }
                                                            },
                                                        },
                                                        scales: 
                                                        {
                                                            y: 
                                                            {
                                                                min: 0,
                                                                display: true,
                                                                ticks: 
                                                                {
                                                                    stepSize: 1,
                                                                    color: '#FFF'
                                                                }
                                                            },
                                                            x:
                                                            {
                                                                display: true,
                                                                ticks:
                                                                {
                                                                    color: '#FFF'
                                                                }
                                                            }
                                                        },
                                                    }
                                                }
                                                data=
                                                {
                                                    {
                                                        labels: Array.from
                                                        (
                                                            {length: updatedScheduleAnalyticsData.length},
                                                            (_, i ) =>
                                                            {
                                                                var date = new Date(`${updatedScheduleAnalyticsData[i].date}-01 00:00`);
                                                                return `${date.toLocaleString('pt-BR', {month: 'short'}).replace('.', '')}/${date.toLocaleString('pt-BR', {year: '2-digit'})}`;
                                                            }
                                                        ),
                                                        datasets: 
                                                        [
                                                            {
                                                                data: Array.from
                                                                (
                                                                    {length: updatedScheduleAnalyticsData.length},
                                                                    (_, i ) =>
                                                                    {
                                                                        return updatedScheduleAnalyticsData[i].count
                                                                    }
                                                                ),
                                                                backgroundColor: '#A6CEE3',
                                                                borderColor: [ '#A6CEE3' ],
                                                                borderWidth: 1,
                                                                label: 'Agendamentos',
                                                                minBarLength: 2,
                                                                maxBarThickness: 50
                                                            }
                                                        ]
                                                    }
                                                    
                                                }
                                            />
        
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                    <Divider
                        classes=
                        {
                            {
                                root: classes.dividerRoot
                            }
                        }
                    />
                    {
                        isAnalyticsLoading
                        ?
                            <Skeleton 
                                animation="wave" 
                                classes=
                                {
                                    {
                                        fitContent: classes.analyticChartSkeletonFitContent,
                                        text: classes.analyticChartSkeletonText
                                    }
                                }
                            >
                                <div 
                                    style=
                                    {
                                        {
                                            minHeight: '270px',
                                            borderRadius: '4px',
                                            minWidth: '100%'
                                        }
                                    }
                                >

                                </div>
                            </Skeleton> 
                        :   
                            (
                                <div id={'chartsection'} className={classes.chartRoot}>
                                    <div className={classes.innerRoot}>
                                        <div>
                                            <div className={classes.chartHeaderDownloadDatasetHeader}>
                                                <h5 className={classes.titleStyle}>
                                                    {`Usuários/App - Dev`}
                                                </h5>
                                                <span className={classes.chartHeaderDownloadDataset}>
                                                    <IconButton
                                                        onClick={(el) => onDatasetClick(`usuarios_app_dev_${new Date().toJSON()}.csv`, userAppDevAnalyticsData)}
                                                        classes=
                                                        {
                                                            {
                                                                root: classes.chartHeaderDownloadDatasetButtonRoot,
                                                            }
                                                        }
                                                    >
                                                        <DownloadRounded/>
                                                    </IconButton>
                                                </span>
                                            </div>
                                            <Bar  
                                                // id={chartID}
                                                // ref={chartRef}
                                                style=
                                                {
                                                    {
                                                        maxHeight: '200px',
                                                        // maxWidth: '50%'
                                                    }
                                                }
                                                width={0}
                                                height={200}
                                                options=
                                                {
                                                    {
                                                        //animation,
                                                        transitions: 
                                                        {
                                                            show: 
                                                            {
                                                                animations: 
                                                                {
                                                                    x: 
                                                                    {
                                                                        from: 1
                                                                    },
                                                                    y: 
                                                                    {
                                                                        from: 1
                                                                    }
                                                                }
                                                            },
                                                            hide: 
                                                            {
                                                                animations: 
                                                                {
                                                                    x: 
                                                                    {
                                                                        to: 1
                                                                    },
                                                                    y: 
                                                                    {
                                                                        to: 1
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        responsive: true,
                                                        plugins:
                                                        {
                                                            title:
                                                            {
                                                                display: false,
                                                            },                      
                                                            legend:
                                                            {
                                                                display: false,
                                                            },
                                                            tooltip: 
                                                            {
                                                                
                                                                callbacks: 
                                                                {
                                                                    // title: function(context)
                                                                    // {
                                                                    //     return `${29 - context[0].parsed.x} minutos atrás`;
                                                                    // },
                                                                    label: function(context: any) 
                                                                    {
                                                                        return `${context.dataset.label} ${context.parsed.y}`;
                                                                    }
                                                                }
                                                            },
                                                        },
                                                        scales: 
                                                        {
                                                            y: 
                                                            {
                                                                min: 0,
                                                                display: true,
                                                                ticks: 
                                                                {
                                                                    stepSize: 1,
                                                                    color: '#FFF'
                                                                }
                                                            },
                                                            x:
                                                            {
                                                                display: true,
                                                                ticks:
                                                                {
                                                                    color: '#FFF'
                                                                }
                                                            }
                                                        },
                                                    }
                                                }
                                                data=
                                                {
                                                    {
                                                        labels: Array.from
                                                        (
                                                            {length: userAppDevAnalyticsData.length},
                                                            (_, i ) =>
                                                            {
                                                                return userAppDevAnalyticsData[i].appName;
                                                            }
                                                        ),
                                                        datasets: 
                                                        [
                                                            {
                                                                data: Array.from
                                                                (
                                                                    {length: userAppDevAnalyticsData.length},
                                                                    (_, i ) =>
                                                                    {
                                                                        return userAppDevAnalyticsData[i].userCount;
                                                                    }
                                                                ),
                                                                backgroundColor: 
                                                                [
                                                                    '#A6CEE3',
                                                                    '#B3E3A6',
                                                                    '#E3D3A6',
                                                                    '#DBA6E3'
                                                                ],
                                                                borderColor: 
                                                                [ 
                                                                    '#A6CEE3',
                                                                    '#B3E3A6',
                                                                    '#E3D3A6',
                                                                    '#DBA6E3'
                                                                ],
                                                                borderWidth: 1,
                                                                label: 'Usuários Apps',
                                                                minBarLength: 2,
                                                                maxBarThickness: 50
                                                            }
                                                        ]
                                                    }
                                                    
                                                }
                                            />
        
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                    <Divider
                        classes=
                        {
                            {
                                root: classes.dividerRoot
                            }
                        }
                    />
                    {
                        isAnalyticsLoading
                        ?
                            <Skeleton 
                                animation="wave" 
                                classes=
                                {
                                    {
                                        fitContent: classes.analyticChartSkeletonFitContent,
                                        text: classes.analyticChartSkeletonText
                                    }
                                }
                            >
                                <div 
                                    style=
                                    {
                                        {
                                            minHeight: '270px',
                                            borderRadius: '4px',
                                            minWidth: '100%'
                                        }
                                    }
                                >

                                </div>
                            </Skeleton> 
                        :   
                            (
                                <div id={'chartsection'} className={classes.chartRoot}>
                                    <div className={classes.innerRoot}>
                                        <div>
                                            <div className={classes.chartHeaderDownloadDatasetHeader}>
                                                <h5 className={classes.titleStyle}>
                                                    {`Usuários/App - Prod`}
                                                </h5>
                                                <span className={classes.chartHeaderDownloadDataset}>
                                                    <IconButton
                                                        onClick={(el) => onDatasetClick(`usuarios_app_prod_${new Date().toJSON()}.csv`, userAppProdAnalyticsData)}
                                                        classes=
                                                        {
                                                            {
                                                                root: classes.chartHeaderDownloadDatasetButtonRoot,
                                                            }
                                                        }
                                                    >
                                                        <DownloadRounded/>
                                                    </IconButton>
                                                </span>
                                            </div>
                                            <Bar  
                                                // id={chartID}
                                                // ref={chartRef}
                                                style=
                                                {
                                                    {
                                                        maxHeight: '200px',
                                                        // maxWidth: '50%'
                                                    }
                                                }
                                                width={0}
                                                height={200}
                                                options=
                                                {
                                                    {
                                                        //animation,
                                                        transitions: 
                                                        {
                                                            show: 
                                                            {
                                                                animations: 
                                                                {
                                                                    x: 
                                                                    {
                                                                        from: 1
                                                                    },
                                                                    y: 
                                                                    {
                                                                        from: 1
                                                                    }
                                                                }
                                                            },
                                                            hide: 
                                                            {
                                                                animations: 
                                                                {
                                                                    x: 
                                                                    {
                                                                        to: 1
                                                                    },
                                                                    y: 
                                                                    {
                                                                        to: 1
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        responsive: true,
                                                        plugins:
                                                        {
                                                            title:
                                                            {
                                                                display: false,
                                                            },                      
                                                            legend:
                                                            {
                                                                display: false,
                                                            },
                                                            tooltip: 
                                                            {
                                                                
                                                                callbacks: 
                                                                {
                                                                    // title: function(context)
                                                                    // {
                                                                    //     return `${29 - context[0].parsed.x} minutos atrás`;
                                                                    // },
                                                                    label: function(context: any) 
                                                                    {
                                                                        return `${context.dataset.label} ${context.parsed.y}`;
                                                                    }
                                                                }
                                                            },
                                                        },
                                                        scales: 
                                                        {
                                                            y: 
                                                            {
                                                                min: 0,
                                                                display: true,
                                                                ticks: 
                                                                {
                                                                    stepSize: 1,
                                                                    color: '#FFF'
                                                                }
                                                            },
                                                            x:
                                                            {
                                                                display: true,
                                                                ticks:
                                                                {
                                                                    color: '#FFF'
                                                                }
                                                            }
                                                        },
                                                    }
                                                }
                                                data=
                                                {
                                                    {
                                                        labels: Array.from
                                                        (
                                                            {length: userAppProdAnalyticsData.length},
                                                            (_, i ) =>
                                                            {
                                                                return userAppProdAnalyticsData[i].appName;
                                                            }
                                                        ),
                                                        datasets: 
                                                        [
                                                            {
                                                                data: Array.from
                                                                (
                                                                    {length: userAppProdAnalyticsData.length},
                                                                    (_, i ) =>
                                                                    {
                                                                        return userAppProdAnalyticsData[i].userCount;
                                                                    }
                                                                ),
                                                                backgroundColor: 
                                                                [
                                                                    '#A6CEE3',
                                                                    '#B3E3A6',
                                                                    '#E3D3A6',
                                                                    '#DBA6E3'
                                                                ],
                                                                borderColor: 
                                                                [ 
                                                                    '#A6CEE3',
                                                                    '#B3E3A6',
                                                                    '#E3D3A6',
                                                                    '#DBA6E3'
                                                                ],
                                                                borderWidth: 1,
                                                                label: 'Usuários Apps',
                                                                minBarLength: 2,
                                                                maxBarThickness: 50
                                                            }
                                                        ]
                                                    }
                                                    
                                                }
                                            />
        
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </div>
                    
            }
            
        </div>
    );
}
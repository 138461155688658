import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import 
{
    Button,
    DialogTitle,
    Dialog,
    List,
    ListItem
} from '@material-ui/core/';

interface IPopupChooseLogTypeProps
{
    classes: any,
    open: boolean,
    showAppLogs: boolean,
    onClose: (type: 'app' | 'sync' | null) => void
}

interface IPopupChooseLogTypeState 
{
    
}

const useStyles = (theme: any) =>
(
    {
        paperRoot:
        {
            backgroundColor: '#323232',
            color: 'white'
        },
        buttonRoot:
        {
            backgroundColor: '#202225',
            color: 'white',
            width: '100%',
            '&:hover':
            {
                backgroundColor: '#2022256b'
            }
        }
    }
);

class PopupChooseLogType extends React.Component<IPopupChooseLogTypeProps, IPopupChooseLogTypeState>
{  
    classes: any;
    constructor(props: IPopupChooseLogTypeProps)
    {
        super(props);
        this.classes = props.classes;
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose(value: 'app' | 'sync' | null)
    {
        this.props.onClose(value);
    }

    render()
    {
        return(
            <Dialog 
                classes=
                {
                    {
                        paper: this.classes.paperRoot
                    }
                } 
                onClose={()=>this.handleClose(null)} 
                aria-labelledby="simple-dialog-title" 
                open={this.props.open}>
                <DialogTitle id="simple-dialog-title">{'Tipo de log'}</DialogTitle>
                <List>
                    <ListItem style={{display: this.props.showAppLogs ? 'flex' : 'none'}}>
                        <Button 
                            onClick={()=>this.handleClose('app')}
                            classes=
                            {
                                {
                                    root: this.classes.buttonRoot
                                }
                            }
                        >
                            {'App'}
                        </Button>
                    </ListItem>
                    <ListItem>
                        <Button 
                            onClick={()=>this.handleClose('sync')}
                            classes=
                            {
                                {
                                    root: this.classes.buttonRoot
                                }
                            }
                        >
                            {'Sincronização'}
                        </Button>
                    </ListItem>
                </List>
            </Dialog>
        )
    }
}

export default withStyles(useStyles)(PopupChooseLogType);
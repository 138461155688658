import React from 'react';
import { ListSubheader, ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'; 
import PopupAddEditUser from '../popup/PopupAddEditUser';
import PopupAddEditApp from '../popup/PopupAddEditApp';
import '../../css/Log.css';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import '../../css/Settings.css';
import 
{ 
    Card, 
    List, 
    ListItem,
    IconButton,
    Fab,
    Menu,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button
} from '@material-ui/core';
import { History, LocationState } from "history";
import App from '../../model/app';
import User from '../../model/user';

interface ISettingsProps
{
    history?: History<LocationState>,
}

interface ISettingsState 
{
    showUserPopup: boolean,
    showAppPopup: boolean,
    mouseX: number | null,
    mouseY: number | null,
    popupAppData: App | null,
    userList: Array<User>,
    appList:
    {
        dev: Array<App>,
        prod: Array<App>,
    },
    showConfirmationDialog: boolean,
    contextMenuUser: User | null,
    popupUserData: User | null,
}

class Settings extends React.Component<ISettingsProps, ISettingsState>
{
    constructor(props: ISettingsProps)
    {
        super(props);
        this.state =
        {
            showUserPopup: false,
            showAppPopup: false,
            userList: [],
            appList: 
            {
                dev: [],
                prod: []
            },
            mouseX: null,
            mouseY: null,
            showConfirmationDialog: false,
            popupAppData: null,
            contextMenuUser: null,
            popupUserData: null
        };
        this.onAddEditUser = this.onAddEditUser.bind(this);
        this.onAddEditApp = this.onAddEditApp.bind(this);
        this.handleFirstDelete = this.handleFirstDelete.bind(this);
        this.handleSecondDelete = this.handleSecondDelete.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleContextMenuClick = this.handleContextMenuClick.bind(this);
        this.handleCloseConfirmationDialog = this.handleCloseConfirmationDialog.bind(this);
        this.getAccounts();
        this.getApps();
        //this.appListBuilder = this.appListBuilder.bind(this);
    }
    
    settingsTheme = createTheme
    (
        {
            overrides:
            {
                //TODO apply colors
                // MuiPaginationItem:
                // {
                //     root:
                //     {
                //         color: 'white !important',
                //     },
                //     ul:
                //     {
                //         "&$selected": 
                //         {      
                //             backgroundColor: "rgba(255, 255, 255, 0.08)",
                //         },
                //     }
                // },
                MuiFab:
                {
                    root: 
                    {
                        backgroundColor: '#008EB4',
                        color: 'white',
                        '&:hover':
                        {
                            backgroundColor: '#005E77'
                        }
                    }
                }
            }
        }
    );

    appSectionTheme = createTheme
    (
        {
            overrides:
            {
                MuiIconButton:
                {
                    root:
                    {
                        color: 'white',
                    },
                },
                MuiCard:
                {
                    root:
                    {
                        width: '100%',
                        padding:'1%',
                        borderRadius: '36px',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        cursor: 'pointer'          
                    }
                },
            }
        }
    );

    editIconTheme = createTheme
    (
        {
            overrides:
            {
                MuiIconButton:
                {
                    root:
                    {
                        color: '#686D76',
                    }
                },
            }
        }
    );

    dialogTheme = 
    {
        style:
        {
            backgroundColor: '#323232',
            color: '#FFFFFF'
        }
    };

    dialogContentTheme = createTheme
    (
        {
            overrides:
            {
                MuiTypography:
                {
                    colorTextSecondary:
                    {
                        color: 'rgb(255 255 255 / 90%)'
                    }
                },
                MuiButton:
                {
                    textPrimary:
                    {
                        color: 'white'
                    }
                }
            }
        }
    );

    closePopup(reload: boolean)
    {
        this.setState
        (
            {
                showUserPopup: false,
                showAppPopup: false,
                popupAppData: null
            },
            () =>
            {
                if(reload)
                {
                    window.location.reload();
                }
            }
        )
    }

    onAddEditUser(userData: User | null)
    {
        this.setState
        (
            {
                showUserPopup: true,
                popupUserData: userData
            }
        )
    }

    onAddEditApp(appData: App | null)
    {
        this.setState
        (
            {
                showAppPopup: true,
                popupAppData: appData
            }
        )
    }
    
    handleContextMenuClick(event: any, user: User) 
    {
        event.preventDefault();
        this.setState
        (
            {
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4,
                contextMenuUser: user
            }
        );
    };
    
    handleClose()
    {
        this.setState
        (
            {
                mouseX: null,
                mouseY: null,
                contextMenuUser: null
            }
        );
    };

    handleFirstDelete()
    {
        this.setState
        (
            {
                showConfirmationDialog: true
            }
        )
    };

    async handleSecondDelete()
    {
        var userID = this.state.contextMenuUser!.id;
        var deleted = await this.deleteAccount(userID);
        if(deleted)
        {
            var tempUserList = this.state.userList.filter((element)=> element.id !== userID);
            this.setState
            (
                {
                    userList: tempUserList
                }
            )
        }
    };

    handleCloseConfirmationDialog(shouldDelete: boolean)
    {
        this.setState
        (
            {
                showConfirmationDialog: false
            }
        )
        if(shouldDelete)
        {
            this.handleSecondDelete();
        }
        this.handleClose();
    }
    
    accountsListBuilder(list: Array<User>)
    {
        var children = [];
        var sThis = this;
        for(var i = 0; i < list.length; i++)
        {
            (
                function (i) 
                {
                    var listItem = 
                    (
                        <ListItem key={list[i].id} className='settingsListItem' onContextMenu={(e)=>sThis.handleContextMenuClick(e, list[i])} style={{ cursor: 'context-menu' }}>
                            <div className='settingsFlexGrow'>
                                <span className='settingsRowTitle'>
                                    {list[i].username}
                                </span>
                                <span className='settingsRowSubtitle'>
                                    {'('.concat(list[i].roleText).concat(')')}
                                </span>
                            </div>
                            <div>
                                <ThemeProvider theme={sThis.editIconTheme}>
                                    <IconButton size='small' onClick={()=>sThis.onAddEditUser(list[i])}>
                                        <EditIcon/>
                                    </IconButton>
                                </ThemeProvider>
                            </div>
                        </ListItem>
                    );
                    children.push(listItem);
                }
            )(i);
        }
        return children;
    } 

    appListBuilder(list: Array<App>)
    {
        var children = [];
        var sThis = this;
        for(var i = 0; i < list.length; i++)
        {
            (
                function (i) 
                {
                    var listItem = 
                    (
                        <ListItem key={i} className='settingsListItem'>
                            <Card className='settingsCard'>
                                <div className='settingsFlexGrow'>
                                    <span className='settingsRowTitle settingsAppRow'>
                                        {list[i].name}
                                    </span>
                                    <span className='settingsRowSubtitle settingsAppTokenRow'>
                                        {`(${list[i].appID?.toString()})`}
                                    </span>
                                </div>
                                <div>
                                    <ThemeProvider theme={sThis.editIconTheme}>
                                        <IconButton size='small' onClick={() => sThis.onAddEditApp(list[i])}>
                                            <EditIcon/>
                                        </IconButton>
                                    </ThemeProvider>
                                </div>
                            </Card>
                        </ListItem>
                    );
                    children.push(listItem);
                }
            )(i);
        }
        return children;
    } 

    getAccounts()
    {
        fetch
        (
            "/api/users", 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    var data = await response.json();
                    this.setState
                    (
                        {
                            userList: data
                        }
                    );
                }
                else
                {
                    if(this.props.history !== undefined)
                    {
                        this.props.history.push('/login');
                    }
                }
            } 
        );
    }

    async deleteAccount(userID: number)
    {
        var response = await fetch
        (
            `/api/user/${userID}`, 
            {
                method: "DELETE",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        );
        return (response.status === 200);
    }

    getApps()
    {
        fetch
        (
            "/api/apps", 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    var data = await response.json();

                    let devApps: Array<App> = [];
                    let prodApps: Array<App> = [];

                    for(let i = 0; i < data.dev.length; i++)
                    {
                        devApps.push
                        (
                            new App
                            (
                                data.dev[i].appID,
                                data.dev[i].icon,
                                data.dev[i].name,
                                data.dev[i].color
                            )
                        );
                    }

                    for(let i = 0; i < data.prod.length; i++)
                    {
                        prodApps.push
                        (
                            new App
                            (
                                data.prod[i].appID,
                                data.prod[i].icon,
                                data.prod[i].name,
                                data.prod[i].color
                            )
                        );
                    }

                    let apps =
                    {
                        dev: devApps,
                        prod: prodApps
                    }

                    this.setState
                    (
                        {
                            appList: apps
                        }
                    );
                }
                else
                {
                    if(this.props.history !== undefined)
                    {
                        this.props.history.push('/login');
                    }
                }
            } 
        );
    }

    render()
    {
        return(
            <div>
                <ThemeProvider theme={this.settingsTheme}>
                    <div className='settingsFlexPage'>
                        <div className='settingsUserAccountsSection'>
                            <div className='settingsUserAccountSubSection'>
                                <h1 className='settingsTitleDark'>
                                    Usuários:
                                </h1>
                                <div className='settingsUserList'>
                                    <List>
                                        {this.accountsListBuilder(this.state.userList)}
                                    </List>
                                </div>
                                <div style={{position: 'absolute', bottom: 0, right:0}} className='settingsUserAccountFAB'>
                                    <Fab onClick={()=>this.onAddEditUser(null)}>
                                        <AddIcon/>
                                    </Fab>
                                </div>
                            </div>
                        </div>
                        <ThemeProvider theme={this.appSectionTheme}>
                            <div className='settingsAppSection'>
                                <div className='settingsAppSubSection'>
                                    <div className='settingsAppTopSection'>
                                        <h1 className='settingsTitleLight'>
                                            App:
                                        </h1>
                                        <div className='settingsAppAddButton'>
                                            <IconButton onClick={()=>this.onAddEditApp(null)}>
                                                <AddIcon/>
                                            </IconButton>
                                        </div>
                                    </div>  
                                </div>
                                <div className='settingsNFlexGrow'>
                                    <List>
                                        <ListSubheader disableSticky style={{fontSize: '12pt', color: '#B1B1B1'}}>
                                            {'Dev'}
                                        </ListSubheader>
                                        {this.appListBuilder(this.state.appList.dev)}
                                        <ListSubheader disableSticky style={{fontSize: '12pt', color: '#B1B1B1'}}>
                                            {'Prod'}
                                        </ListSubheader>
                                        {this.appListBuilder(this.state.appList.prod)}
                                    </List>
                                </div>
                            </div>
                        </ThemeProvider>
                    </div>
                </ThemeProvider>
                <Menu
                    open={this.state.mouseY !== null}
                    onClose={this.handleClose.bind(this)}
                    anchorReference="anchorPosition"
                    anchorPosition=
                    {
                        this.state.mouseY !== null && this.state.mouseX !== null
                        ? 
                            { 
                                top: this.state.mouseY, 
                                left: this.state.mouseX 
                            }
                        : 
                            undefined
                    }
                >
                    <MenuItem onClick={this.handleFirstDelete}>
                        <DeleteIcon style={{color:'#CE2727'}}/>Apagar
                    </MenuItem>
                </Menu>
                <ThemeProvider theme={this.dialogContentTheme}>
                    <Dialog
                        open={this.state.showConfirmationDialog}
                        onClose={()=>this.handleCloseConfirmationDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        PaperProps={this.dialogTheme}
                    >
                        <DialogTitle id="alert-dialog-title">{"Tens certeza que deseja apagar o usuário?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {'Tem certeza que deseja deletar o usuário '.concat(this.state.contextMenuUser !== null ? this.state.contextMenuUser.name : 'null').concat('?\nApagar o usuário resultará em um bloqueio de acesso e todos os dados relacionados ao usuário serão removidos.')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>this.handleCloseConfirmationDialog(false)} color="primary">
                                {'Cancelar'}
                            </Button>
                            <Button onClick={()=>this.handleCloseConfirmationDialog(true)} color="primary">
                                {'Apagar'}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </ThemeProvider>
                <PopupAddEditUser show={this.state.showUserPopup} data={this.state.popupUserData} closePopup={this.closePopup.bind(this)}/>
                <PopupAddEditApp show={this.state.showAppPopup} data={this.state.popupAppData} closePopup={this.closePopup.bind(this)}/>
            </div>
        );
    }
}

export default Settings;
import React from 'react';  
import '../../css/popup/Popup.css';  
import LogoTopOn from '../../resources/LogoTopOn.png';
import 
{  
    Button, 
    TextField,
    IconButton,
    Icon
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'; 
import { ColorResult, SketchPicker } from 'react-color'
import MaterialUiIconPicker from '../materialUiPicker/MaterialUiIconPicker';
import { History, LocationState } from "history";
import App from '../../model/app';

interface IPopupAddEditAppProps
{
    history?: History<LocationState>,
    closePopup: (reload: boolean) => void,
    data: App | null,
    show: boolean
}

interface IPopupAddEditAppState 
{
    name: string,
    appID: string,
    appIcon: string,
    title: string,
    buttonAddModify: string,
    displayColorPicker: boolean,
    color: string,
    shouldOpenIconPicker: boolean,
    nameError: boolean,
    nameHelper: string,
    colorError: boolean,
    colorHelper: string
}

class PopupAddEditApp extends React.Component<IPopupAddEditAppProps, IPopupAddEditAppState>
{  
    constructor(props: IPopupAddEditAppProps)
    {
        super(props);
        var title = 'Adicionar um aplicativo:';
        var buttonAddModify = 'Adicionar';
        var state = 
        {
            name: '',
            appID: '',
            appIcon: 'account_circle'
        }
        if(props.data != null)
        {
            title = 'Modificar aplicativo:';
            buttonAddModify = 'Modificar';
            state.name = props.data.name!;
            state.appID = props.data.appID!;
            state.appIcon = props.data.icon!;
        }
        this.state = 
        {
            title: title,
            buttonAddModify: buttonAddModify,
            name: state.name,
            appID: state.appID,
            displayColorPicker: false,
            color: '#000000',
            shouldOpenIconPicker: false,
            appIcon: state.appIcon,
            nameError: false,
            nameHelper: '',
            colorError: false,
            colorHelper: ''
        }
    }

    UNSAFE_componentWillReceiveProps(props: IPopupAddEditAppProps)
    {
        var title = 'Adicionar um aplicativo:';
        var buttonAddModify = 'Adicionar';
        var state = 
        {
            name: '',
            appID: '',
            color: '#000000',
            appIcon: 'account_circle'
        }
        if(props.data != null)
        {
            title = 'Modificar aplicativo:';
            buttonAddModify = 'Modificar';
            state.name = props.data.name!;
            state.appID = props.data.appID!;
            state.color = props.data.color!;
            state.appIcon = props.data.icon!;
        }

        this.setState
        ( 
            {
                title: title,
                buttonAddModify: buttonAddModify,
                name: state.name,
                appID: state.appID,
                color: state.color,
                shouldOpenIconPicker: false,
                appIcon: state.appIcon
            }
        );
    }

    buttonsTheme = createTheme
    (
        {
            overrides:
            {
                MuiButton:
                {
                    root:
                    {
                        borderRadius: '24px',
                        color: 'white !important',
                        marginLeft: '32px'
                    },
                    containedPrimary: 
                    {
                        backgroundColor: '#008EB4',
                        '&:hover':
                        {
                            backgroundColor: '#005E77'
                        }
                    },
                    containedSecondary:
                    {
                        backgroundColor: '#686D76',
                        '&:hover':
                        {
                            backgroundColor: '#444444'
                        }
                    }
                },
                MuiIconButton:
                {
                    colorPrimary:
                    {
                        color: 'grey'
                    }
                }
            }
        }
    );

    popupTheme = createTheme
    (
        {
            overrides:
            {
                MuiFormLabel:
                {
                    root:
                    {
                        "&$focused": 
                        {
                            color: 'black',
                        }
                    }
                },
                MuiInput:
                {
                    underline:
                    {
                        '&:before':
                        {
                            color: 'black',
                            borderColor: 'black'
                        },
                        '&:hover':
                        {
                            color: 'black',
                            borderColor: 'black'
                        },
                        '&:after':
                        {
                            color: 'black',
                            borderColor: 'black'
                        }
                    }
                }
            }
        }
    );

    onPopupInnerClick(event: any)
    {
        event.stopPropagation();
    }

    onFormSubmit(event: any)
    {
        var name = document.querySelector<HTMLInputElement>("#appname")!.value;
        var color = document.querySelector<HTMLInputElement>("#appcolor")!.value;   
        var errors =
        {
            nameError: false,
            nameHelper: '',
            colorError: false,
            colorHelper: '',
        }

        if(name.length <= 0)
        {
            errors.nameError = true;
            errors.nameHelper = 'O nome não pode ser nulo';
        }

        if(!color.match(/^#[A-F0-9]{6}$/))
        {
            errors.colorError = true;
            errors.colorHelper = 'Cor inválida!';
        }

        this.setState
        (
            {
                nameError: errors.nameError,
                nameHelper: errors.nameHelper,
                colorError: errors.colorError,
                colorHelper: errors.colorHelper
            }
        );
        console.log(errors)
        if(!errors.nameError && !errors.colorError)
        {
            var url = '/api/app';
            var method = 'POST';
            var app = 
            {
                name: name,
                color: color,
                icon: this.state.appIcon
            };

            if(this.props.data != null)
            {
                url = `/api/app/${this.props.data.appID}`;
                method = 'PATCH';
                app = 
                {
                    name: name,
                    color: color,
                    icon: this.state.appIcon
                }
            }

            fetch
            (
                url, 
                {
                    method: method,
                    headers: 
                    {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify
                    (
                        {
                            app: app
                        }
                    ),
                }
            )
            .then
            (
                (resp) =>
                {
                    this.props.closePopup(true);
                }
            );   
        }
        event.preventDefault();
    }

    nameChange(e: any)
    {
        this.setState
        (
            {
                name: e.target.value
            }
        )
    }

    handleClick()
    {
        this.setState
        (
            { 
                displayColorPicker: !this.state.displayColorPicker 
            }
        )
    };
    
    handleClose()
    {
        this.setState
        (
            { 
                displayColorPicker: false 
            }
        )
    };
    
    handleChange(color: ColorResult)
    {
        this.setState
        (
            { 
                color: color.hex.toUpperCase()
            }
        )
    };

    openIconPicker(event: any)
    {
        this.setState
        (
            {
                shouldOpenIconPicker: true
            }
        )
    }

    closeIconPicker(iconName: string)
    {
        var state = 
        {
            shouldOpenIconPicker: false
        }
        if(iconName !== '')
        {
            Object.assign(state, {appIcon: iconName});
            //state.appIcon = iconName;
        }

        this.setState
        (
            state
        )
    }
    
    render() 
    {  

        const styleColor =
        {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: this.state.color,
        }

        if(this.props.show)
        {
            return (  
                <div className='popup' onClick={()=>this.props.closePopup(false)}>  
                    <div className='popup_with_logo_inner' onClick={this.onPopupInnerClick}>  
                        <ThemeProvider theme={this.popupTheme}>
                            <h2>{this.state.title}</h2>
                            <div className='add_edit_popup'>
                                <form 
                                    autoComplete="on"
                                    onSubmit={this.onFormSubmit.bind(this)}
                                >
                                    <div className='column'>
                                        <TextField 
                                            className='biggerinput' 
                                            value={this.state.name} 
                                            onChange={this.nameChange.bind(this)}
                                            error={this.state.nameError} 
                                            helperText={this.state.nameHelper} 
                                            id='appname'  
                                            label="Nome do app" 
                                            required>
                                        </TextField>
                                        <TextField 
                                            className='biggerinput' 
                                            value={this.state.appID} 
                                            id='appID' 
                                            label="App Id" 
                                            disabled={true}>
                                        </TextField>
                                        <div className='color_input row'>
                                            <div className='color_input_indicator color_input_swatch' onClick={ this.handleClick.bind(this) }>
                                                <div style={styleColor} />
                                                {   
                                                    this.state.displayColorPicker 
                                                    ? 
                                                        <div className='color_input_popover'onClick={(e)=>{e.stopPropagation();} } >
                                                            <div className='color_input_cover' onClick={ this.handleClose.bind(this) }/>
                                                            <SketchPicker color={ this.state.color } onChange={ this.handleChange.bind(this) } />
                                                        </div> 
                                                    : 
                                                        null
                                                }
                                            </div>
                                            <TextField 
                                                className='' 
                                                value={this.state.color} 
                                                error={this.state.colorError} 
                                                helperText={this.state.colorHelper} 
                                                id='appcolor' 
                                                label="Cor" 
                                                required 
                                                disabled></TextField> 
                                            <IconButton onClick={this.openIconPicker.bind(this)}>
                                                <Icon style={{ fontSize: 30 }}>{this.state.appIcon}</Icon>
                                            </IconButton>           
                                        </div>
                                        <div className='buttoncontainer'>
                                            <ThemeProvider theme={this.buttonsTheme}>
                                                    <Button className='button' onClick={()=>this.props.closePopup(false)} variant='contained' color="secondary">
                                                        Cancelar
                                                    </Button>
                                                    <Button className='button' variant='contained' color="primary" type='submit'>
                                                        {this.state.buttonAddModify}
                                                    </Button>
                                            </ThemeProvider>
                                        </div>
                                    </div>
                                </form>
                                <div className='column logo'>
                                    <img src={LogoTopOn} width='100%' height='auto' alt='logo'></img>
                                </div>
                            </div>
                        </ThemeProvider>
                    </div>
                    <MaterialUiIconPicker open={this.state.shouldOpenIconPicker} onClose={this.closeIconPicker.bind(this)} />
                </div>  
            );  
        }
        else
        {
            return(null);
        }
    }  
}  

export default PopupAddEditApp;
import { History, LocationState } from "history";
import logo from '../../../resources/logoWhite.png';
import App from '../../../model/app';
import 
{ 
    makeStyles 
} from '@material-ui/core/styles';

import 
{  
    AppBar, 
    Toolbar,  
    Button,
    Typography,
    Divider,
    ListItem,
    List
} from '@material-ui/core';

import
{
    CheckCircleRounded,
    CancelRounded,
    CancelTwoTone,
    CheckCircleOutlineRounded
} from '@material-ui/icons';
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import OdinShield from "../../odin/odin_shield";

interface IAuthorizeAppProps
{
    history?: History<LocationState>,
}

const useStyles = makeStyles
(
    {
        root: 
        {
            height: '100vh',
            width: '100vw',
            background: '#1A1C1D',
            color: 'white'
        },
        appBarNoBackground:
        {
            background: 'transparent'
        },
        content:
        {
            position: 'absolute',
            display: 'flex',
            top: '64px',
            bottom: '0px',
            left: '0px',
            right: '0px'
        },
        svgCloud:
        {
            position: 'fixed',
            height: '100vh',
            top: 0,
            right: 0
        },
        center:
        {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'relative'
        },
        centerWithFlex:
        {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        centerHorizontally:
        {
            display: 'flex',
            justifyContent: 'center'
        },
        centerFlexHorizontally:
        {
            alignItems: 'center'
        },
        centerFlexVertically:
        {
            justifyContent: 'center'
        },
        fillParentHeight:
        {
            top: '0',
            bottom: '0',
            position: 'absolute'
        },
        flexColumn:
        {
            display: 'flex',
            flexDirection: 'column'
        },
        noDataFound:
        {
            width: '100%'
        },
        authorizeSection:
        {
            background: '#141516',
            borderRadius: '5px',
            maxWidth: '400px',
            minWidth: '380px',
            minHeight: '400px', 
            height: 'fit-content',
            position: 'relative',
            overflow: 'hidden',
        },
        authorizeSectionContent:
        {
            display: 'flex',
            padding: '10px 20px',
            flexDirection: 'column',
            alignItems: 'left',
            marginBottom: '57px'
        },
        authorizeSectionHeaderApp:
        {
            marginTop: '25px',
            width: '100%',
            height: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        authorizeSectionHeaderAppLogo:
        {
            width: '97px',
            height: '97px',
        },
        authorizeSectionHeaderAppName:
        {
            marginTop: '10px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: '#DEDCD9'
        },
        authorizeSectionHeaderAppSubtitle:
        {
            color: '#AFABA3'
        },
        authorizeSectionHeaderAppLoggedAs:
        {
            color: '#8F877C'
        },
        authorizeSectionContentTitle:
        {
            textTransform: 'uppercase',
            color: '#DEDCD9',
            fontWeight: 'bold',
        },
        authorizeSectionFooter:
        {
            background: '#26292B',
            position: 'absolute',
            height: '57px',
            width: '100%',
            bottom: '0',
        },
        authorizeSectionFooterContent:
        {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px'
        },
        authorizeSectionScopeListItem:
        {
            paddingLeft: '0px',
            paddingRight: '0px'
        },
        authorizeSectionScopeListItemIcon:
        {
            display: 'flex',
            marginRight: '10px',
        },
        authorizeSectionScopeListItemIconBackgroundColor:
        {
            position: 'absolute',
            zIndex: -1,
            width: '18px',
            height: '18px',
            backgroundColor: 'white',
            margin: '3px',
            borderRadius: '100%'
        },
        containedButton:
        {
            color: '#FFFFFF',
            backgroundColor: '#008EB4',
            '&:hover':
            {
                backgroundColor: '#006682'
            }
        },
        expand:
        {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
        textButton:
        {
            color: '#FFFFFF'
        },
        checkIconColor:
        {
            color: '#036D19'
        },
        cancelIconColor:
        {
            color: '#747F8D'
        }
    }
);

interface Scope
{
    scope: string,
    title: string
}


export default function AuthorizeAppPage(props: IAuthorizeAppProps)
{
    const [app, setApp] = useState<App|null>(null);
    const [alreadyLookForApp, setAlreadyLookForApp] = useState<boolean>(false);
    const [isUserLogged, setIsUserLogged] = useState<boolean>(false);
    const [isAuthorizationStatusSend, setIsAuthorizationStatusSend] = useState<boolean>(false);

    const classes = useStyles();
    const existingScopes: Array<Scope> = 
    [
        {
            scope: 'identify',
            title: 'Tenha acesso ao seu perfil'
        },
        {
            scope: 'docs',
            title: 'Acessar as documentações'
        }
    ]

    const _getRandomFunnyScope = function()
    {
        const randomScopesElements: Array<Scope> =
        [
            {
                scope: 'scooby-doo',
                title: 'Resolver mistérios com a turma do Scooby-Doo'
            },
            {
                scope: 'prey-to-odin',
                title: 'Rezar para Odin'
            },
            {
                scope: 'make-offerings-to-odin',
                title: 'Fazer oferendas para Odin'
            },
            {
                scope: 'hunt-giants-to-odin',
                title: 'Caçar gigantes'
            },
            {
                scope: 'use-bifrost',
                title: 'Usar a Bifrost'
            },
            {
                scope: 'make-a-sacrifice-for-wisdom',
                title: 'Fazer um sacrifício em troca de conhecimento'
            }
        ];
        var idx = Math.floor(Math.random()*randomScopesElements.length);
        return randomScopesElements[idx];
    }

    const checkIfUserIsLogged = function()
    {
        fetch
        (
            "/api/validate", 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            (response)=>
            {
                if(response.status !== 200)
                {
                    window.location.href = `/login?redirect_to=${encodeURIComponent(window.location.href.replace(window.location.protocol, '').replace(window.location.host, '').substring(2))}`;
                }
                else
                {
                    setIsUserLogged(true);
                }
            }
        );
    }

    const getApp = function()
    {
        var url = new URL(window.location.href);
        var appId = url.searchParams.get('app_id');
        fetch
        (
            `/api/adm/app?app_id=${appId}`, 
            {
                method: 'GET',
                headers: 
                {
                    'content-type': 'application/json'
                }
            }
        )
        .then
        (
            (value) => value.json()
        )
        .then
        (
            (json) =>
            {
                if(json)
                {
                    if(json.app)
                    {
                        setApp
                        (
                            new App
                            (
                                json.app.appId,
                                json.app.icon,
                                json.app.name, 
                                json.app.color,
                            )
                        )
                        setAlreadyLookForApp(true);
                    }
                }
            }
        );
    }

    const sendAuthorizationResponse = function(authorized: boolean)
    {
        var url = new URL(window.location.href);
        var appId = url.searchParams.get('app_id');
        var scopeList = url.searchParams.get('scope');
        var sessionId = url.searchParams.get('session_id');
        var cancelParam = '';
        if(!authorized)
        {
            cancelParam = '&cancel=true'
        }
        fetch
        (
            `/api/oauth/authorize?app_id=${appId}&scope=${scopeList?.replace(' ', '+')}&session_id=${sessionId}&response_type=code${cancelParam}`, 
            {
                method: 'POST',
                headers: 
                {
                    'content-type': 'application/json'
                }
            }
        )
        .then
        (
            (response) => response.json()
        )
        .then
        (
            (json) =>
            {
                console.log(json.code);
                if(json)
                {
                    setIsAuthorizationStatusSend(true);
                }
            }
        );
    }

    const generateScopeList = function(scopeList: Array<Scope>) : Array<any>
    {
        var elementList: Array<any> = [];

        scopeList.push(_getRandomFunnyScope());

        for(let i = 0; i < scopeList.length; i++)
        {
            elementList.push
            (
                
                <ListItem 
                    key={scopeList[i].scope}
                    classes=
                    {
                        {
                            gutters: classes.authorizeSectionScopeListItem
                        }
                    }
                >
                    {
                        i + 1 === scopeList.length
                        ?
                            <div className={classes.authorizeSectionScopeListItemIcon}>
                                <CancelRounded
                                    className={classes.cancelIconColor}
                                />
                                <div className={classes.authorizeSectionScopeListItemIconBackgroundColor}></div>
                            </div>
                        :
                            <div className={classes.authorizeSectionScopeListItemIcon}>
                                <CheckCircleRounded
                                    className={classes.checkIconColor}
                                />
                                <div className={classes.authorizeSectionScopeListItemIconBackgroundColor}></div>
                            </div>
                            
                    }
                    
                    {scopeList[i].title}
                </ListItem>
                
            )
        }
        return elementList;
    }

    const generateErrorWidget = function (errorMsg: string)
    {
        return (
            <>
                <div className={`${classes.noDataFound} ${classes.flexColumn} ${classes.fillParentHeight} ${classes.centerFlexVertically} ${classes.centerFlexHorizontally}`}>
                    <CancelTwoTone
                        style={{width: '127px', height: '128px'}}
                    />
                    <Typography variant='body1'>
                        {errorMsg}
                    </Typography>
                </div>
            </>
        );
    }

    const generateSuccessWidget = function ()
    {
        return (
            <>
                <div className={`${classes.noDataFound} ${classes.flexColumn} ${classes.fillParentHeight} ${classes.centerFlexVertically} ${classes.centerFlexHorizontally}`}>
                    <CheckCircleOutlineRounded
                        style={{width: '127px', height: '128px'}}
                    />
                    <Typography variant='body1'>
                        {'Tudo certo!\nVocê já pode fechar esta guia.'}
                    </Typography>
                </div>
            </>
        );
    }

    const generateAuthorizeSection = function ()
    {
        if(!isUserLogged)
        {
            checkIfUserIsLogged();
            return (<></>);
        }
        if(isAuthorizationStatusSend)
        {
            return generateSuccessWidget();
        }
        var url = new URL(window.location.href);
        var appId = url.searchParams.get('app_id');
        var scopeListTmp = url.searchParams.get('scope');
        var sessionId = url.searchParams.get('session_id');

        var scopeListStr: Array<string> = [];
        var scopeList: Array<Scope> = [];

        if(!appId || !scopeListTmp || !sessionId)
        {
            return generateErrorWidget('Dados inválidos');
        }
        if(!appId.match(/^[0-9]{18}$/g))
        {
            return generateErrorWidget('ID do App inválido');
        }
        scopeListStr = scopeListTmp.split(' ');
        var nonExistingScopes = scopeListStr.filter(scope=> !existingScopes.find(x=>x.scope===scope))
        if(nonExistingScopes.length > 0)
        {
            return generateErrorWidget(`Scopes Inválidos: ${nonExistingScopes.join(', ')}`);
        }
        for(let i = 0; i < scopeListStr.length; i++)
        {
            scopeList.push(existingScopes.find(x=>x.scope === scopeListStr[i])!);
        }
        
        if(!alreadyLookForApp && app == null)
        {
            getApp();
            return(
                <div className={`${classes.expand} ${classes.centerWithFlex}`}>
                    <CircularProgress />
                </div>
            );
        }
        else if(alreadyLookForApp && app == null)
        {
            return generateErrorWidget('App Não Encontrado');
        }
        return (
            <>
                <header className={`${classes.authorizeSectionHeaderApp} ${classes.centerHorizontally}`}>
                    <OdinShield
                        className={classes.authorizeSectionHeaderAppLogo}
                        outlineColor={app!.color!}
                    />
                    <Typography 
                        variant="h6" 
                        classes=
                        {
                            {
                                h6: classes.authorizeSectionHeaderAppName
                            }
                        }>
                        {app!.name}
                    </Typography>
                    <Typography 
                        variant="body1" 
                        classes=
                        {
                            {
                                body1: classes.authorizeSectionHeaderAppSubtitle
                            }
                        }
                    >
                        {'quer acessar sua permissão para'}
                    </Typography>
                    <Typography 
                        variant="body2"
                        classes=
                        {
                            {
                                body2: classes.authorizeSectionHeaderAppLoggedAs
                            }
                        } 
                    >
                        {'Logado como'}
                    </Typography>
                    <Divider
                        style=
                        {
                            {
                                width: 'calc(100% - 20px)',
                                margin: '20px 10px 10px 10px',
                                backgroundColor: '#1A1B1C'
                            }
                        }
                    />
                </header>
                <div className={classes.authorizeSectionContent}>
                    <Typography 
                        variant="body1" 
                        classes=
                        {
                            {
                                body1: classes.authorizeSectionContentTitle
                            }
                        }
                    >
                        {'quer acessar sua conta'}
                    </Typography>
                    <List>
                        {
                            generateScopeList(scopeList)
                        }
                    </List>
                </div>
                <footer className={classes.authorizeSectionFooter}>
                    <div className={classes.authorizeSectionFooterContent}>
                        <Button
                            variant={'text'}
                            classes=
                            {
                                {
                                    text: classes.textButton
                                }
                            }
                            onClick={()=>sendAuthorizationResponse(false)}
                        >
                            {'Cancelar'}
                        </Button>
                        <Button
                            variant={'contained'}
                            classes=
                            {
                                {
                                    contained: classes.containedButton
                                }
                            }
                            onClick={()=>sendAuthorizationResponse(true)}
                        >
                            {'Autorizar'}
                        </Button>
                    </div>
                </footer>
            </>
        );
    }

    return (
        <div className={classes.root}>
            <div className={classes.svgCloud}>
                <svg xmlns="http://www.w3.org/2000/svg" width="954.381" height="781" viewBox="0 0 954.381 781">
                    <path id="Path_53" data-name="Path 53" d="M954.381,0H128.219S0,45.617,0,116.713s53.425,84.247,128.219,167.672-10.274,147.124,65.754,220.275,132.74,16.849,238.357,72.329S488.827,685.7,576.988,708.5c149.865,38.743,183.461-18.126,277.809,0S954.381,781,954.381,781Z" fill="#2f3136"/>
                </svg>
            </div>
            <AppBar 
                position="static"
                elevation={0}
                classes=
                {
                    {
                        root: classes.appBarNoBackground,
                    }
                }
            >
                <Toolbar >
                    <img 
                        src={logo} 
                        alt='logo'
                        style=
                        {
                            {
                                maxHeight: '60px',
                                minHeight: '40px',
                                padding: '10px'
                            }
                        }
                    ></img>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                <div className={`${classes.authorizeSection} ${classes.center}`}>
                    {
                        generateAuthorizeSection()
                    }
                </div>          
            </div>
        </div>
    );
}
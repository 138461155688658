import React, 
{ 
    useState 
} from 'react';
import 
{  
    // IconButton,
    Button,
	ButtonGroup,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	// LinearProgress,
} from '@material-ui/core';
import 
{ 
    makeStyles 
} from '@material-ui/core/styles';
import 
{
    TextField
} from '@mui/material';

import 
{
    Autocomplete,
    // createFilterOptions 
} from '@material-ui/lab';

import
{
    LocalizationProvider 
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import CompanyFilters from '../../model/companyFilters';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

// import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import IActionFilter from '../../model/IActionFilter';
import ISyncLogFilter from '../../model/isyncLogFilter';

export interface ISyncLogPopupFilterProps
{
    open: boolean,
    closeHandler: (event: any, filter?: ISyncLogFilter) => void,
    companiesDataset: Array<CompanyFilters>,
    actionsDataset: Array<IActionFilter>,
    filters?: ISyncLogFilter | null
}

const useStyles = makeStyles
(
    {
        buttonTextPrimary:
        {
            color: 'white',
        },
        buttonTextSecondary:
        {
            color: '#008EB4 !important',
            backgroundColor: '#FFFFFF',
            '&:hover':
            {
                backgroundColor: '#EBEBEB'
            }
        },
        buttonContainedPrimary: 
        {
            backgroundColor: '#008EB4',
            '&:hover':
            {
                backgroundColor: '#006682'
            }
        },
        dateSection:
        {
            display: 'flex',
            flexDirection: 'row',
            '&>:nth-child(2n)':
            {
                marginLeft: '10px'
            }
        },
        datePickerText:
        {
            color: 'rgba(0, 0, 0, 0.87)'
        },
        filtersList:
        {
            '&>:not(:first-child)':
            {
                marginTop: '10px'
            }
        }
    }
);

export default function SyncLogPopupFilter(props: ISyncLogPopupFilterProps)
{
    const classes = useStyles();
    const [selectedDateStart, setSelectedDateStart] = useState<Date | null>(null);
    const [selectedDateEnd, setSelectedDateEnd] = useState<Date | null>(null);
    const [openStartDatePopup, setOpenStartDatePopup] = useState<boolean>(false);
    const [openEndDatePopup, setOpenEndDatePopup] = useState<boolean>(false);
    const [currentCompanyFilter, setCurrentCompanyFilter] = useState<CompanyFilters | null>(null);
    const [currentResponseCodeFilter, setCurrentResponseCodeFilter] = useState<{title: string, value: number} | null>(null);
    const [currentActionFilter, setCurrentActionFilter] = useState<IActionFilter|null>(null);

    React.useEffect
    (
        () =>
        {
            if(props.filters?.startDate)
            {
                if(typeof(props.filters.startDate) === 'string')
                {
                    setSelectedDateStart(new Date(props.filters.startDate!.replace('-','/')));
                }
            }
            if(props.filters?.endDate)
            {
                if(typeof(props.filters.endDate) === 'string')
                {
                    setSelectedDateEnd(new Date(props.filters.endDate!.replace('-','/')));
                }
            }
            if(props.filters?.companyToken)
            {
                setCurrentCompanyFilter(props.companiesDataset.find(x=>x.token === props.filters?.companyToken) ?? null);
            }

            if(props.filters?.responseCode)
            {
                setCurrentResponseCodeFilter(responseCodes.find(x=> x.value === props.filters?.responseCode) ?? null);
            }

            if(props.filters?.action)
            {
                setCurrentActionFilter(props.actionsDataset.find(x=>x.action === props.filters?.action) ?? null);
            }

        },
        [
            props.filters,
            props.actionsDataset,
            props.companiesDataset,
            // responseCodes
        ]
    );

    function closeHandler(event: any, filters?: ISyncLogFilter)
    {
        props.closeHandler(event, filters);
        setCurrentCompanyFilter(null);
        setCurrentActionFilter(null);
        setSelectedDateStart(null);
        setSelectedDateEnd(null);
    }

    return(
        <Dialog
            onClose={(event: any) => closeHandler(event)}
            open={props.open}
        >
            <DialogTitle>
               {'Filtrar Logs de Sincronização'}
            </DialogTitle>
            <DialogContent 
                id='dialogContent'
                classes=
                {
                    {
                        root: classes.filtersList
                    }
                }
            >
                {/* <TextField
                    label={'Empresa'}
                    value={'todas'}
                    rend
                /> */}
                {/** Company Section */}
                <Autocomplete
                    options={props.companiesDataset}
                    getOptionLabel={(option) => `${option.companyName} (${option.token})`}
                    onChange={(_, value) => setCurrentCompanyFilter(value)}
                    defaultValue=
                    {
                        currentCompanyFilter 
                        ?
                            currentCompanyFilter
                        :
                            props.companiesDataset.length > 0 
                            ?
                                props.companiesDataset[props.companiesDataset.length-1] 
                            : 
                                {companyName:'Todas', token: 'todas'}
                    }
                    // value=
                    // {
                    //     props.filters
                    //     ?
                    //         props.companiesDataset.find(x=>x.token==props.filters?.companyToken) ?? props.companiesDataset[props.companiesDataset.length-1] 
                    //     :
                    //         props.companiesDataset[props.companiesDataset.length-1] 
                    // }
                    renderOption=
                    {
                        (option) =>
                        {
                            return <div>{option.companyName}</div>
                        }
                    }
                    disableClearable
                    renderInput=
                    {
                        (params) =>
                        {
                            if(params.inputProps)
                            {
                                if((params.inputProps as any).value)
                                {
                                    let idxToSubstr = ((params.inputProps as any).value as string).lastIndexOf('(');;
                                    if(idxToSubstr > 0)
                                    {
                                        ((params.inputProps as any).value as string) = ((params.inputProps as any).value as string).substring(0, idxToSubstr-1);
                                    }
                                }
                            }
                            return <TextField 
                                        {...params} 
                                        variant='standard'
                                        label='Empresa'
                                    />;
                        }
                    }
                />
                {/** Response Code Section */}
                <Autocomplete
                    options={responseCodes}
                    getOptionLabel={(option) => `${option.title} (${option.value})`}
                    defaultValue=
                    {
                        currentResponseCodeFilter
                        ?
                            currentResponseCodeFilter
                        :
                            responseCodes[responseCodes.length - 1]
                    }
                    onChange={(_, value) => setCurrentResponseCodeFilter(value)}
                    // value={currentResponseCodeFilter ?? undefined}
                    // value=
                    // {
                    //     props.filters
                    //     ?
                    //         responseCodes.find(x=> x.value == props.filters?.responseCode) ?? undefined
                    //     :
                    //         undefined
                    // }
                    renderOption=
                    {
                        (option) =>
                        {
                            return <div>{option.title}</div>
                        }
                    }
                    disableClearable
                    // onBlur=
                    // {
                    //     (e) =>
                    //     {
                    //         console.log('a:'+e.target);
                    //     }
                    // }
                    renderInput=
                    {
                        (params) =>
                        {
                            if(params.inputProps)
                            {
                                if((params.inputProps as any).value)
                                {
                                    let idxToSubstr = ((params.inputProps as any).value as string).lastIndexOf('(');;
                                    if(idxToSubstr > 0)
                                    {
                                        ((params.inputProps as any).value as string) = ((params.inputProps as any).value as string).substring(0, idxToSubstr-1);
                                    }
                                }
                            }
                            return <TextField
                                    {...params} 
                                    variant='standard'
                                    label='Response Code' 
                                />;
                        }
                    }
                />
                {/** Date Section */}
                <div className={classes.dateSection}>
                    <LocalizationProvider  dateAdapter={AdapterDateFns}>

                        {/** Date Start */}
                        {/* <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-start"
                            label="Data Inicio"
                            value={selectedDateStart}
                            onChange={(date: Date | null)=>setSelectedDateStart(date)}
                            open={openStartDatePopup}
                            onClose={()=>setOpenStartDatePopup(false)}
                            onClick={() => setOpenStartDatePopup(true)}
                            KeyboardButtonProps=
                            {
                                {
                                    'aria-label': 'change date',
                                }
                            }
                        /> */}
                        <DesktopDatePicker
                            label="Data Inicio"
                            value={selectedDateStart}
                            onChange={(date: Date | null)=>setSelectedDateStart(date)}
                            renderInput=
                            {
                                (params) => 
                                {
                                    return <TextField 
                                                {...params} 
                                                variant='standard'
                                                onClick={() => setOpenStartDatePopup(true)}
                                            />
                                }
                            }
                            open={openStartDatePopup}
                            onClose={()=>setOpenStartDatePopup(false)}
                            
                        />
                        {/** Date End */}
                        {/* <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-end"
                            label="Data Fim"
                            value={selectedDateEnd}
                            onChange={(date: Date | null)=>setSelectedDateEnd(date)}
                            open={openEndDatePopup}
                            onClose={()=>setOpenEndDatePopup(false)}
                            onClick={() => setOpenEndDatePopup(true)}
                            KeyboardButtonProps=
                            {
                                {
                                    'aria-label': 'change date',
                                }
                            }
                        /> */}
                        <DesktopDatePicker
                            label="Data Fim"
                            value={selectedDateEnd}
                            onChange={(date: Date | null)=>setSelectedDateEnd(date)}
                            renderInput=
                            {
                                (params) => 
                                {
                                    return <TextField 
                                                {...params} 
                                                variant='standard'
                                                onClick={() => setOpenEndDatePopup(true)}
                                            />
                                }
                            }
                            open={openEndDatePopup}
                            onClose={()=>setOpenEndDatePopup(false)}
                        />
                    </LocalizationProvider >
                </div>
                {/** Actions Section */}
                <Autocomplete
                    options={props.actionsDataset}
                    getOptionLabel={(option) => option.action}
                    defaultValue=
                    {
                        currentActionFilter
                        ?
                            currentActionFilter
                        :
                            props.actionsDataset.length > 0 
                            ? 
                                props.actionsDataset[props.actionsDataset.length-1] 
                            : 
                                {action:'Todas'}
                    }
                    onChange={(_, value) => setCurrentActionFilter(value)}
                    renderOption=
                    {
                        (option, state) =>
                        {
                            return <div>{option.action}</div>
                        }
                    }
                    // value={currentActionFilter ?? undefined}
                    disableClearable
                    renderInput=
                    {
                        (params) =>
                        {
                            // if(params.inputProps)
                            // {
                            //     if((params.inputProps as any).value)
                            //     {
                            //         let idxToSubstr = ((params.inputProps as any).value as string).lastIndexOf('(');;
                            //         if(idxToSubstr > 0)
                            //         {
                            //             ((params.inputProps as any).value as string) = ((params.inputProps as any).value as string).substring(0, idxToSubstr-1);
                            //         }
                            //     }
                            // }
                            return <TextField 
                                        {...params} 
                                        variant='standard'
                                        label='Ações' 
                                    />;
                        }
                    }
                />
            </DialogContent>
            <DialogActions>
                <ButtonGroup variant='text'>
                    <Button
                        classes=
                        {
                            {
                                textSecondary: classes.buttonTextSecondary,
                            }
                        }
                        color='secondary'
                        onClick={(e: any) => closeHandler(e)}
                    >
                        {'Cancelar'}
                    </Button>
                    <Button 
                        classes=
                        {
                            {
                                // textPrimary: classes.buttonTextPrimary,
                                containedPrimary: classes.buttonContainedPrimary
                            }
                        }
                        variant='contained'
                        color='primary'
                        onClick=
                        {
                            (e: any) => 
                            {
                                // console.log(actionsRect.current);
                                var filters: ISyncLogFilter =
                                {
                                    companyToken: currentCompanyFilter?.token ?? undefined,
                                    companyName: currentCompanyFilter?.companyName ?? undefined,
                                    responseCode: currentResponseCodeFilter?.value ?? undefined,
                                    action: currentActionFilter?.action ?? undefined,
                                    startDate: selectedDateStart ?? undefined,
                                    endDate: selectedDateEnd ?? undefined
                                }
                                if(selectedDateStart)
                                {
                                    filters.startDate = selectedDateStart!.toISOString().substring(0, selectedDateStart!.toISOString().indexOf('T'));
                                }
                                if(selectedDateEnd)
                                {
                                    filters.endDate = selectedDateEnd!.toISOString().substring(0, selectedDateEnd!.toISOString().indexOf('T'));
                                }
                                closeHandler(e, filters);
                            }
                        }
                    >
                        {'Escolher'}
                    </Button>
                </ButtonGroup>
            </DialogActions>
        </Dialog>
    );
}

const responseCodes = 
[
    //100
    {
        title: 'Continue',
        value: 100
    },
    {
        title: 'Switching Protocols',
        value: 101
    },
    {
        title: 'Processing',
        value: 102
    },
    // 200
    {
        title: 'OK',
        value: 200
    },
    {
        title: 'Created',
        value: 201
    },
    {
        title: 'Accepted',
        value: 202
    },
    {
        title: 'Non-authoritative Information',
        value: 203
    },
    {
        title: 'No Content',
        value: 204
    },
    {
        title: 'Reset Content',
        value: 205
    },
    {
        title: 'Partial Content',
        value: 206
    },
    {
        title: 'Multi-Status',
        value: 207
    },
    {
        title: 'Already Reported',
        value: 208
    },
    {
        title: 'IM Used',
        value: 226
    },
    // 300
    {
        title: 'Multiple Choices',
        value: 300
    },
    {
        title: 'Moved Permanently',
        value: 301
    },
    {
        title: 'IM Used',
        value: 302
    },
    {
        title: 'See Other',
        value: 303
    },
    {
        title: 'Not Modified',
        value: 304
    },
    {
        title: 'Use Proxy',
        value: 305
    },
    {
        title: 'Temporary Redirect',
        value: 307
    },
    {
        title: 'Permanent Redirect',
        value: 308
    },
    // 400
    {
        title: 'Bad Request',
        value: 400
    },
    {
        title: 'Unauthorized',
        value: 401
    },
    {
        title: 'Payment Required',
        value: 402
    },
    {
        title: 'Forbidden',
        value: 403
    },
    {
        title: 'Not Found',
        value: 404
    },
    {
        title: 'Method Not Allowed',
        value: 405
    },
    {
        title: 'Not Acceptable',
        value: 406
    },
    {
        title: 'Proxy Authentication Required',
        value: 407
    },
    {
        title: 'Request Timeout',
        value: 408
    },
    {
        title: 'Conflict',
        value: 409
    },
    {
        title: 'Gone',
        value: 410
    },
    {
        title: 'Length Required',
        value: 411
    },
    {
        title: 'Precondition Failed',
        value: 412
    },
    {
        title: 'Payload Too Large',
        value: 413
    },
    {
        title: 'Request-URI Too Long',
        value: 414
    },
    {
        title: 'Unsupported Media Type',
        value: 415
    },
    {
        title: 'Requested Range Not Satisfiable',
        value: 416
    },
    {
        title: 'Expectation Failed',
        value: 417
    },
    {
        title: 'I\'m a teapot',
        value: 418
    },
    {
        title: 'Misdirected Request',
        value: 421
    },
    {
        title: 'Unprocessable Entity',
        value: 422
    },
    {
        title: 'Locked',
        value: 423
    },
    {
        title: 'Failed Dependency',
        value: 424
    },
    {
        title: 'Upgrade Required',
        value: 426
    },
    {
        title: 'Precondition Required',
        value: 428
    },
    {
        title: 'Too Many Requests',
        value: 429
    },
    {
        title: 'Request Header Fields Too Large',
        value: 431
    },
    {
        title: 'Connection Closed Without Response',
        value: 444
    },
    {
        title: 'Unavailable For Legal Reasons',
        value: 451
    },
    {
        title: 'Client Closed Request',
        value: 499
    },
    // 500
    {
        title: 'Internal Server Error',
        value: 500
    },
    {
        title: 'Not Implemented',
        value: 501
    },
    {
        title: 'Bad Gateway',
        value: 502
    },
    {
        title: 'Service Unavailable',
        value: 503
    },
    {
        title: 'Gateway Timeout',
        value: 504
    },
    {
        title: 'HTTP Version Not Supported',
        value: 505
    },
    {
        title: 'Variant Also Negotiates',
        value: 506
    },
    {
        title: 'Insufficient Storage',
        value: 507
    },
    {
        title: 'Loop Detected',
        value: 508
    },
    {
        title: 'Not Extended',
        value: 510
    },
    {
        title: 'Network Authentication Required',
        value: 511
    },
    {
        title: 'Network Connect Timeout Error',
        value: 599
    },
    {
        title: 'Todos',
        value: -1
    }
]
import { Bar, Scatter } from 'react-chartjs-2';

import 
{
    Select,
    MenuItem,
    Divider,
    // TextField,
    // Box,
    Button
} from '@mui/material';
import Skeleton from '@material-ui/lab/Skeleton';

import React,
{ 
    useState,
} from 'react';
import { History, LocationState } from "history";

import 
{ 
    makeStyles 
} from '@material-ui/core/styles';

import 
{
    ListItem,
    List
} from '@material-ui/core';

import 
{ 
    DateRange, 
} from '@mui/lab/DateRangePicker';

import
{
    DesktopDateRangePicker,
    DateRangePickerDayProps, 
    DateRangePickerDay
} from '@mui/lab';

// import { TableCell, TableSortLabel } from '@material-ui/core';
import ScreenAnalyticsEnhancedTable, { IScreenAnalytics } from '../../ScreenAnalyticsEnhancedTable';

interface IAppScreenAnalyticsProps
{
    history?: History<LocationState>,
    classes?: any,
}

// interface IAppScreenAnalyticsState 
// {
    
// }

const useStyles = makeStyles
(
    {
        root: 
        {
            padding: '10px',
            height: '100%',
            // width: '100%'
        },
        visuallyHidden: 
        {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        selectRoot:
        {
            color: '#FFFFFF !important',
            '& fieldset':
            {
                borderColor: 'rgba(255, 255, 255, 0.23) !important'
            },
            '& svg':
            {
                color: 'white',
            }
        },
        filtersArea:
        {
            marginBottom: '5px',
            display: 'flex',
            flexDirection: 'row-reverse',
            '& *:not(:first-child)':
            {
                marginLeft: '5px'
            }
        },
        menu:
        {
            backgroundColor: '#232427 !important',
            color: '#FFF !important'
        },
        analyticChartSkeletonFitContent:
        {
            maxWidth: '100%',
            width: '100%'
        },
        analyticChartSkeletonText:
        {
            height: 'auto',
            transform: 'scale(1, 1)',
            marginTop: 0,
            borderRadius: '4px',
            marginBottom: 0,
            transformOrigin: '0 60%'
        },
        analytics:
        {
            backgroundColor: '#202225',
            border: '1px #1B1B1B solid',
            borderRadius: '5px',
            padding: '5px',
            '& div:not(:first-child)':
            {
                marginTop: '10px'
            }
        },
        chartRoot:
        {
            width: '100%',
            display: 'flex',
            flexDirection: 'column' as const
        },
        innerRoot:
        {
            display: 'flex',
            flexDirection: 'row' as const,
            padding: '15px',
            '& > *':
            {
                width: '50%'
            },
        },
        chartHeader:
        {
            display: 'flex',
            flexDirection: 'row' as const,
            justifyContent: 'space-between',
            '& h3':
            {
                margin: '0'
            }
        },
        titleStyle:
        {
            color: 'white',
            fontSize: '1em',
            paddingTop: '10px',            
        },
        dividerRoot:
        {
            backgroundColor: 'rgba(255, 255, 255, 0.12)'
        },
        dateRangePicker:
        {
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center'
        },
        dateRangePickerRoot:
        {
            '& .MuiPaper-root':
            {
                color: '#FFFFFF',
                backgroundColor: 'rgb(14, 15, 15)',
            },
            '& .MuiPickersCalendarHeader-dayLabel':
            {
                color: '#FFFFFF80'
            },
            '& .MuiPickersDay-root':
            {
                backgroundColor: 'transparent'
            },
            '& .MuiTypography-root':
            {
                color: 'rgb(232, 230, 227)'
            },
            '& .PrivatePickersYear-yearButton:disabled':
            {
                color: 'rgba(255, 255, 255, 0.54) !important',
            },
            '& .MuiPaper-root > div > div > div:not(:last-of-type)':
            {
                borderRight: '2px solid rgba(48, 52, 54, 0.12) !important'
            }
        },
        datePickerIconButton:
        {
            color: '#FFFFFF !important',
            // backgroundColor: '#424242 !important',
            '&:disabled':
            {
                color: 'rgba(255, 255, 255, 0.54) !important',
            }
        },
        datePickerDay:
        {
            color: '#FFFFFF'
        },
        // chartsRoot:
        // {
        //     display: 'flex',
        //     flexDirection: 'row',
        //     // '& *':
        //     // {
        //     //     width: '50%'
        //     // },
        //     // '&:not(:first-child)':
        //     // {
        //     //     marginLeft: '50px'
        //     // }
        // }
    }
);

interface IAnalyticsFilter
{
    id: string,
    app: string
}

export default function AppScreenAnalytics(props: IAppScreenAnalyticsProps)
{
    const classes = useStyles();
    // const [isLogsLoading, setIsAnalyticsLoading] = useState<boolean>(false);
    const [isAnalyticsLoading, setIsAnalyticsLoading] = useState<boolean>(false);
    const [screenAnalytics, setScreenAnalytics] = useState<Array<IScreenAnalytics>>([]);
    const [analyticsFilters, setAnalyticsFilters] = useState<Array<IAnalyticsFilter>>([{app: 'Todos', id: 'todos'}]);
    const [analyticsFilter, setAnalyticsFilter] = useState<string>('todos');
    const [chartsAnalyticsLabel, setChartsAnalyticsLabel] = useState<Array<string>>([]);
    const [chartsAnalyticsData, setChartsAnalyticsData] = useState<Array<number>>([]);
    const [chartsAnalyticsScatterData, setChartsAnalyticsScatterData] = useState<Array<{x:number, y: number}>>([]);
    const [dateRangeFilterValue, setDateRangeFilterValue] = React.useState<DateRange<Date>>([new Date(Date.now()-(1000*60*60*24*28)), new Date()]);
    // const [dateRangeFilterHasChanged, setDateRangeFilterHasChanged] = React.useState<boolean>(false);
    
    const [openDateRange, setOpenDateRange] = useState<boolean>(false);

    const maxBarChart = 5;

    React.useEffect
    (
        () =>
        {
            var searchParams = new URLSearchParams(window.location.search);
            let dateStart: Date | null = null;
            let dateEnd: Date | null = null;
            var dateStartStr = searchParams.get('dateStart');
            var dateEndStr = searchParams.get('dateEnd');
            var app = searchParams.get("app");
            if(dateStartStr)
            {
                var parts = dateStartStr.split('-');
                if(parts.length >= 3)
                {
                    dateStart = new Date(Date.UTC(Number(parts[0]), Number(parts[1]), Number(parts[2])));
                }
            }
            if(dateEndStr)
            {
                var parts2 = dateEndStr.split('-');
                if(parts2.length >= 3)
                {
                    dateEnd = new Date(Date.UTC(Number(parts2[0]), Number(parts2[1]), Number(parts2[2])));
                }
            }
            dateStart = dateStart ?? dateRangeFilterValue[0];
            dateEnd = dateEnd ?? dateRangeFilterValue[1];

            setDateRangeFilterValue
            (
                [
                    dateStart,
                    dateEnd,
                ]
            )
            setAnalyticsFilter(app ?? analyticsFilter);
            getScreenAnalytics();
            getAnalyticsFilters();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    function getScreenAnalytics()
    {
        setIsAnalyticsLoading(true);
        let dayFilter = '';
        if(!dateRangeFilterValue.includes(null))
        {   
            dateRangeFilterValue[0]?.toJSON().substring(0, dateRangeFilterValue[0]?.toJSON().indexOf('T'));

            dayFilter = `startDate=${dateRangeFilterValue[0]?.toJSON().substring(0, dateRangeFilterValue[0]?.toJSON().indexOf('T'))}&endDate=${dateRangeFilterValue[1]?.toJSON().substring(0, dateRangeFilterValue[1]?.toJSON().indexOf('T'))}`
        }
        fetch
        (
            `/api/google/screen_views/app?streamId=${analyticsFilter}${dayFilter.length > 0 ? `&${dayFilter}` : ''}`, 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    var data = await response.json();
                    var dataArray: Array<IScreenAnalytics> = [];
                    for(let i = 0; i < data.length; i++)
                    {
                        dataArray.push
                        (
                            {
                                screen_name: data[i].screen_name,
                                userEngagementDuration: data[i].data.userEngagementDuration,
                                totalUsers: data[i].data.totalUsers,
                                screenPageViews: data[i].data.screenPageViews
                            }
                        )
                    }
                    var arrayLabel = [];
                    var arrayData = [];
                    var scatterData = [];
                    if(dataArray.length > 0)
                    {
                        dataArray.sort((a, b)=> Number(b.screenPageViews) - Number(a.screenPageViews));
                        
                        for(let i = 0; i < maxBarChart; i++)
                        {
                            arrayLabel.push(dataArray[i].screen_name);
                            arrayData.push(Number(dataArray[i].screenPageViews));
                            scatterData.push
                            (
                                {
                                    x: Number(dataArray[i].screenPageViews),
                                    y: Number(dataArray[i].totalUsers)
                                }
                            )
                        }
                    }
                    setChartsAnalyticsData(arrayData);
                    setChartsAnalyticsLabel(arrayLabel);
                    setChartsAnalyticsScatterData(scatterData);
                    setScreenAnalytics(dataArray);
                    setIsAnalyticsLoading(false);
                }
            } 
        );
    }

    function getAnalyticsFilters()
    {
        setIsAnalyticsLoading(true);
        fetch
        (
            `/api/google/analytic/apps`, 
            {
                method: "GET",
                headers: 
                {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
            }
        )
        .then
        (
            async (response) =>
            {
                if(response.status === 200)
                {
                    var data = await response.json();
                    var dataArray: Array<IAnalyticsFilter> = [];
                    dataArray.push
                    (
                        {
                            app: 'Todos',
                            id: 'todos'
                        }
                    )
                    for(let i = 0; i < data.length; i++)
                    {
                        dataArray.push
                        (
                            {
                                app: data[i].app,
                                id: data[i].id,
                            }
                        )
                    }
                    setAnalyticsFilters(dataArray);
                    setIsAnalyticsLoading(false);
                }
            } 
        );
    }

    // const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    // const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => 
    // {
    //     onRequestSort(event, property);
    // };

    return (
        <div>
            <div className={classes.filtersArea}> 
                <Select
                    label="Age"
                    value={analyticsFilter}
                    onChange=
                    {
                        (e) => 
                        {
                            // console.log(e.target.value);
                            setAnalyticsFilter(e.target.value);
                            if(props.history !== undefined)
                            {
                                var searchParams = new URLSearchParams(window.location.search);
                                var url = '';
                                if(e.target.value !== 'todos')
                                {
                                    searchParams.set("app", `${e.target.value}`);    
                                }
                                else
                                {
                                    searchParams.delete('app');
                                }
                                url = window.location.pathname + '?' + searchParams.toString();
                                props.history.push(url);
                            }
                            getScreenAnalytics();
                        }
                    }
                    classes=
                    {
                        {
                            root: classes.selectRoot,
                        }
                    }
                    MenuProps=
                    {
                        {
                            PopoverClasses:
                            {
                                paper: classes.menu
                            }
                        }
                    }
                >
                    {
                        analyticsFilters.map
                        (
                            (filter) =>
                            {
                                // console.log(filter);
                                return ( 
                                    <MenuItem key={`analytics-filter-${filter.id}`} value={filter.id}>{filter.app}</MenuItem>
                                );
                            }
                        )
                    }
                </Select>
                <div
                    className={classes.dateRangePicker}
                >
                    <DesktopDateRangePicker 
                        startText="Inicio"
                        endText='Fim'
                        value={dateRangeFilterValue}
                        open={openDateRange}
                        PopperProps=
                        {
                            {
                                className: classes.dateRangePickerRoot,
                                // anchorOrigin: 
                                // {
                                //     vertical: -50,
                                //     horizontal: 198
                                // },
                                // PaperProps:
                                // {
                                //     classes:
                                //     {
                                //         root: this.classes.datePickerPaperRoot,
                                //     }
                                // }
                            }
                        }
                        onChange=
                        {
                            (newValue) => 
                            {
                                setDateRangeFilterValue(newValue);
                                // setDateRangeFilterHasChanged(true);
                                
                            }
                        }
                        onClose=
                        {
                            ()=>
                            {
                                setOpenDateRange(false);
                                // if(dateRangeFilterHasChanged)
                                // {
                                //     console.log(dateRangeFilterValue);
                                //     setDateRangeFilterHasChanged(false);
                                //     getScreenAnalytics();
                                // }
                            }
                        }
                        onAccept=
                        {
                            (dateRange)=> 
                            {
                                if(props.history !== undefined && dateRange.length >= 2)
                                {
                                    var searchParams = new URLSearchParams(window.location.search)
                                    var url = '';
                                    searchParams.set("dateStart", `${dateRange[0]?.toJSON().substring(0, dateRange[0]?.toJSON().indexOf('T'))}`); 
                                    searchParams.set("dateEnd", `${dateRange[1]?.toJSON().substring(0, dateRange[1]?.toJSON().indexOf('T'))}`); 
                                    url = window.location.pathname + '?' + searchParams.toString();
                                    props.history.push(url);
                                    getScreenAnalytics();
                                }
                            }
                        }
                        renderInput=
                        {
                            (startProps, endProps) => 
                            {
                                let formattedDate = '';
                                let dateStart = dateRangeFilterValue[0];
                                let dateEnd = dateRangeFilterValue[1];
                                if(dateStart?.getFullYear() !== dateEnd?.getFullYear())
                                {
                                    formattedDate = dateStart?.toLocaleString
                                    (
                                        'pt-BR', 
                                        {
                                            month: 'long', // numeric, 2-digit, long, short, narrow
                                            day: '2-digit', // numeric, 2-digit
                                            year: 'numeric', // numeric, 2-digit// numeric, 2-digit
                                        }
                                    ) ?? '';
                                }
                                else
                                {
                                    formattedDate = dateStart?.toLocaleString
                                    (
                                        'pt-BR', 
                                        {
                                            month: 'long', // numeric, 2-digit, long, short, narrow
                                            day: '2-digit', // numeric, 2-digit
                                        }
                                    ) ?? '';
                                }
                                formattedDate = `${formattedDate} - ${dateEnd?.toLocaleString
                                (
                                    'pt-BR', 
                                    {
                                        month: 'long', // numeric, 2-digit, long, short, narrow
                                        day: '2-digit', // numeric, 2-digit
                                        year: 'numeric', // numeric, 2-digit// numeric, 2-digit
                                    }
                                )}`;
                                return (
                                    <React.Fragment>
                                        <Button
                                            variant="text"
                                            style=
                                            {
                                                {
                                                    textTransform: 'capitalize',
                                                    color: 'white',
                                                    fontWeight: 'bold',
                                                    textAlign: 'right'
                                                }
                                            }
                                            onClick=
                                            {
                                                ()=>
                                                {
                                                    setOpenDateRange(true);
                                                }
                                            }
                                        >
                                            {
                                                formattedDate
                                            }
                                        </Button>

                                        {/* <TextField {...startProps} />
                                        <Box sx={{ mx: 2 }}> até </Box>
                                        <TextField {...endProps} /> */}
                                    </React.Fragment>
                                );
                            }
                        }
                        componentsProps=
                        {
                            {
                                leftArrowButton:
                                {
                                    classes:
                                    {
                                        root: classes.datePickerIconButton
                                    }
                                },
                                rightArrowButton:
                                {
                                    classes:
                                    {
                                        root: classes.datePickerIconButton
                                    }   
                                },
                                switchViewButton:
                                {
                                    classes:
                                    {
                                        root: classes.datePickerIconButton
                                    }   
                                }
                            }
                        }
                        renderDay=
                        {
                            (
                                date: Date,
                                dateRangePickerDayProps: DateRangePickerDayProps<Date>
                            ) => 
                            {
                                
                                return (
                                    <DateRangePickerDay 
                                        {...dateRangePickerDayProps as DateRangePickerDayProps<Date>}
                                        style=
                                        {
                                            dateRangePickerDayProps.selected
                                            ?
                                                {
                                                    color: 'rgb(232, 230, 227)',
                                                    backgroundColor: 'rgb(6, 66, 115)'
                                                        
                                                }
                                            :
                                                {
                                                    color: '#FFFFFF'
                                                }
                                        }
                                    />
                                );
                            }
                        }
                    />
                </div>
            </div>
            {
                isAnalyticsLoading
                ?
                    <List>
                        <ListItem>
                            <Skeleton 
                                animation="wave" 
                                classes=
                                {
                                    {
                                        fitContent: classes.analyticChartSkeletonFitContent,
                                        text: classes.analyticChartSkeletonText
                                    }
                                }
                            >
                                <div 
                                    style=
                                    {
                                        {
                                            minHeight: '825px',
                                            borderRadius: '4px',
                                            minWidth: '100%'
                                        }
                                    }
                                >

                                </div>
                            </Skeleton> 
                        </ListItem>
                    </List>
                :   
                    (
                        <div className={classes.analytics}>
                            <div id={'chartsection'} className={classes.chartRoot}>
                                <div className={classes.innerRoot}>
                                    <div>
                                        <div className={classes.chartHeader}>
                                            <h3 className={classes.titleStyle}>
                                                {`Views/Tela`}
                                            </h3>
                                        </div>
                                        
                                        <Bar  
                                            style=
                                            {
                                                {
                                                    maxHeight: '450px',
                                                    // maxWidth: '50%'
                                                }
                                            }
                                            width={0}
                                            height={200}
                                            options=
                                            {
                                                {
                                                    maintainAspectRatio: true,
                                                    responsive: true,
                                                    indexAxis: 'y',
                                                    plugins:
                                                    {
                                                        title:
                                                        {
                                                            display: false,
                                                        },                      
                                                        legend:
                                                        {
                                                            display: false,
                                                        },
                                                        tooltip: 
                                                        {
                                                            callbacks: 
                                                            {
                                                                label: function(context: any) 
                                                                {
                                                                    return `${context.dataset.label} ${context.parsed.x}`;
                                                                }
                                                            }
                                                        },
                                                    },
                                                    scales: 
                                                    {
                                                        y: 
                                                        {
                                                            min: 0,
                                                            ticks: 
                                                            {
                                                                stepSize: 1,
                                                                color: '#FFF'
                                                            }
                                                        },
                                                        x:
                                                        {
                                                            ticks:
                                                            {
                                                                color: '#FFF'
                                                            }
                                                        }
                                                    },
                                                }
                                            }
                                            data=
                                            {
                                                {
                                                    labels: chartsAnalyticsLabel,
                                                    datasets: 
                                                    [
                                                        {
                                                            data: chartsAnalyticsData,
                                                            backgroundColor: '#A6CEE3',
                                                            borderColor: [ '#A6CEE3' ],
                                                            borderWidth: 1,
                                                            label: 'Views'
                                                        }
                                                    ]
                                                }
                                            }
                                            // data={this.state.data}
                                        />
                                    </div>
                                    <div>
                                        <div className={classes.chartHeader}>
                                            <h3 className={classes.titleStyle}>
                                                {`Views e Usuário`}
                                            </h3>
                                        </div>
                                        <Scatter  
                                            style=
                                            {
                                                {
                                                    maxHeight: '450px',
                                                    // maxWidth: '50%'
                                                }
                                            }
                                            height={200}
                                            width={0}
                                            options=
                                            {
                                                {
                                                    maintainAspectRatio: true,
                                                    responsive: true,
                                                    plugins:
                                                    {   
                                                        title:
                                                        {
                                                            display: false,
                                                        },                      
                                                        legend:
                                                        {
                                                            display: false,
                                                        },
                                                        tooltip: 
                                                        {
                                                            footerFont:
                                                            {
                                                                weight: 'normal'
                                                            },
                                                            bodyFont:
                                                            {
                                                                weight: 'bold'
                                                            },
                                                            displayColors: false,
                                                            bodySpacing: 10,
                                                            callbacks: 
                                                            {
                                                                title: function(context: any)
                                                                {
                                                                    return 'Views';
                                                                },
                                                                label: function(context: any) 
                                                                {
                                                                    return `${context.parsed.x}`;
                                                                },
                                                                afterBody: function(context: any)
                                                                {
                                                                    return 'Usuários';
                                                                },
                                                                footer: function(context: any)
                                                                {
                                                                    if(context.length > 0)
                                                                    {
                                                                        return `${context[0].parsed.y}`;
                                                                    }
                                                                    return '';
                                                                },
                                                            }
                                                        }
                                                    },
                                                    // hover: 
                                                    // {
                                                    //     animationDuration: 0, // duration of animations when hovering an item
                                                    // },
                                                    // responsiveAnimationDuration: 0,
                                                    animation: 
                                                    {
                                                        duration: 0,
                                                        onComplete: function(this: any) 
                                                        {
                                                            var chartInstance = this;
                                                            var ctx = this.ctx;
                                         
                                                            // ctx.font = Chart.helpers.fontString
                                                            // (
                                                            //     18,
                                                            //     Chart.defaults.global.defaultFontStyle,
                                                            //     Chart.defaults.global.defaultFontFamily
                                                            // );
                                                            ctx.textAlign = "center";
                                                            ctx.textBaseline = "bottom";
                                         
                                                            this.data.datasets.forEach
                                                            (
                                                                function(dataset: any, i: any) 
                                                                {
                                                                    const meta = chartInstance.getDatasetMeta(i);
                                                                    // console.log(meta);
                                                                    // console.log(dataset);
                                                                    meta.data.forEach
                                                                    (
                                                                        function(point: any, index: any) 
                                                                        {
                                                                            // const data = point[i];
                                                                            if(screenAnalytics.length > 0)
                                                                            {
                                                                                ctx.fillStyle = "#fff";
                                                                                ctx.fillText(screenAnalytics[index].screen_name, point.x, point.y + 20);
                                                                            }
                                                                        }
                                                                    );
                                                                }
                                                            );
                                                        }
                                                    },
                                                    // tooltips: 
                                                    // {
                                                    //     enabled: true
                                                    // },
                                                    scales: 
                                                    {
                                                        y: 
                                                        {
                                                            min: 0,
                                                            title:
                                                            {
                                                                display: true,
                                                                text: 'Usuários',
                                                                color: '#FFF'
                                                            },
                                                            ticks: 
                                                            {
                                                                stepSize: 1,
                                                                color: '#FFF'
                                                            }
                                                        },
                                                        x:
                                                        {
                                                            min: 0,
                                                            title:
                                                            {
                                                                display: true,
                                                                text: 'Views',
                                                                color: '#FFF'
                                                            },
                                                            ticks:
                                                            {
                                                                color: '#FFF'
                                                            }
                                                        }
                                                    },
                                                }
                                            }
                                            data=
                                            {
                                                {
                                                    labels: 
                                                    [
                                                        'aa',
                                                        'bb',
                                                        'cc',
                                                        'dd',
                                                        'ee'
                                                    ],
                                                    datasets: 
                                                    [
                                                        {
                                                            data: chartsAnalyticsScatterData,
                                                            backgroundColor: '#A6CEE3',
                                                            borderColor: [ '#A6CEE3' ],
                                                            borderWidth: 1,
                                                            label: 'Views/Usuários'
                                                        }
                                                    ]
                                                }
                                            }
                                            // data={this.state.data}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Divider
                                classes=
                                {
                                    {
                                        root: classes.dividerRoot
                                    }
                                }
                            />
                            <ScreenAnalyticsEnhancedTable
                                rows={screenAnalytics}
                            />
                        </div>
                    )
                    
            }
            
        </div>
    );
}